<template>
  <v-container fluid>
    <v-card
      tile
      min-height="100%"
      class="text-left"
      :style="'height:' + this.height + 'px; overflow: auto;'"
    >
      <v-card-title
        ><h1 class="h1">Custom Client Config Options</h1></v-card-title
      >
      <v-card-text class="mt-3">
        This is where you can view, create, edit and delete client config
        options.
      </v-card-text>
      <v-card-actions>
        <v-divider></v-divider>
      </v-card-actions>
      <v-card-actions style="width: 80%">
        <v-select
          v-model="clientConfigOption"
          :items="allClientConfigOptions"
          item-title="name"
          return-object
          placeholder="Select client config option"
          density="compact"
          rounded="0"
          variant="outlined"
          clearable
        >
        </v-select>
        <!-- create new client config option -->
        <ClientConfigOptionCreate class="mb-6 ml-3" />
      </v-card-actions>
      <ClientConfigOptionEdit
        v-if="clientConfigOption"
      ></ClientConfigOptionEdit>
    </v-card>
  </v-container>
</template>

<script>
import ClientConfigOptionCreate from "@/components/ClientConfigOptionCreate";
import ClientConfigOptionEdit from "@/components/ClientConfigOptionEdit";

export default {
  name: "ClientConfigOptions",
  components: {
    ClientConfigOptionCreate,
    ClientConfigOptionEdit,
  },
  data: () => ({
    tableHeaders: [
      {
        text: "Name",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Slug", value: "slug" },
      { text: "Description", value: "description" },
    ],
    editMode: false,
  }),
  computed: {
    height() {
      return 1000;
    },
    clientConfigOption: {
      get() {
        return this.$store.state.clientConfigOption;
      },
      set(value) {
        this.$store.commit("setClientConfigOption", value);
      },
    },
    allClientConfigOptions: {
      get() {
        return this.$store.state.allClientConfigOptions;
      },
      set(value) {
        this.$store.commit("setAllClientConfigOptions", value);
      },
    },
  },
  mounted() {
    this.getClientConfigOptions();
  },
  methods: {
    getClientConfigOptions() {
      this.$axios
        .get("/client-config-options")
        .then(
          function (response) {
            // handle success
            this.allClientConfigOptions = response.data;
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            this.emit.emit("systemMessage", {
              title: "Error",
              message: error.response.data.message,
              timeout: -1,
              colour: "error",
            });
            console.error(error);
          }.bind(this),
        );
    },
  },
};
</script>

<style scoped></style>
