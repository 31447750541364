<template>
  <div>
    <GoogleMap />
  </div>
</template>

<script>
import GoogleMap from "@/components/GoogleMap.vue";

export default {
  name: "map_page",
  data: () => ({}),
  components: {
    GoogleMap,
  },
  props: {
    model: null,
    item: {},
    disable: {
      type: Boolean,
      required: false,
      default: false,
    },
    relatedModelResults: {},
  },
  mounted() {},
  methods: {},
  watch: {},
};
</script>

<style scoped></style>
