<template>
  <v-dialog
    v-model="computedNavigateDialog"
    class="navigateElement"
    @keydown.esc="closeDialog()"
    id="navigateDialog"
  >
    <v-card>
      <v-toolbar
        :style="
          'background-color: ' +
          this.$store.state.config.siteConfig.toolbar_colour
        "
        class="text-h6"
      >
        <v-btn icon dark tile @click="closeDialog()" aria-label="Close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title> Search </v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-text-field
          id="searchField"
          ref="searchField"
          v-model="searchString"
          :append-icon="'mdi-map-search-outline'"
          clear-icon="mdi-close-circle"
          clearable
          label="Search for Location..."
          @click:append="SearchForLocation()"
          @keydown.enter="SearchForLocation()"
          :rules="[searchResultRule]"
          variant="outlined"
          density="compact"
          rounded="0"
          class="fields"
          autocomplete="off"
        >
        </v-text-field>
        <v-expand-transition>
          <v-list v-if="results">
            <v-list-item
              v-for="(result, index) in results"
              :key="index"
              @click="navigateToResult(result)"
            >
              <v-list-item-title class="searchOption">{{
                result.formatted_address
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-expand-transition>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
/* global google */

export default {
  name: "MAPNAVIGATE",
  data: () => ({
    searchString: null,
    geocoder: null,
    searchError: false,
    results: null,
  }),
  computed: {
    regions() {
      return JSON.parse(process.env.VUE_APP_REGIONS)[
        this.$store.state.config.siteConfig.site_country
      ];
    },
    searchResultRule() {
      return () => !this.searchError || "Could not find location";
    },
    computedNavigateDialog: {
      get() {
        return this.mapNavigateDialog;
      },
      set(val) {
        this.$emit("update:mapNavigateDialog", val);
      },
    },
    navigateResult: {
      get() {
        return this.$store.state.navigateResult;
      },
      set(value) {
        this.$store.commit("setNavigateResult", value);
      },
    },
  },
  components: {},
  props: {
    mapNavigateDialog: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  mounted() {},
  methods: {
    closeDialog() {
      this.computedNavigateDialog = false;
      this.searchString = null;
      this.searchError = false;
      this.results = null;
    },
    navigateToResult(result) {
      this.navigateResult = result.geometry.location;
      this.closeDialog();
    },
    SearchForLocation() {
      // make a new geocoder
      this.geocoder = new google.maps.Geocoder();

      // get the region and bounds for the geocoder search
      let region = this.regions.region;
      let iso3166CtryCode = this.regions.iso3166;

      // run the search string and find the address within the UK boundaries
      this.geocoder.geocode(
        {
          address: this.searchString + ", " + region,
          componentRestrictions: { country: iso3166CtryCode }, // set limit on country
        },
        (results, status) => {
          // if we find it, store it, so the map can find the change and zoom there
          if (status === "OK") {
            // Filter results to only include those within the specified country
            const filteredResults = results.filter((result) => {
              return result.address_components.some(
                (component) => component.short_name === region,
              );
            });

            if (filteredResults.length == 1) {
              this.navigateToResult(filteredResults[0]);
            } else {
              this.results = filteredResults;
            }
            this.searchString = null;
            this.searchError = false;
          } else if (status === "ZERO_RESULTS") {
            this.searchError = true;
            this.$refs.searchField.validate();
          } else {
            console.log("Geocode was not successful");
          }
        },
      );
    },
  },
  watch: {},
};
</script>

<style scoped>
.navigateElement {
  position: absolute;
  margin: auto;
  width: 50%;
  top: 20px;
  z-index: 1;
  max-width: 80%;
}

.searchOption {
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
