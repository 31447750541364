<template>
  <!-- class="ma-0 pa-0" fill-height -->
  <div>
    <v-progress-circular
      :rotate="360"
      :size="100"
      :width="15"
      :value="import_details.import_percentage"
      color="#51627C"
    >
      {{ import_details.import_percentage }}%
    </v-progress-circular>
    <v-card-text v-if="!import_complete"
      >Importing {{ import_details.name }}...</v-card-text
    >
    <v-card-text v-if="import_complete">Complete</v-card-text>
  </div>
</template>

<script>
export default {
  name: "ProgressTracker",
  data: () => ({
    import_complete: false,
    progress_check_limit: 0,
    check_progress_flag: true,
    timer: null,
  }),
  components: {},
  props: {
    import_details: {},
  },
  mounted() {},
  methods: {
    checkProgress() {
      clearTimeout(this.timer);
      if (this.import_complete || this.progress_check_limit > 9999999999) {
        this.$emit("complete");
        return;
      }

      // get progress
      this.$axios
        .get("/get-progress/" + this.import_details.puid)
        .then(
          function (response) {
            // handle success

            clearTimeout(this.timer);
            this.progress_check_limit++;

            this.import_complete = response.data.complete;

            if (response.data.current_total) {
              // eslint-disable-next-line vue/no-mutating-props
              this.import_details.import_percentage = Math.round(
                (response.data.current_total / response.data.expected_total) *
                  100,
              );
            }

            this.timer = setTimeout(
              function () {
                this.checkProgress();
              }.bind(this),
              3000,
            );
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
          }.bind(this),
        );
    },
  },
  watch: {
    import_details: {
      immediate: true,
      deep: true,
      handler() {
        if (this.import_details.start) {
          this.timer = setTimeout(
            function () {
              this.checkProgress();
            }.bind(this),
            3000,
          );
        }
      },
    },
  },
};
</script>

<style scoped></style>
