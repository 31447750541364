export const localInsightLightTheme = {
  dark: false,
  colors: {
    primary: "#0E5B99",
    success: "#265928",
    error: "#B00020",
  },
  variables: {
    /**
     * colour variables for inverted components e.g.
     * dark text on light background such as chips.
     *
     * on-inverted-*** for text
     * inverted-*** for background
     **/
    "on-inverted-success": "#1A3E1C",
    "inverted-success": "#C6ECC6",

    "on-inverted-error": "#7B0016",
    "inverted-error": "#F8D3D6",

    "on-inverted-default": "#4D4D4D",
    "inverted-default": "#F9F9F9",
  },
};
