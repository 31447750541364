<template>
  <div style="display: contents">
    <slot name="activator" :onClick="openDialogue"></slot>
    <v-dialog v-model="dialogue" scrollable persistent max-width="500px">
      <v-card>
        <v-toolbar
          :color="$store.state.config.siteConfig.toolbar_colour"
          dark
          class="text-h5 pr-5"
          max-height="64px"
        >
          <v-btn
            variant="text"
            id="closePostcodesDialogButton"
            icon="mdi-close"
            dark
            @click="dialogue = false"
            aria-label="Close"
          />
          <v-toolbar-title
            >Export {{ category?.name || customArea?.name }}</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text
          class="text-center"
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            height: 8vh;
            min-height: 85px;
          "
        >
          <v-row>
            <v-col>
              <v-btn
                color="primary"
                tile
                variant="elevated"
                @click="exportGeoJson"
                :loading="exportingGeoJson"
                prepend-icon="mdi-map-outline"
                aria-label="Export GeoJSON"
              >
                export geoJSON
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                color="primary"
                tile
                :loading="exportingPostcodes"
                variant="elevated"
                @click="exportPostcodes"
                prepend-icon="mdi-map-marker"
                aria-label="Export Postcodes"
              >
                export postcodes
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { exportCSVFile } from "@/mixins/ExportCSVFile";

export default {
  name: "ExportCustomAreas",
  data: () => ({
    dialogue: false,
    exportingGeoJson: false,
    exportingPostcodes: false,
  }),
  props: ["category", "customArea"],
  methods: {
    openDialogue() {
      if (this.$store.state.config.siteConfig.site_country === "aus") {
        this.exportGeoJson();
      } else {
        this.dialogue = true;
      }
    },
    exportGeoJson() {
      if (this.exportingGeoJson === true) return;

      this.exportingGeoJson = true;

      const payload = {
        category_ids: this.category?.id ? [this.category.id] : [],
        separate_area_ids: this.customArea?.id ? [this.customArea.id] : [],
      };

      this.$axios
        .post("/custom-area-export-geojson", payload)
        .then(
          function (response) {
            // download geojson file
            const geoJsonData = response.data;
            const blob = new Blob([JSON.stringify(geoJsonData)], {
              type: "application/json",
            });

            // Create a URL for the Blob
            const url = URL.createObjectURL(blob);

            // Create a link element and set its href to the Blob URL
            const link = document.createElement("a");
            link.href = url;

            // Specify the file name
            const currentDate = new Date();
            const date = String(currentDate.getDate()).padStart(2, "0");
            const month = String(currentDate.getMonth() + 1).padStart(2, "0");
            const year = currentDate.getFullYear();
            const fileName = `${this.category?.name || this.customArea?.name}_${date}${month}${year}.geojson`;
            link.download = fileName.toLowerCase().replaceAll(" ", "_");

            link.click(); // Programmatically click the link to trigger the download

            // Clean up by revoking the Blob URL
            URL.revokeObjectURL(url);
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              title: "Error! Failed to export geojson",
              message: error.response.data.message,
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        )
        .finally(() => {
          this.exportingGeoJson = false;
        });
    },
    exportPostcodes() {
      if (this.exportingPostcodes === true) return;

      this.exportingPostcodes = true;

      const payload = {
        category_ids: this.category?.id ? [this.category.id] : [],
        separate_area_ids: this.customArea?.id ? [this.customArea.id] : [],
      };

      this.$axios
        .post("/custom-area-export-postcodes", payload)
        .then(function (response) {
          // download csv file
          const headers = ["custom_area_name", "area_code", "postcode"];

          exportCSVFile(headers, response.data, "postcodes");
        })
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              title: "Error! Failed to export postcodes",
              message: error.response.data.message,
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        )
        .finally(() => {
          this.exportingPostcodes = false;
        });
    },
  },
};
</script>
