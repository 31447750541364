<template>
  <v-container fluid>
    <!-- confirm user deletion -->
    <v-dialog v-model="deleteDialog" width="550">
      <v-card v-if="deleteDialog">
        <v-card-title
          :style="
            'background-color: ' +
            this.$store.state.config.siteConfig.toolbar_colour +
            ';'
          "
          class="text-h6 text-center"
        >
          Confirm Removing User?
        </v-card-title>
        <v-card-actions>
          <v-card-text style="text-align: center">
            Are you sure you want to remove
            <strong>{{ this.editItem.name }}</strong> ({{
              this.editItem.email
            }})? <br />You <i>cannot</i> undo this action!
          </v-card-text>
        </v-card-actions>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            variant="elevated"
            color="success"
            tile
            @click="removeUser()"
            aria-label="cancel"
          >
            cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            variant="elevated"
            color="error"
            tile
            id="confirmDeleteUser"
            @click="doUpdate(editItem)"
            aria-label="Remove User"
          >
            Remove User
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- see login details -->
    <v-dialog v-model="loginDetailsDialogue" width="550">
      <v-card style="width: 500px">
        <v-card-title
          :style="
            'background-color: ' +
            this.$store.state.config.siteConfig.toolbar_colour +
            ';'
          "
          class="text-h6 text-center"
        >
          Login Details
        </v-card-title>
        <v-card-text style="text-align: left; height: 150px">
          <div class="mb-5">
            <b>Number of Logins</b>
            <div>
              {{ editItem.number_of_logins }}
            </div>
          </div>
          <div class="mb-5">
            <b>Last Login</b>
            <div>
              {{ editItem.last_login }}
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            variant="elevated"
            color="error"
            tile
            @click="loginDetailsDialogue = false"
            aria-label="cancel"
          >
            close
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- confirm password reset -->
    <v-dialog v-model="confirmPwdResetDialog" width="550">
      <v-card id="confirmSelfPermissionChange">
        <v-card-title
          :style="
            'background-color: ' +
            this.$store.state.config.siteConfig.toolbar_colour +
            '; color:#ffffff;'
          "
          class="text-h6 text-center"
        >
          Confirm Password Reset
        </v-card-title>
        <v-card-actions>
          <v-card-text style="text-align: center">
            Are you sure you want to reset the password for
            <b>{{ editItem.name }}</b
            >. They will get an email with a link to reset their password.
          </v-card-text>
        </v-card-actions>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            variant="elevated"
            color="success"
            tile
            id="cancelSelfPermissionChange"
            @click="confirmPwdResetDialog = false"
            aria-label="cancel"
          >
            cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            variant="elevated"
            color="error"
            tile
            id="confirmDeleteUser"
            @click="resetPassword()"
            aria-label="Remove User"
          >
            Proceed
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- confirm changing our module group -->
    <v-dialog v-model="confirmDialog" width="550">
      <v-card id="confirmSelfPermissionChange">
        <v-card-title
          :style="
            'background-color: ' +
            this.$store.state.config.siteConfig.toolbar_colour +
            '; color:#ffffff;'
          "
          class="text-h6 text-center"
        >
          Confirm self permission change
        </v-card-title>
        <v-card-actions>
          <v-card-text style="text-align: center">
            You are about to change your user permission level. If you set a
            permission level lower than <b>Group Administrator</b> you will not
            be able to access User Admin module. Are you sure you want to
            proceed?
          </v-card-text>
        </v-card-actions>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            variant="elevated"
            color="success"
            tile
            id="cancelSelfPermissionChange"
            @click="
              users = JSON.parse(JSON.stringify(cloneUsers));
              confirmDialog = false;
            "
            aria-label="cancel"
          >
            cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            variant="elevated"
            color="error"
            tile
            id="confirmDeleteUser"
            @click="
              doUpdate(editItem);
              confirmDialog = false;
            "
            aria-label="Remove User"
          >
            Proceed
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- create user -->
    <v-dialog v-model="createNewDialog" scrollable width="550">
      <v-card>
        <v-toolbar
          :style="
            'background-color: ' +
            this.$store.state.config.siteConfig.toolbar_colour +
            '; color:#ffffff;'
          "
          class="text-h6 pl-6"
        >
          Create New User
        </v-toolbar>
        <v-card-text class="pa-0" style="height: 60vh">
          <v-card-text
            v-if="!clientUserModuleGroups"
            tile
            elevation="0"
            class="progress"
          >
            <div class="progressText">
              <v-progress-circular
                :size="200"
                :width="3"
                color="primary"
                indeterminate
                >Loading
              </v-progress-circular>
            </div>
          </v-card-text>
          <v-card-text v-else style="text-align: center">
            <v-form v-model="valid">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="newUser.name"
                      :error-messages="newUserErrors.name"
                      label="Name"
                      id="newUserNameField"
                      required
                      variant="outlined"
                      density="compact"
                      rounded="0"
                      class="fields"
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="newUser.email"
                      type="email"
                      :error-messages="newUserErrors.email"
                      label="Email Address"
                      id="newUserEmailField"
                      required
                      variant="outlined"
                      density="compact"
                      rounded="0"
                      class="fields"
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" v-if="!newUser.generatePW">
                    <v-text-field
                      v-model="newUser.password"
                      :error-messages="newUserErrors.password"
                      label="password"
                      type="password"
                      id="newUserPasswordField"
                      required
                      variant="outlined"
                      density="compact"
                      rounded="0"
                      class="fields"
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-checkbox
                      v-model="newUser.generatePW"
                      id="autoGeneratePW"
                      label="Auto Generate Password"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12">
                    <v-radio-group
                      v-model="newUser.groupMembership"
                      label="Group Membership"
                      hint="Select Module Membership"
                    >
                      <v-radio
                        v-for="group in clientUserModuleGroups"
                        :key="group.id"
                        :label="group.name"
                        :value="group.id"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12">
                    <v-checkbox
                      v-model="newUser.sendEmail"
                      label="Send New User Account Details Email"
                      id="sendEmail"
                      :disabled="disableSendEmail"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card-text>
        <v-card>
          <v-card-actions>
            <v-btn
              variant="elevated"
              tile
              color="error"
              @click="createNewUser"
              aria-label="cancel"
            >
              cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              variant="elevated"
              tile
              id="saveNewUser"
              color="success"
              @click="saveNewUser"
              aria-label="Create New User"
              :loading="saveNewUserSpinner"
            >
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon light>mdi-cached</v-icon>
                </span>
              </template>
              Create New User
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editDialog" width="400" persistent>
      <v-card v-if="editDialog">
        <v-card-title
          :style="
            'background-color: ' +
            this.$store.state.config.siteConfig.toolbar_colour +
            '; color:#ffffff;'
          "
          class="text-h6 text-center mb-2"
        >
          <v-spacer>Edit {{ this.editFieldName }}</v-spacer>
        </v-card-title>
        <v-card-actions class="pb-0 pt-3">
          <v-form v-model="isFormValid" style="width: 100%">
            <v-text-field
              v-model="editItem[editFieldName]"
              :rules="[maxchars, minchars]"
              :label="'edit ' + editFieldName"
              :error-messages="apiResponseError[editFieldName]"
              single-line
              id="editField"
              counter
              autofocus
              variant="outlined"
              density="compact"
              rounded="0"
              class="fields"
              @focus="resetApiValidationErrors"
              autocomplete="off"
            ></v-text-field>
          </v-form>
        </v-card-actions>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="error"
            variant="elevated"
            tile
            @click="editField()"
            aria-label="cancel"
          >
            cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            variant="elevated"
            :disabled="!isFormValid"
            color="success"
            id="saveEdit"
            tile
            @click="doUpdate(editItem)"
            aria-label="save"
          >
            save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-title><h1 class="h1 text-start">User Admin</h1></v-card-title>
    <v-card-actions>
      <v-divider />
    </v-card-actions>
    <v-card elevation="0" v-if="!loadingTable" :class="{ 'pa-8': small }">
      <v-card-actions>
        <v-col cols="12" lg="4" md="4" sm="4" class="mb-5">
          <v-row>
            <v-text-field
              v-model="search"
              label="Search Users"
              variant="outlined"
              density="compact"
              rounded="0"
              single-line
              autocomplete="off"
            ></v-text-field>
          </v-row>
        </v-col>
        <v-col cols="12" class="mb-10">
          <v-row>
            <v-btn
              color="primary"
              tile
              variant="elevated"
              @click="createNewUser"
              id="createNewUser"
              aria-label="Create New User"
              class="mr-5"
            >
              Create New User</v-btn
            >
            <v-btn
              color="success"
              tile
              variant="elevated"
              @click="downloadUsers"
              aria-label="Download Users"
            >
              Download Users</v-btn
            >
          </v-row>
        </v-col>
      </v-card-actions>
    </v-card>
    <v-data-table
      :search="search"
      :custom-filter="filterNames"
      fixed-header
      :headers="headers"
      :items="users"
      :style="'height:' + (loadingTable ? height - 185 : height - 273) + 'px;'"
      :items-per-page="20"
      class="elevation-1"
      :loading="loadingTable"
    >
      <template v-slot:loading>
        <v-progress-circular
          indeterminate
          size="24"
          color="blue"
        ></v-progress-circular>
      </template>

      <template v-slot:[`item.name`]="props">
        <td
          class="editName"
          :class="{ nonClickable: !props.item.canEdit }"
          title="click to edit"
          @click="editField(props.item, 'name')"
        >
          {{ props.item.name }}
        </td>
      </template>
      <template v-slot:[`item.email`]="props">
        <td class="emailCell">
          {{ props.item.email }}
        </td>
      </template>
      <template v-slot:[`item.account_suspended`]="{ item }">
        <td style="display: flex; justify-content: center; align-items: center">
          <v-switch
            class="ma-0 ml-7 pa-0"
            v-model="item.account_suspended"
            :aria-label="item.account_suspended ? 'yes' : 'no'"
            :color="item.account_suspended ? 'error' : 'success'"
            :disabled="!item.canEdit"
            hide-details
            @change="doUpdate(item)"
          ></v-switch>
        </td>
      </template>
      <template v-slot:[`item.removeUser`]="{ item }">
        <v-btn
          variant="elevated"
          color="error"
          tile
          id="removeUser"
          size="small"
          :disabled="!item.canEdit"
          @click="removeUser(item)"
          aria-label="remove user"
          >{{ item.canEdit === true ? "remove" : "admin" }}
        </v-btn>
      </template>
      <template v-slot:[`item.number_of_logins`]="{ item }">
        <v-btn
          variant="text"
          id="loginDetailsIcon"
          class="mr-5"
          style="padding: 0 !important"
          icon="mdi-information-outline"
          @click="
            editItem = item;
            loginDetailsDialogue = true;
          "
          aria-label="login details"
          label="login details"
        />
      </template>
      <template v-slot:[`item.last_login`]="{ item }">
        <v-btn
          tile
          color="warning"
          aria-label="reset password"
          variant="elevated"
          @click="
            editItem = item;
            confirmPwdResetDialog = true;
          "
          >reset password
        </v-btn>
      </template>
      <template
        v-for="(group, i) in groupInfo"
        v-slot:[`item.groupMembership.${i}.member`]="{ item }"
        v-bind:key="i"
      >
        <td style="display: flex; justify-content: center; align-items: center">
          <v-checkbox
            v-model="item.groupMembership[i].member"
            :disabled="!item.canEdit"
            class="groupCheckbox"
            @change="checkboxLevelPermissions(item, i)"
            :aria-label="
              item.groupMembership[i].member
                ? 'is a member of ' + item.groupMembership[i].slug
                : 'is not a member of ' + item.groupMembership[i].slug
            "
            :title="
              item.groupMembership[i].member
                ? 'is a member of ' + item.groupMembership[i].slug
                : 'is not a member of ' + item.groupMembership[i].slug
            "
          ></v-checkbox>
        </td>
      </template>
    </v-data-table>
    <!--    </v-card>-->
  </v-container>
</template>
<script>
import { exportCSVFile } from "@/mixins/ExportCSVFile";
import { useDisplay } from "vuetify";

export default {
  name: "USER-ADMIN",
  data: () => ({
    saveNewUserSpinner: false,
    height: useDisplay().height,
    appBarBottom: 0,
    disableSendEmail: true,
    newUser: {
      name: null,
      email: null,
      password: null,
      groupMembership: null,
      generatePW: true,
      sendEmail: true,
    },
    newUserErrors: {
      name: null,
      email: null,
      password: null,
      groupMembership: null,
    },
    valid: false,
    clientUserModuleGroups: null,
    createNewDialog: false,
    search: "",
    deleteDialog: false,
    confirmDialog: false,
    loginDetailsDialogue: false,
    confirmPwdResetDialog: false,
    apiResponseError: {
      name: null,
      email: null,
    },
    isFormValid: false,
    editDialog: false,
    editItem: null,
    editFieldName: null,
    users: [],
    cloneUsers: null,
    groupInfo: {},
    loadingTable: false,
    headers: [
      {
        title: "Name",
        toolTip: "users name",
        align: "start",
        sortable: true,
        value: "name",
      },
      {
        title: "Email",
        toolTip: "users email",
        align: "start",
        sortable: true,
        value: "email",
      },
      {
        title: "Login Details",
        toolTip: "Login information",
        align: "center",
        sortable: true,
        value: "number_of_logins",
      },
      {
        title: "Reset Password",
        toolTip: "reset password",
        align: "center",
        sortable: true,
        value: "last_login",
      },
      {
        title: "Account Suspended",
        toolTip: "account suspended",
        align: "center",
        sortable: true,
        value: "account_suspended",
      },
    ],
    snack: false,
    snackColor: "",
    snackText: "",
    maxchars: (v) => v.length <= 40 || "too long!",
    minchars: (v) => (v.length === 0 ? "cannot be empty!" : true),
    small: useDisplay().mdAndDown,
  }),
  components: {
    // FormsInputsAndControls
  },
  props: {},
  mounted() {
    this.updateAppBarBottom();
    window.addEventListener("resize", this.updateAppBarBottom);
    this.getUsers();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateAppBarBottom);
  },
  methods: {
    updateAppBarBottom() {
      this.appBarBottom = document
        .querySelector("#appBar")
        .getBoundingClientRect().bottom;
    },
    downloadUsers() {
      const month = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      var headers = [
        "Name",
        "Email",
        "Number of Logins",
        "Last Login",
        "Account Suspended",
      ];
      var data = [];
      var d = new Date();
      var filename =
        "userexport_localinsight_" +
        d.getDate() +
        "_" +
        month[d.getMonth()] +
        "_" +
        d.getFullYear(); //userexport_localinsight_[THE DATE]
      // sort out the headers
      for (let key in this.groupInfo) {
        headers.push(this.groupInfo[key].name);
      }
      // add the users info
      for (var i = 0; i < this.users.length; i++) {
        data.push([
          this.users[i].name,
          this.users[i].email,
          this.users[i].number_of_logins,
          this.users[i].last_login,
          this.users[i].account_suspended,
        ]);
        // add users group membership
        for (let key in this.users[i].groupMembership) {
          data[data.length - 1].push(this.users[i].groupMembership[key].member);
        }
      }
      // call this
      exportCSVFile(headers, data, filename);
    },
    reset(objectName) {
      for (const key in this[objectName]) {
        if (key === "sendEmail" || key === "generatePW") {
          this[objectName][key] = true;
        } else {
          this[objectName][key] = null;
        }
      }
    },
    getClientUserModuleGroups() {
      this.$axios
        .get("/client-user-module-groups-by-client")
        .then(
          function (response) {
            // handle success
            this.clientUserModuleGroups = response.data;
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to get all clients",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    saveNewUser() {
      this.saveNewUserSpinner = true;
      this.reset("newUserErrors");
      this.emit.emit("systemBusy", true);
      this.$axios
        .post("/new-user", this.newUser)
        .then((response) => {
          // handle success
          this.users = response.data.users;
          this.cloneUsers = JSON.parse(JSON.stringify(this.users));
          this.groupInfo = response.data.groupInfo;
          this.createNewDialog = false;
          this.reset("newUser");
          this.emit.emit("systemBusy", false);
          this.emit.emit("systemMessage", {
            title: "New User Created",
            message: "Success!",
            timeout: 3000,
            colour: "success",
          });
          this.saveNewUserSpinner = false;
        })
        .catch((error) => {
          // console.log(error);
          this.emit.emit("systemBusy", false);
          this.emit.emit("systemMessage", {
            message: error.response.data.message,
            title: "Error! Failed to update user",
            timeout: -1,
            colour: "red",
          });
          this.newUserErrors = error.response.data.errors;
          this.saveNewUserSpinner = false;
        });
    },
    createNewUser() {
      // go and get theses
      if (this.clientUserModuleGroups === null) {
        this.getClientUserModuleGroups();
      }
      this.reset("newUser");
      this.reset("newUserErrors");
      this.createNewDialog = !this.createNewDialog;
    },
    filterNames(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().indexOf(search) !== -1
      );
    },
    apiValidationErrors(errors) {
      for (const field in errors) {
        this.apiResponseError[field] = errors[field];
      }
    },
    resetApiValidationErrors() {
      for (var field in this.apiResponseError) {
        this.apiResponseError[field] = null;
      }
    },
    editField(item = null, fieldName = null) {
      this.resetApiValidationErrors();
      this.editDialog = !this.editDialog;
      this.editItem = this.editDialog ? JSON.parse(JSON.stringify(item)) : null;
      this.editFieldName = fieldName;
    },
    removeUser(item = null) {
      this.deleteDialog = !this.deleteDialog;
      this.editItem = this.deleteDialog
        ? JSON.parse(JSON.stringify(item))
        : null;
      if (this.editItem !== null) {
        this.editItem.removeUser = true;
      }
    },
    checkboxLevelPermissions(item, index) {
      // uncheck all other checkboxes
      for (let key in item.groupMembership) {
        if (key !== index) {
          item.groupMembership[key].member = false;
        }
      }

      // if we are changing our own group, we need to confirm
      if (this.$store.state.config.userProfile.email === item.email) {
        this.confirmDialog = true;
        this.editItem = item;
        return;
      }

      // update
      this.doUpdate(item);
    },
    resetPassword() {
      this.emit.emit("systemBusy", true);

      let resetPasswordEmail = {
        email: this.editItem.email,
      };
      this.$axios
        .post("/user-reset-password", resetPasswordEmail)
        .then(() => {
          this.emit.emit("systemMessage", {
            message:
              "User  " +
              this.editItem.name +
              " has got an email with a reset password link",
            title: "Password reset email sent",
            timeout: 5000,
            colour: "info",
          });
        })
        .catch((error) => {
          this.emit.emit("systemMessage", {
            message: error.response.data.message,
            title: "Error! Password reset failed",
            timeout: -1,
            colour: "error",
          });
        })
        .finally(() => {
          this.emit.emit("systemBusy", false);
          this.confirmPwdResetDialog = false;
        });
    },
    doUpdate(item) {
      if (!item.canEdit) return;

      this.resetApiValidationErrors();
      this.emit.emit("systemBusy", true);
      this.$axios
        .put("/clients-users-update", item)
        .then((response) => {
          // handle success
          this.users = response.data.users;
          this.cloneUsers = JSON.parse(JSON.stringify(this.users));
          this.groupInfo = response.data.groupInfo;
          this.editDialog = false;
          this.deleteDialog = false;
          this.emit.emit("systemBusy", false);
          this.emit.emit("systemMessage", {
            title: "Update Complete",
            message: "Success!",
            timeout: 3000,
            colour: "success",
          });
        })
        .catch((error) => {
          this.users = JSON.parse(JSON.stringify(this.cloneUsers));
          console.log(error);
          this.emit.emit("systemBusy", false);
          this.emit.emit("systemMessage", {
            message: error.response.data.message,
            title: "Error! Failed to update user",
            timeout: 3000,
            colour: "red",
          });
          this.apiValidationErrors(error.response.data.errors);
        });
    },
    getUsers() {
      this.loadingTable = true;
      this.$axios
        .get("/clients-users-get")
        .then(
          function (response) {
            // handle success
            this.users = response.data.users;
            this.cloneUsers = JSON.parse(JSON.stringify(this.users));
            this.groupInfo = response.data.groupInfo;
            // add group info to the headers
            for (const key in this.groupInfo) {
              if (Object.prototype.hasOwnProperty.call(this.groupInfo, key)) {
                this.headers.push({
                  title: this.groupInfo[key].name,
                  toolTip: this.groupInfo[key].description,
                  align: "center",
                  sortable: true,
                  key: "groupMembership." + key + ".member",
                });
              }
            }
            // add the remove user button
            this.headers.push({
              title: "Remove User",
              toolTip: "This will delete the user from your Local Insight site",
              align: "center",
              key: "removeUser",
            });
            this.loadingTable = false;
          }.bind(this),
        )
        .catch(
          function (error) {
            this.loadingTable = false;
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to get all clients",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
  },
  watch: {
    newUser: {
      handler() {
        if (this.newUser.generatePW) {
          this.newUser.sendEmail = true;
          this.disableSendEmail = true;
        } else {
          this.disableSendEmail = false;
        }
      },
      deep: true,
    },
    "newUser.email": function (newVal) {
      if (newVal) {
        this.newUser.email = newVal.toLowerCase();
      }
    },
  },
};
</script>

<style scoped>
.editName {
  cursor: pointer;
  text-decoration: underline;
  text-align: left;
}

.nonClickable {
  pointer-events: none;
}

.v-table__wrapper {
  height: inherit;
}
</style>
