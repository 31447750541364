<template>
  <v-dialog
    v-model="computedPostcodeCustomAreaDialog"
    scrollable
    persistent
    max-width="1500px"
  >
    <template v-slot:activator="{ props }">
      <v-btn
        class="mx-4"
        color="primary"
        variant="elevated"
        tile
        v-bind="props"
        id="uploadPostcodesButton"
        @click="buildCustomArea()"
        aria-label="Upload Postcodes"
      >
        Upload Postcodes
      </v-btn>
    </template>
    <v-card>
      <v-toolbar
        :color="this.$store.state.config.siteConfig.toolbar_colour"
        dark
        class="text-h5"
        max-height="64px"
      >
        <v-btn
          variant="text"
          id="closePostcodesDialogButton"
          icon="mdi-close"
          dark
          @click="closeDialog"
          aria-label="Close"
        />
        <v-toolbar-title v-if="customAreasIndex != null"
          >Edit Custom Area
        </v-toolbar-title>
        <v-toolbar-title v-else>Create Custom Area</v-toolbar-title>
      </v-toolbar>
      <v-card-text v-if="loadingOfPostcodes && editMode" style="height: 90vh">
        <v-card-actions tile elevation="0" class="progress">
          <div class="progressText">
            <v-progress-circular
              :size="200"
              :width="3"
              color="primary"
              indeterminate
              >Loading Postcodes
            </v-progress-circular>
          </div>
        </v-card-actions>
      </v-card-text>
      <v-card-text v-else style="height: 90vh">
        <!-- Core fields -->
        <v-card-subtitle class="mt-5">Basic information</v-card-subtitle>
        <v-card-actions>
          <v-form ref="form" v-model="validation" style="width: 60%">
            <v-row class="mt-0" v-if="customAreasIndex != null">
              <v-col
                cols="12"
                sm="12"
                md="6"
                style="padding-top: 0px !important"
              >
                <Field
                  :schema="fieldSchema.name"
                  v-model:value="customAreasClone.name"
                  id="editing-postcode-area-name-field"
                  :name="customArea.name"
                />
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="6"
                style="padding-top: 0px !important"
              >
                <Field
                  :schema="fieldSchema.custom_area_category_id"
                  v-model:value="customAreasClone.custom_area_category_id"
                />
              </v-col>
              <v-col cols="12" style="padding-top: 0px !important">
                <Field
                  :schema="fieldSchema.description"
                  v-model:value="customAreasClone.description"
                />
              </v-col>
            </v-row>
            <v-row class="mt-0" v-else>
              <v-col
                cols="12"
                sm="12"
                md="6"
                style="padding-top: 0px !important"
              >
                <Field
                  id="creation-postcode-area-name-field"
                  :schema="fieldSchema.name"
                  v-model:value="customArea.name"
                />
                <div
                  v-if="customAreaNameLength >= 80"
                  :class="customAreaNameLength > 100 ? 'text-red' : ''"
                  style="position: relative; top: -20px; left: 347px"
                >
                  {{ customAreaNameLength }}/100
                </div>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="6"
                style="padding-top: 0px !important"
              >
                <Field
                  :schema="fieldSchema.custom_area_category_id"
                  v-model:value="customArea.custom_area_category_id"
                />
              </v-col>
              <v-col cols="12" style="padding-top: 0px !important">
                <Field
                  :schema="fieldSchema.description"
                  v-model:value="customArea.description"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-actions>
        <!--   Edit custom area     -->
        <v-card tile v-if="editMode">
          <v-card-actions>
            <v-btn
              variant="elevated"
              v-if="!addPostcodes"
              color="primary"
              tile
              class="btn-wrap"
              @click="addPostcodes = true"
              aria-label="Add postcodes"
            >
              Add postcodes
              <v-icon right class="mb-1"> mdi-plus</v-icon>
            </v-btn>
            <v-btn
              variant="elevated"
              v-if="addPostcodes"
              color="primary"
              class="btn-wrap"
              tile
              @click="addPostcodes = false"
              aria-label="Go back"
            >
              Go back
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card
          v-if="!addPostcodes && editMode"
          style="display: flex; justify-content: center"
          tile
        >
          <v-menu>
            <template v-slot:activator>
              <div>
                <v-text-field
                  class="fields mb-3"
                  variant="outlined"
                  density="compact"
                  rounded="0"
                  v-if="!addPostcodes"
                  v-model="tableSearch"
                  prepend-inner-icon="mdi-magnify"
                  label="Search postcodes"
                  clearable
                  hide-details="true"
                  :single-line="true"
                  autocomplete="off"
                ></v-text-field>
                <v-data-table
                  fixed-header
                  height="42vh"
                  v-if="!addPostcodes"
                  :headers="selectedPostcodesHeaders"
                  :search="tableSearch"
                  :items="postcodesList"
                  :items-per-page="10"
                  class="elevation-1 custom-table"
                >
                  <template v-slot:[`item.remove`]="{ item }">
                    <div @click="removeItem(item)" class="clickable-icon">
                      <v-icon aria-label="delete" size="small">
                        mdi-delete
                      </v-icon>
                    </div>
                  </template>
                </v-data-table>
              </div>
            </template>
          </v-menu>
        </v-card>
        <div v-else>
          <!-- Select upload type section -->
          <v-card
            v-if="!paste && !csv && !matching && !results"
            height="50%"
            tile
            width="100%"
          >
            <v-card-title class="text-center">
              Select Upload Type
            </v-card-title>
            <v-card-text class="text-center"
              >Upload postcodes by either pasting in a list, or uploading a csv
            </v-card-text>
            <v-container class="d-flex justify-center">
              <v-card-actions>
                <v-btn
                  variant="elevated"
                  width="200px"
                  color="primary"
                  tile
                  @click="paste = true"
                  id="pastePostCodesButton"
                  class="btn-wrap"
                  aria-label="Paste Postcodes"
                  >Paste Postcodes
                </v-btn>
              </v-card-actions>
              <v-card-actions>
                <v-btn
                  variant="elevated"
                  width="200px"
                  color="primary"
                  tile
                  @click="csv = true"
                  class="btn-wrap"
                  aria-label="Upload CSV"
                  >Upload CSV
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-card>
          <!-- paste section -->
          <v-card
            height="50%"
            width="100%"
            align-center
            justify-center
            v-if="paste && !matching && !results"
          >
            <v-card-title> Paste Postcodes</v-card-title>
            <v-card-text
              >Paste a list of postcodes into the box below in the format of
              <b>each postcode on a new line</b>
              - we can then map these postcodes to create a custom area
            </v-card-text>
            <v-container
              class="d-flex justify-center pastePostcodes"
              height="100%"
              width="100%"
            >
              <v-textarea
                density="compact"
                rounded="0"
                class="fields"
                id="pastePostcodesField"
                variant="outlined"
                clearable
                no-resize
                label="Postcodes..."
                v-model="raw_postcode"
              ></v-textarea>
            </v-container>
            <v-container class="d-flex justify-center">
              <v-card-actions>
                <v-btn
                  variant="elevated"
                  :disabled="!raw_postcode"
                  width="200px"
                  color="primary"
                  id="matchPastedPostcodes"
                  tile
                  @click="matchPastedPostcodes()"
                  class="btn-wrap"
                  aria-label="Match Postcodes"
                  >Match Postcodes
                </v-btn>
              </v-card-actions>
              <v-card-actions>
                <v-btn
                  variant="elevated"
                  width="200px"
                  color="error"
                  tile
                  @click="clearValues"
                  class="btn-wrap"
                  aria-label="cancel"
                  >cancel
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-card>

          <!-- csv section -->
          <v-card
            height="50%"
            width="100%"
            align-center
            justify-center
            v-if="csv && !matching && !results"
          >
            <v-card-title> Upload CSV</v-card-title>
            <v-card-text
              >Upload a CSV containing your postcodes - we can then match these
              to create a custom area
            </v-card-text>
            <v-container
              class="d-flex justify-center"
              height="100%"
              width="100%"
            >
              <!-- file input field -->
              <v-file-input
                label=".csv files only..."
                accept=".csv"
                type="file"
                variant="outlined"
                density="compact"
                rounded="0"
                multiple
                class=""
                v-model="postcode_csv"
              ></v-file-input>
            </v-container>
            <v-container class="d-flex justify-center">
              <v-card-actions>
                <v-btn
                  variant="elevated"
                  :disabled="!postcode_csv"
                  width="200px"
                  color="primary"
                  tile
                  @click="processCSV()"
                  class="btn-wrap"
                  aria-label="Match Postcodes"
                  >Match Postcodes
                </v-btn>
              </v-card-actions>
              <v-card-actions>
                <v-btn
                  variant="elevated"
                  width="200px"
                  color="error"
                  tile
                  @click="
                    csv = false;
                    postcode_csv = null;
                  "
                  class="btn-wrap"
                  aria-label="cancel"
                  >cancel
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-card>

          <!-- matching/loading section -->
          <v-card
            height="50%"
            width="100%"
            align-center
            justify-center
            v-if="matching && !results"
          >
            <v-card-title class="justify-center">
              Matching Postcodes
            </v-card-title>
            <v-card-text class="text-center mb-5"
              >Matching Postcodes, this could take a minute...
            </v-card-text>
            <v-row width="100%" align="center" class="mb-5">
              <v-col class="justify-center text-center" cols="12">
                <v-progress-circular
                  :size="100"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </v-col>
            </v-row>
          </v-card>
          <!-- results -->
          <v-card
            height="50%"
            width="100%"
            align-center
            justify-center
            v-if="results"
            style="overflow-y: auto"
            class="mx-auto text-center"
          >
            <v-card-title>Match Complete</v-card-title>
            <v-card-text
              >We have matched {{ matched_length }}/{{ original_length }}
              Postcodes
            </v-card-text>
            <v-card-actions class="justify-center mb-5">
              <v-btn
                variant="elevated"
                v-if="removeUnmatchedButtonActive && show_unmatched"
                width="200px"
                color="warning"
                tile
                id="removeUnmatchedPostcodes"
                @click="removeUnmatched"
                class="btn-wrap"
                aria-label="Remove Unmatched"
              >
                Remove Unmatched
              </v-btn>
              <v-btn
                variant="elevated"
                width="200px"
                color="primary"
                tile
                @click="results = false"
                class="btn-wrap"
                aria-label="Return to Postcodes"
              >
                Return to Postcodes
              </v-btn>
              <v-btn
                variant="elevated"
                width="200px"
                v-if="customAreasIndex === null"
                :disabled="!saveButtonActive"
                color="success"
                id="savePostcodes"
                tile
                @click="upsertCustomArea"
                class="btn-wrap"
                aria-label="Save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
          <v-card>
            <!-- matched table -->
            <v-container
              v-if="show_unmatched"
              style="display: flex; justify-content: center"
            >
              <v-menu>
                <template v-slot:activator>
                  <v-data-table
                    v-if="show_unmatched"
                    :headers="headers"
                    :items="unmatched"
                    height="42vh"
                    hover
                    :items-per-page="10"
                    class="elevation-1"
                    style="max-width: 30%"
                  >
                    <template v-slot:item="{ item }">
                      <tr class="">
                        <td
                          v-for="(header, index) in headers"
                          :key="index"
                          class="tooltip-content"
                        >
                          <v-tooltip
                            v-if="item.country"
                            activator="parent"
                            location="bottom"
                            text="Postcode beyond your country"
                          >
                            <template v-slot:activator="{ props }">
                              <div v-bind="props">
                                <span class="text-orange">
                                  {{ item[header.value] }}
                                </span>
                              </div>
                            </template>
                          </v-tooltip>
                          <v-tooltip
                            v-else
                            activator="parent"
                            location="bottom"
                            text="Unidentified postcode"
                          >
                            <template v-slot:activator="{ props }">
                              <div v-bind="props">
                                <span class="text-red">
                                  {{ item[header.value] }}
                                </span>
                              </div>
                            </template>
                          </v-tooltip>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </template>
              </v-menu>
            </v-container>
          </v-card>
        </div>
        <!-- Buttons -->
      </v-card-text>
      <v-card v-if="!loadingOfPostcodes" tile elevation="2">
        <v-card-actions>
          <v-card-subtitle
            v-if="this.$store.state.displayOnsSource"
            class="source"
          >
            <p>
              Source: Office for National Statistics licensed under the Open
              Government Licence v.3.0
            </p>
            <p>
              Contains OS data © Crown copyright and database right
              {{ year }}
            </p>
          </v-card-subtitle>
          <v-spacer />
          <v-btn
            variant="elevated"
            v-if="customAreasIndex !== null"
            color="success"
            :disabled="!editButtonActive"
            id="savedEdited"
            tile
            @click="upsertCustomArea"
            aria-label="Save"
            class="mb-2 btn-wrap"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import Field from "@/components/Fields.vue";

export default {
  name: "CreateAreaPostcodes",
  data: () => ({
    fieldSchema: {},
    parentAreasLoaded: false,
    customArea: {
      area_ids: [],
      postcode_ids: [],
    },
    typeID: null,
    validation: false,
    paste: false,
    csv: false,
    matching: false,
    results: false,
    show_unmatched: false,
    raw_postcode: null,
    postcode_csv: null,
    postcode_array: [],
    matched: [],
    unmatched: [],
    headers: [{ title: "Postcode", value: "postcode" }],
    cloneOfInput: null,
    selectedPostcodesHeaders: [
      { title: "Postcode", value: "postcode" },
      { title: "Remove", value: "remove", width: "20%" },
    ],
    upsertingProcess: false,
    tableSearch: "",
    customAreasClone: [],
    postcodesList: [],
    addPostcodes: false,
    showEditTable: true,
    editModeCustomAreaid: null,
    loadingOfPostcodes: true,
    continueExecution: true,
  }),
  components: {
    Field,
  },
  computed: {
    year() {
      return new Date().getFullYear();
    },
    matched_length() {
      return this.matched.length;
    },
    evenOnePostcodeIsTrue() {
      return (
        this.postcodesList.length !== 0 || this.postcode_array.length !== 0
      );
    },
    original_length() {
      return this.postcode_array.length;
    },
    computedPostcodeCustomAreaDialog() {
      return this.postcodeCustomAreaDialog;
    },
    customAreasIndex: {
      get() {
        return this.areaIndex;
      },
      set(val) {
        this.$emit("update:areaIndex", val);
      },
    },
    customAreas: {
      get() {
        return this.$store.state.customAreas;
      },
      set(value) {
        this.$store.commit("setCustomAreas", value);
      },
    },
    bestfitPolyArray: {
      get() {
        return this.$store.state.bestfitPolyArray;
      },
      set(value) {
        this.$store.commit("setBestfitPolyArray", value);
      },
    },
    bestfitAreaList: {
      get() {
        return this.$store.state.bestfitAreaList;
      },
      set(value) {
        this.$store.commit("setBestfitAreaList", value);
      },
    },
    selectedPostcodes: {
      get() {
        return this.customAreas[this.customAreasIndex].postcodes.filter(
          (item) => item.postcode,
        );
      },
    },
    matchButton: {
      get() {
        return this.raw_postcode.length < 0;
      },
    },
    removeUnmatchedButtonActive: {
      get() {
        return this.unmatched.length > 0;
      },
    },
    saveButtonActive() {
      return (
        !this.unmatched.length &&
        this.cloneOfInput === this.raw_postcode &&
        this.matched_length &&
        this.original_length &&
        !this.upsertingProcess &&
        (this.customArea.description
          ? this.customArea.description.length <= 250
          : true) &&
        (this.customArea.name ? this.customArea.name.length <= 100 : true)
      );
    },
    editButtonActive() {
      return (
        !this.unmatched.length &&
        this.cloneOfInput === this.raw_postcode &&
        this.evenOnePostcodeIsTrue &&
        !this.upsertingProcess
      );
    },
    editMode() {
      return this.customAreasIndex !== null;
    },
    customAreaNameLength() {
      return this.customArea.name ? this.customArea.name.length : 0;
    },
  },
  props: {
    postcodeCustomAreaDialog: {
      type: Boolean,
      default: false,
      required: true,
    },
    areaIndex: {},
    schema: {},
    categories: Array,
  },
  mounted() {
    this.raw_postcode = null;
    this.getTypeId();
  },
  created() {
    this.restorePostcode();
  },
  methods: {
    removeItem(item) {
      // edit the local clone
      var customArea = this.customAreasClone;

      // remove from list
      this.postcodesList = this.postcodesList.filter((el) => {
        return el.postcode !== item.postcode;
      });

      customArea.postcodes.splice(item, 1);
      customArea.areas.splice(item, 1);
      customArea.custom_areas_postcodes.splice(item, 1);
    },
    removeUnmatched() {
      // remove from array all elements that equal unmatched.postcode
      this.postcode_array = this.postcode_array.filter((el) => {
        return !this.unmatched.some((f) => {
          return f.postcode === el;
        });
      });
      this.unmatched = [];

      // user might want to make changes to the postcode list, so we have to reset the textarea
      this.raw_postcode = this.postcode_array.join("\n");
      this.cloneOfInput = JSON.parse(JSON.stringify(this.raw_postcode));

      // hide the table
      this.show_unmatched = false;
    },
    restorePostcode() {
      const savedPostcode = localStorage.getItem("saved_postcode");
      if (savedPostcode) {
        this.raw_postcode = savedPostcode;
      }
    },
    processCSV() {
      // open up the reader
      var reader = new FileReader();
      reader.readAsText(this.postcode_csv[0]);
      reader.onload = () => {
        // when weve got a result, set it as the raw postcode string and carry on matching as normal
        var data = reader.result;
        this.raw_postcode = data;
        this.matchPastedPostcodes();
      };
    },
    matchPastedPostcodes() {
      this.show_unmatched = false;
      this.matching = true;
      this.continueExecution = true;
      // convert the pasted string into an array
      this.postcode_array = this.raw_postcode.split(/\r?\n/);

      // if the last element of the array is empty, as usually there is an empty line from the paste, remove it
      if (this.postcode_array[this.postcode_array.length - 1] === "") {
        this.postcode_array.pop();
      }

      // delete all duplicates from the postcode_array
      this.postcode_array = [...new Set(this.postcode_array)];

      this.$axios
        .post("/postcode/match", this.postcode_array)
        .then(
          function (response) {
            if (!this.continueExecution) {
              return;
            }

            // handle success
            this.matched = response.data.matched;
            this.unmatched = response.data.unmatched;
            this.matching = false;
            this.results = true;

            // create clone to check if user made any changes to the postcodes then we can enable or disable the save button
            this.raw_postcode = this.postcode_array.join("\n");
            this.cloneOfInput = this.raw_postcode;

            // check if there are any countries that are not the same as the user's country
            var hasInvalidCountry = this.unmatched.some((item) => item.country);
            if (hasInvalidCountry) {
              this.emit.emit("systemMessage", {
                title: "Postcodes out of current country range",
                message: "Postcodes beyond your country",
                timeout: 5000,
                colour: "orange",
              });
              this.show_unmatched = true;
            } else if (this.unmatched.length) {
              this.emit.emit("systemMessage", {
                title: "Please Check Postcodes",
                message:
                  "Local Insight could not match the postcodes on the following rows, Edit your postcodes or ignore those rows and continue to import",
                timeout: 5000,
                colour: "orange",
              });
              this.show_unmatched = true;
            }
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to match postcodes",
              timeout: -1,
              colour: "red",
            });
            // handle error
            console.error(error);
            this.matching = false;
            this.results = true;
          }.bind(this),
        );
    },
    getTypeId() {
      this.$axios
        .get("/custom-areas-types/search/postcodes")
        .then(
          function (response) {
            // handle success
            this.typeID = response.data.id;
            this.emit.emit("systemBusy", false);
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemBusy", false);
          }.bind(this),
        );
    },
    // Clear dialog and close it
    clearValues() {
      this.paste = false;
      this.addPostcodes = true;
      this.matched = [];
      this.raw_postcode = null;
    },

    // If there is a selected custom area, call the api for updating, else call the api for creating
    upsertCustomArea() {
      //this.resetApiValidationErrors();
      // arrays for the api object
      var $pc_id_array = this.matched.map((postcode) => postcode.pc_id);
      var $area_id_array = this.matched.map((postcode) => postcode.area_id);
      this.customArea.type_id = this.typeID;

      // Assign the extracted arrays to customArea properties
      this.customArea.postcode_ids = $pc_id_array;
      this.customArea.area_ids = $area_id_array.filter(this.onlyUnique);

      // Merge new postcodes and areas if in edit mode, removing duplicates
      if (this.editMode) {
        var currentArea = this.customAreasClone;

        // Destructure customArea properties into separate variables
        var { postcode_ids: newPostcodeIds, area_ids: newAreaIds } =
          this.customArea;

        // Merge new and old area_ids, removing duplicates
        currentArea.area_ids = Array.from(
          new Set([...newAreaIds, ...currentArea.areas.map((area) => area.id)]),
        );

        // Merge new and old postcode_ids, removing duplicates
        currentArea.postcode_ids = Array.from(
          new Set([
            ...newPostcodeIds,
            ...currentArea.custom_areas_postcodes.map((id) => id.postcode_id),
          ]),
        );
      }

      // if this passes validation then call the api
      this.$refs.form.validate().then(({ valid: isValid }) => {
        if (isValid) {
          if (this.customAreasIndex != null) {
            this.upsertingProcess = true;
            // Custom area update
            this.emit.emit("systemMessage", {
              title: "Updating...",
              message:
                "You can close the dialog and continue working while we're updating your custom area",
              timeout: -1,
              colour: "warning",
            });
            this.emit.emit("systemBusy", true);

            // make the call!
            this.$axios
              .put(
                "/custom-areas/" + this.customAreas[this.customAreasIndex].id,
                this.customAreasClone,
              )
              .then(() => {
                this.emit.emit("systemMessage", {
                  title: "Custom Area Update Complete",
                  message: "Success!",
                  timeout: 3000,
                  colour: "green",
                });
                // Update with new data
                this.customAreasClone = this.$cloneDeep(this.customAreas);
                this.closeDialog();
                this.emit.emit("systemBusy", false);
              })
              .catch((error) => {
                this.emit.emit("systemBusy", false);
                this.emit.emit("systemMessage", {
                  message: error.response.data.message,
                  title: "Error! Custom Area Update Failed",
                  timeout: -1,
                  colour: "red",
                });
                this.apiValidationErrors(error.response.data.errors);
              })
              .finally(() => {
                this.upsertingProcess = false;
              });
          } else {
            this.emit.emit("systemBusy", true);
            // Custom area create
            this.emit.emit("systemMessage", {
              message:
                "You can close the dialog and continue working while we're creating your custom area",
              title: "Creating Custom Area...",
              timeout: -1,
              colour: "warning",
            });
            this.upsertingProcess = true;

            // make the call!
            this.$axios
              .post("/custom-areas", this.customArea)
              .then(() => {
                this.emit.emit("systemMessage", {
                  title: "Custom Area Creation Complete",
                  message: "Success!",
                  timeout: 3000,
                  colour: "success",
                });
                this.customAreasClone = this.$cloneDeep(this.customAreas);

                // Refresh custom areas table
                this.$emit("getCustomAreasByCategory");

                this.closeDialog();
                this.emit.emit("systemBusy", false);
              })
              .catch((error) => {
                this.emit.emit("systemBusy", false);
                this.emit.emit("systemMessage", {
                  message: error.response.data.message,
                  title: "Error! Custom Area Creation Failed",
                  timeout: -1,
                  colour: "error",
                });
                this.apiValidationErrors(error.response.data.errors);
              })
              .finally(() => {
                this.upsertingProcess = false;
              });
          }
        }
      });
    },
    getCustomAreasPostcodes() {
      this.$axios
        .get(
          "/custom-areas-bring-postcodes/" +
            this.customAreas[this.customAreasIndex].id,
        )
        .then(
          function (response) {
            // handle success
            this.customAreasClone = response.data[0];
            this.postcodesList = this.customAreasClone.postcodes;
            this.loadingOfPostcodes = false;
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
          }.bind(this),
        );
    },
    closeDialog() {
      // first close the dialog
      this.$emit("dialog", false);
      // then reset the view of the dialog
      this.paste = false;
      this.addPostcodes = true;
      this.csv = false;
      // Reset page to initial state
      this.matched = [];
      this.cloneOfInput = null;
      this.raw_postcode = null;
      this.tableSearch = "";
      this.continueExecution = false;
      this.postcode_array = [];
      this.matching = false;
      this.unmatched = false;
      this.results = false;
      this.areasByAreaLevel = [];
      this.selectedAreaLevel = false;
      this.customAreasIndex = null;
      this.raw_postcode = null;
      this.postcode_csv = null;
      this.showEditTable = true;
      this.show_unmatched = false;
      this.loadingOfPostcodes = true;
      // Restore originals
      this.customArea = {
        areas: [],
      };
    },
    // Build empty fields for creation
    buildCustomArea() {
      for (const key in this.fieldSchema) {
        if (this.fieldSchema[key].useInCreationMode) {
          this.customArea[key] = null;
        }
      }
      this.$emit("dialog", true);
    },
    apiValidationErrors(errors) {
      for (let field in errors) {
        this.fieldSchema[field].apiResponseError = errors[field];
      }
    },
    resetApiValidationErrors() {
      for (let field in this.fieldSchema) {
        this.fieldSchema[field].apiResponseError = null;
      }
    },
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    },
  },
  watch: {
    postcodeCustomAreaDialog(val) {
      if (val) {
        this.fieldSchema = this.$cloneDeep(this.schema);
        this.resetApiValidationErrors();
        // get the postcodes
        if (this.editMode) {
          this.addPostcodes = false;
          this.getCustomAreasPostcodes();
        }
      }
    },
    categories: {
      handler(val) {
        if (val && Object.keys(this.fieldSchema).length > 0) {
          this.fieldSchema.custom_area_category_id.selectItems = val;
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.tooltip {
  position: relative;
  display: inline-block;
}

.progressText {
  width: 100%;
  text-align: center;
  font-size: 14px;
  height: 300px;
}

.progress {
  margin-top: 150px;
  width: 100%;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;
}

.tooltip-country .tooltiptext {
  background-color: orange;
}

.tooltip-undefined .tooltiptext {
  background-color: red;
}

.clickable-icon {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  padding: 8px;
  transition: background-color 0.2s;
}

.clickable-icon:hover {
  background-color: #ececed;
  border-radius: 5px;
}

/* remove hover effect on table rows */
.custom-table .v-data-table__row:hover {
  background-color: inherit !important;
}

.custom-table .v-data-table__row:hover {
  background-color: inherit !important;
}

.custom-table
  .v-data-table__wrapper
  tbody
  tr:hover:not(.v-data-table__expand-row):not(.v-data-table__empty-wrapper),
.custom-table
  .v-data-table__wrapper
  tbody
  tr:hover
  .v-data-table__expanded__content {
  background-color: inherit !important;
}

.unmatchedPostcodeRow:hover {
  background-color: #ececec;
}

.source {
  font-size: 10px;
  position: "absolute";
  bottom: "0";
  margin-top: 5px;
}

.btn-wrap :deep(.v-btn__content) {
  white-space: normal !important;
}

.btn-wrap {
  min-height: 2.5em;
}
</style>
