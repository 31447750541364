<template>
  <div class="text-center">
    <v-dialog
      v-model="computedMapDataDialog"
      max-width="800px"
      scrollable
      id="mapDataDialog"
    >
      <v-card>
        <v-toolbar
          :style="
            'background-color: ' +
            this.$store.state.config.siteConfig.toolbar_colour
          "
          class="text-h6"
        >
          <v-btn
            tile
            icon
            dark
            @click="computedMapDataDialog = false"
            aria-label="Close"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title> Map Data </v-toolbar-title>
          <v-spacer></v-spacer>
          <div style="width: 50%">
            <v-text-field
              id="searchIndicators"
              class="fields data-search mr-2"
              clearable
              variant="outlined"
              density="compact"
              rounded="0"
              hide-details="true"
              label="Search indicators"
              prepend-inner-icon="mdi-magnify"
              :single-line="true"
              v-model="searchTerm"
              autocomplete="off"
            ></v-text-field>
          </div>
        </v-toolbar>
        <!-- loading UX -->
        <v-card-actions v-if="loading" tile elevation="0" class="progress">
          <div class="progressText">
            <v-progress-circular
              :size="200"
              :width="3"
              color="#51627C"
              indeterminate
              >Loading Map Data</v-progress-circular
            >
          </div>
        </v-card-actions>
        <v-card-text
          v-else
          style="height: 500px"
          class="overflow-y-auto mt-0 pt-0"
        >
          <v-card-text class="pb-0"
            >Search through the available indicators using the box
            above</v-card-text
          >
          <v-card-text v-if="searchTerm && !searchHits" class="pb-0"
            >No indicator matches your search term</v-card-text
          >
          <v-card-actions>
            <v-list
              style="width: 100%"
              id="indicatorResults"
              :opened="opened"
              rounded="0"
            >
              <div
                v-for="(theme, groupNum) in themesAndIndicators"
                :key="theme.id"
                id="theme"
              >
                <v-list-group
                  ref="themeLists"
                  :id="'indicatorResults_' + groupNum"
                  v-if="!theme.hidden"
                  :value="theme.id"
                  no-action
                >
                  <template #activator="{ props }">
                    <v-list-item v-bind="props">
                      <v-list-item-title>
                        {{
                          theme.name + " (" + shownIndicatorCount(theme) + ")"
                        }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template
                    v-for="(indicator, index) in theme.indicators"
                    :key="index"
                  >
                    <v-list-item
                      v-if="!indicator.hidden"
                      @click="selectIndicator(indicator.id)"
                      :class="{
                        'custom-data pl-6 ml-16': indicator.client_id,
                        'standard-data pl-6 ml-16': !indicator.client_id,
                      }"
                    >
                      <v-list-item-title
                        :id="groupNum + '_indicator_' + index"
                        class="indicator"
                      >
                        {{ indicator.indicator_name }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-list-group>
              </div>
            </v-list>
          </v-card-actions>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            tile
            color="error"
            variant="elevated"
            @click="computedMapDataDialog = false"
            aria-label="close"
          >
            close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "MAPDATA",
  data: () => ({
    loading: true,
    themesAndIndicators: [],
    searchTerm: "",
    groupStates: {},
    opened: [],
  }),
  computed: {
    computedMapDataDialog: {
      get() {
        return this.mapDataDialog;
      },
      set(val) {
        this.$emit("update:mapDataDialog", val);
      },
    },
    searchHits: function () {
      if (!this.searchTerm) {
        return false;
      }

      let total = 0;
      this.themesAndIndicators.forEach((theme) => {
        const hasVisibleIndicators = theme.indicators.some(
          (indicator) => !indicator.hidden,
        );
        if (hasVisibleIndicators) {
          total += 1;
        }
      });

      return total > 0;
    },
  },
  components: {},
  props: {
    mapDataDialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    selectedIndicator: null,
  },
  mounted() {
    this.getThemesComplete();
  },
  methods: {
    shownIndicatorCount(theme) {
      // return the number of indicators which are not hidden
      return theme.indicators.filter((indicator) => !indicator.hidden).length;
    },
    getThemesComplete() {
      this.loading = true;
      this.$axios
        .get("/themes-indicators-map")
        .then(
          function (response) {
            // handle success
            this.themesAndIndicators = response.data;
            this.loading = false;
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.loading = false;
          }.bind(this),
        );
    },
    resetThemeLists() {
      if (this.searchTerm) {
        let shouldUpdateOpened = false;
        this.themesAndIndicators.forEach((theme) => {
          let allHidden = true;
          theme.indicators.forEach((indicator) => {
            if (
              indicator.indicator_name
                .toLowerCase()
                .includes(this.searchTerm.toLowerCase())
            ) {
              indicator.hidden = false;
              allHidden = false;
            } else {
              indicator.hidden = true;
            }
          });

          if (allHidden) {
            theme.hidden = true;
            this.opened = this.opened.filter((id) => id !== theme.id);
          } else {
            theme.hidden = false;
            if (!this.opened.includes(theme.id)) {
              this.opened.push(theme.id);
              shouldUpdateOpened = true;
            }
          }
        });

        // Update opened array if necessary
        if (shouldUpdateOpened) {
          this.opened = [...new Set(this.opened)];
        }
      } else {
        // Clear search term: make everything visible
        this.themesAndIndicators.forEach((theme) => {
          theme.hidden = false;
          theme.indicators.forEach((indicator) => {
            indicator.hidden = false;
          });
        });
        this.opened = [];
      }
    },
    selectIndicator(indicatorId) {
      this.$emit("selectedIndicator", indicatorId);
      this.resetThemeLists();
      this.computedMapDataDialog = false;
    },
  },
  watch: {
    computedMapDataDialog: function (val) {
      if (!val) {
        this.searchTerm = "";
      }
    },
    searchTerm() {
      this.resetThemeLists();
    },
  },
};
</script>

<style scoped>
.progressText {
  width: 100%;
  text-align: center;
  font-size: 14px;
  height: 500px;
}
.progress {
  margin-top: 150px;
  width: 100%;
}

.standard-data {
  border-left: 5px solid #4da7ff;
}

.custom-data {
  border-left: 5px solid #ffa64d;
}

.data-search {
  background-color: white !important;
  color: rgb(81, 98, 124) !important;
}
</style>
