<!--
  This component is used for v-data-tables that need
  draggable rows and slot styling for the items at the same time
-->
<template>
  <tr class="tableRow" :class="{ darkenBackground: isSelectedTheme }">
    <td
      v-for="(header, index) in headers"
      :key="index"
      :class="header.value === 'indicatorCode' ? 'nameWidth' : ''"
    >
      <slot :item="item" :name="columnName(header)">
        <div class="slotElem">
          {{ getNonSlotValue(item, header) }}
        </div>
      </slot>
    </td>
  </tr>
</template>

<script>
export default {
  name: "DataTableRowHandler",
  components: {},
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    headers: {
      type: Array,
      default: () => {
        return [];
      },
    },
    selectedItem: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    isSelectedTheme() {
      if (
        this.selectedItem &&
        Object.prototype.hasOwnProperty.call(this.selectedItem, "id")
      ) {
        return this.selectedItem.id === this.item.id;
      }

      return false;
    },
  },
  methods: {
    columnName(header) {
      return `item.${header.value}`;
    },
    getNonSlotValue(item, header) {
      const val = item[header.value];

      return val || "";
    },
  },
};
</script>

<style scoped>
td {
  border-top: 1px solid #d5d5d5 !important;
  align-items: center;
}

.darkenBackground {
  background: #eaeaea;
}

.tableRow:hover {
  background: #eaeaea;
}

.nameWidth {
  width: 45%;
  padding-left: 15px;
}
</style>
