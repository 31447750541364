<template>
  <v-dialog
    v-model="searchIndicatorDialog"
    fullscreen
    transition="dialog-bottom-transition"
    height="100%"
  >
    <template v-slot:activator="{ props }">
      <v-btn
        color="success"
        v-bind="props"
        tile
        class="ml-4 mt-1"
        title="add indicator"
        id="addIndicatorDialogue"
        variant="elevated"
        aria-label="add Indicator"
        >add Indicator</v-btn
      >
    </template>
    <v-card>
      <v-toolbar
        :color="this.$store.state.config.siteConfig.toolbar_colour"
        dark
        class="text-h6"
      >
        <v-btn
          @click="closeSearchDialog()"
          class="mr-4"
          dark
          id="closeIndicatorDialog"
          variant="text"
          icon="mdi-close"
          aria-label="Close"
        />
        Search for indicators and custom data</v-toolbar
      >
      <v-container>
        <v-row>
          <v-col>
            <v-form
              ref="form"
              v-model="validation"
              @submit.prevent="searchForIndicator()"
              class="indicatorSearch"
            >
              <v-text-field
                class="fields mt-5"
                variant="outlined"
                density="compact"
                id="searchBox"
                rounded="0"
                autofocus
                label="Enter Indicator Name"
                ref="search"
                v-model="searchString"
                autocomplete="off"
              ></v-text-field>
            </v-form>
          </v-col>
          <v-col class="d-flex align-center" cols="1">
            <v-btn
              :disable="this.$store.state.busy || validation"
              color="success"
              tile
              id="searchButton"
              title="click to search for indicator"
              :loading="loading"
              @click="searchForIndicator()"
              aria-label="search"
            >
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon light>mdi-cached</v-icon>
                </span>
              </template>
              Search
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-card>
              <v-card-title>{{ theme.name }}</v-card-title>
              <v-card-text
                :style="'max-height:' + (height - 262) + 'px; overflow: auto;'"
              >
                <v-list id="addedIndicatorsList">
                  <v-list-item
                    v-for="item in indicators_in_theme"
                    :key="item.id"
                  >
                    <span
                      class="pl-3"
                      style="display: flex"
                      :class="{
                        customDataItem: metadata[item.indicatorCode].client_id,
                        indicatorItem: !metadata[item.indicatorCode].client_id,
                      }"
                    >
                      <v-list-item-title>{{
                        metadata[item.indicatorCode].indicator_name
                      }}</v-list-item-title>
                    </span>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="8">
            <v-card>
              <v-card-title>Search results</v-card-title>
              <v-card-text
                v-if="
                  typeof allSearchData.hits !== 'undefined' &&
                  allSearchData.hits == 0
                "
              >
                There were no matches for the search term "{{
                  allSearchData.search_term
                }}"
              </v-card-text>
              <v-card-text style="overflow: hidden" class="pa-0">
                <v-data-table
                  v-if="allSearchData.hits"
                  :headers="headers"
                  :items="searchResults"
                  :items-per-page="25"
                  :height="height - 316"
                  class="elevation-1"
                  item-key="indicator_code"
                  :single-expand="searchPanelSingleExpand"
                  :expanded="searchPanelExpanded"
                >
                  <template v-slot:[`item.indicator_name`]="{ item }">
                    <span
                      class="pl-3"
                      :class="{
                        customDataItem: item.client_id,
                        indicatorItem: !item.client_id,
                      }"
                    >
                      {{ item.indicator_name }}
                    </span>
                  </template>
                  <template v-slot:[`item.add`]="{ item }">
                    <span v-if="inThemeAlready(item)" class="ml-3">
                      <!-- <v-chip class="in-theme-bubble">{{ theme.name }}</v-chip> -->
                      <v-icon style="color: green">mdi-check</v-icon>
                    </span>
                    <span v-else>
                      <v-progress-circular
                        v-if="item.saving"
                        class="ml-3"
                        id="addingIndicatorSpinner"
                        indeterminate
                        color="success"
                      ></v-progress-circular>
                      <v-btn
                        v-else
                        icon="mdi-plus"
                        variant="text"
                        @click.stop="addIndicatorToTheme(item)"
                        :disabled="addingAnIndicatorToTheme"
                        title="add to theme"
                        id="addIcon"
                        aria-label="add to theme"
                      />
                    </span>
                  </template>
                  <template v-slot:[`item.metadata`]="{ expand, isExpanded }">
                    <v-btn
                      class="pa-0"
                      :icon="
                        !isExpanded ? 'mdi-chevron-down' : 'mdi-chevron-up'
                      "
                      title="metadata information"
                      aria-label="metadata information"
                      label="metadata information"
                      @click="expand(!isExpanded)"
                    />
                  </template>
                  <template v-slot:expanded-row="{ item }">
                    <td
                      :colspan="headers.length"
                      class="expandedRow pa-4 text-left"
                      style="width: 100px; border-left: 5px solid #e7e7e7"
                    >
                      <div class="pt-3 mb-0">
                        {{ item.indicator_description }}
                      </div>
                      <div class="mt-3">
                        <p><strong>Source: </strong>{{ item.source }}</p>
                        <p>
                          <strong>Date: </strong
                          >{{
                            item.date_coverage_text
                              ? item.date_coverage_text
                              : "-"
                          }}
                        </p>
                      </div>
                    </td>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
// import FormsInputsAndControls from '@/components/formsInputsAndControls'
import { useDisplay } from "vuetify";

export default {
  name: "IndicatorSearchFullscreen",
  data: () => ({
    height: useDisplay().height,
    addingAnIndicatorToTheme: false,
    addiAnIndicatorToThemeResult: null,
    allSearchData: {},
    loading: false,
    searchIndicatorDialog: false,
    searchResults: [],
    searchString: "",
    headers: [
      {
        title: "Indicator name",
        value: "indicator_name",
        cellClass: "indicator",
      },
      { title: "Show metadata", value: "data-table-expand" },
      { title: "Add to theme", value: "add", sortable: false },
    ],
    searchPanelExpanded: [],
    searchPanelSingleExpand: false,
    // searchRules: [v => !!v || 'Search string is required'],
    validation: false,
  }),
  components: {
    // FormsInputsAndControls
  },
  props: {
    indicators_in_theme: {},
    metadata: {},
    theme: {},
  },
  computed: {},
  mounted() {
    // this.$refs.search.focus();
    // this.$nextTick(() => this.$refs.search.focus())
  },
  methods: {
    inThemeAlready(indicator) {
      return (
        this.indicators_in_theme.findIndex(
          (p) => p.indicatorCode === indicator.indicator_code,
        ) != -1
      );
    },
    addIndicatorToTheme(indicator) {
      this.addingAnIndicatorToTheme = true;
      indicator["saving"] = true;
      this.$axios
        .post("/themes-manager-indicators", {
          themeID: this.theme["id"],
          indicatorCode: indicator["indicator_code"],
        })
        .then(
          function (response) {
            // handle success
            this.addingAnIndicatorToTheme = false;
            indicator["saving"] = false;
            this.addiAnIndicatorToThemeResult = response.data;
            this.$emit("indicator-added-to-theme", response.data);
            this.emit.emit("systemMessage", {
              message:
                "The " +
                indicator["indicator_name"] +
                " indicator is now part of the " +
                this.theme["name"] +
                " theme",
              title: "Success!",
              timeout: 3000,
              colour: "green",
            });
          }.bind(this),
        )
        .catch(
          function (error) {
            this.addingAnIndicatorToTheme = false;
            indicator["saving"] = false;
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title:
                "Error! Something went wrong when trying to add the indicator to the theme",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    closeSearchDialog() {
      this.searchIndicatorDialog = false;
      this.searchString = "";
      this.searchResults = [];
      this.allSearchData = {};
    },
    searchForIndicator() {
      if (this.$refs.form.validate() && this.searchString != "") {
        // to search pass the special characters through the URL we need to encode string
        let searchString = encodeURIComponent(
          this.searchString.replace("%", "\\%"),
        );

        this.loading = true;
        this.$axios
          .get("themes-manager-indicator-search/" + searchString)
          .then(
            function (response) {
              // handle success
              this.loading = false;
              this.allSearchData = response.data;
              this.searchResults = response.data.standard_indicators;
            }.bind(this),
          )
          .catch(
            function (error) {
              this.loading = false;
              // handle error
              console.error(error);
              this.emit.emit("systemMessage", {
                message: error.response.data.message,
                title: "Error! Something went wrong when searching",
                timeout: -1,
                colour: "red",
              });
            }.bind(this),
          );
      }
    },
  },
  watch: {
    selectedItem: {
      handler() {
        // this.$emit('selectedItem', this.selectedItem)
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.v-chip.in-theme-bubble {
  background-color: #4da7ff;
  color: white;
}

.indicatorItem {
  border-left: 5px solid #4da7ff;
}

.customDataItem {
  border-left: 5px solid #ffa64d;
}
</style>
