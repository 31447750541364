<template>
  <v-dialog v-model="dialog" scrollable persistent max-width="800px">
    <template v-slot:activator="{ props }">
      <v-btn
        color="primary"
        tile
        variant="elevated"
        v-bind="props"
        aria-label="Create New"
        class="mb-6 ml-4"
      >
        Create New
      </v-btn>
    </template>
    <v-card>
      <v-toolbar
        :color="this.$store.state.config.siteConfig.toolbar_colour"
        dark
        class="text-h6 text-center"
        max-height="70px"
      >
        <v-spacer>Create New Report</v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" v-model="validation" lazy-validation>
          <v-container>
            <v-row style="padding-top: 15px !important">
              <v-col
                cols="12"
                sm="12"
                md="6"
                v-for="(item, key) in newReport"
                :key="key"
                style="padding-top: 0px !important"
              >
                <Field
                  :schema="fieldSchema[key]"
                  v-model:value="newReport[key]"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card>
        <v-card-actions>
          <v-btn
            color="error"
            variant="elevated"
            tile
            @click="dialog = false"
            aria-label="Close"
          >
            Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            variant="elevated"
            :disable="this.$store.state.busy"
            color="success"
            tile
            @click="createNewReport()"
            aria-label="Create"
          >
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import Field from "@/components/Fields.vue";
export default {
  name: "ReportCreate",
  data: () => ({
    fieldSchema: {},
    dialog: false,
    newReport: {},
    validation: false,
  }),
  components: {
    Field,
  },
  computed: {},
  props: {
    schema: {},
  },
  mounted() {
    // clone the schema as we do not want any changes (api errors etc) propergating anywhere where this schema is being used
    this.fieldSchema = this.$cloneDeep(this.schema);
  },
  methods: {
    createNewReport() {
      this.resetApiValidationErrors();

      this.$refs.form.validate().then(({ valid: isFormValid }) => {
        if (isFormValid) {
          this.emit.emit("systemMessage", {
            title: "Creating new Report",
            colour: "warning",
          });
          // make the call!
          this.$axios
            .post("/reports", this.newReport)
            .then(() => {
              this.emit.emit("systemMessage", {
                message: "Report Creation Complete",
                title: "Success!",
                timeout: 3000,
                colour: "green",
              });

              this.$emit("newReportCreated");
              this.newReport = {};
              // close the dialogue
              this.dialog = false;
            })
            .catch((error) => {
              this.emit.emit("systemMessage", {
                message: error.response.data.message,
                title: "Error! Report Creation Failed",
                timeout: -1,
                colour: "red",
              });
              this.apiValidationErrors(error.response.data.errors);
            });
        }
      });
    },
    buildNewReport() {
      for (const key in this.fieldSchema) {
        if (this.fieldSchema[key].useInCreationMode) {
          this.newReport[key] = null;
        }
      }
    },
    apiValidationErrors(errors) {
      for (const field in errors) {
        this.fieldSchema[field].apiResponseError = errors[field];
      }
    },
    resetApiValidationErrors() {
      for (var field in this.fieldSchema) {
        this.fieldSchema[field].apiResponseError = null;
      }
    },
  },
  watch: {
    dialog: {
      handler() {
        if (this.dialog && Object.keys(this.newReport).length === 0) {
          this.buildNewReport();
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped></style>
