<template>
  <div style="width: 100%">
    <v-text-field
      v-if="
        (schema.fieldType == 'text' || schema.fieldType == 'password') &&
        schema.show
      "
      v-model="computedValue"
      :type="schema.fieldType"
      :label="schema.label"
      :readonly="schema.readOnly"
      :error-messages="schema.apiResponseError"
      :rules="buildValidation()"
      :maxlength="schema.maxLength ? schema.maxLength : null"
      :title="schema.title ? schema.title : ''"
      variant="outlined"
      density="compact"
      rounded="0"
      class="fields"
      :hint="schema.hint"
      @click="$emit('click', fieldID)"
      @focus="$emit('focus', fieldID)"
      @blur="$emit('blur', fieldID)"
      @keyup="$emit('keyup', fieldID)"
      :append-icon="schema.appendInnerIcon ? schema.appendInnerIcon : null"
      @click:append="
        $emit(
          schema.appendInnerIconOnClick ? schema.appendInnerIconOnClick : null,
          computedValue,
        )
      "
      autocomplete="off"
    ></v-text-field>
    <v-text-field
      v-if="schema.fieldType == 'number' && schema.show"
      v-model="computedValue"
      :label="schema.label"
      :readonly="schema.readOnly"
      :error-messages="schema.apiResponseError"
      :rules="buildValidation()"
      :maxnumber="schema.maxNumber ? schema.maxNumber : null"
      :minnumber="schema.minNumber ? schema.minNumber : null"
      :style="'width: ' + (schema.width ? schema.width : '100%')"
      variant="outlined"
      density="compact"
      rounded="0"
      class="fields"
      :hint="schema.hint"
      type="number"
      @click="$emit('click', fieldID)"
      @focus="$emit('focus', fieldID)"
      @blur="$emit('blur', fieldID)"
      @keyup="$emit('keyup', fieldID)"
      autocomplete="off"
    ></v-text-field>
    <v-text-field
      v-if="schema.fieldType == 'date' && schema.show"
      v-model="formattedDate"
      :label="schema.label"
      readonly
      :error-messages="schema.apiResponseError"
      :rules="buildValidation()"
      variant="outlined"
      density="compact"
      rounded="0"
      class="fields"
      :hint="schema.hint"
      :prepend-icon="!schema.readOnly ? 'mdi-calendar-month-outline' : null"
      @click:prepend="datePicker = !datePicker"
      autocomplete="off"
    ></v-text-field>
    <v-dialog max-width="300px" v-model="datePicker" v-if="!schema.readOnly">
      <v-date-picker
        v-model="computedValue"
        width="400"
        :color="this.$store.state.config.siteConfig.toolbar_colour"
        @update:modelValue="closeDatePicker"
      ></v-date-picker>
    </v-dialog>
    <v-textarea
      v-if="schema.fieldType == 'text-area' && schema.show"
      v-model="computedValue"
      :label="schema.label"
      :readonly="schema.readOnly"
      :error-messages="schema.apiResponseError"
      :rules="buildValidation()"
      :auto-grow="schema.autogrow ? true : false"
      :row-height="schema.rowHeight"
      :rows="schema.rows"
      :counter="schema.counter ? schema.counter : null"
      variant="outlined"
      density="compact"
      rounded="0"
      class="fields"
      :append-icon="schema.appendInnerIcon ? schema.appendInnerIcon : null"
      @click:append="
        $emit(
          schema.appendInnerIconOnClick ? schema.appendInnerIconOnClick : null,
          computedValue,
        )
      "
      :hint="schema.hint"
      @click="$emit('click')"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
      @keyup="$emit('keyup', fieldID)"
    ></v-textarea>
    <v-checkbox
      :id="schema.id"
      v-if="schema.fieldType == 'checkbox' && schema.show"
      v-model="computedValue"
      :label="schema.label"
      :readonly="schema.readOnly"
      :error-messages="schema.apiResponseError"
      :rules="buildValidation()"
      :disabled="schema.disabled ? true : false"
      variant="outlined"
      density="compact"
      rounded="0"
      class="fields"
      :hint="schema.hint"
    ></v-checkbox>
    <v-select
      v-if="schema.fieldType == 'select' && schema.show"
      v-model="computedValue"
      :items="schema.selectItems"
      :item-title="schema.selectText ? schema.selectText : 'text'"
      :item-value="schema.selectValue ? schema.selectValue : 'value'"
      :return-object="schema.returnObject ? true : false"
      :loading="schema.loading ? true : false"
      :disabled="schema.disabled ? true : false"
      :label="schema.label"
      :title="schema.title ? schema.title : ''"
      :readonly="schema.readOnly"
      :error-messages="schema.apiResponseError"
      :rules="buildValidation()"
      variant="outlined"
      density="compact"
      rounded="0"
      class="fields"
      :hint="schema.hint"
      clearable
      :multiple="schema.multiple ? true : false"
      :chips="schema.chips ? true : false"
      @change="$emit('change', fieldID)"
    >
    </v-select>
    <v-radio-group
      v-if="schema.fieldType == 'radio' && schema.show"
      v-model="computedValue"
      :label="schema.label"
      density="compact"
      rounded="0"
      mandatory
      column
    >
      <v-radio
        v-for="n in schema.radioItems"
        :key="n"
        :label="n"
        :value="n"
      ></v-radio>
    </v-radio-group>
    <v-autocomplete
      v-if="schema.fieldType == 'autocomplete' && schema.show"
      v-model="computedValue"
      :returnObject="schema.returnObject"
      :items="schema.selectItems"
      :item-title="schema.selectText ? schema.selectText : 'name'"
      :item-value="schema.selectValue ? schema.selectValue : 'value'"
      :label="schema.label"
      :disabled="schema.disabled ? true : false"
      :error-messages="schema.apiResponseError"
      :rules="buildValidation()"
      :hint="schema.hint"
      :readonly="schema.readonly"
      :filled="schema.filled ? schema.filled : false"
      :chips="schema.chips ? schema.chips : false"
      :multiple="schema.multiple ? schema.multiple : false"
      :autofocus="schema.autoFocus || false"
      variant="outlined"
      density="compact"
      rounded="0"
      class="fields"
      align-center
      clearable
      autocomplete="off"
    >
    </v-autocomplete>
  </div>
</template>

<script>
import { formatDate } from "@/mixins/FormatDate";
import { useDate } from "vuetify";

// LOOK!
//   schemaExample: {
//     "label": "Client ID",
//     "fieldType": "text",
//     "apiResponseError": "",
//     "readOnly": true,
//     "show": true,
//     "required": false,
//     "minLength": false,
//     "maxLength": false,
//     "validationRegex": false,
//     "failedRegexMessage": null,
//     "value": "",
//     "hint": null,
//     selectItems: [ --- required for select field type only
//      { text: 1, value: 1 },
//      { text: 2, value: 2 },
//      { text: 3, value: 3 },
//     ],
//     selectText: "name" --- used for select field type only but not required
//     selectValue: "id" --- used for select field type only but not required
//     returnObject: true --- used for select field type only but not required
//   },

export default {
  name: "FIELDS",
  data: () => ({
    datePicker: false,
    date: null,
  }),
  mounted() {
    if (this.schema.fieldType === "date" && this.value == "") {
      this.computedValue = new Date();
    }
  },
  computed: {
    formattedDate: {
      get() {
        if (!this.value) {
          return formatDate(new Date());
        } else {
          return formatDate(this.value);
        }
      },
    },
    computedValue: {
      get() {
        // we need this bit of logic because the vuetify date selecter has a meltdown if you don't pass a real date into it
        if (this.schema.fieldType === "date") {
          if (this.value == "" || this.value == null) {
            return null;
          } else {
            return useDate().parseISO(this.value);
          }
        } else {
          return this.value;
        }
      },
      set(value) {
        // convert it into something the DB can use (YYYY-MM-DD) (the "toISOString().substring(0, 10)" way seems to lose a day when working with this date picker hence the long hand version below)
        if (this.schema.fieldType === "date") {
          value = new Date(value);
          value =
            value.getFullYear() +
            "-" +
            (value.getMonth() + 1 < 10
              ? "0" + (value.getMonth() + 1)
              : value.getMonth() + 1) +
            "-" +
            (value.getDate() < 10 ? "0" : "") +
            value.getDate();
        }
        this.$emit("update:value", value);
      },
    },
  },
  components: {},
  props: {
    fieldID: null,
    value: {
      required: false,
    },
    schema: Object,
  },
  methods: {
    closeDatePicker() {
      this.datePicker = false;
      this.$emit("change", this.fieldID);
    },
    buildValidation() {
      var validation = [];
      if (this.schema.required) {
        validation.push((v) => !!v || "* field is required");
      }
      if (this.schema.minLength) {
        validation.push(
          (v) =>
            (v && v.length >= this.schema.minLength) ||
            "* minimum of " + this.schema.minLength + " characters required",
        );
      }
      if (this.schema.maxLength) {
        validation.push(
          (v) =>
            (v && v.length <= this.schema.maxLength) ||
            "* maximum of " + this.schema.maxLength + " characters allowed",
        );
      }

      if (this.schema.minNumber !== undefined) {
        validation.push(
          (v) =>
            Number(v) >= Number(this.schema.minNumber) ||
            "* must be number equal to or greater than " +
              this.schema.minNumber,
        );
      }
      if (this.schema.maxNumber) {
        validation.push(
          (v) =>
            Number(v) <= Number(this.schema.maxNumber) ||
            "* must be number equal to or lower than " + this.schema.maxNumber,
        );
      }
      if (this.schema.validationRegex) {
        // not gotten around to this!
      }
      return validation;
    },
    tryAppend() {
      console.log("HELLO");
    },
  },
  watch: {},
};
</script>

<style scoped></style>
