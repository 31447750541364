<template>
  <v-expansion-panels v-model="isOpened" class="my-4 indicatorCard">
    <v-expansion-panel
      style="border: solid 1px #c2c2c2; border-radius: 8px"
      :value="true"
    >
      <!-- Header -->
      <v-expansion-panel-title
        class="expansionTitlePanel pl-0 pr-4"
        :style="{ borderBottom: isOpened ? 'solid 1px #c2c2c2' : '' }"
      >
        <template v-slot:default>
          <!-- skeleton loader  -->
          <v-card-actions v-if="loading" style="width: 80%">
            <v-skeleton-loader
              type="list-item-two-line"
              style="background-color: inherit"
              width="100%"
            />
          </v-card-actions>

          <v-card-actions v-else>
            <div class="circle mr-3 ml-2" style="background-color: green" />
            <div class="d-flex flex-column">
              <div class="d-flex align-items-center">
                <div class="text" style="font-weight: 500">
                  {{ indicator.indicator_name }}
                </div>
              </div>
              <div class="d-flex mt-2 align-center">
                <v-icon icon="mdi-tag-outline mr-1" size="17px" />
                <p class="text text-caption">tags</p>
              </div>
            </div>
          </v-card-actions>
        </template>
      </v-expansion-panel-title>

      <!-- Content under the panel -->
      <v-expansion-panel-text>
        <!-- loading skeleton -->
        <v-row v-if="loading">
          <v-col cols="9" class="py-0 pl-0 pr-2">
            <div class="infoValue" v-for="n in 4" :key="n">
              <v-skeleton-loader
                class="pa-0 ma-0"
                max-width="200"
                type="subtitle"
              ></v-skeleton-loader>
              <v-skeleton-loader
                class="pa-0 ma-0"
                type="subtitle"
              ></v-skeleton-loader>
            </div>
            <!-- Custom content -->
            <slot name="firstColumn" />
          </v-col>
          <v-divider vertical class="mt-2" />
          <v-col cols="3">
            <v-skeleton-loader
              v-for="n in 5"
              :key="n"
              type="list-item-two-line"
              style="background-color: inherit"
            />

            <slot name="secondColumn" />
          </v-col>
        </v-row>

        <!-- Information -->
        <v-row v-else class="indicatorInfo">
          <v-col cols="9" class="py-0 pl-0 pr-2">
            <!-- About -->
            <v-card-actions class="px-0 py-2">
              <v-icon icon="mdi-information-outline" />
              <v-card-text class="pa-0">
                <div class="text-subtitle-1">About</div>
                <div>{{ indicator.indicator_description }}</div>
              </v-card-text>
            </v-card-actions>
            <!-- Warnings -->
            <v-card-actions class="px-0 py-2">
              <v-icon icon="mdi-alert" />
              <v-card-text class="pa-0">
                <div class="text-subtitle-1">Warnings</div>
                <div>
                  {{ indicator.warnings }}
                </div>
              </v-card-text>
            </v-card-actions>
            <!-- Source -->
            <v-card-actions class="px-0 py-2">
              <v-icon icon="mdi-bullhorn-variant-outline" />
              <v-card-text class="pa-0">
                <div class="text-subtitle-1">Source</div>
                <div>
                  {{ indicator.source }}
                </div>
              </v-card-text>
            </v-card-actions>

            <!-- Custom columns -->
            <slot name="firstColumn" />
          </v-col>
          <v-divider vertical class="mt-2" />
          <v-col cols="3" class="pl-4">
            <!-- Latest time point -->
            <v-card-actions class="px-0 py-2">
              <v-icon icon="mdi-calendar-clock" />
              <v-card-text class="pa-0">
                <div class="text-subtitle-1">Latest time point</div>
                <div>
                  {{ indicator.latest_timepoint }}
                </div>
              </v-card-text>
            </v-card-actions>

            <!-- Time points -->
            <v-card-actions class="px-0 py-2">
              <v-icon icon="mdi-timeline-plus" />
              <v-card-text class="pa-0">
                <div class="text-subtitle-1">Time points</div>
                <div>
                  {{ indicator.timepoints }}
                </div>
              </v-card-text>
            </v-card-actions>

            <!-- Time point availability range -->
            <v-card-actions class="px-0 py-2">
              <v-icon icon="mdi-calendar-range" />
              <v-card-text class="pa-0">
                <div class="text-subtitle-1">Time point availability range</div>
                <div>
                  {{ indicator.time_point_availability_range }}
                </div>
              </v-card-text>
            </v-card-actions>

            <!-- Update Frequency -->
            <v-card-actions class="px-0 py-2">
              <v-icon icon="mdi-update" />
              <v-card-text class="pa-0">
                <div class="text-subtitle-1">Update Frequency</div>
                <div>
                  {{ indicator.update_frequency }}
                </div>
              </v-card-text>
            </v-card-actions>

            <!-- Coverage -->
            <v-card-actions class="px-0 py-2">
              <v-icon icon="mdi-earth" />
              <v-card-text class="pa-0">
                <div class="text-subtitle-1">Coverage</div>
                <div>
                  {{ indicator.coverage }}
                </div>
              </v-card-text>
            </v-card-actions>

            <slot name="secondColumn" />
          </v-col>
        </v-row>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
export default {
  name: "IndicatorCard",
  data: () => ({
    isOpened: false,
  }),
  props: {
    loading: {
      type: Boolean,
      default: false,
      required: false,
    },
    indicator: {
      type: Object,
      default: () => ({}),
      required: false,
    },
  },
  methods: {},
};
</script>
<style scoped>
.v-card-actions {
  gap: 0;
}

.circle {
  width: 11px;
  height: 11px;
  border-radius: 10px;
}

.expansionTitlePanel {
  max-height: 77px !important;
  min-height: 10px !important;
}

.indicatorInfo .v-icon {
  margin-right: 12px;
  margin-top: 8px;
}

.indicatorInfo .v-card-actions {
  align-items: start;
}

* {
  color: #4d4d4d;
}
</style>

<style>
/* customize the positioning of the skeleton loaders */
.infoValue {
  margin-top: 4px;
}

.infoValue .v-skeleton-loader:nth-child(1) .v-skeleton-loader__bone {
  margin-bottom: 3px;
}

.infoValue .v-skeleton-loader:nth-child(2) .v-skeleton-loader__bone {
  margin-top: 0px;
  margin-bottom: 8px;
}

.infoValue .v-skeleton-loader__bone {
  margin-left: 0;
}

.indicatorCard .v-expansion-panel-text__wrapper {
  padding: 16px;
}

.indicatorCard .v-row {
  margin: 0;
}
</style>
