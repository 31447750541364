<template>
  <v-container fluid class="pa-0">
    <!-- the parent card/tile -->
    <v-card tile min-height="100%">
      <v-card-title
        ><h1 class="h1 text-left mt-7">
          Theme and indicator management
        </h1></v-card-title
      >
      <div class="pa-3">
        <v-divider />
      </div>
      <v-container fluid pa-4>
        <v-row dense>
          <!-- List of Themes for this client -->
          <v-col cols="4">
            <v-card
              class="text-center"
              v-if="loadingThemes"
              :style="'height:' + (height - 180) + 'px'"
            >
              <v-card-text class="pa-5" id="loadingThemes">
                <v-progress-circular
                  indeterminate
                  size="64"
                  color="primary"
                ></v-progress-circular>
                <div class="mt-3">Loading Themes</div>
              </v-card-text>
            </v-card>
            <v-card tile pa-5 v-else>
              <v-card-actions>
                <v-card-title>Themes</v-card-title>
              </v-card-actions>
              <v-card-actions>
                <v-card-text v-if="!themes.length" class="text-center">
                  You do not have any themes.
                </v-card-text>
                <v-card-text
                  v-else
                  :style="'height:' + (height - 305) + 'px; overflow: auto;'"
                >
                  <v-data-table
                    :headers="themeTableHeaders"
                    :items="themes"
                    id="themeTable"
                    item-value="id"
                    :items-per-page="-1"
                    v-model="selectedThemeArray"
                  >
                    <template #body>
                      <Draggable
                        v-model="themes"
                        item-key="id"
                        @end="updateThemePosition"
                        class="draggable-container"
                      >
                        <template v-slot:item="{ element, index }">
                          <data-table-row-handler
                            :item="element"
                            :headers="themeTableHeaders"
                            :selected-item="selectedTheme"
                            style="cursor: pointer"
                            @click="
                              selectTheme({
                                value: true,
                                item: element,
                                keypress: true,
                              })
                            "
                          >
                            <template v-slot:[`item.id`]>
                              <td>
                                <v-btn
                                  icon="mdi-drag"
                                  variant="text"
                                  style="cursor: move !important"
                                  class="pa-0"
                                  :id="'theme_' + index"
                                  title="drag to re-order"
                                  aria-label="drag to re-order"
                                  @keydown="updateOrderByKey($event, index)"
                                />
                              </td>
                            </template>
                            <template v-slot:[`item.name`]="{ item }">
                              <td
                                tabindex="0"
                                class="text-left themeName"
                                @keyup.enter="
                                  selectTheme({
                                    value: true,
                                    item: item,
                                    keypress: true,
                                  })
                                "
                                aria-label="Use the Enter button to fetch the indicators in this theme"
                              >
                                {{
                                  item.name +
                                  " (" +
                                  item.number_of_indicators +
                                  ")"
                                }}
                              </td>
                            </template>
                            <template v-slot:[`item.edit`]="{ item }">
                              <v-icon
                                @click.stop="editThemeProcess(item)"
                                aria-label="edit theme"
                                id="editTheme"
                                aria-hidden="false"
                                title="edit theme"
                              >
                                mdi-pencil
                              </v-icon>
                            </template>
                            <template v-slot:[`item.remove`]="{ item }">
                              <v-icon
                                @click.stop="deleteThemeProcess(item)"
                                aria-label="Delete theme"
                                id="removeTheme"
                                aria-hidden="false"
                                title="delete theme"
                              >
                                mdi-delete
                              </v-icon>
                            </template>
                          </data-table-row-handler>
                        </template>
                      </Draggable>
                    </template>

                    <!-- hide header -->
                    <template #headers></template>
                    <!-- hide footer -->
                    <template #bottom></template>
                  </v-data-table>
                </v-card-text>
              </v-card-actions>
              <v-card-actions class="pt-0">
                <v-spacer />
                <v-btn
                  color="error"
                  tile
                  variant="elevated"
                  class="mt-1"
                  @click="resetThemes(true)"
                  title="reset themes"
                >
                  Reset themes
                </v-btn>
                <v-btn
                  color="success"
                  tile
                  id="addTheme"
                  variant="elevated"
                  class="mt-1"
                  @click="
                    newTheme = { name: null };
                    showNewThemeDialog = true;
                  "
                  title="add theme"
                >
                  Add theme
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <!-- Indicators in the selected theme -->
          <v-col cols="8">
            <v-card tile pa-5>
              <v-card-text
                v-if="!showThemesIndicatorPanel && !loadingThemesIndicators"
                >Select a theme to display and manage indicators</v-card-text
              >
              <v-card-actions>
                <v-row>
                  <v-col>
                    <v-card-title class="text-left"
                      ><span v-if="selectedTheme && selectedTheme.name">{{
                        selectedTheme.name
                      }}</span></v-card-title
                    ></v-col
                  >
                  <v-col style="text-align: right">
                    <v-spacer />
                    <span v-if="showThemesIndicatorPanel">
                      <IndicatorSearchFullscreen
                        :theme="selectedTheme"
                        :indicators_in_theme="themesIndicators"
                        :metadata="standardMetadata"
                        @indicator-added-to-theme="addIndicatorToTheme"
                      /> </span
                  ></v-col>
                </v-row>
              </v-card-actions>

              <v-card-actions v-if="showThemesIndicatorPanel">
                <v-card-text v-if="themesIndicators.length === 0">
                  You do not have any indicators in this theme.
                </v-card-text>
                <v-card-text
                  v-else
                  style="overflow: hidden"
                  class="indicator-table-space pa-0"
                >
                  <!--   just the zone for dragging the indicators and scroll up   -->
                  <v-card-text
                    id="scroll-up"
                    style="
                      padding-top: 57px;
                      position: absolute;
                      z-index: 1001 !important;
                      padding-left: 100%;
                    "
                    @dragover.prevent
                    @dragenter="scrollAndDrag"
                    @dragleave="finishScroll"
                    @mouseleave="finishScroll"
                    :style="{
                      display: !isDraggingElement ? 'none' : '',
                    }"
                  >
                  </v-card-text>

                  <v-data-table
                    :headers="headers"
                    :items="themesIndicators"
                    :items-per-page="-1"
                    :style="{ height: height - 253 + 'px' }"
                    class="elevation-1"
                    fixed-header
                    id="indicatorTable"
                    :height="height + 'px'"
                    :loading="loadingThemesIndicators"
                    loading-text="Loading Indicators... Please wait"
                  >
                    <template v-slot:[`header.map`]>
                      <div style="display: inline-flex">
                        <span class="mt-4" style="font-size: 14px"> Map </span>
                        <v-checkbox
                          :model-value="selectAllCheckBox('map')"
                          color="primary"
                          id="showAllOnMap"
                          title="Select all"
                          hide-details
                          @change="selectAll('map')"
                        >
                        </v-checkbox>
                      </div>
                    </template>
                    <template v-slot:[`header.publicSite`]>
                      <div style="display: inline-flex">
                        <span class="mt-5" style="font-size: 14px">
                          Public site
                        </span>
                        <v-checkbox
                          :model-value="selectAllCheckBox('publicSite')"
                          color="primary"
                          title="Select all"
                          hide-details
                          @change="selectAll('publicSite')"
                        >
                        </v-checkbox>
                      </div>
                    </template>

                    <template #body>
                      <Draggable
                        v-model="themesIndicators"
                        item-key="id"
                        @end="updateThemeIndicators"
                        class="draggable-container"
                      >
                        <!--  to customize draggable table rows with `item.string` we need to add a custom component as that lets us to have template in template -->
                        <template v-slot:item="{ element, index }">
                          <data-table-row-handler
                            :item="element"
                            :headers="headers"
                          >
                            <template v-slot:[`item.ordering`]="{ item }">
                              <span
                                :title="
                                  standardMetadata[item.indicatorCode].client_id
                                    ? 'Custom Data'
                                    : 'OCSI Data'
                                "
                                :class="
                                  standardMetadata[item.indicatorCode].client_id
                                    ? 'custom-data'
                                    : 'ocsi-data'
                                "
                                style="cursor: default"
                                class="ml-2"
                              >
                              </span>
                              <v-btn
                                variant="text"
                                class="mr-3 drag-handle"
                                icon="mdi-drag"
                                :id="'indicator_' + index"
                                aria-describedby="indicator-position-change-instructions"
                                aria-label="drag to re-order"
                                label="drag to re-order"
                                title="drag to re-order"
                                @keydown="
                                  updateIndicatorOrderByKey($event, index)
                                "
                              />
                            </template>
                            <template v-slot:[`item.indicatorCode`]="{ item }">
                              <div class="text-left indicatorName">
                                {{
                                  standardMetadata[item.indicatorCode]
                                    .indicator_name
                                }}
                              </div>
                            </template>
                            <template v-slot:[`item.map`]>
                              <v-layout
                                style="display: flex; justify-content: center"
                              >
                                <div class="custom-checkbox">
                                  <input
                                    v-model="element.map"
                                    type="checkbox"
                                    :id="'mapcheckbox_' + index"
                                    style="cursor: pointer"
                                    tabindex="0"
                                    aria-label="show on Map"
                                    title="Show on Map"
                                    @change="updateThemeIndicators()"
                                  />
                                  <label :for="'mapcheckbox_' + index"></label>
                                </div>
                              </v-layout>
                            </template>
                            <template v-slot:[`item.dashboard`]>
                              <v-layout
                                style="display: flex; justify-content: center"
                              >
                                <div class="custom-checkbox">
                                  <input
                                    v-model="element.dashboard"
                                    type="checkbox"
                                    tabindex="0"
                                    :id="'dashboardcheckbox_' + index"
                                    style="cursor: pointer"
                                    aria-label="show on dashboard"
                                    title="Show on Dashboard"
                                    class="custom-checkbox"
                                    @change="updateThemeIndicators()"
                                  />
                                  <label
                                    :for="'dashboardcheckbox_' + index"
                                  ></label>
                                </div>
                              </v-layout>
                            </template>
                            <template v-slot:[`item.publicSite`]>
                              <v-layout
                                style="display: flex; justify-content: center"
                              >
                                <div class="custom-checkbox">
                                  <input
                                    v-model="element.publicSite"
                                    type="checkbox"
                                    tabindex="0"
                                    style="cursor: pointer"
                                    :id="'publicSitecheckbox_' + index"
                                    aria-label="show on Public Site"
                                    title="Show on your public site"
                                    @change="updateThemeIndicators()"
                                  />
                                  <label
                                    :for="'publicSitecheckbox_' + index"
                                  ></label>
                                </div>
                              </v-layout>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                              <v-icon
                                class="mr-5 deleteIndicator"
                                small
                                @click.stop="deleteThemeIndicatorProcess(item)"
                                aria-label="delete indicator"
                                aria-hidden="false"
                                label="delete indicator from theme"
                                title="delete indicator from theme"
                              >
                                mdi-delete
                              </v-icon>
                            </template>
                            <template v-slot:[`item.metadata`]="{ item }">
                              <v-btn
                                variant="text"
                                id="metaDataIcon"
                                class="mr-5"
                                style="padding: 0 !important"
                                icon="mdi-information-outline"
                                @click="showIndicatorMetadata(item)"
                                title="metadata information"
                                aria-label="metadata information"
                                label="metadata information"
                              />
                            </template>
                          </data-table-row-handler>
                        </template>
                      </Draggable>
                    </template>

                    <!-- hide footer -->
                    <template #bottom></template>
                  </v-data-table>

                  <!--   just the zone for dragging the indicators and scroll down   -->
                  <v-card-text
                    id="scroll-down"
                    style="
                      padding-top: 51px;
                      bottom: 0px;
                      position: absolute;
                      padding-left: 100%;
                    "
                    @dragenter="scrollAndDrag"
                    @dragleave="finishScroll"
                    @mouseleave="finishScroll"
                    @dragover.prevent
                    :style="{
                      display: !isDraggingElement ? 'none' : '',
                    }"
                  >
                  </v-card-text>
                </v-card-text>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-dialog v-model="showDeleteThemeDialog" scrollable max-width="550px">
      <v-card>
        <v-toolbar
          :style="
            'max-height: 60px; ' +
            'background-color: ' +
            this.$store.state.config.siteConfig.toolbar_colour +
            '; color:#ffffff;'
          "
          class="text-h6 text-center"
        >
          <v-spacer>
            <v-toolbar-title>Confirm Theme Deletion</v-toolbar-title>
          </v-spacer>
        </v-toolbar>
        <v-card-text class="mt-3">
          Are you sure you want to delete the "{{ theme_clone.name }}" theme?
        </v-card-text>
        <v-card-text class="mb-3">
          Indicators in this theme will be still be available to any other
          themes they have been assigned to.
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            variant="elevated"
            color="success"
            tile
            @click="showDeleteThemeDialog = false"
            aria-label="cancel"
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            variant="elevated"
            color="error"
            id="confirmDeleteTheme"
            tile
            @click="deleteTheme()"
            aria-label="delete"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showEditThemeDialog" scrollable max-width="440px">
      <v-card>
        <v-toolbar
          :style="
            'max-height: 60px; ' +
            'background-color: ' +
            this.$store.state.config.siteConfig.toolbar_colour +
            '; color:#ffffff;'
          "
          class="text-h6 text-center"
        >
          <v-spacer>
            <v-toolbar-title>Change name of theme</v-toolbar-title>
          </v-spacer>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text class="pa-5 pb-0 editThemeName">
          <v-text-field
            class="fields mt-2"
            density="compact"
            id="editNameField"
            rounded="0"
            label="Name"
            variant="outlined"
            required
            v-model="theme_clone.name"
            autocomplete="off"
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            variant="elevated"
            color="error"
            tile
            @click="showEditThemeDialog = false"
            aria-label="close"
          >
            Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            variant="elevated"
            color="success"
            id="saveNameEdit"
            tile
            @click="editTheme()"
            aria-label="save"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showNewThemeDialog" scrollable max-width="440px">
      <v-card>
        <v-toolbar
          :style="
            'max-height: 60px; ' +
            'background-color: ' +
            this.$store.state.config.siteConfig.toolbar_colour +
            '; color:#ffffff;'
          "
          class="text-h6 text-center"
        >
          <v-spacer>
            <v-toolbar-title>Add new theme</v-toolbar-title>
          </v-spacer>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text class="newThemeName">
          <v-text-field
            v-model="newTheme.name"
            autofocus
            id="inputNameBox"
            @keyup.enter="addTheme()"
            class="fields mt-3 mb-3"
            hide-details="false"
            label="Name"
            variant="outlined"
            density="compact"
            rounded="0"
            required
            autocomplete="off"
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            variant="elevated"
            color="error"
            tile
            @click="showNewThemeDialog = false"
          >
            Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            variant="elevated"
            id="saveNewTheme"
            color="success"
            tile
            @click="addTheme()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmDialog" width="550">
      <v-card>
        <v-toolbar
          :style="
            'background-color: ' +
            this.$store.state.config.siteConfig.toolbar_colour
          "
          class="text-h6 text-center"
        >
          <v-spacer>Confirm Reset To Default Themes</v-spacer>
        </v-toolbar>
        <v-card-text class="mt-3 mb-3" style="text-align: center">
          Are you sure you want to delete all of your custom themes and revert
          to the default? This will also reset your Default Dashboard Themes.
          You <i>cannot</i> undo this action!
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            variant="elevated"
            color="success"
            tile
            @click="resetThemes(false)"
            aria-label="cancel"
          >
            cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            variant="elevated"
            color="error"
            tile
            @click="resetThemes(true)"
            aria-label="Reset"
          >
            Reset
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <IndicatorDetailsDialog
      v-model:dialog="indicatorMetadataDialogue"
      :indicator="standardMetadata[indicatorMetadata?.indicatorCode]"
    />
    <v-dialog v-model="showDeleteIndicatorDialog" scrollable max-width="550px">
      <v-card>
        <v-toolbar
          :style="
            'max-height: 60px; ' +
            'background-color: ' +
            this.$store.state.config.siteConfig.toolbar_colour +
            '; color:#ffffff;'
          "
          class="text-h6 text-center"
        >
          <v-spacer>
            <v-toolbar-title>Remove indicator</v-toolbar-title>
          </v-spacer>
        </v-toolbar>
        <v-card-text class="mt-3">
          Are you sure you want to remove the indicator from the theme?
        </v-card-text>
        <v-card-text class="mb-3">
          If this indicator is part of other themes it will still be available
          in those themes.
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            variant="elevated"
            color="success"
            tile
            @click="showDeleteIndicatorDialog = false"
            aria-label="Cancel"
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            variant="elevated"
            color="error"
            class="confirmIndicatorDeletion"
            tile
            @click="removeIndicatorFromTheme()"
            aria-label="Delete"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import IndicatorSearchFullscreen from "@/components/IndicatorSearchFullscreen";
import IndicatorDetailsDialog from "@/components/IndicatorDetailsDialog";
import Draggable from "vuedraggable";
import DataTableRowHandler from "@/components/DataTableRowHandler.vue";
import { useDisplay } from "vuetify";

export default {
  name: "ThemesManager",
  data: () => ({
    height: useDisplay().height,
    confirmDialog: false,
    changingCount: false,
    indicatorBeingRemoved: null,
    loadingThemes: false,
    loadingThemesIndicators: false,
    newTheme: {
      name: null,
    },
    themeTableHeaders: [
      { text: "Ordering", value: "id" },
      { text: "Theme name", value: "name" },
      { text: "Edit", value: "edit" },
      { text: "Remove", value: "remove" },
    ],
    positionChangeModeIndicators: false,
    positionChangeModeThemes: false,
    selectedThemeArray: [],
    selectedTheme: null,
    showDeleteThemeDialog: false,
    showDeleteIndicatorDialog: false,
    showEditThemeDialog: false,
    showNewThemeDialog: false,
    showThemesPanel: false,
    showThemesIndicatorPanel: false,
    standardMetadata: {},
    theme_clone: {
      name: null,
    },
    themes: [],
    themesClone: [],
    themesIndicators: [],
    themesIndicatorsClone: [],
    scrollInterval: null,
    isDraggingElement: false,
    themeKeyMoveInterval: null,
    indicatorKeyMoveInterval: null,
    indicatorMetadata: null,
    indicatorMetadataDialogue: false,
  }),
  components: {
    IndicatorSearchFullscreen,
    Draggable,
    DataTableRowHandler,
    IndicatorDetailsDialog,
  },
  computed: {
    headers: {
      get() {
        let result = [
          {
            title: "",
            value: "ordering",
            sortable: false,
          },
          {
            title: "Indicator name",
            value: "indicatorCode",
            sortable: false,
          },
          { title: "Map", value: "map", align: "center", sortable: false },
          {
            title: "Default Dashboard",
            value: "dashboard",
            align: "center",
            sortable: false,
          },
          {
            title: "Remove",
            value: "actions",
            sortable: false,
          },
          {
            title: "Metadata",
            value: "metadata",
            sortable: false,
          },
        ];
        if (this.$store.state.config.siteConfig.is_public_site) {
          result.splice(result.length - 1, 0, {
            title: "Public Site",
            value: "publicSite",
            align: "center",
            sortable: false,
          });
        }
        return result;
      },
    },
  },
  props: {
    model: null,
    item: {},
    disable: {
      type: Boolean,
      required: false,
      default: false,
    },
    relatedModelResults: {},
  },
  mounted() {
    this.getThemes();
    document.addEventListener("dragover", () => {
      this.isDraggingElement = true;
    });
    document.addEventListener("drop", () => {
      this.isDraggingElement = false;
      this.finishScroll();
    });
  },
  beforeUnmount() {
    document.removeEventListener("dragover", () => {
      this.isDraggingElement = false;
    });
    document.removeEventListener("drop", () => {
      this.isDraggingElement = false;
    });
  },
  methods: {
    showIndicatorMetadata(item) {
      this.indicatorMetadata = item;
      this.indicatorMetadataDialogue = true;
    },
    updateIndicatorOrderByKey(event, index) {
      clearTimeout(this.indicatorKeyMoveInterval);

      let direction = false;

      switch (event.keyCode) {
        case 38:
          direction = "up";
          break;
        case 40:
          direction = "down";
          break;
      }

      if (direction) {
        this.themesIndicators = this.reorderArray(
          this.themesIndicators,
          index,
          direction,
        );
        this.$nextTick(() => {
          if (direction === "up") {
            document
              .getElementById(
                index - 1 < 0
                  ? "indicator_" + index
                  : "indicator_" + (index - 1),
              )
              .focus();
          } else {
            document
              .getElementById(
                index + 1 > this.themesIndicators.length - 1
                  ? "indicator_" + index
                  : "indicator_" + (index + 1),
              )
              .focus();
          }
        });

        // give it a second to update the key
        this.indicatorKeyMoveInterval = setTimeout(() => {
          this.updateThemeIndicators();
        }, 1000);
      }
    },
    updateOrderByKey(event, index) {
      clearTimeout(this.themeKeyMoveInterval);

      let direction = false;

      switch (event.keyCode) {
        case 38:
          direction = "up";
          break;
        case 40:
          direction = "down";
          break;
      }

      if (direction) {
        this.themes = this.reorderArray(this.themes, index, direction);
        this.$nextTick(() => {
          if (direction === "up") {
            document
              .getElementById(
                index - 1 < 0 ? "theme_" + index : "theme_" + (index - 1),
              )
              .focus();
          } else {
            document
              .getElementById(
                index + 1 > this.themes.length - 1
                  ? "theme_" + index
                  : "theme_" + (index + 1),
              )
              .focus();
          }
        });

        // give it a second to update the key
        this.themeKeyMoveInterval = setTimeout(() => {
          this.updateThemePosition();
        }, 1000);
      }
    },
    reorderArray(arr, index, direction) {
      if (index < 0 || index >= arr.length) {
        return arr;
      }
      if (direction !== "up" && direction !== "down") {
        return arr;
      }
      if (direction === "up" && index === 0) {
        return arr;
      }
      if (direction === "down" && index === arr.length - 1) {
        return arr;
      }
      const elementToMove = arr.splice(index, 1)[0];
      if (direction === "up") {
        arr.splice(index - 1, 0, elementToMove);
      } else if (direction === "down") {
        arr.splice(index + 1, 0, elementToMove);
      }
      return arr;
    },
    scrollAndDrag(event) {
      // if we dragged an indicator and move the cursor up or down, then scroll the list
      event.preventDefault();

      // get the table element
      const dataTable = document.querySelector(
        ".indicator-table-space .v-table__wrapper",
      );

      if (dataTable) {
        this.scrollInterval = setInterval(() => {
          // get the position of dragged element on the screen
          const mouseY = event.clientY;
          const rect = dataTable.getBoundingClientRect();
          const topOffset = mouseY - rect.top;
          const bottomOffset = rect.bottom - mouseY;

          // scroll step in pixels
          const scrollSpeed = 45;
          const easing = 0.1;
          let scrollAmount = 0;

          if (topOffset < 80) {
            scrollAmount = -scrollSpeed; // scroll up
          } else if (bottomOffset < 50) {
            scrollAmount = scrollSpeed; // scroll down
          }

          const scroll = () => {
            dataTable.scrollTop += scrollAmount;
            scrollAmount *= 1 - easing;

            if (scrollAmount > 0.1 || scrollAmount < -0.1) {
              // we use this stuff only to make the scroll smooth
              requestAnimationFrame(scroll);
            }
          };

          requestAnimationFrame(scroll);
        }, 500);
      }
    },
    finishScroll() {
      clearInterval(this.scrollInterval);
    },
    selectAllCheckBox(thing) {
      return this.themesIndicators.every((item) => item[thing] === true);
    },
    selectAll(thing) {
      var trueOrFalse = this.selectAllCheckBox(thing);

      this.themesIndicators.forEach((indicator) => {
        indicator[thing] = !trueOrFalse;
      });

      this.updateThemeIndicators();
    },
    getThemes() {
      this.showThemesPanel = false;
      this.loadingThemes = true;
      this.emit.emit("systemBusy", true);
      this.$axios
        .get("/themes-manager-themes")
        .then(
          function (response) {
            // handle success
            this.themes = response.data;
            this.themesClone = JSON.parse(JSON.stringify(this.themes));

            this.showThemesPanel = true;
            this.emit.emit("systemBusy", false);
            this.loadingThemes = false;
          }.bind(this),
        )
        .catch(
          function (error) {
            this.emit.emit("systemBusy", false);
            this.loadingThemes = false;
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              title: "Error! Failed to get all the themes",
              message: error.response.data.message,
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    loadIndicatorMetadata(data) {
      this.standardMetadata = {}; // empty the current dictionary
      data.forEach((element) => {
        this.standardMetadata[element.indicator_code] = element;
      });
    },
    addTheme() {
      if (this.newTheme.name) {
        this.emit.emit("systemMessage", {
          title: "Creating new theme",
          message: "Please wait",
          timeout: -1,
          colour: "warning",
        });
        this.$axios
          .post("/themes", this.newTheme)
          .then(
            function (response) {
              // handle success
              // add in the number_of_indicators
              this.showNewThemeDialog = false;
              response.data["number_of_indicators"] = 0;
              this.themes.push(response.data);
              this.themesClone = JSON.parse(JSON.stringify(this.themes));
              this.emit.emit("systemMessage", {
                title: "Success!",
                message: "New theme created",
                timeout: 3000,
                colour: "green",
              });
            }.bind(this),
          )
          .catch(
            function (error) {
              // handle error
              console.error(error);
              this.emit.emit("systemMessage", {
                title: "Error! Failed to create new theme",
                message: error.response.data.message,
                timeout: -1,
                colour: "red",
              });
            }.bind(this),
          );
      } else {
        this.emit.emit("systemMessage", {
          message: "The theme must have a name",
          title: "Error!",
          timeout: -1,
          colour: "red",
        });
      }
    },
    deleteThemeProcess(theme) {
      this.showDeleteThemeDialog = true;
      this.theme_clone = JSON.parse(JSON.stringify(theme));
    },
    deleteThemeIndicatorProcess(indicator) {
      this.showDeleteIndicatorDialog = true;
      this.indicatorBeingRemoved = JSON.parse(JSON.stringify(indicator));
    },
    removeIndicatorFromTheme() {
      this.emit.emit("systemMessage", {
        message: "Please wait",
        title: "Deleting...",
        timeout: -1,
        colour: "warning",
      });
      this.$axios
        .delete(
          "/themes-manager-indicators/" + this.indicatorBeingRemoved["id"],
        )
        .then(
          function (response) {
            // handle success
            if (response.data) {
              this.themesIndicators = response.data.indicators;
              this.themesIndicatorsClone = JSON.parse(
                JSON.stringify(this.themesIndicators),
              );
              this.loadIndicatorMetadata(response.data.metadata);

              this.changingCount = true; // this "turns off" the selectedTheme watcher and stops an indicator reload
              this.selectedTheme.number_of_indicators--;

              this.showDeleteIndicatorDialog = false;
              this.indicatorBeingRemoved = null;

              this.emit.emit("systemMessage", {
                message: "Indicator removed from the theme",
                title: "Success!",
                timeout: 3000,
                colour: "green",
              });
              this.showDeleteIndicatorDialog = false;
            } else {
              // the theme indicator matrix was not found on the server to be deleted
              this.showDeleteIndicatorDialog = false;

              let error =
                "Error! Something went wrong at our end - please contact support";
              console.error(error);
              this.emit.emit("systemMessage", {
                message: error,
                title: "Unable to remove the indicator from the theme",
                timeout: -1,
                colour: "red",
              });
            }
          }.bind(this),
        )
        .catch(
          function (error) {
            this.showDeleteIndicatorDialog = false;
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to remove the indicator from the theme",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    deleteTheme() {
      this.emit.emit("systemBusy", true);
      this.$axios
        .delete("/themes/" + this.theme_clone["id"], this.theme_clone)
        .then(
          function () {
            // handle success
            // remove deleted theme from the themes array
            const index = this.themes.findIndex(
              (p) => p.id === this.theme_clone.id,
            );
            if (index !== -1) {
              this.themes.splice(index, 1);
            }
            this.themesClone = JSON.parse(JSON.stringify(this.themes));
            this.showDeleteThemeDialog = false;
            this.theme_clone = { name: null };

            this.emit.emit("systemMessage", {
              message: "Theme deleted",
              title: "Success!",
              timeout: 3000,
              colour: "green",
            });
            this.emit.emit("systemBusy", false);
          }.bind(this),
        )
        .catch(
          function (error) {
            this.emit.emit("systemBusy", false);
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to delete the theme",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    editThemeProcess(theme) {
      this.showEditThemeDialog = true;
      this.theme_clone = JSON.parse(JSON.stringify(theme));
    },
    editTheme() {
      if (this.theme_clone.name) {
        this.emit.emit("systemBusy", true);
        this.$axios
          .put(
            "/themes-manager-theme/" + this.theme_clone["id"],
            this.theme_clone,
          )
          .then(
            function (response) {
              // handle success
              let data = response.data;

              const index = this.themes.findIndex((p) => p.id === data.id);
              if (index === -1) {
                this.themes.push(data);
              } else {
                this.themes[index] = data;
              }
              this.themesClone = JSON.parse(JSON.stringify(this.themes));

              this.showEditThemeDialog = false;
              this.emit.emit("systemMessage", {
                message: "Theme details changed",
                title: "Success!",
                timeout: 3000,
                colour: "green",
              });
              this.emit.emit("systemBusy", false);
            }.bind(this),
          )
          .catch(
            function (error) {
              this.emit.emit("systemBusy", false);
              // handle error
              console.error(error);
              this.emit.emit("systemMessage", {
                message: error.response.data.message,
                title: "Error! Failed to change the details",
                timeout: -1,
                colour: "red",
              });
            }.bind(this),
          );
      } else {
        this.emit.emit("systemMessage", "Complete the highlighted fields");
      }
    },
    resetThemes(state) {
      if (this.confirmDialog && state) {
        this.emit.emit("systemBusy", true);
        this.showThemesPanel = false;
        this.loadingThemes = true;
        this.confirmDialog = false;
        this.showThemesIndicatorPanel = false;
        this.$axios
          .put("/reset-to-default-themes")
          .then(
            function () {
              this.getThemes();
            }.bind(this),
          )
          .catch(
            function (error) {
              this.getThemes();
              console.error(error);
              this.emit.emit("systemMessage", {
                message: error,
                title: "Error! Failed to Reset to Default Themes",
                timeout: -1,
                colour: "red",
              });
            }.bind(this),
          );
      } else {
        this.confirmDialog = state;
      }
    },
    addIndicatorToTheme(data) {
      this.themesIndicators = data.indicators;
      this.themesIndicatorsClone = JSON.parse(
        JSON.stringify(this.themesIndicators),
      );
      this.loadIndicatorMetadata(data.metadata);
      this.changingCount = true; // this "turns off" the selectedTheme watcher and stops an indicator reload
      this.selectedTheme.number_of_indicators++;
    },
    updateThemeIndicators() {
      this.emit.emit("systemMessage", {
        title: "Updating the details",
        message: "We're updating your indicators",
        timeout: -1,
        colour: "warning",
      });

      // update the order
      this.themesIndicators.forEach((element, index) => {
        element.position = index + 1;
      });

      this.$axios
        .put("/themes-manager-theme-indicators", this.themesIndicators)
        .then(
          function (response) {
            // handle success
            this.themesIndicators = response.data.new_details.indicators;
            this.themesIndicatorsClone = JSON.parse(
              JSON.stringify(this.themesIndicators),
            );
            this.loadIndicatorMetadata(response.data.new_details.metadata);

            this.emit.emit("systemMessage", {
              title: "Success!",
              message: "Update Complete",
              timeout: 3000,
              colour: "green",
            });
          }.bind(this),
        )
        .catch(() => {
          // handle error
          this.themesIndicators = JSON.parse(
            JSON.stringify(this.themesIndicatorsClone),
          );
          this.emit.emit("systemMessage", {
            message: "",
            title: "Error! Failed to update the indicator",
            timeout: -1,
            colour: "red",
          });
        });
    },
    getThemesIndicators(theme) {
      if (!theme.number_of_indicators) {
        // if there are no indicators fast forward to the null state
        this.themesIndicators = [];
        this.themesIndicatorsClone = [];
        this.showThemesIndicatorPanel = true;
        return;
      }

      this.showThemesIndicatorPanel = false;
      this.loadingThemesIndicators = true;
      this.emit.emit("systemBusy", true);
      this.$axios
        .get("/themes-manager-indicators/" + theme.id)
        .then(
          function (response) {
            // handle success
            this.themesIndicators = response.data.indicators;
            this.themesIndicatorsClone = JSON.parse(
              JSON.stringify(this.themesIndicators),
            );
            this.loadIndicatorMetadata(response.data.metadata);
            this.showThemesIndicatorPanel = true;
            this.emit.emit("systemBusy", false);
            this.loadingThemesIndicators = false;
          }.bind(this),
        )
        .catch(
          function (error) {
            this.emit.emit("systemBusy", false);
            this.loadingThemesIndicators = false;
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              title:
                "Error! Failed to get the indicators for the selected theme",
              message: error.response.data.message,
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    updateThemePosition() {
      this.emit.emit("systemMessage", {
        title: "Updating theme order",
        message: "Please wait...",
        timeout: 1000,
        colour: "warning",
      });

      this.themes.forEach((element, index) => {
        element.position = index + 1;
      });

      this.$axios
        .put("/themes-manager-theme-order", this.themes)
        .then(
          function (response) {
            // handle success
            this.themes = response.data;
            this.themesClone = JSON.parse(JSON.stringify(this.themes));
            this.emit.emit("systemMessage", {
              title: "Theme order updated",
              message: "Success!",
              timeout: 3000,
              colour: "green",
            });
          }.bind(this),
        )
        .catch(
          function () {
            // handle error
            this.themes = JSON.parse(JSON.stringify(this.themesClone));
            this.emit.emit("systemMessage", {
              title: "Error! Failed to update the theme order",
              message: "",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    selectTheme(event) {
      if (event.value) {
        const index = this.themes.findIndex((p) => p.id === event.item.id);
        if (index !== -1) {
          this.selectedTheme = this.themes[index];
          // check if this was triggered by a key press rather than a checkbox change
          if (event.keypress) {
            this.selectedThemeArray = [event.item];
          }
        }
      } else {
        this.selectedTheme = null;
      }
    },
  },
  watch: {
    selectedTheme: {
      handler() {
        if (this.selectedTheme) {
          if (!this.changingCount) {
            // do not load the indicators if the number_indicators is being changed
            this.getThemesIndicators(this.selectedTheme);
          }
          this.changingCount = false;
        } else {
          this.showThemesIndicatorPanel = false;
          this.themesIndicators = [];
          this.themesIndicatorsClone = [];
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.indicator {
  border-left: 5px solid #4da7ff;
}

.custom-data {
  border-left: 5px solid #ffa64d;
  cursor: help;
}

.ocsi-data {
  border-left: 5px solid #4da7ff;
  cursor: help;
}

.drag-handle {
  cursor: move !important;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.headerCheckbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
<style>
.v-table--fixed-header > .v-table__wrapper > table > thead {
  z-index: 1000 !important;
}
tbody .v-input__details {
  display: none;
}
.draggable-container {
  display: contents;
}

.custom-checkbox input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 21px;
  height: 14px;
  position: relative;
}

.custom-checkbox input[type="checkbox"]::before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #727272;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: -2px;
  border-radius: 3px;
}

.custom-checkbox input[type="checkbox"]:checked::before {
  background-color: #1866be;
  border: 2px solid #1866be;
  border-radius: 3px;
}

.custom-checkbox input[type="checkbox"]:checked::after {
  content: "\2713";
  font-size: 16px;
  color: #fff;
  position: absolute;
  top: 0px;
  left: 2px;
}
</style>
