<template>
  <v-card>
    <v-divider></v-divider>
    <v-card-actions>
      <v-list-item three-line>
        <v-list-item-title class="text-h5 mb-1"
          >{{ clientConfigOption.name }}
        </v-list-item-title>
        <v-list-item-subtitle>{{
          clientConfigOption.slug
        }}</v-list-item-subtitle>
        <v-list-item-subtitle
          >{{ clientConfigOption.description }}
        </v-list-item-subtitle>
      </v-list-item>

      <!-- editing dialog -->
      <v-dialog v-model="dialog" persistent max-width="600px">
        <template v-slot:activator="{ props }">
          <!-- this spacer pushes the button to the right of the card -->
          <v-spacer></v-spacer>
          <v-btn
            v-bind="props"
            tile
            variant="elevated"
            class="ma-2"
            color="warning"
            aria-label="Edit"
            title="Edit Custom Client Config Option"
          >
            Edit
          </v-btn>
        </template>
        <v-card>
          <v-card-title
            :style="
              'background-color: ' +
              this.$store.state.config.siteConfig.toolbar_colour
            "
            class="text-h6 text-center"
          >
            Edit Custom Client Config Option
          </v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="validation">
              <v-text-field
                label="Custom Client Config Option Name*"
                v-model="clientConfigOptionClone.name"
                required
                :rules="rules.name"
                density="compact"
                rounded="0"
                variant="outlined"
                :error-messages="apiResponseErrors.name"
                autocomplete="off"
              ></v-text-field>
              <v-text-field
                label="Slug*"
                v-model="clientConfigOptionClone.slug"
                required
                :rules="rules.slug"
                density="compact"
                rounded="0"
                variant="outlined"
                :error-messages="apiResponseErrors.slug"
                autocomplete="off"
              ></v-text-field>
              <v-select
                v-model="clientConfigOptionClone.fieldType"
                :items="['text', 'text-area', 'checkbox']"
                label="Field Type"
                density="compact"
                rounded="0"
                variant="outlined"
              >
              </v-select>
              <v-textarea
                label="Description*"
                v-model="clientConfigOptionClone.description"
                required
                :rules="rules.description"
                density="compact"
                rounded="0"
                variant="outlined"
                :error-messages="apiResponseErrors.description"
              ></v-textarea>
              <small>*indicates required field</small>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="error"
              variant="elevated"
              tile
              @click="dialog = false"
              aria-label="Close"
            >
              Close
            </v-btn>
            <v-spacer />
            <v-btn
              tile
              color="success"
              variant="elevated"
              @click="updateSelectedClientConfigOption()"
              aria-label="Save Changes"
            >
              Save Changes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "ClientConfigOptionDialog",
  computed: {
    clientConfigOption: {
      get() {
        return this.$store.state.clientConfigOption;
      },
      set(value) {
        this.$store.commit("setClientConfigOption", value);
      },
    },
    allClientConfigOptions: {
      get() {
        return this.$store.state.allClientConfigOptions;
      },
      set(value) {
        this.$store.commit("setAllClientConfigOptions", value);
      },
    },
  },
  data: () => ({
    dialog: false,
    validation: false,
    clientConfigOptionClone: {},
    apiResponseErrors: {
      name: null,
      slug: null,
      description: null,
    },
    rules: {
      name: [(v) => !!v || "Name is required"],
      slug: [
        (v) => !!v || "Slug is required",
        (v) => (v && !/\s/g.test(v)) || "No Spaces Please!",
      ],
      description: [(v) => !!v || "Description is required"],
    },
  }),
  mounted() {},
  methods: {
    updateSelectedClientConfigOption() {
      // if this passes validation then call the api
      this.resetApiValidationErrors();
      this.$refs.form.validate().then(({ valid: isValid }) => {
        if (isValid) {
          this.emit.emit("systemBusy", true);
          // make the call!
          this.$axios
            .put(
              "/client-config-options/" + this.clientConfigOptionClone.id,
              this.clientConfigOptionClone,
            )
            .then((response) => {
              this.emit.emit("systemMessage", {
                title: "Update Complete",
                message: "Success!",
                timeout: 3000,
                colour: "green",
              });
              // update the selected config (this will also propagate to the dropdown list)
              Object.assign(this.clientConfigOption, response.data);
              // close the dialogue
              this.dialog = false;
              this.emit.emit("systemBusy", false);
            })
            .catch((error) => {
              this.emit.emit("systemBusy", false);
              this.emit.emit("systemMessage", {
                message: error.response.data.message,
                title: "Error! Client Config Option Update Failed",
                timeout: -1,
                colour: "error",
              });
              this.apiValidationErrors(error.response.data.errors);
            });
        }
      });
    },
    apiValidationErrors(errors) {
      for (const field in errors) {
        this.apiResponseErrors[field] = errors[field];
      }
    },
    resetApiValidationErrors() {
      for (const field in this.apiResponseErrors) {
        this.apiResponseErrors[field] = null;
      }
    },
  },
  watch: {
    dialog: {
      handler() {
        if (!this.dialog) {
          // closing
          this.resetApiValidationErrors();
          // revert back to it's original value
          this.$store.commit(
            "setSelectedClientConfigOption",
            Object.assign({}, this.originalSelectedClientConfigOption),
          );
        } else {
          // clone this for later
          this.clientConfigOptionClone = this.$cloneDeep(
            this.clientConfigOption,
          );
        }
      },
    },
  },
};
</script>

<style scoped></style>
