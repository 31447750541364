// call this to display a date from a database
function formatDate(date) {
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(date).toLocaleDateString(undefined, options);
}
// convert it into something the DB can use (YYYY-MM-DD) (the "toISOString().substring(0, 10)" way seems to lose a day when working with the vuetify date picker hence the long hand version below)
function formatToSQLDate(date = null) {
  if (date) {
    var value = new Date(date);
    value =
      value.getFullYear() +
      "-" +
      (value.getMonth() + 1 < 10
        ? "0" + (value.getMonth() + 1)
        : value.getMonth() + 1) +
      "-" +
      (value.getDate() < 10 ? "0" : "") +
      value.getDate();
    return value;
  } else {
    return null;
  }
}
export { formatDate, formatToSQLDate };
