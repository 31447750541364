<template>
  <v-dialog
    v-model="dialogVisible"
    scrollable
    persistent
    width="900px"
    :height="dialogHeight"
  >
    <v-card>
      <v-toolbar
        :color="this.$store.state.config.siteConfig.toolbar_colour"
        dark
        class="text-h5"
        max-height="64px"
      >
        <v-btn
          id="closeStandardAreaDialogButton"
          icon="mdi-close"
          dark
          @click="closeDialog"
          aria-label="Close"
        />
        <v-toolbar-title> Edit Custom Area </v-toolbar-title>
      </v-toolbar>
      <v-card-text tile>
        <v-form ref="form" v-model="validation" class="mt-2">
          <v-row class="mt-0" v-if="customAreasIndex != null">
            <v-col cols="12" sm="12" md="6" style="padding-top: 0px !important">
              <Field
                :schema="fieldSchema.name"
                v-model:value="customAreas[customAreasIndex].name"
                id="editing-area-name-field"
                :name="customAreas[customAreasIndex].name"
              />
            </v-col>
            <v-col cols="12" sm="12" md="6" style="padding-top: 0px !important">
              <Field
                :schema="fieldSchema.custom_area_category_id"
                v-model:value="
                  customAreas[customAreasIndex].custom_area_category_id
                "
              />
            </v-col>
            <v-col cols="12" style="padding-top: 0px !important">
              <Field
                :schema="fieldSchema.description"
                v-model:value="customAreas[customAreasIndex].description"
              />
            </v-col>
          </v-row>
        </v-form>
        <!-- Buttons -->
      </v-card-text>
      <v-card tile>
        <v-card-actions v-if="fieldSchema">
          <v-spacer />
          <v-btn
            color="success"
            id="saveButton"
            variant="elevated"
            tile
            @click="upsertCustomArea()"
            width="100px"
            aria-label="save"
          >
            save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import Field from "@/components/Fields.vue";

export default {
  name: "CustomAreaBasicEdit",
  components: {
    Field,
  },
  props: {
    schema: Object,
    dialog: Boolean,
    categories: Array,
    areaIndex: Number,
  },
  data: () => ({
    customAreasClone: [],
    fieldSchema: {},
    validation: false,
  }),
  computed: {
    dialogHeight() {
      // Check if the screen size is 'sm' or smaller
      if (this.$vuetify.display.xs || this.$vuetify.display.sm) {
        return "470px";
      } else {
        return "400px";
      }
    },
    dialogVisible: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("update:dialog", val);
      },
    },
    customAreasIndex: {
      get() {
        return this.areaIndex;
      },
      set(val) {
        this.$emit("update:areaIndex", val);
      },
    },
    customAreas: {
      get() {
        return this.$store.state.customAreas;
      },
      set(value) {
        this.$store.commit("setCustomAreas", value);
      },
    },
  },
  watch: {
    dialogVisible() {
      if (this.dialogVisible) {
        this.resetApiValidationErrors();
        // Backup custom areas
        this.customAreasClone = this.$cloneDeep(this.customAreas);
      }
    },
    categories: {
      handler(val) {
        if (val) {
          this.fieldSchema.custom_area_category_id.selectItems = val;
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.fieldSchema = this.$cloneDeep(this.schema);
  },
  methods: {
    apiValidationErrors(errors) {
      for (let field in errors) {
        this.fieldSchema[field].apiResponseError = errors[field];
      }
    },
    closeDialog() {
      this.customAreas = this.customAreasClone;
      this.customAreasIndex = null;
      this.dialogVisible = false;
    },
    resetApiValidationErrors() {
      for (let field in this.fieldSchema) {
        this.fieldSchema[field].apiResponseError = null;
      }
    },
    upsertCustomArea() {
      this.resetApiValidationErrors();
      // if this passes validation then call the api
      this.$refs.form.validate().then(({ valid: isValid }) => {
        if (isValid) {
          // Custom area update
          this.emit.emit("systemMessage", {
            title: "Updating Custom Area",
            message: "Updating",
            timeout: 4000,
            colour: "warning",
          });

          // add the timepoint
          this.customAreas[this.customAreasIndex].timepoint =
            this.selectedTimepoint;

          // make the call!
          this.$axios
            .put(
              "/custom-areas/" + this.customAreas[this.customAreasIndex].id,
              this.customAreas[this.customAreasIndex],
            )
            .then(() => {
              this.emit.emit("systemMessage", {
                title: "Custom Area Update Complete",
                message: "Success!",
                timeout: 3000,
                colour: "green",
              });
              // Refresh custom areas table

              // Update with new data
              this.customAreasClone = this.$cloneDeep(this.customAreas);
              this.$emit("getCustomAreasByCategory");
              this.closeDialog();

              this.emit.emit("systemBusy", false);
            })
            .catch((error) => {
              this.emit.emit("systemBusy", false);
              this.emit.emit("systemMessage", {
                message: error.response.data.message,
                title: "Error! Custom Area Update Failed",
                timeout: -1,
                colour: "red",
              });
              this.apiValidationErrors(error.response.data.errors);
            });
        }
      });
    },
  },
};
</script>
<style scoped></style>
