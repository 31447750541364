<template>
  <v-dialog
    v-model="geojson_custom_area_dialog"
    persistent
    hide-overlay
    fullscreen
  >
    <template v-slot:activator="{ props }">
      <v-btn
        tile
        class="mx-4"
        color="primary"
        variant="elevated"
        id="importGeoJSONButton"
        v-bind="props"
        @click="buildCustomArea()"
        aria-label="Import GeoJSON"
      >
        Import GeoJSON</v-btn
      >
    </template>
    <v-card>
      <v-dialog v-model="unsavedDialog" width="550" class="save-dialog">
        <v-card>
          <v-card-title
            :style="
              'background-color: ' +
              this.$store.state.config.siteConfig.toolbar_colour +
              '; color: white'
            "
            class="text-h6"
          >
            Unsaved Areas
          </v-card-title>
          <v-card-actions>
            <v-card-text style="text-align: center">
              Underlying areas for your imported GeoJSON file have been Bestfit
              without saving. Exiting now will result in losing this work.
            </v-card-text>
          </v-card-actions>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              tile
              class="btn-wrap"
              color="success"
              variant="elevated"
              @click="unsavedDialog = false"
              aria-label="Go back to Custom Area"
            >
              Go back to Custom Area
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              tile
              class="btn-wrap"
              color="error"
              variant="elevated"
              @click="closeDialog()"
              aria-label="Exit without saving"
            >
              Exit without saving
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="convertDialog" width="550" class="convert-dialog">
        <v-card>
          <v-card-title
            :style="
              'background-color: ' +
              this.$store.state.config.siteConfig.toolbar_colour +
              '; color: white'
            "
            class="text-h6"
          >
            CRS Conversion Needed
          </v-card-title>
          <v-card-actions>
            <v-card-text style="text-align: center" v-if="converting">
              <v-progress-circular indeterminate size="120" color="blue">
                Converting....</v-progress-circular
              >
            </v-card-text>
            <v-card-text style="text-align: center" v-else height="200">
              We've noticed your file uses a different map coordinate reference
              system (CRS) ({{ nativeCRS }}) than our system does (WGS84:4326).
              We can quickly convert your file to the compatible format, or you
              can upload a different file if you prefer.
            </v-card-text>
          </v-card-actions>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              tile
              class="btn-wrap"
              color="error"
              variant="elevated"
              @click="convertDialog = false"
              aria-label="Try Another File"
            >
              Use Different File
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              tile
              class="btn-wrap"
              color="success"
              variant="elevated"
              @click="convertImportedFile()"
              aria-label="Convert GeoJSON"
            >
              Convert GeoJSON
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="badFileDialog" width="550">
        <v-card>
          <v-card-title
            :style="
              'background-color: ' +
              this.$store.state.config.siteConfig.toolbar_colour
            "
            class="text-h6 text-white text-start"
          >
            File Error Detected
          </v-card-title>
          <v-card-actions>
            <v-card-text style="text-align: center">
              <p>We could not process the file you have uploaded.</p>
              <p>
                Please try another file, or contact
                <a class="no-underline" href="mailto:support@ocsi.co.uk"
                  >User Support</a
                >
                for help
              </p>
            </v-card-text>
          </v-card-actions>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              tile
              class="btn-wrap"
              color="error"
              variant="elevated"
              rounded="0"
              @click="badFileDialog = false"
              aria-label="Try Another File"
            >
              Try Another File
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="noCRS" width="550">
        <v-card>
          <v-card-title
            :style="
              'background-color: ' +
              this.$store.state.config.siteConfig.toolbar_colour
            "
            class="text-h6 text-white text-start"
          >
            No CRS Detected
          </v-card-title>
          <v-card-actions>
            <v-card-text style="text-align: center">
              <p>We could not detect a CRS for this file.</p>
              <p>
                We can continue with the default CRS (WGS84:4326) or you can try
                another file.
              </p>
            </v-card-text>
          </v-card-actions>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              tile
              class="btn-wrap"
              color="success"
              variant="elevated"
              rounded="0"
              @click="setDefaultCRS()"
              aria-label="Continue with default"
            >
              Continue with default
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              tile
              class="btn-wrap"
              color="error"
              variant="elevated"
              rounded="0"
              @click="noCRS = false"
              aria-label="Try Another File"
            >
              Try Another File
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="areaCountDialog" width="550">
        <v-card>
          <v-card-title
            :style="
              'background-color: ' +
              this.$store.state.config.siteConfig.toolbar_colour
            "
            class="text-h6"
          >
            Too Many Areas!
          </v-card-title>
          <v-card-actions>
            <v-card-text style="text-align: center">
              <p>
                Error, you have tried to import a file containing
                {{ areaCount }} areas. You are only allowed to upload a maximum
                of 250 at a time.
              </p>
              <p>
                Please contact
                <a class="no-underline" href="mailto:support@ocsi.co.uk"
                  >User Support</a
                >
                for help setting these areas up.
              </p>
            </v-card-text>
          </v-card-actions>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="btn-wrap"
              variant="elevated"
              rounded="0"
              color="error"
              @click="areaCountDialog = false"
              aria-label="Try Another File"
            >
              Try Another File
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="areaDetailsDialog" width="550" class="save-dialog">
        <v-card>
          <v-card-title
            :style="
              'background-color: ' +
              this.$store.state.config.siteConfig.toolbar_colour +
              '; color: white'
            "
            class="text-h6"
          >
            <v-btn
              tile
              dark
              text
              :elevation="0"
              icon="mdi-close"
              @click="areaDetailsDialog = false"
              aria-label="Close"
              :style="
                'background-color: ' +
                this.$store.state.config.siteConfig.toolbar_colour +
                '; color: white'
              "
            >
            </v-btn>
            {{ editArea.name }}
          </v-card-title>
          <v-card-actions>
            <v-card-text style="text-align: center">
              <v-form ref="form" v-model="validation" lazy-validation>
                <Field
                  :schema="schema.name"
                  v-model:value="editArea.name"
                  id="customAreaNameField"
                  :name="editArea.name"
                />
                <Field
                  :schema="schema.custom_area_category_id"
                  v-model:value="editArea.custom_area_category_id"
                />
                <Field
                  :schema="schema.description"
                  v-model:value="editArea.description"
                />
              </v-form>
              <v-expansion-panels :disabled="editArea.areas.length === 0">
                <v-expansion-panel>
                  <v-expansion-panel-title>
                    Constituent Areas
                  </v-expansion-panel-title>
                  <v-expansion-panel-text class="expansion-panel-content">
                    <v-list two-line>
                      <v-list-item
                        class="pa-0"
                        v-for="item in editArea.areas"
                        :key="item.id"
                      >
                        <v-list-item-title>{{
                          item.area_name
                        }}</v-list-item-title>
                        <v-list-item-subtitle>{{
                          item.area_code
                        }}</v-list-item-subtitle>
                        <v-divider></v-divider>
                      </v-list-item>
                    </v-list>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>
          </v-card-actions>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              tile
              color="error"
              variant="elevated"
              @click="resetAreaDetails()"
              aria-label="Close"
            >
              Close
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              tile
              :disabled="disabledSaveDetailsButton"
              color="success"
              variant="elevated"
              @click="areaDetailsDialog = false"
              aria-label="Save"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-toolbar
        :color="this.$store.state.config.siteConfig.toolbar_colour"
        dark
        class="text-h5"
        max-height="64px"
      >
        <v-btn
          tile
          id="closeGeoJSONDialogButton"
          icon
          dark
          @click="closeConfirmation"
          aria-label="Close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Import GeoJSON</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pa-0 no-scroll">
        <v-row style="height: 94vh">
          <v-col cols="8" style="width: 100%; height: 95vh" class="pt-3">
            <div
              ref="mapCanvas"
              id="mapCanvas"
              class="map-canvas"
              style="width: 100%; height: 100%"
            ></div>
          </v-col>
          <v-col cols="4" style="overflow-y: auto">
            <v-row dense class="mr-2">
              <v-col cols="12" lg="6">
                <v-file-input
                  class="truncate-input pt-2"
                  v-model="geoJsonFile"
                  accept=".geojson"
                  clearable
                  variant="outlined"
                  density="compact"
                  rounded="0"
                  label="Select GeoJSON File"
                  @change="loadGeoJSON()"
                ></v-file-input>
              </v-col>
              <v-col>
                <v-select
                  class="truncate-input pt-2"
                  clearable
                  variant="outlined"
                  density="compact"
                  rounded="0"
                  :disabled="!geoJSON"
                  v-model="nameField"
                  :items="foundFields"
                  label="Name Field"
                  @update:modelValue="processGeoJsonAreas()"
                ></v-select>
              </v-col>
            </v-row>
            <v-card class="toolbar-card" style="width: 96%">
              <v-card-title>
                <v-row class="mr-1">
                  <v-col cols="8">
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
                      clearable
                      variant="outlined"
                      density="compact"
                      rounded="0"
                      class="mt-2"
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-switch
                      v-model="displaySwitch"
                      :label="this.displayLabel"
                      :disabled="selectedAreas.length == 0"
                    ></v-switch>
                  </v-col>
                </v-row>
                <v-select
                  v-model="selectedCategory"
                  :items="categories"
                  item-title="name"
                  item-value="id"
                  label="Custom Areas Category"
                  class="fields mt-2"
                  prepend-icon="mdi-selection-multiple"
                  variant="outlined"
                  density="compact"
                  rounded="0"
                  clearable
                  @update:modelValue="setCategories()"
                  :disabled="geoJsonAreas.length == 0"
                ></v-select>
              </v-card-title>
              <v-data-table
                ref="areasTable"
                :search="search"
                fixed-header
                :height="this.tableHeight"
                :class="{ 'custom-disabled-table': disableTable }"
                item-value="bestfit_id"
                :headers="headers"
                :items="geoJsonAreas"
                @update:items-per-page="updateItemsPerPage"
                v-model:page="page"
              >
                <template v-slot:[`header.selected`]>
                  <v-menu offset-y>
                    <template v-slot:activator="{ props }">
                      <v-btn
                        tile
                        variant="text"
                        icon="mdi-menu-down"
                        v-bind="props"
                        aria-label="open areas table"
                        :disabled="geoJsonAreas.length == 0"
                      >
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="selectShown">
                        <v-list-item-title>Select Shown</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="selectAll">
                        <v-list-item-title>Select All</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="clearSelection">
                        <v-list-item-title>Clear All</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
                <template v-slot:[`item.selected`]="{ item }">
                  <v-checkbox v-model="item.selected" hide-details></v-checkbox>
                </template>
                <template v-slot:[`item.name`]="{ item }">
                  <span
                    @click="openDetailsDialog(item)"
                    style="cursor: pointer"
                  >
                    <v-icon aria-label="edit" style="font-size: 16px"
                      >mdi-pencil</v-icon
                    >
                    {{ item.name }}
                  </span>
                </template>
                <template v-slot:[`item.bestfit`]="{ item }">
                  <v-tooltip location="top" activator="parent">
                    <template v-slot:activator="{ props }">
                      <div v-if="item.bestfit === 'working'">
                        <v-progress-circular
                          indeterminate
                          size="24"
                          color="blue"
                        ></v-progress-circular>
                      </div>
                      <div v-else>
                        <v-icon
                          v-bind="props"
                          :color="getBestfitIconColor(item)"
                        >
                          {{ getBestfitIcon(item) }}
                        </v-icon>
                      </div>
                    </template>
                    <span v-if="item.bestfit_message">{{
                      item.bestfit_message
                    }}</span>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.saved`]="{ item }">
                  <v-tooltip location="top" activator="parent">
                    <template v-slot:activator="{ props }">
                      <div v-if="item.saved === 'saving'">
                        <v-progress-circular
                          indeterminate
                          size="24"
                          color="blue"
                        ></v-progress-circular>
                      </div>
                      <div v-else>
                        <v-icon v-bind="props" :color="getSaveIconColor(item)">
                          {{ getSaveIcon(item) }}
                        </v-icon>
                      </div>
                    </template>
                    <span v-if="item.saved_message">{{
                      item.saved_message
                    }}</span>
                  </v-tooltip>
                </template>
              </v-data-table>
              <v-card-actions>
                <v-btn
                  tile
                  class="btn-wrap"
                  :disabled="selectedAreas.length == 0 || bestfitting"
                  color="info"
                  variant="elevated"
                  @click="bestFitGeoJSONAreas()"
                  width="160"
                  aria-label="best fit geojson areas"
                >
                  <template v-if="bestfitting">
                    <v-progress-circular
                      indeterminate
                      size="24"
                      color="white"
                    ></v-progress-circular>
                  </template>
                  <template v-else> Bestfit Selected </template>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  tile
                  class="btn-wrap"
                  width="160"
                  color="success"
                  variant="elevated"
                  :disabled="!allAreasBestFit"
                  @click="saveSelectedAreas()"
                  aria-label="Save"
                >
                  Save
                </v-btn>
              </v-card-actions>
              <v-card-subtitle
                v-if="this.$store.state.displayOnsSource"
                class="source"
              >
                <p>
                  Source: Office for National Statistics licensed under the Open
                  Government Licence v.3.0
                </p>
                <p>
                  Contains OS data © Crown copyright and database right
                  {{ year }}
                </p>
              </v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
/* global google */

import Field from "@/components/Fields.vue";
import CustomAreaSchema from "@/schemas/CustomAreaManager";
import { loadGoogleMaps } from "@/mixins/LoadGoogleMaps";
import { toRaw } from "vue";
import { useDisplay } from "vuetify";

let map = null;

export default {
  name: "ImportGeoJSON",
  data: () => ({
    geojson_custom_area_dialog: false,
    schema: CustomAreaSchema,
    geoJsonFile: null,
    alertType: "info",
    alertMessage: "Import a GeoJSON File",
    customArea: {
      area_ids: [],
      areas: [],
      poly: [],
      type_id: 4,
    },
    customAreasClone: [],
    displaySwitch: false,
    areaPolygons: [],
    itemsPerPage: 10,
    page: 1,
    validation: false,
    zoom: 6,
    center: { lat: null, lng: null },
    geoJsonAreas: [],
    geoJSON: null,
    nameField: null,
    foundFields: [],
    loadGeoms: false,
    unsaved: false,
    unsavedDialog: false,
    areaDetailsDialog: false,
    areaCountDialog: false,
    convertDialog: false,
    badFileDialog: false,
    noCRS: false,
    converting: false,
    bestfitting: false,
    saving: false,
    uid: null,
    loaded: false,
    dialogAreaIndex: null,
    importCRS: null,
    areaCount: null,
    mapOptions: {
      gestureHandling: "greedy",
      scaleControl: true,
    },
    headers: [
      { title: "Select", key: "selected", sortable: false },
      { title: "Name", key: "name" },
      { title: "Bestfit", key: "bestfit" },
      { title: "Saved", key: "saved" },
    ],
    search: "",
    editArea: {},
    areaDetailsClone: null,
    selectedCategory: null,
    breakpoint: null,
    nativeCRS: "27700",
  }),
  components: {
    Field,
  },
  computed: {
    year() {
      return new Date().getFullYear();
    },
    disableTable() {
      if (this.bestfitting || this.saving) {
        return true;
      } else {
        return false;
      }
    },
    tableHeight() {
      switch (this.breakpoint) {
        case "xs":
          return "25vh";
        case "sm":
          return "35vh";
        case "md":
          return "40vh";
        case "lg":
          return "40vh";
        case "xl":
          return "45vh";
        default:
          return "20vh";
      }
    },
    displayLabel() {
      switch (this.breakpoint) {
        case "xs":
          return "";
        case "sm":
          return "";
        case "md":
          return "";
        case "lg":
          return "Display";
        case "xl":
          return "Display Areas";
        default:
          return "Display Areas";
      }
    },
    allAreasBestFit() {
      if (
        this.selectedAreas.length > 0 &&
        this.selectedAreas.every((area) => area.bestfit === true) &&
        this.selectedAreas.every((area) => area.bestfit_status === "success") &&
        this.selectedAreas.every((area) =>
          area.description ? area.description.length < 250 : true,
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    selectedAreas() {
      // return all areas where selected = true
      return this.geoJsonAreas.filter((area) => area.selected);
    },
    disabledSaveDetailsButton: {
      get() {
        let selectedItem = this.editArea;

        if (Object.prototype.hasOwnProperty.call(selectedItem, "description")) {
          return (
            selectedItem.description && selectedItem.description.length > 250
          );
        } else {
          return false;
        }
      },
    },
  },
  props: {
    categories: Array,
  },
  mounted() {
    this.breakpoint = useDisplay().name;
    this.zoom = this.$store.state.config.siteConfig.initialMapZoom.zoom;
    this.center.lat = this.$store.state.config.siteConfig.initialMapZoom.lat;
    this.center.lng = this.$store.state.config.siteConfig.initialMapZoom.lng;
  },
  methods: {
    updateItemsPerPage(value) {
      this.itemsPerPage = value;
    },
    setDefaultCRS() {
      this.importCRS = "WGS84:4326";
      this.noCRS = false;
      this.extractAreasFromGeoJSON();
    },
    selectShown() {
      // first deselect all
      this.clearSelection();

      const start = (this.page - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      //select these areas
      this.geoJsonAreas.slice(start, end).forEach((area) => {
        area.selected = true;
      });
    },
    selectAll() {
      // select all the areas
      this.geoJsonAreas.forEach((area) => {
        area.selected = true;
      });
    },
    clearSelection() {
      // clear all the selected areas
      this.geoJsonAreas.forEach((area) => {
        area.selected = false;
      });
    },
    setCategories() {
      // for each geojson area, set the category to the selected one
      this.geoJsonAreas.forEach((area) => {
        area.custom_area_category_id = this.selectedCategory;
      });
    },
    resetAreaDetails() {
      // reset the details back to what they were before
      this.editArea.name = this.areaDetailsClone.name;
      this.editArea.description = this.areaDetailsClone.description;
      this.editArea.category_id = this.areaDetailsClone.category_id;

      this.areaDetailsDialog = false;
    },
    closeConfirmation() {
      if (this.unsaved) {
        this.unsavedDialog = true;
      } else {
        this.closeDialog();
      }
    },
    // Clear dialog and close it
    closeDialog() {
      // Reset page to initial state
      this.unsavedDialog = false;
      this.geojson_custom_area_dialog = false;
      this.geoJSON = null;
      this.geoJsonFile = null;
      this.geoJsonAreas = [];
      this.nameField = null;
      this.selectedAreas = [];
      this.unsaved = false;
      this.customAreas = this.customAreasClone;
      this.dialogAreaIndex = null;
      this.selectedCategory = null;
      this.noCRS = false;

      // reset the map to the original center & zoom
      map.setZoom(this.zoom);
      map.setCenter(this.center);
    },
    // Build empty fields for creation
    buildCustomArea() {
      for (const key in this.schema) {
        if (this.schema[key].useInCreationMode) {
          this.customArea[key] = "";
        }
      }
      this.geojson_custom_area_dialog = true;
    },
    apiValidationErrors(errors) {
      for (const field in errors) {
        this.schema[field].apiResponseError = errors[field];
      }
    },
    resetApiValidationErrors() {
      for (var field in this.schema) {
        this.schema[field].apiResponseError = null;
      }
    },
    async bestFitGeoJSONAreas() {
      const areasToBesfit = [];
      this.bestfitting = true;

      // Iterate through filtered features directly as feature objects
      this.selectedAreas.forEach((feature) => {
        // Check if feature is not undefined (e.g., not found)
        if (feature && feature.bestfit !== true) {
          // Mark the feature as being worked on
          feature.bestfit = "working";

          // Add the feature's polygon and bestfit_id to the areasToBesfit array
          areasToBesfit.push({
            bestfit_id: feature.bestfit_id,
            poly: feature.poly,
          });
        }
      });

      // split the areasToBesfit array into chunks of 15
      const chunkSize = 20;
      for (let i = 0; i < areasToBesfit.length; i += chunkSize) {
        const chunk = areasToBesfit.slice(i, i + chunkSize);

        try {
          const response = await this.$axios.post("/bulk-bestfit", chunk);

          // update the selectedAreas array with the results
          response.data.forEach((result) => {
            const bestfit_id = result.bestfit_id;

            // update the selected area using the index
            const feature = this.selectedAreas.find(
              (area) => area.bestfit_id === bestfit_id,
            );
            if (feature) {
              feature.bestfit = result.bestfit;
              feature.bestfit_status = result.bestfit_status;
              feature.bestfit_message = result.bestfit_message;
              feature.areas = result.areas;
              // reset first, then set the new ones
              feature.area_ids = [];
              feature.area_ids = feature.areas.map(
                (constituentArea) => constituentArea.id,
              );
              feature.area_geoms = result.area_geoms;

              // If the result has a geometry, then there was a change to the drawn geometry, e.g. it got fixed
              if (result.geometry) {
                feature.geometry = JSON.parse(result.geometry);
              }
            }

            // then say we've not saved these results
            this.unsaved = true;
          });

          this.displayGeoJSON();
        } catch (error) {
          // Handle error
          console.error(error);
        }
      }

      // Check for any bestfit error and report back
      let bestfitErrorCount = 0;
      let bestfitWarningCount = 0;

      for (const area of this.selectedAreas) {
        if (area.bestfit_status === "error") {
          bestfitErrorCount++;
        } else if (area.bestfit_status === "warning") {
          bestfitWarningCount++;
        }
      }

      if (bestfitErrorCount > 0 || bestfitWarningCount > 0) {
        let warningMessage = "";

        if (bestfitErrorCount > 0) {
          warningMessage =
            bestfitErrorCount +
            (bestfitErrorCount === 1 ? " Area" : " Areas") +
            " could not be bestfit. Please deselect or fix these areas before saving.";
        }

        if (bestfitWarningCount > 0) {
          if (warningMessage !== "") {
            warningMessage += "\n"; // Add a line break if error message was added
          }
          warningMessage +=
            bestfitWarningCount +
            (bestfitWarningCount === 1 ? " Area" : " Areas") +
            " were bestfit successfully, but some areas were out of range.";
        }

        this.emit.emit("systemMessage", {
          title: "Bestfit Warning",
          message: warningMessage,
          timeout: 0,
          colour: "warning",
        });
      }

      this.displayGeoJSON();
      this.bestfitting = false;
    },

    getStandardAreas(area) {
      // create the poly layer if it's not already there
      if (this.standardPolyLayer === null) {
        this.$refs.mapRef.$mapPromise.then((map) => {
          this.standardPolyLayer = new google.maps.Data({ map: map });
        });
      }

      this.$axios
        .post("/standard-geometries", area.areas)
        .then(
          function (response) {
            area.area_geoms = response.data;
            this.displayGeoJSON();
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
          }.bind(this),
        );
    },
    zoomToAreas() {
      // gather up all the bounds of any loaded polys
      var bounds = new google.maps.LatLngBounds();

      // iterate over each polygon in this.polygonObjects and extend the bounds
      this.areaPolygons.forEach(function (polygon) {
        polygon.getPath().forEach(function (latLng) {
          bounds.extend(latLng);
        });
      });

      // fit the map to the bounds to show all the polygons
      map.fitBounds(bounds);
    },
    loadGeoJSON() {
      if (!this.geoJsonFile) return;

      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const newGeoJSON = JSON.parse(e.target.result);
        const fields = newGeoJSON.features[0].properties;
        this.foundFields = Object.keys(fields);
        this.areaCount = newGeoJSON.features.length;
        if (this.areaCount >= 250) {
          this.areaCountDialog = true;
          this.geoJsonFile = null;
        } else {
          // if we've already got one, and this one is different, clear out what we have for the new one
          if (
            this.geoJSON !== null &&
            JSON.stringify(this.geoJSON) !== JSON.stringify(newGeoJSON)
          ) {
            this.geoJsonAreas = [];
            this.nameField = null;
            this.selectedAreas = [];
            this.unsaved = false;
            this.geoJSON = newGeoJSON;
          } else {
            this.geoJSON = newGeoJSON;
          }
        }
      };

      fileReader.readAsText(this.geoJsonFile);
    },
    processGeoJsonAreas() {
      if (this.geoJsonAreas.length == 0) {
        this.importCRS = this.geoJSON.crs?.properties?.name;
      }

      // check if it's using a native CRS
      let country = this.$store.getters.siteConfig.site_country;

      // default to GBR, but check if we need to test for AUS
      if (country == "aus") {
        this.nativeCRS = "7844";
      }

      if (typeof this.importCRS === "undefined") {
        this.noCRS = true;
      } else if (this.importCRS.includes(this.nativeCRS)) {
        this.convertDialog = true;
      } else {
        this.extractAreasFromGeoJSON();
      }
    },
    convertImportedFile() {
      this.converting = true;
      // build the uid as the client name + the timestamp
      let name = this.$store.state.config.customClientConfig.client_name;
      name = name.replace(/\s/g, "_");
      this.uid = name + "_" + Date.now();

      // set up import data
      let formData = new FormData();
      formData.append("file", this.geoJsonFile);
      formData.append("nameField", this.nameField);
      formData.append("uid", this.uid);

      //send the data
      this.$axios
        .post("/convert-geojson", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(
          function (response) {
            this.converting = false;
            this.convertDialog = false;
            this.geoJSON = JSON.parse(response.data.data);
            this.extractAreasFromGeoJSON();
          }.bind(this),
        )
        .catch(() => {
          this.converting = false;
          this.badFileDialog = true;
        });
    },
    extractAreasFromGeoJSON() {
      this.geoJsonAreas = this.geoJSON.features.map((feature, index) => {
        const isMultiPolygon = feature.geometry.type === "MultiPolygon";
        const polygonCoordinates = isMultiPolygon
          ? feature.geometry.coordinates.map((polygon) =>
              polygon[0].map(([lng, lat]) => ({ lat, lng })),
            )
          : feature.geometry.coordinates[0].map(([lng, lat]) => ({ lat, lng }));

        return {
          bestfit_id: index,
          selected: false,
          name: feature.properties[this.nameField],
          geometry: feature.geometry,
          poly: polygonCoordinates,
          areas: [],
          area_ids: [],
          area_geoms: null,
          bestfit: false,
          bestfit_status: null,
          bestfit_message: "Bestfit Area",
          saved: false,
          saved_message: "Area not saved",
          type_id: 4,
          custom_area_category_id: null,
          description: null,
        };
      });

      this.loaded = true;
    },
    removeAllAreas() {
      // remove old polygons before adding the new
      let features = map.data;
      features.forEach(function (feature) {
        features.remove(feature);
      });

      // set the map to null for any shown, then empty the array
      this.areaPolygons.forEach((polygon) => {
        toRaw(polygon).setMap(null);
      });
      this.areaPolygons = [];
    },
    async displayGeoJSON() {
      if (this.displaySwitch && this.selectedAreas.length > 0) {
        // clear the map of anything there at the moment
        this.removeAllAreas();

        // load the google maps library
        const { Polygon } = await google.maps.importLibrary("maps");
        this.selectedAreas.forEach((feature) => {
          const geometry = feature.geometry;
          const geometryType = geometry.type;

          if (geometryType === "Polygon" || geometryType === "MultiPolygon") {
            if (geometryType === "Polygon") {
              // Polygon with possible holes
              const googleMapsPaths = geometry.coordinates.map((ring) =>
                ring.map((coord) => {
                  return { lat: coord[1], lng: coord[0] };
                }),
              );

              const googleMapsPolygon = new Polygon({
                map,
                paths: googleMapsPaths,
                fillOpacity: 0,
                zIndex: 10,
                strokeColor: "red",
                clickable: false,
              });

              this.areaPolygons.push(googleMapsPolygon);
            } else if (geometryType === "MultiPolygon") {
              // MultiPolygon
              const multiCoordinates = toRaw(geometry.coordinates);
              multiCoordinates.forEach((coords) => {
                const googleMapsPaths = coords.map((coord) => {
                  return coord.map((subCoord) => {
                    return { lat: subCoord[1], lng: subCoord[0] };
                  });
                });

                const googleMapsPolygon = new Polygon({
                  map,
                  paths: googleMapsPaths,
                  fillOpacity: 0,
                  zIndex: 10,
                  strokeColor: "red",
                  clickable: false,
                });

                this.areaPolygons.push(googleMapsPolygon);
              });
            }
          }
        });

        // then merge all the constituent areas and show them on the map as well
        let mergedFeatures = [];
        this.selectedAreas.forEach((area) => {
          if (area.area_geoms) {
            try {
              const areaGeomsObject = JSON.parse(area.area_geoms);
              const areaGeoms = areaGeomsObject.features;

              if (areaGeoms) {
                areaGeoms.forEach((feature) => {
                  mergedFeatures.push(feature);
                });
              }
            } catch (error) {
              console.error(`Error parsing JSON for area: ${area.name}`, error);
            }
          }
        });

        if (mergedFeatures.length > 0) {
          map.data.addGeoJson({
            type: "FeatureCollection",
            features: mergedFeatures,
          });
        }

        map.data.setStyle({ fillOpacity: 0, zIndex: 5 });

        this.zoomToAreas();
      }
    },
    async saveSelectedAreas() {
      this.saving = true;
      const areasToSave = [];

      // get out a list of selected areas, which have not already been saved
      this.selectedAreas.forEach((area) => {
        if (area.saved !== true) {
          // mark the area as being worked on
          area.saved = "saving";

          // make sure the area_ids are unique
          area.area_ids = [...new Set(area.area_ids)];

          // add the details we need to save it
          areasToSave.push({
            index: this.selectedAreas.indexOf(area),
            name: area.name,
            description: area.description,
            custom_area_category_id: area.custom_area_category_id,
            type_id: area.type_id,
            area_ids: area.area_ids,
            geometry: area.geometry,
          });
        }
      });

      try {
        const response = await this.$axios.post(
          "/bulk-custom-areas",
          areasToSave,
        );

        // update the selectedAreas array with the results
        response.data.forEach((result) => {
          const areaIndex = result.index; // get the index from the API response

          // update the selected area using the index
          if (this.selectedAreas[areaIndex]) {
            this.selectedAreas[areaIndex].saved = result.saved;
            this.selectedAreas[areaIndex].saved_message = result.saved_message;
          }
        });
      } catch (error) {
        // Handle error
        console.error(error);
      }

      this.saving = false;

      // check for any bad saves and report back if there are any
      let saveErrorCount = 0;
      for (const area of this.selectedAreas) {
        if (area.saved === false) {
          saveErrorCount++;
        }
      }

      if (saveErrorCount > 0) {
        this.emit.emit("systemMessage", {
          title: "Save Warning",
          message:
            saveErrorCount +
            (saveErrorCount === 1 ? " Area" : " Areas") +
            " could not be saved, check 'saved' column for details",
          timeout: 0,
          colour: "warning",
        });
      }

      // when they're all done, check if they're all saved successfully and close dialog if true
      const allAreasSaved = this.selectedAreas.every(
        (area) => area.saved === true,
      );

      if (allAreasSaved) {
        this.unsaved = false;
        this.closeConfirmation();
      }
    },

    openDetailsDialog(item) {
      this.editArea = item;
      // take a copy of the details they might change
      this.areaDetailsClone = {
        name: item.name,
        description: item.description,
        category_id: item.category_id,
      };

      this.areaDetailsDialog = true;
    },
    clearMap() {
      // remove polygons and their constituent areas
      this.removeAllAreas();

      // reset the map to the original center & zoom
      map.setZoom(this.zoom);
      map.setCenter(this.center);
    },
    getBestfitIcon(item) {
      if (item.bestfit === "working") {
        return "mdi-cog";
      } else if (item.bestfit === true && item.bestfit_status == "error") {
        return "mdi-alert";
      } else if (item.bestfit === true && item.bestfit_status == "warning") {
        return "mdi-alert";
      } else if (item.bestfit === false) {
        return "mdi-close";
      } else if (item.bestfit === true) {
        return "mdi-check";
      } else {
        return "mdi-alert";
      }
    },
    getBestfitIconColor(item) {
      if (item.bestfit === "working") {
        return "blue";
      } else if (item.bestfit === false) {
        return "red";
      } else if (item.bestfit === true && item.bestfit_status == "warning") {
        return "orange";
      } else if (item.bestfit === true && item.bestfit_status == "error") {
        return "red";
      } else if (item.bestfit === true) {
        return "green";
      } else {
        return "orange";
      }
    },
    getSaveIcon(item) {
      if (item.saved === "working") {
        return "mdi-cog";
      } else if (item.saved === true && item.saved_status == "error") {
        return "mdi-alert";
      } else if (item.saved === true && item.saved_status == "warning") {
        return "mdi-alert";
      } else if (item.saved === false) {
        return "mdi-close";
      } else if (item.saved === true) {
        return "mdi-check";
      } else {
        return "mdi-alert";
      }
    },
    getSaveIconColor(item) {
      if (item.saved === "working") {
        return "blue";
      } else if (item.saved === false) {
        return "red";
      } else if (item.saved === true && item.saved_status == "warning") {
        return "orange";
      } else if (item.saved === true && item.saved_status == "error") {
        return "red";
      } else if (item.saved === true) {
        return "green";
      } else {
        return "orange";
      }
    },
    handleSelectedAreasChange() {
      // if there are selected areas, enable the display switch
      if (this.selectedAreas.length > 0) {
        this.displaySwitch = true;
      } else {
        this.displaySwitch = false;
      }

      // if the selected areas, update the display, this will clear the map if there are no selected areas
      this.displayGeoJSON();
    },
  },
  watch: {
    geojson_custom_area_dialog: {
      handler() {
        if (this.geojson_custom_area_dialog) {
          // Backup custom areas
          this.customAreasClone = this.$cloneDeep(this.customAreas);

          loadGoogleMaps(this.center, this.zoom).then((loadedMap) => {
            map = loadedMap;
          });
        }
      },
      deep: false,
    },
    selectedAreas: {
      handler: "handleSelectedAreasChange",
      deep: true,
    },
    displaySwitch() {
      if (this.displaySwitch) {
        this.displayGeoJSON();
      } else {
        this.clearMap();
      }
    },
  },
};
</script>

<style scoped>
.no-underline {
  text-decoration: none;
}

.fixed-height-alert {
  height: 38px;
}

.save-dialog {
  z-index: 9999;
}

.toolbar-card {
  width: 100%;
  margin-right: 6px;
}

.convert-dialog .v-dialog__content {
  max-height: 350px;
}

.expansion-panel-content {
  max-height: 200px;
  overflow-y: auto;
}

.custom-disabled-table {
  pointer-events: none;
  /* Disable pointer events on the table */
  opacity: 0.5;
  /* Apply an opacity to visually indicate disabled state */
}

.v-file-input {
  text-overflow: hidden;
}
.no-scroll {
  overflow-y: auto;
  overflow-x: hidden;
}

.truncate-input {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.source {
  font-size: 10px;
  padding-bottom: 5px;
}

.btn-wrap :deep(.v-btn__content) {
  white-space: normal !important;
}

.btn-wrap {
  min-height: 2.5em;
}

.v-file-input :deep(.v-input__prepend) {
  margin-right: 4px;
}
</style>
