<template>
  <v-row :style="'height: ' + (height - 50) + 'px;'">
    <v-col xs="6" sm="8" md="8" xl="9" xxl="10" class="pr-0">
      <div v-for="index in 3" :key="index">
        <DiscoveryToolMap
          :mapID="index"
          ref="maps"
          :view-info="viewInfo"
          :selected-indicator="selectedIndicators[index - 1]"
          :colour-scheme="colourScheme"
          :get-loaded-data-for-new-map="getLoadedDataForNewMap"
          v-model:areaMouseOverInfoProp="areaMouseOverInfo"
          @syncZoom="handleSyncZoom"
          @syncCenter="handleSyncCenter"
          @handleClickOnMap="handleClickOnMap"
          @syncHoverOverMap="syncHoverOverMap"
          @deBounce="deBounce"
          @resizeTheMaps="resizeTheMaps"
          @openSnackbar="openSnackbar"
        />
      </div>

      <!-- area selection messages -->
      <v-snackbar
        v-model="snackBar.show"
        :timeout="snackBar.timeout"
        :color="snackBar.colour"
        close-on-back
        rounded="pill"
        class="discoveryToolSnackbar"
        :style="{ marginRight: mapSidebarWidth + 'px' }"
      >
        {{ snackBar.message }}
      </v-snackbar>
    </v-col>
    <v-col ref="mapSidebar" xs="6" sm="4" md="4" xl="3" xxl="2" class="px-0">
      <DiscoveryToolMapSidebar
        ref="DTSidebarComponent"
        :viewInfo="viewInfo"
        :areaMouseOverInfo="areaMouseOverInfo"
        :colourScheme="colourScheme"
        :lockedView="lockedView"
        :indicators="indicators"
        :selectedLAorCLA="selectedLAorCLA"
        v-model:selectedIndicatorsProp="selectedIndicators"
        v-model:areasOfInterestProp="areasOfInterest"
        :loadingIndicators="loadingIndicators"
        :showDataOutsideBoundaries="showDataOutsideBoundaries"
        @closeMap="closeDialog"
        @selectedDataLevelChanged="handleDataLevelSelected"
        @lockViewChanged="handleLockViewChanged"
        @changeIndicator="updateselectedIndicators"
        @zoomToAreaOfInterest="zoomToAreaOfInterest"
        @showDataOutsideBoundaries="handleDataBoundaryViewChanged"
        @getAreaOfInterestGeometries="getAreaOfInterestGeometries"
        @displayAreaOfInterestOnMaps="displayAreaOfInterestOnMaps"
        @hideAreaOfInterestOnMaps="hideAreaOfInterestOnMaps"
      />
    </v-col>
  </v-row>
</template>

<script>
import { useDisplay } from "vuetify";
import DiscoveryToolMapSidebar from "@/components/DiscoveryToolMapSidebar.vue";
import DiscoveryToolMap from "@/components/DiscoveryToolMap.vue";

export default {
  name: "DiscoveryToolMapsPage",
  components: { DiscoveryToolMapSidebar, DiscoveryToolMap },
  data: () => ({
    height: useDisplay().height,
    mapSidebarWidth: 0,
    selectedLAorCLA: [],
    center: { lat: 54.36, lng: -2.59 },
    zoom: 6,
    previousZoom: 6,
    clientBoundary: [],
    polygonObjects: [],
    viewInfo: {
      viewportInfo: {},
      indicatorInfo: [],
      numeratorInfo: [],
    },
    indicators: [],
    loadingIndicators: true,
    selectedIndicators: [],
    zoomAreaLevels: {
      1: 6,
      2: 6,
      3: 6,
      4: 6,
      5: 6,
      6: 6,
      7: 6,
      8: 6, // LA
      9: 5,
      10: 5, //Ward
      11: 2, // MSOA
      12: 2,
      13: 1, // LSOA
      14: 1,
      15: 1,
      16: 1,
      17: 1,
      18: 1,
      19: 1,
      20: 1,
      21: 1,
      22: 1,
    },
    selectedDataLevel: null,
    previousDataLevel: null,
    lockedView: false,
    areaMouseOverInfo: {},
    colourScheme: [],
    idleTimeout: null,
    hotspotFlag: "all",
    mapBoundariesGeoJson: [],
    showDataOutsideBoundaries: false,
    snackBar: {
      show: false,
      message: "",
      timeout: 4000,
      colour: "primary",
    },
    fetchingAreaGeometrySelectedOnMap: false,
    syncZoomInProgress: false,
    syncCenterInProgress: false,
    parentAreaBoundaries: null,
    areaOfInterestPolygons: [], // geometries that will be used when reopend a map
  }),
  async mounted() {
    this.emit.emit("systemBusy", true);

    // Collect all mapSetUp promises
    const mapSetUpPromises = this.$refs.maps.map((map) => map.mapSetUp());

    // Wait for all mapSetUp promises to resolve
    await Promise.all(mapSetUpPromises);

    // Once all maps are set up, get the parent boundaries
    setTimeout(() => {
      this.getParentBoundaries();
    }, 1000);

    // set data level to lsoa and lock view on page load
    this.selectedDataLevel = 1;
    this.setSelectedDataLevel();
    this.lockedView = true;
  },
  beforeUnmount() {
    this.emit.emit("systemBusy", false);
  },
  computed: {
    areasOfInterest: {
      get() {
        return this.$store.state.areasOfInterest;
      },
      set(value) {
        this.$store.commit("setAreasOfInterest", value);
      },
    },
  },
  methods: {
    handleSyncZoom(changedMapID, zoom) {
      if (this.syncZoomInProgress) return;

      this.syncZoomInProgress = true;
      this.$refs.maps.forEach((mapComponent) => {
        if (mapComponent.isTabOpened && mapComponent.mapID !== changedMapID) {
          mapComponent.syncZoom(zoom);
        }
      });
      this.syncZoomInProgress = false;
    },
    handleSyncCenter(changedMapID, center) {
      if (this.syncCenterInProgress) return;

      this.syncCenterInProgress = true;
      this.$refs.maps.forEach((mapComponent) => {
        if (mapComponent.isTabOpened && mapComponent.mapID !== changedMapID) {
          mapComponent.syncCenter(center);
        }
      });
      this.syncCenterInProgress = false;
    },
    async handleClickOnMap(areaID, geometries) {
      if (this.fetchingAreaGeometrySelectedOnMap) return;

      // if it's not LSOA level, don't do anything
      if (this.viewInfo.viewportInfo.dataLevel !== 1) {
        this.openSnackbar(
          "You can only select areas at LSOA level",
          4000,
          "error",
        );
        return;
      }

      let area = null;

      // is it already on the list?
      for (let key in this.areasOfInterest) {
        for (let j = 0; j < this.areasOfInterest[key].length; j++) {
          if (this.areasOfInterest[key][j].id === areaID) {
            area = JSON.parse(JSON.stringify(this.areasOfInterest[key][j]));
            break;
          }
        }
      }

      if (!area) {
        const response = await this.getSelectedAreaOnMap(areaID);

        if (response.error) {
          this.openSnackbar(response.error, 4000, "error");
          return;
        }
        area = JSON.parse(JSON.stringify(response));

        // add it to the list
        const type = area.is_double_disadvantage ? "disadvantaged" : "others";
        this.areasOfInterest[type].push(area);
      }

      const type = area.is_double_disadvantage ? "disadvantaged" : "others";
      area.selected = !area.selected;

      // update the checkbox
      this.areasOfInterest[type].forEach((a) => {
        if (a.id === areaID) a.selected = area.selected;
      });

      if (area.selected) {
        this.displayAreaOfInterestOnMaps(area, false, geometries);
      } else {
        this.hideAreaOfInterestOnMaps(areaID);
      }

      this.openSnackbar(
        area.selected
          ? "Area of interest selected"
          : "Area of interest selection removed",
        4000,
        "#265928",
      );
      this.fetchingAreaGeometrySelectedOnMap = false;

      //reset the report dialog flags to force regeneration of report
      this.$refs.DTSidebarComponent.$refs.reportDialog.selectedAreasChanged();
    },
    async syncHoverOverMap(hoveredAreaID, isHoverOut = false) {
      this.$refs.maps.forEach((map) => {
        if (map.isTabOpened) {
          let data = isHoverOut
            ? { value: "-", name: "None" }
            : map.getAreaValue(hoveredAreaID);

          this.areaMouseOverInfo[map.selectedIndicator.id].value = data.value;
          this.areaMouseOverInfo["area_name"] = data.name;

          // higthlight the area
          map.higlightAreaOnMap(hoveredAreaID, isHoverOut ? 0.5 : 3.5);
        }
      });
    },
    closeDialog() {
      this.emit.emit("systemBusy", false);
      this.$router.push("/discovery-tool");
    },
    // get data for just opened map via the expansion tab
    getLoadedDataForNewMap(requestingMapID) {
      // TODO: add disabling of all unopened panels on mounted until the parent boundaries are fetched as if there's no boundaries, we won't be able to get anything
      let data = {
        zoom: null,
        center: null,
        parentBoundaries: this.parentAreaBoundaries,
        areaOfInterestPolygons: this.areaOfInterestPolygons,
      };

      const openedMap = this.$refs.maps.find(
        (map) => map.isTabOpened && map.mapID !== requestingMapID,
      );

      if (openedMap) {
        data.zoom = openedMap.getCurrentZoomLevel();
        data.center = openedMap.getCurrentCenter();
      }

      return data;
    },
    getIndicators() {
      this.loadingIndicators = true;
      this.$axios
        .get("/get-indicators-discovery-tool")
        .then((response) => {
          // handle success
          this.indicators = response.data;

          if (this.indicators.length < 3) {
            this.emit.emit("systemMessage", {
              message:
                "Currently you don't have enough indicators assigned to your client. Please contact support for assistance",
              title: "Failed to fetch indicators",
              timeout: -1,
              colour: "error",
            });

            return;
          }

          // assign the first 3 indicators to the selectedIndicators
          this.selectedIndicators = this.indicators
            .slice(0, 3)
            .map((indicator) => JSON.parse(JSON.stringify(indicator)));

          // we need it for hovering over maps
          this.selectedIndicators.forEach((indicator) => {
            this.areaMouseOverInfo[indicator.id] = {};
          });

          this.colourScheme = response.data[0].colour_scheme;

          setTimeout(() => {
            this.updateViewDetails({ forceUpdate: true });
          }, 500);
        })
        .catch((error) => {
          // handle error
          console.error(error);
          this.emit.emit("systemMessage", {
            message: error.response.data.message,
            title: "Failed to fetch indicators",
            timeout: -1,
            colour: "error",
          });
        })
        .finally(() => {
          this.loadingIndicators = false;
        });
    },
    deBounce({ forceUpdate = false } = {}) {
      // clear this
      clearTimeout(this.idleTimeout);
      // start the countdown again!
      this.idleTimeout = setTimeout(
        function () {
          this.updateViewDetails({ forceUpdate: forceUpdate });
        }.bind(this),
        1250,
      );
    },
    updateViewDetails({ forceUpdate = false } = {}) {
      const currentMapBounds = this.$refs.maps
        .find((map) => map.isTabOpened)
        .getMapBounds();

      this.viewInfo.viewportInfo.xmin = currentMapBounds.xmin;
      this.viewInfo.viewportInfo.ymin = currentMapBounds.ymin;
      this.viewInfo.viewportInfo.xmax = currentMapBounds.xmax;
      this.viewInfo.viewportInfo.ymax = currentMapBounds.ymax;

      if (this.selectedDataLevel) {
        // data level selected from dropdown
        this.setSelectedDataLevel();
      } else {
        // map zoom data level
        this.setZoomDataLevel();
      }

      // nothing further if data is same
      if (!this.viewDataChanged() && !forceUpdate) {
        return;
      }

      // add the the hotspot flag go determine what data to get back
      this.viewInfo.viewportInfo.hotspotFlag = this.hotspotFlag;

      //add displayed boundaries/custom areas visualisations flag into backend data
      this.viewInfo.viewportInfo.mapBoundariesGeoJson =
        this.mapBoundariesGeoJson;

      this.viewInfo.viewportInfo.showDataOutsideBoundaries =
        this.showDataOutsideBoundaries;

      // get geometries for each opened map
      this.$refs.maps.forEach((mapComponent) => {
        if (mapComponent.isTabOpened) {
          mapComponent.getPolygonsWithDataset();
        }
      });
    },
    /** whether need to fetch figures and visuals (save some backend calls) */
    viewDataChanged() {
      /**
       * Do not need to fetch indicator visualisations and quintile figures if:
       * i) view is locked (data level fixed) and zooming closer
       * ii) view is unlocked, zooming closer and new data level is same as previous zoom
       */
      let response = true;
      let currentZoom = this.$refs.maps
        .find((map) => map.isTabOpened)
        .getCurrentZoomLevel();

      if (
        (this.lockedView && currentZoom >= this.previousZoom) ||
        (!this.lockedView &&
          currentZoom >= this.previousZoom &&
          this.viewInfo.viewportInfo.dataLevel === this.previousDataLevel)
      ) {
        response = false;
      }

      this.previousZoom = currentZoom; //update previous zoom value with current zoom level

      return response;
    },
    /** set data level based on selected level */
    setSelectedDataLevel() {
      //set data level to selected and reset flag
      if (!this.lockedView && this.selectedDataLevel) {
        this.previousDataLevel = this.viewInfo.viewportInfo.dataLevel;
        this.viewInfo.viewportInfo.dataLevel = this.selectedDataLevel;
        this.selectedDataLevel = null;
      }
    },
    /** set data level based on map zoom */
    setZoomDataLevel() {
      this.previousDataLevel = this.viewInfo.viewportInfo.dataLevel;

      let currentZoomLevel = this.$refs.maps[0].getCurrentZoomLevel();
      currentZoomLevel = this.zoomAreaLevels[currentZoomLevel];

      // find the lowest show level within the indicators
      let lowestShowLevel = Infinity;
      this.selectedIndicators.forEach((indicator) => {
        if (indicator.lowest_show_level < lowestShowLevel) {
          lowestShowLevel = indicator.lowest_show_level;
        }
      });

      // not locked & zoom is below data lowest level - set data level to lowest
      if (!this.lockedView && lowestShowLevel > currentZoomLevel) {
        this.viewInfo.viewportInfo.dataLevel = lowestShowLevel;
      } else {
        //not locked - set data level to zoom
        if (!this.lockedView) {
          this.viewInfo.viewportInfo.dataLevel = currentZoomLevel;
        }
      }
    },
    updateselectedIndicators() {
      // TODO: hook for the future
    },
    openSnackbar(message, timeout = 4000, colour = "primary") {
      // get the width of the sidebar
      this.mapSidebarWidth = this.$refs.mapSidebar.$el.offsetWidth;

      // close any open snackbar
      this.snackBar.show = false;

      // open a new one
      this.snackBar.message = message;
      this.snackBar.timeout = timeout;
      this.snackBar.colour = colour;
      this.snackBar.show = true;
    },
    resizeTheMaps() {
      const openedMapsCount = this.$refs.maps.filter(
        (map) => map.isTabOpened,
      ).length;

      this.$refs.maps.forEach((map) => {
        map.adjustMapHeight(openedMapsCount);
      });
    },
    async getSelectedAreaOnMap(areaID) {
      // loading snackbar
      this.openSnackbar("Fetching area details...", -1, "#0E5B99");
      this.fetchingAreaGeometrySelectedOnMap = true;

      try {
        const response = await this.$axios.post("/get-area-selected-on-map", {
          parent_area_ids: this.selectedLAorCLA,
          selected_area_id: areaID,
        });
        return response.data;
      } catch (error) {
        console.error(error);

        return {
          error: error.response.data.message,
        };
      } finally {
        this.fetchingAreaGeometrySelectedOnMap = false;
      }
    },
    handleDataLevelSelected(newValue) {
      this.selectedDataLevel = newValue;
      if (!this.lockedView) {
        this.updateViewDetails();
      }
    },
    handleLockViewChanged(newValue) {
      this.lockedView = newValue;
      if (!this.lockedView) {
        this.updateViewDetails();
      }
    },
    handleDataBoundaryViewChanged(newValue) {
      this.showDataOutsideBoundaries = newValue;
      this.updateViewDetails({ forceUpdate: true });
    },
    async getParentBoundaries() {
      // get passed area IDs from the URL
      this.selectedLAorCLA = this.$route.params.parentAreaIDs.split("-");

      this.$axios
        .post("/get-boundaries-discovery-tool", {
          area_ids: this.selectedLAorCLA,
        })
        .then((response) => {
          // save them in case we need them later
          this.parentAreaBoundaries = response.data;

          // display the boundaries
          this.$refs.maps.forEach((mapComponent) => {
            mapComponent.displayParentAreaBoundaries(
              this.parentAreaBoundaries,
              true,
            );
          });

          // add client boundary to list of boundaries currently displayed
          this.mapBoundariesGeoJson.push(
            JSON.stringify(JSON.parse(response.data.boundary)),
          );

          // once we've got the boundaries and zoomed in let's display the data
          setTimeout(() => {
            this.getIndicators();
          }, 1000);
        })
        .catch((error) => {
          // handle error
          this.emit.emit("systemMessage", {
            message: error.response.data.message,
            title: "Failed to fetch boundaries for the selected areas",
            timeout: -1,
            colour: "error",
          });
          console.error(error);
        });
    },
    hideAreaOfInterestOnMaps(areaID) {
      this.$refs.maps.forEach((map) => {
        if (map.isTabOpened) {
          map.hideAreaOfInterest(areaID);
        }
      });

      // when hiding, remember the visible in case a map is reopened
      this.areaOfInterestPolygons.forEach((area) => {
        if (area.areaID === areaID) {
          area.visible = false;
        }
      });
    },
    async displayAreaOfInterestOnMaps(area, zoomIn = false, geometries = null) {
      let isGeomFetched = area.boundary_fetched || false;

      // get geometries if haven't already and display area on the maps
      if (!isGeomFetched) {
        if (!geometries) {
          geometries = await this.getAreaOfInterestGeometries(area.id);
        }
        this.updatePolygonsWithAreaOfInterest(geometries);

        // remember the geometries for when a map is reopened
        this.areaOfInterestPolygons.push({
          areaID: area.id,
          geometries: geometries,
          visible: true,
        });
      } else {
        // geometries are already there, just make them visible
        this.$refs.maps.forEach((map) => {
          if (map.isTabOpened) {
            map.makeAreaOfInterestVisible(area.id);
          }
        });

        // remember the visible in case a map is reopened
        this.areaOfInterestPolygons.forEach((a) => {
          if (a.areaID === area.id) {
            a.visible = true;
          }
        });
      }

      if (zoomIn) {
        setTimeout(() => {
          this.zoomToAreaOfInterest(area.id);
        }, 200);
      }
    },
    async getAreaOfInterestGeometries(areaID) {
      try {
        this.setAreaLoadingState(areaID, true);

        const geometries = await this.$axios.get(
          `/get-geom-for-area-of-interest/${areaID}`,
        );

        this.setAreaLoadingState(areaID, false);

        return geometries.data;
      } catch (error) {
        this.emit.emit("systemMessage", {
          message: error.response.data.message,
          title: "Failed to fetch geometrics",
          timeout: -1,
          colour: "error",
        });
        return {};
      }
    },
    setAreaLoadingState(areaID, isLoading) {
      for (let key in this.areasOfInterest) {
        const area = this.areasOfInterest[key].find(
          (area) => area.id === areaID,
        );

        if (area) {
          area.loading = isLoading;
          break;
        }
      }
    },
    updatePolygonsWithAreaOfInterest(geometries) {
      this.$refs.maps.forEach((map) => {
        if (map.isTabOpened) {
          map.updatePolygonWithAreasOfInterest(geometries);
        }
      });
    },
    zoomToAreaOfInterest(areaID) {
      this.$refs.maps.forEach((map) => {
        if (map.isTabOpened) {
          map.zoomToAreaOfInterest(areaID);
        }
      });

      this.deBounce({ forceUpdate: true });
    },
  },
};
</script>

<style scoped></style>
<style>
/* some styling for the vuetify's snackbar */
.discoveryToolSnackbar .v-snackbar__content {
  text-align: center !important;
}
.discoveryToolSnackbar .v-snackbar__wrapper {
  min-width: unset !important;
}
</style>
