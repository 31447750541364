<template>
  <v-dialog v-model="dialog" scrollable persistent max-width="800px">
    <template v-slot:activator="{ props }">
      <v-btn
        color="primary"
        tile
        variant="elevated"
        v-bind="props"
        aria-label="Create New"
      >
        Create New
      </v-btn>
    </template>
    <v-card>
      <v-toolbar
        :color="this.$store.state.config.siteConfig.toolbar_colour"
        dark
        class="text-h5 pl-3"
        >Create New Client</v-toolbar
      >
      <v-card-text>
        <v-form ref="form" v-model="validation" lazy-validation>
          <v-container>
            <v-row style="padding-top: 15px !important">
              <v-col
                cols="12"
                sm="12"
                md="6"
                v-for="(item, key) in newClient"
                :key="key"
                style="padding-top: 0px !important"
              >
                <Field
                  :schema="fieldSchema[key]"
                  v-model:value="newClient[key]"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          variant="elevated"
          color="error"
          tile
          @click="dialog = false"
          aria-label="Close"
        >
          Close
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          variant="elevated"
          :disable="this.$store.state.busy"
          color="success"
          tile
          @click="createNewClient()"
          aria-label="Create Client"
        >
          Create Client
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Field from "@/components/Fields.vue";
export default {
  name: "ClientManagerCreate",
  data: () => ({
    fieldSchema: {},
    dialog: false,
    newClient: {},
    validation: false,
  }),
  components: {
    Field,
  },
  props: {
    schema: {},
    clients: {
      type: Array,
      default: () => {
        return [];
      },
      required: false,
    },
  },
  mounted() {
    // clone the schema as we do not want any changes (api errors etc) propergating anywhere where this schema is being used
    this.fieldSchema = this.$cloneDeep(this.schema);
  },
  methods: {
    createNewClient() {
      this.resetApiValidationErrors();

      this.$refs.form.validate().then(({ valid: isValid }) => {
        if (isValid) {
          this.emit.emit("systemMessage", {
            message: "",
            title: "Creating new Client",
            timeout: -1,
            colour: "warning",
          });

          // make the call!
          this.$axios
            .post("/clients", this.newClient)
            .then((response) => {
              this.emit.emit("systemMessage", {
                title: "Success!",
                message: "Client Creation Complete",
                timeout: 3000,
                colour: "green",
              });

              // add the new one to the list
              this.$emit("add-client", response.data);

              // close the dialogue
              this.dialog = false;
              this.buildNewClient();
            })
            .catch((error) => {
              this.emit.emit("systemMessage", {
                message: error.response.data.message,
                title: "Error! Client Creation Failed",
                timeout: -1,
                colour: "red",
              });
              this.apiValidationErrors(error.response.data.errors);
            });
        }
      });
    },
    buildNewClient() {
      for (const key in this.fieldSchema) {
        if (this.fieldSchema[key].useInCreationMode) {
          this.newClient[key] = null;
        }
      }
    },
    apiValidationErrors(errors) {
      for (const field in errors) {
        this.fieldSchema[field].apiResponseError = errors[field];
      }
    },
    resetApiValidationErrors() {
      for (var field in this.fieldSchema) {
        this.fieldSchema[field].apiResponseError = null;
      }
    },
  },
  watch: {
    dialog: {
      handler() {
        if (this.dialog && Object.keys(this.newClient).length === 0) {
          this.buildNewClient();
        }
      },
      deep: true,
    },
    "newClient.client_type"() {
      // if it's a demo client then set the report limit 10
      if (this.newClient.client_type === "demo") {
        this.newClient.report_limit = 10;
      } else {
        this.newClient.report_limit = null;
      }

      // if it's a test client set the sector
      if (this.newClient.client_type === "test") {
        this.newClient.client_sector = "other";
      }
    },
  },
};
</script>

<style scoped></style>
