<template>
  <v-chip density="compact" class="py-1 px-1 ocsi-only-chip">
    <v-icon icon="mdi-crown"></v-icon>
    <v-tooltip activator="parent"
      >This feature is only available to OCSI admins</v-tooltip
    >
  </v-chip>
</template>

<script>
export default {
  name: "OCSIONLYCHIP",
  data: () => ({}),
};
</script>

<style scoped>
.ocsi-only-chip {
  background-color: #ffeaa5;
}

.ocsi-only-chip i {
  color: #af6200;
}
</style>
