<template>
  <div>
    <v-btn
      color="warning"
      rounded="0"
      variant="elevated"
      @click="dialog = true"
      aria-label="View / Edit"
    >
      View / Edit
    </v-btn>
    <v-dialog
      v-model="dialog"
      fullscreen
      transition="dialog-bottom-transition"
      hide-overlay
      persistent
      scrollable
    >
      <!-- KPI Selection -->
      <v-dialog v-model="addKPIDialog" width="300">
        <v-card>
          <v-toolbar
            :style="
              'background-color: ' +
              this.$store.state.config.siteConfig.toolbar_colour
            "
          >
            <v-toolbar-title class="text-left"
              >How Many KPI boxes?</v-toolbar-title
            ></v-toolbar
          >
          <v-card-actions>
            <v-card-text>
              <v-row align="center">
                <v-select
                  v-model="numberOfKPIs"
                  :items="[1, 2, 3, 4]"
                  label="Select number of KPI boxes"
                  variant="outlined"
                  density="compact"
                  rounded="0"
                  class=""
                  align-center
                  clearable
                >
                </v-select
              ></v-row>
            </v-card-text>
          </v-card-actions>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              rounded="0"
              variant="elevated"
              @click="addKPIDialog = false"
              aria-label="Close"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="testKPIsDialog" width="90vw">
        <v-card>
          <v-card-actions>
            <KPIs :KPIsObject="testKPIResponse" />
          </v-card-actions>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              rounded="0"
              variant="elevated"
              @click="testKPIsDialog = false"
              aria-label="Close"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="testDynamicTextDialog" width="800">
        <v-card>
          <v-card-actions>
            <v-card-text>
              <div v-html="testDynamicTextResponse" class="testDynamicText" />
            </v-card-text>
          </v-card-actions>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              rounded="0"
              variant="elevated"
              @click="testDynamicTextDialog = false"
              aria-label="Close"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="confirmDialog" width="550">
        <v-card>
          <v-toolbar
            :style="
              'background-color: ' +
              this.$store.state.config.siteConfig.toolbar_colour
            "
          >
            <v-toolbar-title class="text-left"
              >Confirm Deletion?</v-toolbar-title
            ></v-toolbar
          >
          <v-card-actions>
            <v-card-text style="text-align: center">
              Are you sure you want to delete this? You <i>cannot</i> undo this
              action!
            </v-card-text>
          </v-card-actions>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              color="primary"
              rounded="0"
              variant="elevated"
              @click="
                deleteItem(false);
                itemToDelete = null;
              "
              aria-label="cancel"
            >
              cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              rounded="0"
              variant="elevated"
              @click="deleteItem(true)"
              aria-label="Delete"
            >
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="unsavedChangesDialog" width="550">
        <v-card>
          <v-toolbar
            :style="
              'background-color: ' +
              this.$store.state.config.siteConfig.toolbar_colour
            "
          >
            <v-toolbar-title class="text-left"
              >Are you crazy?! You have unsaved changes!</v-toolbar-title
            ></v-toolbar
          >
          <v-card-actions>
            <v-card-text style="text-align: center">
              My Friend! You will lose your changes...forever!...if you don't
              press SAVE
            </v-card-text>
          </v-card-actions>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              color="success"
              rounded="0"
              variant="elevated"
              @click="
                $emit('updateTheme');
                dialog = false;
              "
              aria-label="Save Them!"
            >
              Save Them!
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              rounded="0"
              variant="elevated"
              @click="
                dialog = false;
                $emit('cancelEdit');
                unsavedChangesDialog = false;
              "
              aria-label="Don't Care!"
            >
              Don't Care!
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-card>
        <v-toolbar
          :color="this.$store.state.config.siteConfig.toolbar_colour"
          dark
          class="text-h5"
          style="max-height: 63px"
        >
          <v-btn icon dark @click="close()" aria-label="Close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ reportThemeObject.name }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text id="editThemeArea">
          <v-card-actions>
            <v-row align="center">
              <v-col cols="3">
                <v-select
                  v-model="countryForClone"
                  id="selectReportTheme"
                  :items="countries"
                  item-title="name"
                  item-value="country"
                  label="Clone this Theme:"
                  variant="outlined"
                  density="compact"
                  rounded="0"
                  class=""
                  align-center
                  clearable
                >
                </v-select>
              </v-col>
              <v-col class="mb-6">
                <v-btn
                  :disabled="!countryForClone"
                  color="warning"
                  left
                  rounded="0"
                  variant="elevated"
                  @click="cloneForAnotherSite(reportThemeObject.id)"
                  aria-label="create clone"
                >
                  create clone
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
          <v-card-actions>
            <v-text-field
              v-model="reportThemeObject.title"
              type="text"
              label="Theme Header"
              variant="outlined"
              density="compact"
              rounded="0"
              class="width"
              hint="Theme Header"
              autocomplete="off"
            ></v-text-field>
          </v-card-actions>
          <div class="description-header">Theme Description</div>
          <v-card-actions>
            <v-btn
              color="success"
              class="ma-2"
              rounded="0"
              variant="elevated"
              size="small"
              @click="
                dynTextMode = true;
                addMetaTableDialog = true;
              "
              aria-label="Add Indicator"
            >
              Add Indicator
            </v-btn>
            <v-btn
              color="primary"
              class="ma-2"
              rounded="0"
              variant="elevated"
              size="small"
              @click="testDynamicText(reportThemeObject.description)"
              aria-label="Test Dynamic Text"
              :loading="fetchingDynamicTextResponse"
              :disabled="fetchingDynamicTextResponse"
            >
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon light>mdi-cached</v-icon>
                </span>
              </template>
              Test Dynamic Text
            </v-btn>
          </v-card-actions>
          <v-card-actions class="pt-0" id="tiptap">
            <TipTapOCSI
              v-model:content="reportThemeObject.description"
              styling="width:100vw; max-width:800px"
              :underline="false"
              :orderedList="false"
              :heading1="false"
              :heading2="false"
              :heading3="false"
              :image="false"
              :link="false"
            />
          </v-card-actions>
          <v-card-actions>
            <v-btn
              color="success"
              rounded="0"
              variant="elevated"
              @click="$emit('updateTheme')"
              aria-label="Save Changes"
            >
              Save Changes
            </v-btn>
            <v-btn
              color="warning"
              rounded="0"
              variant="elevated"
              @click="setIndicatorValues()"
              aria-label="Aggregate Data"
            >
              Aggregate Data
            </v-btn>
          </v-card-actions>
          <br />
          <v-divider></v-divider>
          <v-card-subtitle class="text-h5 ma-5 ml-0"
            >Add/Edit Theme Content</v-card-subtitle
          >
          <span v-if="indicatorValues">
            <v-card-actions
              v-for="(item, index) in reportThemeObject.config"
              :key="index"
            >
              <!-- KPI -->
              <span v-if="item.kpi_config">
                <v-card-actions class="pt-0" style="width: 166px">
                  <v-select
                    v-model="item.order"
                    :items="pageItemCount"
                    label="Page Position"
                    variant="outlined"
                    density="compact"
                    rounded="0"
                    class="select"
                    align-center
                    @change="$emit('updateTheme')"
                  >
                  </v-select>
                  <v-btn
                    class="ml-4"
                    style="top: -10px"
                    rounded="0"
                    variant="elevated"
                    color="error"
                    :title="'delete kpi'"
                    @click="
                      itemToDelete = item.id;
                      deleteItem(null);
                    "
                    aria-label="Delete KPI's"
                  >
                    <v-icon aria-label="delete" dark>
                      mdi-delete-forever-outline
                    </v-icon>
                  </v-btn>
                </v-card-actions>
                <v-card-actions>
                  <v-btn
                    color="success"
                    class="ma-2"
                    rounded="0"
                    variant="elevated"
                    size="small"
                    @click="
                      dynTextMode = true;
                      addMetaTableDialog = true;
                    "
                    aria-label="Add Indicator"
                  >
                    Add Indicator
                  </v-btn>
                  <v-btn
                    color="primary"
                    class="ma-2"
                    rounded="0"
                    variant="elevated"
                    size="small"
                    @click="testKPIs(item.kpi_config)"
                    aria-label="Test KPI's"
                    :loading="fetchingDynamicTextResponse"
                    :disabled="fetchingDynamicTextResponse"
                  >
                    <template v-slot:loader>
                      <span class="custom-loader">
                        <v-icon light>mdi-cached</v-icon>
                      </span>
                    </template>
                    Test KPIs
                  </v-btn>
                </v-card-actions>
                <v-card-actions>
                  <v-card v-for="(item, index) in item.kpi_config" :key="index">
                    <v-card-actions>
                      <v-text-field
                        v-model="item.header"
                        label="Header"
                        title="Header (dynamic text)"
                        variant="outlined"
                        density="compact"
                        rounded="0"
                        width="20vw"
                        autocomplete="off"
                      ></v-text-field>
                    </v-card-actions>
                    <v-card-actions>
                      <v-text-field
                        v-model="item.body"
                        label="Body"
                        title="Body (dynamic text)"
                        variant="outlined"
                        density="compact"
                        rounded="0"
                        autocomplete="off"
                      ></v-text-field>
                    </v-card-actions>
                    <v-card-actions>
                      <v-text-field
                        v-model="item.footer"
                        label="Footer"
                        title="Footer (dynamic text)"
                        variant="outlined"
                        density="compact"
                        rounded="0"
                        autocomplete="off"
                      ></v-text-field>
                    </v-card-actions>
                  </v-card>
                </v-card-actions>
                <v-card-actions>
                  <v-text-field
                    v-model="item.kpi_config[0].source"
                    label="KPI Source"
                    title="KPI Source (dynamic text)"
                    variant="outlined"
                    density="compact"
                    rounded="0"
                    autocomplete="off"
                  ></v-text-field>
                </v-card-actions>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="success"
                    rounded="0"
                    variant="elevated"
                    @click="$emit('updateTheme')"
                    aria-label="update KPI(s)"
                  >
                    update KPI(s)
                  </v-btn>
                </v-card-actions>
              </span>
              <!-- Text -->
              <span v-if="item.text">
                <v-card-actions class="pt-0" style="width: 166px">
                  <v-select
                    v-model="item.order"
                    :items="pageItemCount"
                    label="Page Position"
                    variant="outlined"
                    density="compact"
                    rounded="0"
                    class="select"
                    align-center
                    @change="$emit('updateTheme')"
                  >
                  </v-select>
                  <v-btn
                    class="ml-4"
                    style="top: -10px"
                    rounded="0"
                    variant="elevated"
                    color="error"
                    :title="'delete text'"
                    @click="
                      itemToDelete = item.id;
                      deleteItem(null);
                    "
                    aria-label="Delete text"
                  >
                    <v-icon aria-label="delete" dark>
                      mdi-delete-forever-outline
                    </v-icon>
                  </v-btn>
                </v-card-actions>
                <v-card-actions>
                  <v-btn
                    color="success"
                    class="ma-2"
                    rounded="0"
                    variant="elevated"
                    size="small"
                    @click="
                      dynTextMode = true;
                      addMetaTableDialog = true;
                    "
                    aria-label="Add Indicator"
                  >
                    Add Indicator
                  </v-btn>
                  <v-btn
                    color="primary"
                    class="ma-2"
                    rounded="0"
                    variant="elevated"
                    size="small"
                    @click="testDynamicText(item.text)"
                    aria-label="Test Dynamic Text"
                    :loading="fetchingDynamicTextResponse"
                    :disabled="fetchingDynamicTextResponse"
                  >
                    <template v-slot:loader>
                      <span class="custom-loader">
                        <v-icon light>mdi-cached</v-icon>
                      </span>
                    </template>
                    Test Dynamic Text
                  </v-btn>
                </v-card-actions>
                <v-card-actions class="pt-0">
                  <TipTapOCSI
                    v-model:content="item.text"
                    styling="width:100vw; max-width:1200px"
                    :underline="false"
                    :orderedList="false"
                    :heading1="false"
                    :heading2="false"
                    :image="false"
                  />
                </v-card-actions>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="success"
                    rounded="0"
                    variant="elevated"
                    @click="$emit('updateTheme')"
                    aria-label="update text"
                  >
                    update text
                  </v-btn>
                </v-card-actions>
              </span>
              <!-- Table -->
              <span v-if="item.indicatorHTMLTableRef">
                <IndicatorDataTableEditor
                  :selectedComparators="selectedComparators"
                  :item="item"
                  :comparators="selectedComparators"
                  :comparatorsList="comparatorsList"
                  v-on:updateTheme="$emit('updateTheme')"
                  v-on:setIndicatorValues="setIndicatorValues()"
                />
              </span>
              <!-- Chart -->
              <span v-if="item.chartType">
                <ReportThemeChartEditor
                  @update:item="(val) => (item = val)"
                  :selectedComparators="selectedComparators"
                  :item="item"
                  :comparators="selectedComparators"
                  :comparatorsList="comparatorsList"
                  :indicatorValues="indicatorValues"
                  v-on:updateTheme="$emit('updateTheme')"
                  v-on:setIndicatorValues="setIndicatorValues()"
                  :load="load"
                />
              </span>
            </v-card-actions>
          </span>
          <v-card-actions class="pt-0">
            <v-select
              v-model="addItem"
              :items="addThemeItems"
              label="Add Item"
              variant="outlined"
              density="compact"
              rounded="0"
              class="select"
              align-center
              clearable
              return-object
            >
            </v-select>
          </v-card-actions>
        </v-card-text>
      </v-card>
      <AddMetaTable
        v-model:addMetaTableDialog="addMetaTableDialog"
        :indicatorHTMLTableRef="indicatorHTMLTableRef"
        v-on:updateTheme="$emit('updateTheme')"
        v-on:setIndicatorValues="setIndicatorValues()"
        :dynTextMode="dynTextMode"
      />
      <AddChartToReportTheme
        v-model:addChartDialog="addChartDialog"
        v-on:updateTheme="$emit('updateTheme')"
      />
    </v-dialog>
  </div>
</template>
<script>
import TipTapOCSI from "@/components/TipTapOCSI";
import AddMetaTable from "@/components/AddMetaTable";
import AddChartToReportTheme from "@/components/AddChartToReportTheme";
import IndicatorDataTableEditor from "@/components/IndicatorDataTableEditor";
import ReportThemeChartEditor from "@/components/ReportThemeChartEditor";
import KPIs from "@/components/KPIs";
import { exportCSVFile } from "@/mixins/ExportCSVFile";

export default {
  name: "REPORT_THEME_EDITOR",
  data: () => ({
    testKPIResponse: null,
    testKPIsDialog: false,
    numberOfKPIs: 0,
    addKPIDialog: false,
    fetchingDynamicTextResponse: false,
    exportCSVFile: exportCSVFile,
    load: false,
    testDynamicTextDialog: false,
    testDynamicTextResponse: null,
    indicatorHTMLTableRef: null,
    addItem: null,
    addThemeItems: [
      { title: "Indicator Data Table", value: "addIndicatorDataTable" },
      { title: "Chart", value: "addChart" },
      { title: "Text", value: "addText" },
      { title: "KPI", value: "addKPI" },
    ],
    tableHeaders: [],
    loading: false,
    metaDataSearchResults: [],
    metaDataSearch: null,
    autocompleteMetaData: null,
    dialog: false,
    addMetaTableDialog: false,
    addChartDialog: false,
    confirmDialog: false,
    unsavedChangesDialog: false,
    dynTextMode: false,
    countries: [],
    countryForClone: null,
    itemToDelete: null,
  }),
  components: {
    AddMetaTable,
    IndicatorDataTableEditor,
    ReportThemeChartEditor,
    AddChartToReportTheme,
    KPIs,
    TipTapOCSI,
  },
  computed: {
    pageItemCount: {
      get() {
        let array = [];
        for (var i = 0; i < this.reportThemeObject.config.length; i++) {
          array.push(i);
        }
        return array;
      },
    },
    reportThemeObject: {
      get() {
        return this.$store.state.reportThemeObject;
      },
      set(value) {
        this.$store.commit("setReportThemeObject", value);
      },
    },
    indicatorValues: {
      get() {
        return this.$store.state.indicatorValues;
      },
      set(value) {
        this.$store.commit("setIndicatorValues", value);
      },
    },
    reportThemeObjectClone: {
      get() {
        return this.$store.state.reportThemeObjectClone;
      },
    },
  },
  props: {
    selectedComparators: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    comparatorsList: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
  },
  mounted() {
    // set the cloning countries
    if (
      ["eng", "sct", "wls"].includes(
        this.$store.state.config.siteConfig.site_country,
      )
    ) {
      this.countries = [
        { country: "E", name: "England" },
        { country: "W", name: "Wales" },
        { country: "S", name: "Scotland" },
      ];
    } else if (this.$store.state.config.siteConfig.site_country === "aus") {
      this.countries = [{ country: "A", name: "Australia" }];
    }
  },
  unmounted() {},
  methods: {
    cloneForAnotherSite() {
      this.emit.emit("systemMessage", {
        title: "Creating the Clone",
        message: "Please Wait...",
        timeout: -1,
        colour: "warning",
      });
      this.$axios
        .put(
          "/clone-theme/" +
            this.reportThemeObject["id"] +
            "/" +
            this.countryForClone,
        )
        .then((response) => {
          this.emit.emit("systemMessage", {
            title: "Success!",
            message: "Clone created",
            timeout: 3000,
            colour: "green",
          });
          this.$emit("clone", response.data);
        })
        .catch((error) => {
          // handle error
          console.error(error);
          this.emit.emit("systemMessage", {
            message: error.response.data.message,
            title: "Error! Failed to clone theme",
            timeout: -1,
            colour: "red",
          });
        });
    },
    close() {
      if (
        JSON.stringify(this.reportThemeObjectClone) !=
        JSON.stringify(this.reportThemeObject)
      ) {
        this.unsavedChangesDialog = true;
      } else {
        this.dialog = false;
        this.$emit("cancelEdit");
      }
    },
    testDynamicText(text) {
      this.fetchingDynamicTextResponse = true;
      this.testDynamicTextResponse = null;
      let test = { text: text };
      for (var i = 0; i < this.selectedComparators.length; i++) {
        test[i] = this.selectedComparators[i];
      }
      // make the call!
      this.$axios
        .post("/reports-themes-test-dynamic-text", test)
        .then((response) => {
          this.testDynamicTextDialog = true;
          this.testDynamicTextResponse = response.data;
          this.fetchingDynamicTextResponse = false;
        })
        .catch((error) => {
          this.fetchingDynamicTextResponse = false;
          this.emit.emit("systemMessage", error.response.data.message);
        });
    },
    testKPIs(kpiConfig) {
      this.fetchingDynamicTextResponse = true;
      this.testKPIResponse = null;
      let test = { kpiConfig: kpiConfig };
      for (var i = 0; i < this.selectedComparators.length; i++) {
        test[i] = this.selectedComparators[i];
      }
      // make the call!
      this.$axios
        .post("/reports-themes-test-kpis", test)
        .then((response) => {
          this.testKPIsDialog = true;
          this.testKPIResponse = response.data;
          this.fetchingDynamicTextResponse = false;
          this.fetchingDynamicTextResponse = false;
        })
        .catch((error) => {
          this.fetchingDynamicTextResponse = false;
          this.emit.emit("systemMessage", error.response.data.message);
        });
    },
    deleteItem(state) {
      if (state === null) {
        this.confirmDialog = true;
      } else if (state === true) {
        this.reportThemeObject.config = this.reportThemeObject.config.map(
          (item) => {
            if (item.id === this.itemToDelete) {
              item.delete = true;
            }
            return item;
          },
        );

        this.$emit("updateTheme");
        this.confirmDialog = false;
      } else {
        this.confirmDialog = false;
      }
    },
    setIndicatorValues() {
      // set this so we can auto scroll back to it after we have saved
      var scrollTo = document.querySelector("#editThemeArea")
        ? document.querySelector("#editThemeArea").scrollTop
        : 0;
      var tempIndicatorValues = {}; // we use this to leave any deleted indicaters behind
      var newKey = null;
      let indicatorName = null;
      for (var i = 0; i < this.reportThemeObject.config.length; i++) {
        for (
          var j = 0;
          j < this.reportThemeObject.config[i].indicators.length;
          j++
        ) {
          for (var k = 0; k < this.comparatorsList.length; k++) {
            // use shortName check
            indicatorName =
              this.reportThemeObject.config[i].use_short_indicator_name &&
              this.reportThemeObject.config[i].indicators[j].shortName !== null
                ? this.reportThemeObject.config[i].indicators[j].shortName
                : this.reportThemeObject.config[i].indicators[j].indicatorName;
            newKey =
              this.comparatorsList[k].area_code +
              "." +
              this.reportThemeObject.config[i].indicators[j].indicatorCode +
              "." +
              this.reportThemeObject.config[i].indicators[j].date +
              "." +
              indicatorName;
            if (typeof this.indicatorValues[newKey] === "undefined") {
              this.indicatorValues[newKey] = null;
            }
            tempIndicatorValues[newKey] = this.indicatorValues[newKey];
          }
        }
      }
      this.addItem = null;
      this.emit.emit("systemMessage", {
        title: "Aggregating Data for Selected Areas",
        message: "Please Wait...",
        timeout: -1,
        colour: "warning",
      });
      tempIndicatorValues.progressID = false;
      this.indicatorValues = null;
      this.$axios
        .post("/indicator-values-by-area", tempIndicatorValues)
        .then(
          function (response) {
            // handle success
            this.emit.emit("systemMessage", {
              title: "Success",
              message: "Aggregation Complete",
              timeout: 3000,
              colour: "success",
            });
            this.$nextTick(() => {
              this.indicatorValues = response.data;
              this.emit.emit("systemBusy", false);
              setTimeout(function () {
                if (document.querySelector("#editThemeArea")) {
                  document.querySelector("#editThemeArea").scroll({
                    top: scrollTo,
                    left: 0,
                    behavior: "smooth",
                  });
                }
              }, 700);
            });
          }.bind(this),
        )
        .catch(
          function (error) {
            this.emit.emit("systemBusy", false);
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              title: "Error! Failed to get Indicator Values",
              message: error.response.data.message,
              timeout: -1,
              colour: "error",
            });
          }.bind(this),
        );
    },
  },
  watch: {
    // new KPI(s)
    numberOfKPIs: {
      handler(val) {
        if (val) {
          var kpiArray = [];
          for (var i = 0; i < val; i++) {
            kpiArray.push({
              source: "",
              header: "",
              body: "",
              footer: "",
            });
          }
          this.reportThemeObject["newKPI"] = kpiArray;
          this.$emit("updateTheme");
          this.addKPIDialog = false;
          this.$nextTick(() => {
            this.numberOfKPIs = 0;
          });
        }
      },
    },
    dialog: {
      handler() {
        // for triggering loading the charts
        this.$nextTick(() => {
          this.load = this.dialog;
        });
      },
      deep: true,
    },
    addItem(val) {
      if (val) {
        switch (val.value) {
          case "addIndicatorDataTable":
            this.addMetaTableDialog = true;
            this.dynTextMode = false;
            break;
          case "addChart":
            this.addChartDialog = true;
            break;
          case "addText":
            this.reportThemeObject["newTextArea"] =
              "Add and edit your text here...";
            this.$emit("updateTheme");
            break;
          case "addKPI":
            this.addKPIDialog = true;
            break;
          default:
            this.addMetaTableDialog = false;
            this.addChartDialog = false;
            this.addKPIDialog = false;
        }
        this.$nextTick(() => {
          this.addItem = null;
        });
      }
    },
  },
};
</script>

<style scoped>
/* styling for tip tap word count */
.text-overline {
  color: #000 !important;
}
.width {
  width: 100vw;
  max-width: 600px;
}
.height {
  height: 200px;
  overflow: auto;
}
.description-header {
  position: relative;
  margin-left: 17px;
  margin-bottom: 5px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: 0.0071428571em;
  color: rgba(0 0 0);
}
.meta-description-header {
  position: relative;
  margin-left: 17px;
  margin-bottom: 2px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: 0.0071428571em;
  color: rgba(0 0 0);
}
.sub-header {
  position: relative;
  margin-top: 15px;
  margin-bottom: -6px;
  margin-left: 17px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: 0.0071428571em;
  color: rgba(0 0 0);
}
.select {
  max-width: 250px !important;
}
.testDynamicText::v-deep p {
  margin-bottom: 20px !important;
  text-align: left;
}
#tiptap ::v-deep ul {
  padding-left: 24px;
}
</style>
