export const Debounce = {
  methods: {
    debounce(func = null, wait = 500) {
      let timeout = null;
      return (...args) => {
        if (timeout) {
          clearTimeout(timeout);
        }

        timeout = setTimeout(() => {
          func(...args);
          timeout = null;
        }, wait);
      };
    },
  },
};
