<template>
  <v-card tile width="100%" pa-5>
    <v-container fluid>
      <v-row class="mt-10">
        <v-file-input
          v-model="file"
          label=".csv"
          accept=".csv"
          type="file"
          style="width: 60%"
          density="compact"
          rounded="0"
          variant="outlined"
          class="ma-3 mt-0"
        ></v-file-input>
        <v-text-field
          v-model="timepoint"
          label="Time Point"
          density="compact"
          rounded="0"
          variant="outlined"
          class="ma-3 mt-0"
          autocomplete="off"
        ></v-text-field>
      </v-row>
      <v-row>
        <v-spacer />
        <v-btn
          variant="elevated"
          class="ma-3"
          tile
          color="blue darken-1"
          @click="confirmLookUpDialogue = true"
          :disabled="!file || !timepoint"
        >
          Import lookup
        </v-btn>
      </v-row>
    </v-container>
    <v-dialog v-model="confirmLookUpDialogue" width="450">
      <v-card>
        <v-card-title
          :style="
            'background-color: ' +
            this.$store.state.config.siteConfig.toolbar_colour
          "
          class="text-h6 text-center"
        >
          <v-spacer style="color: white">{{
            loading ? "Loading..." : "Confirm"
          }}</v-spacer>
        </v-card-title>
        <!-- loading UX -->
        <v-card-actions v-if="loading" tile elevation="0" class="progress">
          <div class="progressText mb-11">
            <v-progress-circular
              :size="230"
              :width="3"
              color="#51627C"
              indeterminate
              >Importing lookups... <br />
              This may take a few minutes
            </v-progress-circular>
          </div>
        </v-card-actions>
        <div v-else>
          <v-card-actions>
            <v-card-text style="text-align: center">
              <p>
                This will overwrite the existing parent areas mentioned in the
                CSV file. Are you sure you want to continue?
              </p>
            </v-card-text>
          </v-card-actions>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              tile
              color="primary"
              variant="elevated"
              @click="confirmLookUpDialogue = false"
            >
              cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn tile color="warning" variant="elevated" @click="lookup()">
              continue
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
export default {
  name: "LookupUpload",
  data: () => ({
    file: null,
    timepoint: "",
    confirmLookUpDialogue: false,
    loading: false,
  }),
  methods: {
    lookup() {
      this.loading = true;

      // csv file has 2 headers, 'child area code' and 'parent area code'
      const file = this.file;
      const reader = new FileReader();
      reader.readAsText(file);

      reader.onload = () => {
        const csv = reader.result;
        const data = [];
        const rows = csv.split(/\r\n|\n/).filter(Boolean); // filter out empty strings
        const headers = rows[0].split(",");

        for (let i = 1; i < rows.length; i++) {
          const row = rows[i].split(",");
          const entry = {};
          for (let j = 0; j < headers.length; j++) {
            entry[headers[j]] = row[j];
          }
          data.push(entry);
        }

        this.$axios
          .post("/lookup-upload", {
            timepoint: this.timepoint,
            file: data,
          })
          .then(
            function () {
              // handle success
              this.emit.emit("systemMessage", {
                title: "Lookup is done",
                message: "Success",
                timeout: 3000,
                colour: "success",
              });
              this.file = null;
              this.timepoint = "";
              this.loading = false;
              this.confirmLookUpDialogue = false;
            }.bind(this),
          )
          .catch(
            function (error) {
              // handle error
              console.error(error);
              this.loading = false;
              this.emit.emit("systemMessage", {
                message: error.response.data.message,
                title: "Error! Failed",
                timeout: -1,
                colour: "error",
              });
            }.bind(this),
          );
      };
    },
  },
};
</script>
<style scoped></style>
