<template>
  <v-card
    class="pa-10 scrollPage text-left"
    :style="'height:' + (height - 50) + 'px;'"
  >
    <v-card-actions
      v-if="customPageLoading"
      tile
      elevation="0"
      class="progress"
    >
      <div class="progressText">
        <v-progress-circular
          :size="200"
          :width="3"
          color="primary"
          indeterminate
        >
          Loading pages
        </v-progress-circular>
      </div>
    </v-card-actions>
    <div v-else id="extraPageContent" v-html="customPageData.page_html"></div>
  </v-card>
</template>

<script>
import { useDisplay } from "vuetify";
import { getURLParam } from "@/mixins/GetURLParam";

export default {
  name: "ViewCustomPages",
  data: () => ({
    height: useDisplay().height,
    customPageData: {},
    customPageLoading: false,
  }),
  mounted() {},
  methods: {
    getCustomPageData() {
      let id = getURLParam("page-id");
      this.customPageLoading = true;

      this.$axios
        .get("/page/" + id)
        .then(
          function (response) {
            this.customPageData = response.data;
            this.customPageLoading = false;
          }.bind(this),
        )
        .catch(
          function (error) {
            this.emit.emit("systemMessage", {
              title: "Error! Page data retrieval failed",
              message: error.response.data.message,
              timeout: -1,
              colour: "error",
            });
          }.bind(this),
        );
    },
  },
  watch: {
    "$route.query.page-id": {
      handler(newPageId) {
        if (newPageId) {
          this.getCustomPageData();
        }
      },
      immediate: true, // This will call the handler immediately with the current value of the query param
    },
  },
};
</script>

<style scoped>
.scrollPage {
  overflow-y: auto;
  width: 100%;
}
</style>
<style>
/* styles to get the tip tap tables looking good */
#extraPageContent table th,
#extraPageContent table td {
  padding: 6px 13px;
  border: 1px solid #d0d7de;
}
#extraPageContent .table-wrapper {
  display: contents;
}
/* grayed rows of the table */
#extraPageContent table tr:nth-child(even) {
  background-color: #f4f6f8;
}
/* task checkboxes view */
.task-list-item {
  display: flex;
}
.task-list-item input {
  margin-right: 10px;
}

#extraPageContent ul,
#extraPageContent ol {
  margin-left: 30px !important;
}
</style>
