function setCookie(name, value, hours) {
  if (hours) {
    var date = new Date();
    date.setTime(date.getTime() + hours * 60 * 60 * 1000);
    var expires = "; expires=" + date.toGMTString();
  } else {
    expires = "";
  }
  document.cookie =
    name + "=" + value + expires + "; path=/; domain=localinsight.org";
}

export { setCookie };
