<template>
  <v-chip
    v-if="colour"
    variant="elevated"
    :color="colour"
    class="text-black mr-3"
    style="font-weight: 600"
  >
    {{ currentEnvironment.toUpperCase() }}
  </v-chip>
</template>
<script>
export default {
  name: "EnvironmentTag",
  data: () => ({
    environmentColours: [
      { dev: "#AAD643" },
      { stg: "#F2EB70" },
      { uat: "#FA7372" },
      { datalake: "#F7B348" },
    ],
    currentEnvironment: "",
    colour: "",
  }),
  mounted() {
    // set the chip only once on mounted so there will be no way to edit it
    this.currentEnvironment = process.env.VUE_APP_ENVIRONMENT;

    this.environmentColours.forEach((env) => {
      if (env[this.currentEnvironment]) {
        this.colour = env[this.currentEnvironment];
      }
    });
  },
};
</script>
<style scoped>
.v-chip__content {
  color: rgba(0, 0, 0, 0.7);
}
</style>
