<template>
  <v-container fluid>
    <v-card :style="'height:' + (height - 65) + 'px; overflow: auto;'" tile>
      <v-card-text class="text-left">
        <v-card-title class="pl-0"
          ><h1 class="h1">User Module Group Manager</h1></v-card-title
        >
        <v-card-actions>
          <v-divider></v-divider>
        </v-card-actions>
        A page for creating, editing and deleting module groups; and also for
        adding and removing modules and users to said groups.
      </v-card-text>
      <v-card-actions>
        <!-- dropdown card/tile -->
        <v-card tile width="100%">
          <v-card-actions style="width: 50%">
            <v-autocomplete
              v-model="clientObject"
              returnObject
              :items="clients"
              item-title="client_name"
              item-value="id"
              label="View Client Module Groups"
              density="compact"
              rounded="0"
              variant="outlined"
              clearable
              :loading="loadingClients"
              :readonly="loadingClients"
              autocomplete="off"
            >
            </v-autocomplete>
          </v-card-actions>
        </v-card>
      </v-card-actions>

      <v-card-actions v-if="showPanel">
        <v-row dense>
          <!-- List of Client Module Groups -->
          <v-col cols="6">
            <v-card tile pa-5>
              <v-card-title class="text-start"
                >{{ clientName }} module groups</v-card-title
              >
              <v-card-actions>
                <v-card-text
                  v-if="!clientModuleData.client_user_module_groups.length"
                >
                  This client does not have any module groups.
                </v-card-text>
                <v-list
                  density="compact"
                  rounded="0"
                  width="100%"
                  class="text-left"
                >
                  <v-list-item
                    v-for="item in clientModuleData.client_user_module_groups"
                    :key="item.id"
                    @click="selectedModuleGroup = item"
                    :style="
                      'background: ' +
                      (selectedModuleGroup === item ? '#e9e9e9;' : '')
                    "
                  >
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card-actions>
              <v-card-actions>
                <v-btn
                  color="success"
                  tile
                  variant="elevated"
                  @click="buildNewModuleGroup()"
                  aria-label="Add new module group"
                >
                  Add new module group
                </v-btn>
                <v-spacer></v-spacer>
                <v-dialog
                  v-model="showNewGroupDialog"
                  scrollable
                  max-width="300px"
                >
                  <v-card>
                    <v-card-title
                      :style="
                        'background-color: ' +
                        this.$store.state.config.siteConfig.toolbar_colour
                      "
                      class="text-center"
                    >
                      Add new module group
                    </v-card-title>
                    <v-card-text>
                      <v-form
                        class="mt-3"
                        ref="form"
                        v-model="validationNewModuleGroup"
                        lazy-validation
                      >
                        <span v-for="(item, key) in newModuleGroup" :key="key">
                          <Field
                            :schema="fieldSchemaNew[key]"
                            v-model:value="newModuleGroup[key]"
                            class="my-3"
                          />
                        </span>
                      </v-form>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-btn
                        variant="elevated"
                        color="error"
                        tile
                        @click="showNewGroupDialog = false"
                        aria-label="Close"
                      >
                        Close
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        variant="elevated"
                        color="success"
                        tile
                        @click="addClientModuleGroup()"
                        aria-label="Save"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-card-actions>
            </v-card>
          </v-col>

          <!-- Client Module Group specific "sttuff" -->
          <v-col cols="6">
            <v-card tile pa-5>
              <div class="text-left">
                <v-card-title>Module group information panel</v-card-title>
                <v-card-text v-if="selectedModuleGroup"
                  >Showing the {{ selectedModuleGroup["name"] }} module group
                </v-card-text>
                <v-card-text v-else
                  >Select a module group to display its information
                </v-card-text>
                <v-card-text
                  v-if="!clientModuleData.client_user_module_groups.length"
                  >Create a module group to display its information
                </v-card-text>
              </div>

              <v-card v-if="selectedModuleGroup">
                <!--  Tabs headers -->
                <v-tabs v-model="tab" color="primary">
                  <v-tab value="details"> Details </v-tab>
                  <v-tab value="modules"> Modules </v-tab>
                  <v-tab value="users"> Users </v-tab>
                </v-tabs>

                <!--  Tabs content  -->
                <v-window v-model="tab">
                  <v-window-item value="details">
                    <v-row>
                      <v-col>
                        <v-card-title
                          >{{ selectedModuleGroup["name"] }} module group
                          details
                        </v-card-title>
                        <v-card-text>
                          <v-form
                            ref="editform"
                            v-model="validationEditModuleGroup"
                            lazy-validation
                          >
                            <span
                              v-for="(item, key) in fieldSchemaEdit"
                              :key="key"
                            >
                              <Field
                                :schema="fieldSchemaEdit[key]"
                                v-model:value="selectedModuleGroupClone[key]"
                              />
                            </span>
                          </v-form>
                        </v-card-text>
                      </v-col>
                    </v-row>
                  </v-window-item>

                  <v-window-item value="modules">
                    <v-card elevation="0">
                      <v-card-title
                        >Modules assigned to the
                        {{ selectedModuleGroup["name"] }} module group
                      </v-card-title>

                      <v-card-text style="overflow: auto">
                        <span
                          v-for="(
                            module, index
                          ) in clientModuleData.client_available_modules"
                          :key="index"
                        >
                          <v-checkbox
                            v-model="selectedModuleGroupClone.modules"
                            :value="module.id"
                            :key="index"
                            @change="toggleCheckbox()"
                            hide-details
                            density="compact"
                            rounded="0"
                            :label="module.label"
                            :return-object="true"
                          >
                          </v-checkbox>
                        </span>
                      </v-card-text>
                    </v-card>
                  </v-window-item>

                  <v-window-item value="users">
                    <v-card elevation="0">
                      <v-card-title
                        >Users in the {{ selectedModuleGroup["name"] }} module
                        group
                      </v-card-title>

                      <v-card-text>
                        <span
                          v-for="(
                            clientsUser, index
                          ) in clientModuleData.clients_users"
                          :key="index"
                        >
                          <v-checkbox
                            v-model="selectedModuleGroupClone.users"
                            :value="clientsUser.id"
                            :key="index"
                            density="compact"
                            rounded="0"
                            :label="clientsUser.name"
                            hide-details
                            :return-object="true"
                          >
                          </v-checkbox>
                        </span>
                      </v-card-text>
                    </v-card>
                  </v-window-item>
                </v-window>

                <v-card tile>
                  <v-card-actions>
                    <v-btn
                      color="success"
                      variant="elevated"
                      tile
                      @click="editClientModuleGroup()"
                      aria-label="Save changes"
                    >
                      Save changes
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="error"
                      variant="elevated"
                      tile
                      @click="showModuleGroupDeleteConfirmDialog = true"
                      aria-label="Delete group"
                    >
                      Delete group
                    </v-btn>
                  </v-card-actions>
                </v-card>
                <v-dialog
                  v-model="showModuleGroupDeleteConfirmDialog"
                  persistent
                  max-width="400"
                >
                  <v-card>
                    <v-card-title
                      :style="
                        'background-color: ' +
                        this.$store.state.config.siteConfig.toolbar_colour
                      "
                      class="text-center"
                    >
                      Confirm deletion
                    </v-card-title>
                    <v-card-text
                      class="text-center"
                      style="height: 100px; padding: 30px"
                    >
                      <v-spacer>
                        Are you sure you want to delete this module group?
                      </v-spacer>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        color="success"
                        variant="elevated"
                        @click="showModuleGroupDeleteConfirmDialog = false"
                        aria-label="Cancel"
                        tile
                      >
                        Cancel
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="error"
                        variant="elevated"
                        @click="
                          showModuleGroupDeleteConfirmDialog = false;
                          deleteClientModuleGroup();
                        "
                        tile
                        aria-label="Yes"
                      >
                        delete
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-card>
            </v-card>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import schema from "@/schemas/ClientUserGroupModulesManager";
import Field from "@/components/Fields.vue";
import { useDisplay } from "vuetify";

export default {
  name: "UserModuleGroupManager",
  data: () => ({
    height: useDisplay().height,
    tab: null,
    clients: [],
    fieldSchemaNew: {},
    fieldSchemaEdit: {},
    clientID: null,
    clientName: null,
    clientObject: null,
    clientModuleData: {
      client_available_modules: [],
      client_user_module_groups: [],
      clients_users: [],
    },
    loadingClients: true,
    newModuleGroup: {},
    editModuleGroup: {},
    selectedModuleGroup: null,
    selectedModuleGroupClone: {},
    showPanel: false,
    showNewGroupDialog: false,
    showModuleGroupDeleteConfirmDialog: false,
    validationNewModuleGroup: false,
    validationEditModuleGroup: false,
  }),
  components: {
    Field,
  },
  mounted() {
    this.getAllClients();
    this.fieldSchemaNew = this.$cloneDeep(schema);
    this.fieldSchemaEdit = this.$cloneDeep(schema);
  },
  methods: {
    getAllClients() {
      this.loadingClients = true;
      this.$axios
        .get("/clients")
        .then(
          function (response) {
            // handle success
            this.clients = response.data;
            this.loadingClients = false;
          }.bind(this),
        )
        .catch(
          function (error) {
            this.loadingClients = false;
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to get all clients",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    getClientModuleGroups() {
      this.emit.emit("systemBusy", true);
      this.showPanel = false;

      this.$axios
        .get("/client-user-module-groups/client-search/" + this.clientID)
        .then(
          function (response) {
            // handle success
            this.clientModuleData = response.data;
            this.emit.emit("systemBusy", false);
            this.showPanel = true;
          }.bind(this),
        )
        .catch(
          function (error) {
            this.emit.emit("systemBusy", false);
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to get selected client",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    buildNewModuleGroup() {
      this.showNewGroupDialog = true;

      for (const key in this.fieldSchemaNew) {
        if (this.fieldSchemaNew[key].useInCreationMode) {
          this.newModuleGroup[key] = null;
        }
      }
      this.newModuleGroup["client_id"] = this.clientID;
      this.validationNewModuleGroup = false;
    },
    addClientModuleGroup() {
      this.emit.emit("systemBusy", true);
      this.$axios
        .post("/client-user-module-groups", this.newModuleGroup)
        .then(
          function (response) {
            // handle success
            let data = response.data;
            this.clientModuleData["client_user_module_groups"].push(data);
            this.selectedModuleGroup = data;

            this.showNewGroupDialog = false;
            this.emit.emit("systemMessage", {
              title: "New client module group created",
              message: "Success!",
              timeout: 3000,
              colour: "green",
            });
            this.emit.emit("systemBusy", false);
          }.bind(this),
        )
        .catch(
          function (error) {
            this.emit.emit("systemBusy", false);
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to create new module group",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    deleteClientModuleGroup() {
      this.emit.emit("systemBusy", true);
      this.$axios
        .delete(
          "/client-user-module-groups/" +
            this.selectedModuleGroupClone["id"] +
            "/" +
            this.clientID,
        )
        .then(
          function (response) {
            // handle success
            this.selectedModuleGroup = null;
            this.clientModuleData = response.data;
            this.emit.emit("systemMessage", {
              title: "Client module group deleted",
              message: "Success!",
              timeout: 3000,
              colour: "green",
            });
            this.emit.emit("systemBusy", false);
          }.bind(this),
        )
        .catch(
          function (error) {
            this.emit.emit("systemBusy", false);
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to delete the module group",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    editClientModuleGroup() {
      this.emit.emit("systemBusy", true);
      this.$axios
        .put(
          "/client-user-module-groups/" + this.selectedModuleGroupClone.id,
          this.selectedModuleGroupClone,
        )
        .then(
          function (response) {
            // handle success
            this.clientModuleData = response.data;
            // this.selectedModuleGroup = data;

            this.showNewGroupDialog = false;
            this.emit.emit("systemMessage", {
              message: "Client module group details changed",
              title: "Success!",
              timeout: 3000,
              colour: "green",
            });
            this.emit.emit("systemBusy", false);
          }.bind(this),
        )
        .catch(
          function (error) {
            this.emit.emit("systemBusy", false);
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to change the details",
              timeout: -1,
              colour: error.response.status === 422 ? "blue" : "red",
            });
          }.bind(this),
        );
    },
    toggleCheckbox() {},
  },
  watch: {
    clientID: {
      handler() {
        if (this.clientID) {
          this.getClientModuleGroups();
        } else {
          this.showPanel = false;
        }
      },
      deep: true,
    },
    clientObject: {
      handler() {
        if (this.clientObject) {
          this.clientID = this.clientObject.id;
          this.clientName = this.clientObject.client_name;
          this.selectedModuleGroup = null;
          this.newModuleGroup = {};
        } else {
          this.clientID = null;
          this.clientName = null;
          this.selectedModuleGroup = null;
          this.newModuleGroup = {};
        }
      },
      deep: true,
    },
    selectedModuleGroup: {
      handler() {
        if (this.selectedModuleGroup) {
          this.selectedModuleGroupClone = this.$cloneDeep(
            this.selectedModuleGroup,
          );
        } else {
          this.selectedModuleGroupClone = {};
        }
      },
      deep: true,
    },
  },
};
</script>
