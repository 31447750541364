<template>
  <div>
    <v-dialog v-model="computedDialog" max-width="700px" scrollable>
      <v-card>
        <v-toolbar
          :style="
            'max-height: 60px; ' +
            'background-color: ' +
            $store.state.config.siteConfig.toolbar_colour
          "
          class="text-h6"
        >
          <v-spacer>
            <v-toolbar-title>
              <v-btn
                icon
                dark
                @click="computedDialog = false"
                aria-label="Close"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              About the indicator</v-toolbar-title
            >
          </v-spacer>
        </v-toolbar>
        <!-- loading UX -->
        <v-card-actions
          v-if="!indicator"
          tile
          elevation="0"
          class="text-center"
          :style="`min-height: 500px !important; height: ${height + 5}vh`"
        >
          <v-spacer>
            <div>
              <v-progress-circular
                :size="200"
                :width="2"
                color="#51627C"
                style="font-size: 14px"
                indeterminate
                >Loading...</v-progress-circular
              >
            </div>
          </v-spacer>
        </v-card-actions>

        <div v-else>
          <v-card
            :style="{
              maxHeight: maxHeight + 'vh',
              height: height + 'vh',
              overflowY: 'auto',
            }"
            tile
          >
            <!-- Content -->
            <v-card-text class="text pb-1">
              <div class="text-subtitle-1" style="font-weight: 600">
                {{ indicator.indicator_name }}
              </div>
              <div class="text-caption" style="color: gray">
                <v-icon
                  v-if="indicator.tags"
                  icon="mdi-tag-outline"
                  size="15px"
                />
                {{ indicator.tags }}
              </div>
            </v-card-text>
            <v-card-text class="information px-3 pt-0">
              <div v-for="field in indicatorFields" :key="field.key">
                <v-card-actions
                  v-if="indicator[field.key]"
                  class="px-0 py-2 align-start"
                >
                  <v-icon
                    v-if="field.icon"
                    :icon="field.icon"
                    class="mt-2 mr-1"
                  />
                  <v-card-text class="pa-0">
                    <div>
                      <b>{{ field.name }}</b>
                    </div>
                    <div>{{ indicator[field.key] }}</div>
                  </v-card-text>
                </v-card-actions>
              </div>

              <!-- put some extra content if needed -->
              <slot />
            </v-card-text>
          </v-card>
          <v-card tile class="pa-0">
            <v-card-actions class="">
              <v-spacer></v-spacer>
              <v-btn
                variant="elevated"
                tile
                color="error"
                @click="computedDialog = false"
                aria-label="close"
              >
                close
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "MAPABOUTIND",
  data: () => ({
    indicatorFields: [
      {
        name: "About",
        icon: "mdi-information-outline",
        key: "indicator_description",
      },
      {
        name: "Warnings",
        icon: "mdi-alert",
        key: "warnings",
      },
      {
        name: "Source",
        icon: "mdi-bullhorn-variant-outline",
        key: "source",
      },
      {
        name: "Reference date",
        icon: "mdi-calendar",
        key: "date_coverage_text",
      },
      {
        name: "Update Frequency",
        icon: "mdi-update",
        key: "update_frequency",
      },
    ],
  }),
  computed: {
    computedDialog: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("update:dialog", val);
      },
    },
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    indicator: {},
    height: {
      type: Number,
      required: false,
    },
    maxHeight: {
      type: Number,
      required: false,
      default: 50,
    },
  },
};
</script>

<style scoped>
.information * {
  color: #4d4d4d;
}
</style>
