<template>
  <div v-if="showServiceTypesLegend">
    <v-card flat class="pa-0">
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-title
            style="height: 64px"
            class="dialog-title header-hover text-left"
            disable-icon-rotate
            @click="togglePanelCloseIcon()"
          >
            <div class="subheader" id="ServiceLegendTitle">Services</div>
            <span v-if="loadingServices"
              >Loading... {{ serviceLoadPercentage }}%</span
            >
            <template v-slot:actions>
              <v-progress-circular
                v-if="loadingServices"
                indeterminate
                color="#51627C"
                size="25"
              />
              <div v-else-if="!panelToggle">
                <v-icon color="success" size="32"
                  >mdi-information-outline</v-icon
                >
              </div>
              <div v-else>
                <v-icon color="error" size="32"
                  >mdi-arrow-up-drop-circle-outline</v-icon
                >
              </div>
            </template>
          </v-expansion-panel-title>
          <v-divider></v-divider>
          <v-expansion-panel-text
            class="text-left px-0 mx-0 expansionPanelServices"
          >
            <v-card-actions
              v-if="
                $store.state.config.siteConfig.site_country !== 'aus' &&
                selectedCustomAreas.length
              "
              class="mapLegendSwitcher"
            >
              <v-switch
                v-model="displayServicesOutsideCustomAreas"
                :disabled="loadingServices"
                color="black"
                hide-details
                label="Display Services Outside Custom Areas"
              />
            </v-card-actions>
            <v-card-actions class="mapLegendSwitcher">
              <v-switch
                v-model="areServicesClustered"
                :disabled="loadingServices"
                color="black"
                hide-details
                class="clusterServicesSwitcher"
              >
                <template v-slot:label>
                  <p>Cluster nearby Services</p>
                  <p style="font-size: 14px; color: #4d4d4d">
                    Disabling may impact map performance
                  </p>
                </template>
              </v-switch>
            </v-card-actions>
            <v-list style="padding: 8px 24px 16px">
              <v-list-item
                v-for="serviceType in selectedTypes"
                :key="serviceType.id"
                class="text-left px-0 mx-0"
              >
                <template v-slot:prepend>
                  <v-avatar class="mr-4">
                    <v-icon :color="serviceType.colour"
                      >mdi-{{ serviceType.icon }}</v-icon
                    >
                  </v-avatar>
                </template>
                {{ serviceType.name }}
                <template v-slot:append>
                  <v-btn
                    variant="text"
                    icon="mdi-magnify-plus-outline"
                    aria-label="zoom to type"
                    class="icon-success"
                    @click="zoomToServiceType(serviceType)"
                    label="zoom to type"
                  >
                  </v-btn>
                </template>
              </v-list-item>
            </v-list>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "ServiceTypeLegend",
  computed: {
    showServiceTypesLegend() {
      if (this.selectedTypes && this.selectedTypes.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    headerColour() {
      return this.$store.state.config.siteConfig.toolbar_colour;
    },
    displayServicesOutsideCustomAreas: {
      get() {
        return this.displayServicesOutsideCustomAreasProp;
      },
      set(val) {
        this.$emit("update:displayServicesOutsideCustomAreasProp", val);
      },
    },
    areServicesClustered: {
      get() {
        return this.areServicesClusteredProp;
      },
      set(val) {
        this.$emit("update:areServicesClusteredProp", val);
      },
    },
  },
  data: () => ({
    serviceTypeDetails: [],
    panelToggle: false,
  }),
  components: {},
  props: {
    selectedTypes: {
      type: Array,
      default: () => [],
    },
    loadingServices: {
      type: Boolean,
      default: false,
    },
    serviceLoadPercentage: {
      type: Number,
      default: 0,
    },
    displayServicesOutsideCustomAreasProp: {
      type: Boolean,
      default: true,
    },
    selectedCustomAreas: {
      type: Array,
      default: () => [],
    },
    areServicesClusteredProp: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {},
  methods: {
    togglePanelCloseIcon() {
      this.panelToggle = !this.panelToggle;
    },
    toggleTypeFromMap(type) {
      type.visible = !type.visible;
      this.$emit("toggleTypeFromMap", type);
    },
    zoomToServiceType(type) {
      this.$emit("zoomToServiceType", type);
    },
  },
  watch: {},
};
</script>

<style scoped>
.subheader {
  font-size: 18px;
  font-weight: 500;
  padding: 10px;
}

.legendSection {
  max-height: 250px;
  overflow-y: auto;
}

.icon-success:hover {
  color: green;
}

.icon-error:hover {
  color: red;
}

.v-expansion-panel :v-deep(.v-expansion-panel-content__wrap) {
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 0px;
  margin-right: 0px;
}

.v-list-item :v-deep(.v-list-item__content) {
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 5px;
  margin-right: 5px;
}

.typeTitle {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.typeTitleText {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.header-hover:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.mapLegendSwitcher {
  padding: 0 16px !important;
  background-color: #ebebeb;
}
</style>
<style>
.expansionPanelServices .v-expansion-panel-text__wrapper {
  padding: 0 !important;
}

/* force the description to be underneath the label */
.clusterServicesSwitcher .v-label {
  display: block !important;
}

.clusterServicesSwitcher .v-input__control {
  align-items: center;
}
</style>
