<template>
  <Bar
    v-if="loaded"
    :data="chartData"
    :options="chartOptions"
    :aria-label="'Bar chart showing ' + clonedChartConfig.title"
    :plugins="[chartDataLabels]"
  />
</template>
<script>
import { Bar } from "vue-chartjs";
import {
  Chart,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
Chart.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Pyramid",
  components: { Bar },
  data: () => ({
    prefix: null,
    suffix: null,
    loaded: false,
    options: null,
    clonedChartConfig: null,
    chartDataLabels: ChartDataLabels,
    chartDataLabelSizeThreshold: 15,
    requiredComparator: null,
    comparators: {
      C1: 0,
      C2: 1,
      C3: 2,
    },
    max: 0,
  }),
  computed: {
    chartData() {
      return this.clonedChartConfig;
    },
    chartOptions() {
      return this.options;
    },
  },
  props: {
    chartConfig: null,
    stacked: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mounted() {
    this.clonedChartConfig = JSON.parse(JSON.stringify(this.chartConfig));
    this.requiredComparator =
      this.clonedChartConfig.datasets[
        this.comparators[this.clonedChartConfig.comparator]
      ];
    this.buildChart();
    this.reConfigureForPyramid();
  },
  methods: {
    reConfigureForPyramid() {
      var leftData = [];
      var rightData = [];
      var requiredComparator =
        this.clonedChartConfig.datasets[
          this.comparators[this.clonedChartConfig.comparator]
        ];
      var reConfiguredDatasets = [];
      var datasetTemplate = structuredClone(requiredComparator);
      // loop through requiredComparator.data
      for (var i = 0; i < requiredComparator.data.length; i++) {
        if (i % 2 !== 0) continue;
        leftData.push(requiredComparator.data[i] * -1);
        rightData.push(
          typeof requiredComparator.data[i + 1] !== "undefined"
            ? requiredComparator.data[i + 1]
            : 0,
        );
      }
      // left side
      datasetTemplate.data = leftData;
      datasetTemplate.numbers = leftData;
      datasetTemplate.stack = "Stack 0";
      datasetTemplate.backgroundColor =
        this.clonedChartConfig.datasets[0].backgroundColor;
      datasetTemplate.label = this.clonedChartConfig.left_label;
      reConfiguredDatasets.push(structuredClone(datasetTemplate));
      // right side
      datasetTemplate.data = rightData;
      datasetTemplate.numbers = rightData;
      datasetTemplate.stack = "Stack 0";
      datasetTemplate.backgroundColor =
        this.clonedChartConfig.datasets[1].backgroundColor;
      datasetTemplate.label = this.clonedChartConfig.right_label;
      reConfiguredDatasets.push(structuredClone(datasetTemplate));
      // update the datasets
      this.clonedChartConfig.datasets = reConfiguredDatasets;
    },
    convertStringToNumber(input) {
      // If the input is a number, convert it to a string first
      if (typeof input === "number") {
        input = input.toString();
      }

      // Now safely call match() on the string
      let cleanStr = input.match(/-?\d+(\.\d+)?/);

      // If a valid number is found, convert it to a float
      if (cleanStr) {
        return parseFloat(cleanStr[0]);
      } else {
        return NaN;
      }
    },
    sortPrefixesAndSuffixes() {
      // these charts only like numbers, so we have to stript out any Prefixes & Suffixes in the data and add concatinate them to the the labels and tooltips later on
      for (var i = 0; i < this.clonedChartConfig.datasets.length; i++) {
        // only need to do this for the selected comparator as pyramid charts only have one
        if (
          this.requiredComparator.label !==
          this.clonedChartConfig.datasets[i].label
        ) {
          continue;
        }
        // only do this the once
        if (
          typeof this.clonedChartConfig.datasets[i].prefixes === "undefined"
        ) {
          this.clonedChartConfig.datasets[i].prefixes = [];
          this.clonedChartConfig.datasets[i].suffixes = [];
          this.clonedChartConfig.datasets[i].numbers = [];
          for (
            var j = 0;
            j < this.clonedChartConfig.datasets[i].data.length;
            j++
          ) {
            // If there is data, then process prefix and suffix
            if (this.clonedChartConfig.datasets[i].data[j]) {
              this.clonedChartConfig.datasets[i].numbers.push(
                this.convertStringToNumber(
                  this.clonedChartConfig.datasets[i].data[j],
                ),
              );
              // we do this for pyramids so each half is equal
              if (
                this.clonedChartConfig.datasets[i].numbers[
                  this.clonedChartConfig.datasets[i].numbers.length - 1
                ] > this.max
              ) {
                // debugger;
                this.max =
                  this.clonedChartConfig.datasets[i].numbers[
                    this.clonedChartConfig.datasets[i].numbers.length - 1
                  ];
              }
              // give the pyramid chart a bit of space
              this.max = Math.ceil(this.max * 10) / 10;
              this.clonedChartConfig.datasets[i].data[j] = String(
                this.clonedChartConfig.datasets[i].data[j],
              );
              // Attempt to get the suffix
              var match =
                this.clonedChartConfig.datasets[i].data[j].match(/\D+$/);
              var newSuffix = match ? match[0] : "";
              this.suffix = newSuffix;

              // If the suffix has changed, report to user that there is mixed data
              if (this.suffix !== newSuffix) {
                this.$emit("data-error", this.clonedChartConfig.canvasID);
              }

              this.clonedChartConfig.datasets[i].suffixes.push(this.suffix);
              // Remove the suffix from the data
              this.clonedChartConfig.datasets[i].data[j] =
                this.clonedChartConfig.datasets[i].data[j].replace(/\D+$/, "");
              // Do similar to the above for the prefix
              this.clonedChartConfig.datasets[i].data[j] = String(
                this.clonedChartConfig.datasets[i].data[j],
              );
              match = this.clonedChartConfig.datasets[i].data[j].match(/^\D+/);
              this.prefix = match ? match[0] : "";
              this.clonedChartConfig.datasets[i].prefixes.push(this.prefix);
              // Remove the prefix from the data
              this.clonedChartConfig.datasets[i].data[j] =
                this.clonedChartConfig.datasets[i].data[j].replace(/^\D+/, "");
            } else {
              // No data so do behaviour for no prefix/suffix found
              this.clonedChartConfig.datasets[i].suffixes.push("");
              this.clonedChartConfig.datasets[i].prefixes.push("");
              // Alert the user
              this.$emit("data-error", this.clonedChartConfig.canvasID);
            }
            // convert back to number
            this.clonedChartConfig.datasets[i].data[j] =
              this.clonedChartConfig.datasets[i].numbers[j];
          }
        }
      }
    },
    buildChart() {
      this.sortPrefixesAndSuffixes();
      // Sum the data for each stacked bar chart to find the largest value
      var largestValue = 0;
      var currentStack = 0;
      // Loop through each comparator
      for (var i = 0; i < this.clonedChartConfig.labels.length; i++) {
        // Loop through each indicator
        currentStack = 0;
        for (var j = 0; j < this.clonedChartConfig.datasets.length; j++) {
          var value = parseFloat(this.clonedChartConfig.datasets[j].data[i]);
          if (!isNaN(value)) {
            currentStack += value;
          }
        }
        if (currentStack > largestValue) {
          largestValue = currentStack;
        }
      }
      // For stacked bar charts, do not go over 100 on the Y axis if the data doesn't go over 100
      var ticks = {
        beginAtZero: true,
        callback: (value) => {
          return this.prefix + value + this.suffix;
        },
      };
      // Round down to nearest 1% to account for summing rounding errors.
      largestValue = Math.floor(largestValue);
      if (largestValue <= 100) {
        if (this.stacked) {
          ticks.max = 100;
        }
      }
      this.options = {
        barPercentage: 1,
        categoryPercentage: 1,
        indexAxis: "y",
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            text: this.clonedChartConfig.title,
            font: {
              size: 18,
              style: "normal",
              lineHeight: 1.2,
            },
          },
          datalabels: {
            //chartjs-plugin-datalabels config
            color: (context) => {
              if (
                this.getChartValuePercentOfMax(context) >
                this.chartDataLabelSizeThreshold
              ) {
                return "white";
              }
            },
            anchor: (context) => {
              //1st stacked bar
              if (
                this.stacked &&
                this.getChartValuePercentOfMax(context) <
                  this.chartDataLabelSizeThreshold &&
                this.getChartValueDatasetPosition(context) === 0
              ) {
                return "start";
              }
              //next stacked bar
              if (
                this.stacked &&
                this.getChartValuePercentOfMax(context) <
                  this.chartDataLabelSizeThreshold &&
                this.getChartValueDatasetPosition(context) > 0
              ) {
                return "end";
              }
            },
            align: (context) => {
              //1st stacked bar
              if (
                this.stacked &&
                this.getChartValuePercentOfMax(context) <
                  this.chartDataLabelSizeThreshold &&
                this.getChartValueDatasetPosition(context) === 0
              ) {
                return "start";
              }

              //next stacked bar
              if (
                this.stacked &&
                this.getChartValuePercentOfMax(context) <
                  this.chartDataLabelSizeThreshold &&
                this.getChartValueDatasetPosition(context) > 0
              ) {
                return "end";
              }
            },
            offset: (context) => {
              //left side pyramid row val
              let leftVal =
                context.chart.config.data.datasets[0].data[context.dataIndex];
              //right side pyramid row val
              let rightVal =
                context.chart.config.data.datasets[1].data[context.dataIndex];
              //pixel width of chart
              let chartWidth = context.chart.width;
              //pixel width of y axis
              let yAxisWidth = context.chart.chartArea.left;
              //label padding (for values outside of bar 0 otherwise value)
              let padding =
                Math.abs((leftVal / this.getChartMaxValue(context)) * 100) <
                this.chartDataLabelSizeThreshold
                  ? 0
                  : 4; //4 is default chartjs datalabels value

              /**
               * account for wierd situation where is left side has a value and
               * right side is 0 and label wants to float left - the label needs
               * to be offset by 50% of the chart width multiplied by % width of the
               * left column
               */
              if (context.datasetIndex > 0 && leftVal !== 0 && rightVal === 0) {
                return (
                  ((chartWidth - yAxisWidth) / 2) *
                    (Math.abs(leftVal) / this.getChartMaxValue(context)) -
                  padding
                );
              }

              return 0;
            },
            formatter: function (value, context) {
              //add prefix/suffix to data value & round to 2dp
              if (value) {
                let dataIndex = context.dataset.data.indexOf(value);
                let isInt = value % 1 === 0;
                let displayVal = isInt ? value : value.toFixed(2);
                return (
                  context.dataset.prefixes[dataIndex] +
                  Math.abs(displayVal) +
                  context.dataset.suffixes[dataIndex]
                );
              }
            },
            font: (context) => {
              let size = this.getChartLabelFontSize(context);
              return {
                size: size,
                weight: 600,
              };
            },
          },
          tooltip: {
            enabled: false,
            callbacks: {
              title: (title) => {
                const name = this.clonedChartConfig.labels[title[0].dataIndex];

                return Array.isArray(name) ? name.join("") : name;
              },
              label: (tooltipItem) => {
                const { label: areaName } = tooltipItem.dataset;
                const { raw: value, datasetIndex, dataIndex } = tooltipItem;
                const dataset = this.clonedChartConfig.datasets[datasetIndex];
                const prefix = dataset.prefixes[dataIndex];
                const suffix = dataset.suffixes[dataIndex];

                return ` ${areaName}: ${prefix}${value}${suffix}`;
              },
            },
          },
        },
        legend: {
          labels: {
            fontSize: 16,
          },
        },
        scales: {
          x: {
            display: true,
            min: this.max * -1, // Minimum x-axis value for pyramid chart
            max: this.max, // Minimum x-axis value for pyramid chart
            ticks: {
              callback: (value) => {
                return Math.abs(value);
              },
              font: {
                size: 14,
                style: "normal",
                lineHeight: 1.2,
              },
            },
          },
          y: {
            stacked: this.stacked,
            display: true,
            title: {
              display: false,
              text: !this.clonedChartConfig.chartYLabel
                ? ""
                : this.clonedChartConfig.chartYLabel,
              font: {
                size: 16,
                style: "normal",
                lineHeight: 1.2,
              },
            },
            padding: { top: 30, left: 0, right: 0, bottom: 0 },
          },
        },
      };
      this.loaded = true;
    },
    //return the highest data value from given chart context
    getChartMaxValue(chartCtx) {
      let chartVals = [];
      chartCtx.chart.config.data.datasets.forEach((d) => {
        let dsVals = [...d["data"]];
        //convert negative numbers to positive (for pyramid only)
        dsVals = dsVals.map((v) => Math.abs(v));
        chartVals.push(...dsVals);
      });

      return Math.max(...chartVals);
    },
    //return whether specific chart value as percentage of chart max value
    getChartValuePercentOfMax(chartCtx) {
      let maxValue = this.getChartMaxValue(chartCtx);
      let index = chartCtx.dataIndex; //index of data for context instance
      let value = chartCtx.dataset.data[index]; //value of datapoint for context instance
      return (Math.abs(value) / maxValue) * 100;
    },
    //return the font size (px) for a given chart context
    getChartLabelFontSize(chartCtx) {
      //max number of chars in chart labels (exluding pre/suffix)
      let maxChars = Math.max(
        ...chartCtx.chart.config.data.datasets[0].data.map(
          (d) => d.toFixed(2).toString().length,
        ),
        ...chartCtx.chart.config.data.datasets[1].data.map(
          (d) => d.toFixed(2).toString().length,
        ),
      );
      //number of datasets for each bar group
      /**
       *  abitrary calculation to create a dynamic pixel size
       *  e.g. max single character pixel size / max character length in labels * chart width (where 1000px is 100%)
       */
      let size = Math.round((72 / maxChars) * (chartCtx.chart.width / 1000));

      //assuming we don't want giant labels in basic charts - limit font size
      if (size > 25) {
        return 25;
      }

      return size;
    },
    //return position of current contact value in dataset group e.g. comparitor 1,2 or 3
    getChartValueDatasetPosition(chartCtx) {
      return chartCtx.datasetIndex;
    },
  },
  watch: {},
};
</script>

<style scoped></style>
