<template>
  <Line
    v-if="loaded"
    :data="chartData"
    :options="chartOptions"
    :aria-label="'Line chart showing ' + clonedChartConfig.title"
  />
</template>
<script>
import { Line } from "vue-chartjs";
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
// import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  // ChartDataLabels,
);

export default {
  name: "LineChart",
  // eslint-disable-next-line vue/no-reserved-component-names
  components: { Line },
  data: () => ({
    prefix: null,
    suffix: null,
    loaded: false,
    options: null,
    clonedChartConfig: null,
  }),
  computed: {
    chartData() {
      return this.clonedChartConfig;
    },
    chartOptions() {
      return this.options;
    },
  },
  props: {
    chartConfig: null,
  },
  mounted() {
    this.clonedChartConfig = JSON.parse(JSON.stringify(this.chartConfig));
    this.lineBreakLables();
    this.buildChart();
  },
  methods: {
    // this breaks up the chart labels onto multiple lines if they are too long
    lineBreakLables() {
      var labelThresHold = 3;
      var maxWordsInARow =
        this.clonedChartConfig.labels.toString().length < 170 ? 3 : 1;
      // if there are more than the labels threshold
      if (
        this.clonedChartConfig.labels.length > labelThresHold ||
        this.clonedChartConfig.labels.toString().length > 120
      ) {
        for (var i = 0; i < this.clonedChartConfig.labels.length; i++) {
          if (this.clonedChartConfig.labels[i].length > 18) {
            let explode = this.clonedChartConfig.labels[i].split(" ");
            let labelArray = [];
            labelArray.push("");
            let counter = 0;
            for (var j = 0; j < explode.length; j++) {
              // this number refers to the number of words in a row
              if (j && j % maxWordsInARow === 0) {
                counter++;
                labelArray.push("");
              }
              labelArray[counter] = labelArray[counter] + " " + explode[j];
            }
            this.clonedChartConfig.labels[i] = labelArray;
          }
        }
      }
    },
    sortPrefixesAndSuffixes() {
      // these charts only like numbers, so we have to stript out any Prefixes & Suffixes in the data and concatinate them to the the labels and tooltips later on
      for (var i = 0; i < this.clonedChartConfig.datasets.length; i++) {
        // only do this the once
        if (
          typeof this.clonedChartConfig.datasets[i].prefixes === "undefined"
        ) {
          this.clonedChartConfig.datasets[i].prefixes = [];
          this.clonedChartConfig.datasets[i].suffixes = [];
          for (
            var j = 0;
            j < this.clonedChartConfig.datasets[i].data.length;
            j++
          ) {
            // If there is data, then process prefix and suffix
            if (this.clonedChartConfig.datasets[i].data[j]) {
              this.clonedChartConfig.datasets[i].data[j] = String(
                this.clonedChartConfig.datasets[i].data[j],
              );
              // Attempt to get the suffix
              var match =
                this.clonedChartConfig.datasets[i].data[j].match(/\D+$/);
              var newSuffix = match ? match[0] : "";
              this.suffix = newSuffix;

              // If the suffix has changed, report to user that there is mixed data
              if (this.suffix !== newSuffix) {
                this.$emit("data-error", this.clonedChartConfig.canvasID);
              }

              this.clonedChartConfig.datasets[i].suffixes.push(this.suffix);
              // Remove the suffix from the data
              this.clonedChartConfig.datasets[i].data[j] =
                this.clonedChartConfig.datasets[i].data[j].replace(/\D+$/, "");

              // Do similar to the above for the prefix
              this.clonedChartConfig.datasets[i].data[j] = String(
                this.clonedChartConfig.datasets[i].data[j],
              );
              match = this.clonedChartConfig.datasets[i].data[j].match(/^\D+/);
              this.prefix = match ? match[0] : "";
              this.clonedChartConfig.datasets[i].prefixes.push(this.prefix);
              // Remove the prefix from the data
              this.clonedChartConfig.datasets[i].data[j] =
                this.clonedChartConfig.datasets[i].data[j].replace(/^\D+/, "");
            } else {
              // No data so do behaviour for no prefix/suffix found
              this.clonedChartConfig.datasets[i].suffixes.push("");
              this.clonedChartConfig.datasets[i].prefixes.push("");
              // Alert the user
              this.$emit("data-error", this.clonedChartConfig.canvasID);
            }
          }
        }
      }
    },
    buildChart() {
      this.sortPrefixesAndSuffixes();

      // Go through all data and find largest value
      var largestValue = 0;
      for (var i = 0; i < this.clonedChartConfig.datasets.length; i++) {
        largestValue = this.clonedChartConfig.datasets[i].data.reduce(
          function (a, v) {
            if (!isNaN(parseFloat(v))) {
              return parseFloat(a) > parseFloat(v) ? a : v;
            } else {
              return a;
            }
          },
        );
      }

      // Limit Y axis value
      var ticks = {
        beginAtZero: true,
        callback: (value) => {
          const floatValue = parseFloat(value);

          // Format the y-axis tick labels:
          // - If the value is an integer, display it without decimal places.
          // - If the value has decimal places, display up to three decimal places, trimming any trailing zeros.
          return floatValue % 1 === 0
            ? this.prefix + floatValue + this.suffix
            : this.prefix +
                floatValue.toFixed(3).replace(/\.?0+$/, "") +
                this.suffix;
        },
      };
      // Round down to nearest 1% to account for summing rounding errors.
      largestValue = Math.floor(largestValue);
      if (largestValue <= 100) {
        // Uncomment this if functionality is needed again
        // ticks.max = 100;
      }

      //set last comparator to dashed line
      this.clonedChartConfig.datasets[
        this.clonedChartConfig.datasets.length - 1
      ].borderDash = [10, 5];

      this.options = {
        responsive: true,
        maintainAspectRatio: false,
        elements: {
          point: {
            pointRadius: this.clonedChartConfig.display_data_point_markers
              ? 3
              : 0,
            pointHitRadius: 40,
          },
        },
        plugins: {
          legend: {
            position: "top",
            labels: {
              fontSize: 16,
            },
          },
          title: {
            display: true,
            text: this.clonedChartConfig.title,
            font: {
              size: 18,
              style: "normal",
              lineHeight: 1.2,
            },
          },
          tooltip: {
            enabled: true,
            callbacks: {
              title: (title) => {
                const name = this.clonedChartConfig.labels[title[0].dataIndex];

                return Array.isArray(name) ? name.join("") : name;
              },
              label: (tooltipItem) => {
                const { label: areaName } = tooltipItem.dataset;
                const { raw: value, datasetIndex, dataIndex } = tooltipItem;
                const dataset = this.clonedChartConfig.datasets[datasetIndex];
                const prefix = dataset.prefixes[dataIndex];
                const suffix = dataset.suffixes[dataIndex];

                return ` ${areaName}: ${prefix}${value}${suffix}`;
              },
            },
          },
        },
        scales: {
          x: {
            display: true,
            ticks: {
              font: {
                size: 14,
                style: "normal",
                lineHeight: 1.2,
              },
            },
          },
          y: {
            display: true,
            ticks: {
              ...ticks,
              fontSize: 16,
            },
            title: {
              display: !this.clonedChartConfig.chartYLabel ? false : true,
              text: !this.clonedChartConfig.chartYLabel
                ? ""
                : this.clonedChartConfig.chartYLabel,
              font: {
                size: 16,
                style: "normal",
                lineHeight: 1.2,
              },
            },
            padding: { top: 30, left: 0, right: 0, bottom: 0 },
          },
        },
      };
      this.loaded = true;
    },
  },
  watch: {},
};
</script>

<style scoped></style>
