<template>
  <div class="text-center">
    <v-dialog
      v-model="computedCustomAreaInfoDialog"
      width="500"
      scrollable
      persistent
    >
      <v-card>
        <v-toolbar
          :style="
            'background-color: ' +
            this.$store.state.config.siteConfig.toolbar_colour
          "
          class="text-h6"
        >
          <v-btn
            icon
            dark
            @click="computedCustomAreasInfoDialog = false"
            aria-label="Close"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title> Custom Areas Infomation</v-toolbar-title>
        </v-toolbar>

        <v-card-actions height="70vh"> About your Area </v-card-actions>
        <v-card-actions> NOTHING! </v-card-actions>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            tile
            @click="computedCustomAreaInfoDialog = false"
            aria-label="close"
          >
            close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "MAPCUSTOMAREASINFO",
  data: () => ({}),
  computed: {
    computedCustomAreaInfoDialog: {
      get() {
        return this.customAreaInfoDialog;
      },
      set(val) {
        this.$emit("update:customAreaInfoDialog", val);
      },
    },
  },
  components: {},
  props: {
    customAreaInfoDialog: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  mounted() {},
  methods: {},
  watch: {},
};
</script>

<style scoped></style>
