<template>
  <div style="width: 100%">
    <v-row align="stretch">
      <v-col class="d-flex justify-center">
        <v-card
          :style="
            'width:' +
            100 / KPIsObject.length +
            '%; border:1px solid #51627c; height:200px; text-align: center;'
          "
          v-for="(item, index) in KPIsObject"
          :key="index"
          class="ma-2 justify-center"
          max-width="800px"
        >
          <v-card-subtitle
            style="white-space: normal; min-height: 60px"
            class="mt-1 text-subtitle-1 font-weight-medium mb-0 kpi"
            >{{ item.header }}</v-card-subtitle
          >
          <v-card-title
            class="text-h4 mt-0 mb-3 kpi"
            style="height: 44px !important"
            >{{ item.body }}</v-card-title
          >
          <v-divider class="mr-3 ml-3"></v-divider>
          <v-card-actions
            class="text-subtitle-2 font-weight-light kpi"
            style="white-space: normal; min-height: 60px"
            >{{ item.footer }}</v-card-actions
          >
        </v-card>
      </v-col>
    </v-row>
    <v-row align="stretch">
      <v-col class="d-flex justify-center align-center mt-0 pt-0"
        >{{ KPIsObject[0].source !== null ? "Source: " : ""
        }}{{ KPIsObject[0].source }}
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import FormsInputsAndControls from '@/components/formsInputsAndControls'

export default {
  name: "KPIs",
  data: () => ({}),
  components: {},
  computed: {},
  props: {
    KPIsObject: null,
  },
  mounted() {},
  methods: {},
  watch: {},
};
</script>

<style scoped>
.kpi {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
</style>
