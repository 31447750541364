<template>
  <!-- class="ma-0 pa-0" fill-height -->
  <div>
    <v-dialog v-model="viewInfoDialog" width="550" scrollable>
      <v-card>
        <v-toolbar
          :style="
            'background-color: ' +
            this.$store.state.config.siteConfig.toolbar_colour
          "
        >
          <v-toolbar-title class="text-left"
            >Indicator Information</v-toolbar-title
          ></v-toolbar
        >
        <v-card-text style="height: 500px">
          <v-card-text v-if="viewInfoDialog"
            ><span
              @click="copyIndicatorText()"
              title="click to copy"
              class="moreInfo"
              >Copy indicator:</span
            >
            {{
              this.viewInfoObject.indicatorCode +
              "." +
              this.viewInfoObject.indicatorDate
            }}</v-card-text
          >
          <v-card-text
            v-for="(item, index) in this.viewInfoObject"
            :key="index"
          >
            <strong>{{ index }}</strong
            ><br />{{ item }}
          </v-card-text>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            rounded="0"
            variant="elevated"
            @click="viewInfoDialog = false"
            aria-label="close"
          >
            close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="explanatoryTextDialog" width="500" persistent>
      <v-card>
        <v-card-title
          :style="
            'background-color: ' +
            this.$store.state.config.siteConfig.toolbar_colour
          "
          class="text-h6"
        >
          Update Explanatory Text
        </v-card-title>
        <v-card-actions height="70vh">
          <v-text-field
            v-model="explanatoryText"
            type="text"
            label="explanatory text"
            variant="outlined"
            density="compact"
            rounded="0"
            class="fields"
            autocomplete="off"
          ></v-text-field>
        </v-card-actions>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="red"
            rounded="0"
            variant="elevated"
            @click="explanatoryTextDialog = false"
            aria-label="cancel"
          >
            cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            rounded="0"
            variant="elevated"
            @click="saveExplanatoryText()"
            aria-label="Update"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmDialog" width="550">
      <v-card>
        <v-card-title
          :style="
            'background-color: ' +
            this.$store.state.config.siteConfig.toolbar_colour
          "
          class="text-h6"
        >
          Confirm Deletion?
        </v-card-title>
        <v-card-actions>
          <v-card-text style="text-align: center">
            Are you sure you want to delete this table? You <i>cannot</i> undo
            this action!
          </v-card-text>
        </v-card-actions>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="primary"
            rounded="0"
            variant="elevated"
            @click="deleteItem(false)"
            aria-label="cancel"
          >
            cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            rounded="0"
            variant="elevated"
            @click="deleteItem(true)"
            aria-label="Delete"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-actions>
      <v-row>
        <v-col cols="1">
          <v-select
            v-model="item.order"
            :items="pageItemCount"
            label="Position"
            variant="outlined"
            density="compact"
            rounded="0"
            class="select pt-0"
            align-center
            @change="$emit('updateTheme')"
          >
          </v-select>
        </v-col>
        <v-col cols="2">
          <v-text-field
            label="Table Name"
            variant="outlined"
            density="compact"
            rounded="0"
            class="pt-0"
            align-center
            v-model="item.itemName"
            @change="$emit('updateTheme')"
            autocomplete="off"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field
            label="Table Source"
            variant="outlined"
            density="compact"
            rounded="0"
            class="pt-0"
            align-center
            v-model="item.itemSource"
            @change="$emit('updateTheme')"
            autocomplete="off"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-btn
            color="success"
            rounded="0"
            variant="elevated"
            @click="$emit('updateTheme')"
            class="mr-6"
            aria-label="Save Changes"
          >
            Save Changes
          </v-btn>
          <v-btn
            color="warning"
            rounded="0"
            variant="elevated"
            @click="$emit('setIndicatorValues')"
            aria-label="aggregate"
          >
            aggregate
          </v-btn>
          <v-btn
            color="error"
            class="ml-6"
            rounded="0"
            variant="elevated"
            @click="deleteItem(null)"
            aria-label="delete"
          >
            delete
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
    <v-card-actions class="pt-0"
      >Table ID: {{ item.indicatorHTMLTableRef }}</v-card-actions
    >
    <v-card-actions class="pt-0">
      <AddMetaTable
        v-model:addMetaTableDialog="addMetaTableDialog"
        :indicatorHTMLTableRef="item.indicatorHTMLTableRef"
        :addDomainHTMLTableColTwo="addDomainHTMLTableColTwo"
        v-on:updateTheme="$emit('updateTheme')"
      />

      <!-- Standard Table Type -->
      <table
        v-if="!item.HTMLTableType || item.HTMLTableType == 1"
        class="blueTable"
      >
        <thead>
          <tr>
            <th style="width: 30vw">Indicator</th>
            <th v-for="(comparator, index) in comparators" :key="index">
              {{ comparitorName(comparator) }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(indicator, index) in HTMLTableIndicators" :key="index">
            <td>
              <v-icon
                style="cursor: pointer"
                title="remove indicator"
                @click="
                  removeIndicator(
                    reportThemeObject.id,
                    indicator.id,
                    indicator.indicatorHTMLTableRef,
                  )
                "
                >mdi-trash-can-outline</v-icon
              >
              <input
                type="number"
                class="orderField"
                title="indicator order"
                v-model.number="indicator.order"
              />
              <span
                class="viewInfo"
                @click="viewIndicatorInformation(indicator)"
                >{{
                  item.use_short_indicator_name && indicator.shortName !== null
                    ? indicator.shortName
                    : indicator.indicatorName
                }}
                {{
                  item.concatenate_dates
                    ? " (" + formatDate(indicator.date) + ")"
                    : ""
                }}</span
              >
            </td>
            <td
              align="center"
              v-for="(comparator, index) in comparators"
              :key="index"
            >
              {{
                rateOrValue(
                  comparator +
                    "." +
                    indicator.indicatorCode +
                    "." +
                    indicator.date +
                    "." +
                    indicator.indicatorName,
                )
              }}
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td
              align="center"
              v-for="(comparator, index) in comparators"
              :key="index"
            ></td>
          </tr>
          <tr>
            <td>
              <v-btn
                color="success"
                class="ma-2"
                rounded="0"
                variant="elevated"
                size="x-small"
                @click="
                  addMetaTableDialog = true;
                  addDomainHTMLTableColTwo = false;
                "
                aria-label="Add Indicator"
                >Add Indicator</v-btn
              >
              <v-radio-group
                v-model="item.HTMLTableType"
                class="small ma-2"
                @change="$emit('updateTheme')"
              >
                <template v-slot:label>
                  <div>Table Type:</div>
                </template>
                <v-radio label="Standard" :value="1"></v-radio>
                <v-radio label="Rate & Count" :value="2"></v-radio>
                <v-radio label="Domain" :value="3"></v-radio>
              </v-radio-group>
              <v-checkbox
                label="Concatenate Date To Indicator Name"
                v-model="item.concatenate_dates"
                @change="$emit('updateTheme')"
              ></v-checkbox>
              <v-checkbox
                label="Use Short Indicator Name If Available"
                v-model="item.use_short_indicator_name"
                @change="$emit('updateTheme')"
              ></v-checkbox>
            </td>
            <td
              align="center"
              v-for="(comparator, index) in comparators"
              :key="index"
            ></td>
          </tr>
        </tbody>
      </table>

      <!-- Rate & Count Table Type -->
      <table v-if="item.HTMLTableType == 2" class="blueTable">
        <thead>
          <tr>
            <th style="width: 20vw">Indicator</th>
            <th
              :style="
                'width:' +
                100 / (comparators.length + 1) +
                'px; max-width:' +
                100 / (comparators.length + 1) +
                'px'
              "
              v-for="(comparator, index) in comparators"
              :key="index"
            >
              {{ comparitorName(comparator) }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
            <td v-for="(comparator, index) in comparators" :key="index">
              <table width="100%" border="0">
                <tr>
                  <th align="center" width="50%">Count</th>
                  <th align="center">Rate</th>
                </tr>
              </table>
            </td>
          </tr>
          <tr v-for="(indicator, index) in HTMLTableIndicators" :key="index">
            <td>
              <v-icon
                style="cursor: pointer"
                title="remove indicator"
                @click="
                  removeIndicator(
                    reportThemeObject.id,
                    indicator.id,
                    indicator.indicatorHTMLTableRef,
                  )
                "
                >mdi-trash-can-outline</v-icon
              >
              <input
                type="number"
                class="orderField"
                title="indicator order"
                v-model.number="indicator.order"
              />
              <span
                class="viewInfo"
                @click="viewIndicatorInformation(indicator)"
                >{{
                  item.use_short_indicator_name && indicator.shortName !== null
                    ? indicator.shortName
                    : indicator.indicatorName
                }}
                {{
                  item.concatenate_dates
                    ? " (" + formatDate(indicator.date) + ")"
                    : ""
                }}</span
              >
            </td>
            <td v-for="(comparator, index) in comparators" :key="index">
              <table width="100%" border="0">
                <tr>
                  <td align="center" width="50%">
                    {{
                      indicatorValues[
                        comparator +
                          "." +
                          indicator.indicatorCode +
                          "." +
                          indicator.date +
                          "." +
                          (item.use_short_indicator_name &&
                          indicator.shortName !== null
                            ? indicator.shortName
                            : indicator.indicatorName)
                      ]
                    }}
                  </td>
                  <td align="center">
                    {{
                      indicatorValues[
                        comparator +
                          "." +
                          indicator.indicatorCode +
                          "." +
                          indicator.date +
                          "." +
                          (item.use_short_indicator_name &&
                          indicator.shortName !== null
                            ? indicator.shortName
                            : indicator.indicatorName) +
                          ".rate"
                      ]
                    }}
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td
              align="center"
              v-for="(comparator, index) in comparators"
              :key="index"
            ></td>
          </tr>
          <tr>
            <td>
              <v-btn
                color="success"
                class="ma-2"
                rounded="0"
                variant="elevated"
                size="x-small"
                @click="
                  addMetaTableDialog = true;
                  addDomainHTMLTableColTwo = false;
                "
                aria-label="Add Indicator"
                >Add Indicator</v-btn
              >
              <v-radio-group
                v-model="item.HTMLTableType"
                class="small ma-2"
                @change="$emit('updateTheme')"
              >
                <template v-slot:label>
                  <div>Table Type:</div>
                </template>
                <v-radio label="Standard" :value="1"></v-radio>
                <v-radio label="Rate & Count" :value="2"></v-radio>
                <v-radio label="Domain" :value="3"></v-radio>
              </v-radio-group>
              <v-checkbox
                label="Concatenate Date To Indicator Name"
                v-model="item.concatenate_dates"
                @change="$emit('updateTheme')"
              ></v-checkbox>
              <v-checkbox
                label="Use Short Indicator Name If Available"
                v-model="item.use_short_indicator_name"
                @change="$emit('updateTheme')"
              ></v-checkbox>
            </td>
            <td v-for="(comparator, index) in comparators" :key="index"></td>
          </tr>
        </tbody>
      </table>

      <!-- Domain Table Type -->
      <table v-if="item.HTMLTableType == 3" class="blueTable">
        <thead>
          <tr>
            <th style="width: 20%">Indicator</th>
            <th
              style="width: 30%"
              align="center"
              title="click to edit"
              @click="editExplanatoryText('domainHTMLTableColOneText')"
            >
              <span
                v-if="
                  item.domainHTMLTableColOneText === '' ||
                  (item.domainHTMLTableColOneText == '') === null
                "
                >add explanatory text</span
              >
              <span v-else>{{ item.domainHTMLTableColOneText }}</span>
              <v-icon aria-label="edit" style="color: #ffffff; cursor: pointer"
                >mdi-pencil-outline</v-icon
              >
            </th>
            <th style="width: 20%">Indicator</th>
            <th
              style="width: 30%"
              align="center"
              title="click to edit"
              @click="editExplanatoryText('domainHTMLTableColTwoText')"
            >
              <span
                v-if="
                  item.domainHTMLTableColTwoText === '' ||
                  item.domainHTMLTableColTwoText === null
                "
                >add explanatory text</span
              >
              <span v-else>{{ item.domainHTMLTableColTwoText }}</span>
              <v-icon aria-label="edit" style="color: #ffffff; cursor: pointer"
                >mdi-pencil-outline</v-icon
              >
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>&nbsp;</td>
            <td>
              <table width="100%" border="0">
                <tr>
                  <td
                    :style="
                      'width:' +
                      100 / comparators.length +
                      'px; max-width:' +
                      100 / comparators.length +
                      'px'
                    "
                    align="center"
                    v-for="(comparator, index) in comparators"
                    :key="index"
                  >
                    <strong>{{ comparitorName(comparator) }}</strong>
                  </td>
                </tr>
              </table>
            </td>
            <td>&nbsp;</td>
            <td>
              <table width="100%" border="0">
                <tr>
                  <td
                    :style="
                      'width:' +
                      100 / comparators.length +
                      'px; max-width:' +
                      100 / comparators.length +
                      'px'
                    "
                    align="center"
                    v-for="(comparator, index) in comparators"
                    :key="index"
                  >
                    <strong>{{ comparitorName(comparator) }}</strong>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <table class="blueTable" style="width: 100% !important">
                <tbody>
                  <tr
                    style="height: 50px"
                    v-for="(indicator, index) in domainHTMLTableColOne"
                    :key="index"
                  >
                    <td>
                      <v-icon
                        style="cursor: pointer"
                        title="remove indicator"
                        @click="
                          removeIndicator(
                            reportThemeObject.id,
                            indicator.id,
                            indicator.indicatorHTMLTableRef,
                          )
                        "
                        >mdi-trash-can-outline</v-icon
                      >
                      <input
                        type="number"
                        class="orderField"
                        title="indicator order"
                        v-model.number="indicator.order"
                      />
                      <span
                        class="viewInfo"
                        @click="viewIndicatorInformation(indicator)"
                        >{{
                          item.use_short_indicator_name &&
                          indicator.shortName !== null
                            ? indicator.shortName
                            : indicator.indicatorName
                        }}
                        {{
                          item.concatenate_dates
                            ? " (" + formatDate(indicator.date) + ")"
                            : ""
                        }}</span
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td>
              <table class="blueTable" style="width: 100% !important">
                <tbody>
                  <tr
                    style="height: 50px"
                    v-for="(indicator, index) in domainHTMLTableColOne"
                    :key="index"
                  >
                    <td>
                      <table width="100%" border="0">
                        <tr>
                          <td
                            :style="
                              'width:' +
                              100 / comparators.length +
                              'px; max-width:' +
                              100 / comparators.length +
                              'px'
                            "
                            align="center"
                            v-for="(comparator, index) in comparators"
                            :key="index"
                          >
                            {{
                              indicatorValues[
                                comparator +
                                  "." +
                                  indicator.indicatorCode +
                                  "." +
                                  indicator.date +
                                  "." +
                                  (item.use_short_indicator_name &&
                                  indicator.shortName !== null
                                    ? indicator.shortName
                                    : indicator.indicatorName)
                              ]
                            }}
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td>
              <table class="blueTable" style="width: 100% !important">
                <tbody>
                  <tr
                    style="height: 50px"
                    v-for="(indicator, index) in domainHTMLTableColTwo"
                    :key="index"
                  >
                    <td>
                      <v-icon
                        style="cursor: pointer"
                        title="remove indicator"
                        @click="
                          removeIndicator(
                            reportThemeObject.id,
                            indicator.id,
                            indicator.indicatorHTMLTableRef,
                          )
                        "
                        >mdi-trash-can-outline</v-icon
                      >
                      <span
                        class="viewInfo"
                        @click="viewIndicatorInformation(indicator)"
                        >{{
                          item.use_short_indicator_name &&
                          indicator.shortName !== null
                            ? indicator.shortName
                            : indicator.indicatorName
                        }}
                        {{
                          item.concatenate_dates
                            ? " (" + formatDate(indicator.date) + ")"
                            : ""
                        }}</span
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td>
              <table class="blueTable" style="width: 100% !important">
                <tbody>
                  <tr
                    style="height: 50px"
                    v-for="(indicator, index) in domainHTMLTableColTwo"
                    :key="index"
                  >
                    <td>
                      <table width="100%" border="0">
                        <tr>
                          <td
                            :style="
                              'width:' +
                              100 / comparators.length +
                              'px; max-width:' +
                              100 / comparators.length +
                              'px'
                            "
                            align="center"
                            v-for="(comparator, index) in comparators"
                            :key="index"
                          >
                            {{
                              indicatorValues[
                                comparator +
                                  "." +
                                  indicator.indicatorCode +
                                  "." +
                                  indicator.date +
                                  "." +
                                  (item.use_short_indicator_name &&
                                  indicator.shortName !== null
                                    ? indicator.shortName
                                    : indicator.indicatorName)
                              ]
                            }}
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>
              <v-btn
                color="success"
                class="ma-2"
                rounded="0"
                variant="elevated"
                size="x-small"
                @click="
                  addMetaTableDialog = true;
                  addDomainHTMLTableColTwo = false;
                "
                aria-label="Add Indicator"
                >Add Indicator</v-btn
              >
              <v-radio-group
                v-model="item.HTMLTableType"
                class="small ma-2"
                @change="$emit('updateTheme')"
              >
                <template v-slot:label>
                  <div>Table Type:</div>
                </template>
                <v-radio label="Standard" :value="1"></v-radio>
                <v-radio label="Rate & Count" :value="2"></v-radio>
                <v-radio label="Domain" :value="3"></v-radio>
              </v-radio-group>
              <v-checkbox
                label="Concatenate Date To Indicator Name"
                v-model="item.concatenate_dates"
                @change="$emit('updateTheme')"
              ></v-checkbox>
              <v-checkbox
                label="Use Short Indicator Name If Available"
                v-model="item.use_short_indicator_name"
                @change="$emit('updateTheme')"
              ></v-checkbox>
            </td>
            <td></td>
            <td valign="top">
              <v-btn
                color="success"
                class="ma-2"
                rounded="0"
                variant="elevated"
                size="x-small"
                @click="
                  addMetaTableDialog = true;
                  addDomainHTMLTableColTwo = true;
                "
                aria-label="Add Indicator"
                >Add Indicator</v-btn
              >
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </v-card-actions>
  </div>
</template>

<script>
import AddMetaTable from "@/components/AddMetaTable";
import { formatDate } from "@/mixins/FormatDate";
import { copyToClipboard } from "@/mixins/CopyToClipboard";

export default {
  name: "IDTE",
  data: () => ({
    formatDate: formatDate,
    addMetaTableDialog: false,
    addDomainHTMLTableColTwo: false,
    viewInfoDialog: false,
    viewInfoObject: null,
    explanatoryTextDialog: false,
    explanatoryTextField: null,
    explanatoryText: null,
    idleTimeout: null,
    confirmDialog: false,
  }),
  components: {
    AddMetaTable,
  },
  computed: {
    pageItemCount: {
      get() {
        let array = [];
        for (var i = 0; i < this.reportThemeObject.config.length; i++) {
          array.push(i);
        }
        return array;
      },
    },
    indicatorValues: {
      get() {
        return this.$store.state.indicatorValues;
      },
      set(value) {
        this.$store.commit("setIndicatorValues", value);
      },
    },
    HTMLTableIndicators: {
      get() {
        let array = [];
        for (var i = 0; i < this.item.indicators.length; i++) {
          if (this.item.indicators[i].indicatorHTMLTableRef) {
            array.push(this.item.indicators[i]);
          }
        }
        return array;
      },
    },
    domainHTMLTableColOne: {
      get() {
        let array = [];
        for (var i = 0; i < this.HTMLTableIndicators.length; i++) {
          if (!this.HTMLTableIndicators[i].domainHTMLTableColTwo) {
            array.push(this.HTMLTableIndicators[i]);
          }
        }
        return array;
      },
    },
    domainHTMLTableColTwo: {
      get() {
        let array = [];
        for (var i = 0; i < this.HTMLTableIndicators.length; i++) {
          if (this.HTMLTableIndicators[i].domainHTMLTableColTwo) {
            array.push(this.HTMLTableIndicators[i]);
          }
        }
        return array;
      },
    },
    reportThemeObject: {
      get() {
        return this.$store.state.reportThemeObject;
      },
      set(value) {
        this.$store.commit("setReportThemeObject", value);
      },
    },
  },
  props: {
    comparators: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    selectedComparators: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    comparatorsList: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    disable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mounted() {},
  methods: {
    copyIndicatorText() {
      if (
        copyToClipboard(
          this.viewInfoObject.indicatorCode +
            "." +
            this.viewInfoObject.indicatorDate,
        )
      ) {
        // show a snack bar to pop up for a second to say it’s been copied to their clipboard
        this.emit.emit("systemMessage", {
          title: "The value has been copied to the clipboard",
          message: "",
          timeout: 3000,
          color: "green",
        });
      } else {
        /* clipboard write failed */
        this.emit.emit("systemMessage", {
          title: "The value has NOT been copied to the clipboard",
          message: "Error",
          timeout: -1,
          colour: "red",
        });
      }
    },
    rateOrValue(key) {
      // if it's a standard HTML table and it's a dynamic indicater then return the rate and NOT the count
      if (key.includes("*")) {
        return this.indicatorValues[key + ".rate"];
      } else {
        return this.indicatorValues[key];
      }
    },
    saveExplanatoryText() {
      this.item[this.explanatoryTextField] = this.explanatoryText;
      this.$emit("updateTheme");
      this.explanatoryTextDialog = false;
    },
    editExplanatoryText(val) {
      this.explanatoryTextField = val;
      this.explanatoryText = this.$cloneDeep(this.item[val]);
      this.explanatoryTextDialog = true;
    },
    removeIndicator(themeID, id, ref) {
      let i = 0;
      let areadCode = null;
      let url =
        "reports-themes/remove-indicator/" +
        themeID +
        "/" +
        id +
        "/" +
        ref +
        "/" +
        "htmlTable";
      while (i < 3) {
        areadCode = null;
        if (typeof this.selectedComparators[i] !== "undefined") {
          areadCode = this.selectedComparators[i];
        }
        url = url + "/" + areadCode;
        i++;
      }
      this.emit.emit("systemBusy", true);
      this.$axios
        .delete(url)
        .then(
          function (response) {
            // handle success
            this.reportThemeObject = response.data;
            this.reportThemeObjectClone = this.$cloneDeep(
              this.reportThemeObject,
            );
            this.$emit("setIndicatorValues");
            this.emit.emit("systemBusy", false);
          }.bind(this),
        )
        .catch(
          function (error) {
            this.emit.emit("systemBusy", false);
            // handle error
            console.error(error);
          }.bind(this),
        );
    },
    comparitorName(comparator) {
      for (var i = 0; i < this.comparatorsList.length; i++) {
        if (this.comparatorsList[i].area_code === comparator) {
          return this.comparatorsList[i].area_name;
        }
      }
    },
    viewIndicatorInformation(item) {
      this.viewInfoObject = item;
      this.viewInfoDialog = true;
    },
    deleteItem(state) {
      if (state === null) {
        this.confirmDialog = true;
      } else if (state === true) {
        this.item["delete"] = true;
        this.$emit("updateTheme");
        this.confirmDialog = false;
      } else {
        this.confirmDialog = false;
      }
    },
  },
  watch: {},
};
</script>

<style scoped>
.moreInfo {
  color: #363636;
  text-decoration: underline;
  cursor: pointer;
}
.orderField {
  width: 40px;
  margin: 5px;
  border: 1px solid #000000;
  background-color: #ffffff;
  text-align: center;
}
table.blueTable {
  border: 0px solid #000000;
  background-color: #ffffff;
  width: 95vw;
  text-align: left;
}
table.blueTable td,
table.blueTable th {
  border: 0px solid #aaaaaa;
  padding: 0px 0px;
}
table.blueTable tbody td {
  font-size: 13px;
}
table.blueTable tr:nth-child(even) {
  background: #ffffff;
}
table.blueTable thead {
  background: #3edbcf;
}
table.blueTable thead th {
  font-size: 14px;
  font-weight: normal;
  color: #ffffff;
  text-align: center;
}
table.blueTable thead th:first-child {
}
table.blueTable tfoot td {
  font-size: 14px;
}
table.blueTable tfoot .links {
  text-align: right;
}
table.blueTable tfoot .links a {
  display: inline-block;
  background: #1c6ea4;
  color: #ffffff;
  padding: 2px 8px;
  border-radius: 5px;
}
.viewInfo {
  text-decoration: underline;
  cursor: pointer;
}
</style>
