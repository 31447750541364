<template>
  <v-container fluid pa-5 fill-height>
    <!-- the parent card/tile -->
    <v-card variant="flat" :style="'height:' + (this.height - 80) + 'px;'">
      <v-card-title class="text-left"
        ><h1 class="h1">Data Manager</h1></v-card-title
      >
      <v-card-actions>
        <v-divider></v-divider>
      </v-card-actions>
      <v-card-text class="text-left">
        This is where you can import /
        <v-btn
          text
          class="ma-0"
          color="success"
          variant="elevated"
          size="small"
          rounded="0"
          @click="metaDataDialog = true"
          aria-label="Edit"
        >
          Edit
        </v-btn>
        Indicators, Indicator Metadata and their associated data.
      </v-card-text>
      <v-card-text class="text-left pl-1">
        <v-btn
          color="warning"
          variant="elevated"
          class="mx-2"
          size="small"
          rounded="0"
          @click="confirmCacheTruncatingDialog = true"
          aria-label="truncate cache"
        >
          truncate cache
        </v-btn>
        <v-btn
          class="mx-2"
          color="success"
          variant="elevated"
          rounded="0"
          size="small"
          @click="downloadDataDialog = true"
          aria-label="download data"
        >
          download data
        </v-btn>
        <ForceAggregation />
      </v-card-text>
      <v-card-actions>
        <!-- file input and meta table card -->
        <v-card tile height="65vh" pa-5 variant="flat">
          <v-card-subtitle class="pb-3 pt-3 text-left"
            >Import your metadata and indicator data here (Click
            <span class="here" @click="generateMetadataImportTemplate"
              >here</span
            >
            for a new metadata import template, or
            <span class="here" @click="generateMetadataUpdateTemplate"
              >here</span
            >
            for an update template)</v-card-subtitle
          >
          <v-card-actions style="width: 100%; max-width: 800px !important">
            <!-- file input field -->
            <v-file-input
              label=".xslx, .xls or .csv files only please. Multiple files, indicators and metadata are all welcome!"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              type="file"
              variant="outlined"
              density="compact"
              rounded="0"
              multiple
              v-model="file"
              @change="inputFileFieldOnChange()"
              :error-messages="fileTypeError"
            ></v-file-input>
          </v-card-actions>
          <!-- metadate table -->
          <v-card-actions
            style="display: flex !important; overflow-x: auto !important"
          >
            <!-- metaData table -->
            <span v-if="metaData.length > 0" style="min-width: 100%">
              <div class="flex-nowrap">
                <v-data-table
                  density="compact"
                  rounded="0"
                  :items-per-page="25"
                  height="44vh"
                  fixed-header
                  v-model="selected"
                  :headers="tableHeaders"
                  :items="metaData"
                  item-key="id"
                  class="elevation-1"
                  @pagination="clearSelect()"
                  show-select
                >
                  <template v-slot:[`header.data-table-select`]="{}">
                    <v-checkbox
                      v-model="selectAll"
                      :indeterminate="indeterminate"
                      primary
                      hide-details
                    ></v-checkbox>
                  </template>

                  <!-- Slot for item selection: checkbox -->
                  <template #item="{ item }">
                    <tr :class="metaDataRowBackground(item)">
                      <td class="table-cell text-center">
                        <v-checkbox
                          v-model="selected"
                          :value="item"
                          hide-details
                          class="pr-10"
                        />
                      </td>
                      <td
                        v-for="header in tableHeaders"
                        :key="header.value"
                        class="table-cell text-center"
                      >
                        <v-dialog max-width="500">
                          <template
                            v-slot:activator="{ props: activatorProps }"
                          >
                            <span
                              v-if="!formFieldSchema[header.value].readOnly"
                              v-bind="activatorProps"
                              style="cursor: pointer"
                              title="click to edit value"
                              @click="editClone(item[header.value])"
                              >{{
                                item[header.value] !== ""
                                  ? item[header.value]
                                  : "&nbsp;&nbsp;&nbsp;&nbsp;"
                              }}
                              <v-icon style="font-size: 14px"
                                >mdi-pencil</v-icon
                              ></span
                            ><span v-else>{{ item[header.value] }}</span>
                          </template>
                          <template v-slot:default="{ isActive }">
                            <v-card :title="'Edit: ' + header.value">
                              <v-card-text>
                                <Field
                                  :schema="formFieldSchema[header.value]"
                                  v-model:value="editCloneValue"
                                />
                                <span
                                  style="color: red"
                                  v-if="selected.length > 1"
                                  >Please Note: You are editing
                                  <strong>{{ this.selected.length }}</strong>
                                  indicators</span
                                >
                              </v-card-text>
                              <v-card-actions>
                                <v-btn
                                  color="error"
                                  rounded="0"
                                  variant="elevated"
                                  text="cancel changes"
                                  @click="isActive.value = false"
                                ></v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="success"
                                  rounded="0"
                                  variant="elevated"
                                  text="confirm changes"
                                  @click="
                                    isActive.value = false;
                                    item[header.value] = confirmEdit(
                                      item,
                                      header.value,
                                    );
                                  "
                                ></v-btn>
                              </v-card-actions>
                            </v-card>
                          </template>
                        </v-dialog>
                        <!-- metaData errors -->
                        <p style="color: red">
                          {{
                            metaDataErrorUIController(
                              header.value,
                              item["indicator_code"],
                            )
                          }}
                        </p>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <div class="table-footer-prepend d-flex pl-2 align-center">
                  <v-btn
                    color="success"
                    rounded="0"
                    variant="elevated"
                    size="small"
                    v-if="
                      importToBrowserPercentage === 100 &&
                      metadataErrors.length === 0 &&
                      Object.keys(this.$options.dataForUpload).length > 0
                    "
                    aria-label="Import Data to Database"
                    @click="calculateTotalNumberOfRowsOfData()"
                  >
                    Import Data to Database
                  </v-btn>
                  <v-btn
                    color="primary"
                    rounded="0"
                    variant="elevated"
                    size="small"
                    v-if="
                      (importToBrowserPercentage === 100 &&
                        metadataErrors.length > 0) ||
                      (importToBrowserPercentage === 100 &&
                        Object.keys(this.$options.dataForUpload).length === 0)
                    "
                    aria-label="Update Metadata"
                    @click="
                      totalIndicatorCodes = 0;
                      updateMetaDataDB(true);
                    "
                    :loading="updateingMetaData"
                    :disabled="updateingMetaData"
                  >
                    Update Metadata
                    <template v-slot:loader>
                      <span class="custom-loader">
                        <v-icon light>mdi-cached</v-icon>
                      </span>
                    </template>
                  </v-btn>
                  <v-switch
                    v-model="showAllColumns"
                    label="Show All Columns"
                    color="primary"
                    value="primary"
                    hide-details
                    style="margin-left: 15px"
                  ></v-switch>
                  <v-switch
                    v-model="errorColumnsOnly"
                    label="Only Show Error Columns"
                    color="primary"
                    hide-details
                    style="margin-left: 15px"
                  ></v-switch>
                  <span
                    @click="downloadTable"
                    class="downloadTable"
                    title="click to download the above table, unfiltered and also includes errors and warnings"
                    >download the above table</span
                  >
                </div>
              </div>
            </span>
          </v-card-actions>
        </v-card>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="importDialog" persistent max-width="500">
      <v-card v-if="importToDBComplete">
        <v-card-title class="text-h5"> Import Complete! </v-card-title>
        <v-card-actions style="height: 120px">
          <Success />
        </v-card-actions>
        <br />
        <v-card-text
          ><strong
            >{{ importFeedbackSuccess.length }} /
            {{ totalIndicatorCodesCount }}</strong
          >
          indicators imported. There are
          <strong>{{ importFeedbackErrors.length }}</strong> Standard Data
          errors, <strong>{{ error500s.length }}</strong> Server Errors and
          there are <strong>{{ importFeedbackCheckQAChecks.length }}</strong> QA
          checks which need reviewing. Click
          <span class="here" @click="exportFeedbackArray()">here</span> to
          download and review the details of your import.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            text
            @click="
              importDialog = false;
              importToDBComplete = false;
            "
            aria-label="close"
          >
            close
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else-if="generatingDynamicDataFlag">
        <v-card-title class="text-h5">Nearly there!</v-card-title>
        <v-card-actions style="text-align: center">
          <v-progress-linear indeterminate color="success"></v-progress-linear>
        </v-card-actions>
        <br />
        <v-card-text
          >Generating Dynamic Data...only another minute or two...</v-card-text
        >
      </v-card>
      <v-card v-else>
        <v-toolbar
          :style="
            'background-color: ' +
            this.$store.state.config.siteConfig.toolbar_colour
          "
        >
          <v-toolbar-title class="text-left">{{
            !importToBrowserPercentage
              ? "Begin the Import Process..."
              : "Processing Import..."
          }}</v-toolbar-title></v-toolbar
        >
        <v-card-text v-if="!importToBrowserPercentage" class="text-body-1">
          <p>
            You have selected <strong>{{ numberOfFiles }}</strong> file(s), a
            total of <strong>{{ totalImportSize }}</strong> in size.
          </p>
          <p>
            <strong>Please be aware</strong> that any metadata that successfully
            passes validation (meaning it contains no errors) will be
            automatically inserted/updated in the database. Additionally, it is
            important to note that metadata with only warnings will also undergo
            insertion/update processes in the database.
          </p>
          <p>
            If you have uploaded a mixture of metadata files and their
            corresponding data files, the data will not be imported until you
            press the
            <span style="color: green">'Import Data to Database'</span> button
            which will show on completion of importing all the metadate (with no
            validation errors).
          </p>
          <p>The import process could take a few minutes.</p>
          <p>
            Press the <span style="color: green">'START'</span> button below to
            begin.
          </p>
        </v-card-text>
        <v-card-text
          class="text-body-1"
          v-if="
            // eslint-disable-next-line prettier/prettier
            importToBrowserPercentage > 1 && importToBrowserPercentage < 100
          "
          >Importing data into your browser...
        </v-card-text>
        <v-card-actions v-if="importToBrowserPercentage !== 100">
          <v-progress-linear
            color="success"
            v-model="importToBrowserPercentage"
            height="25"
          >
            <strong
              >{{ Math.ceil(importToBrowserPercentage) }}%</strong
            ></v-progress-linear
          >
        </v-card-actions>
        <v-card-text
          class="text-body-1"
          v-if="importToBrowserPercentage === 100 && totalIndicatorCodes === 0"
          ><v-progress-linear indeterminate color="success"></v-progress-linear>
          <p class="text-center mt-5 mb-5">
            Processing Metadata...this can take a few minutes.
          </p></v-card-text
        >
        <v-card-text
          class="text-body-1"
          v-if="importToBrowserPercentage == 100 && totalIndicatorCodes !== 0"
          >You are importing
          <strong>{{ totalIndicatorCodes }}</strong> indicators and a total of
          <strong>{{ totalRowsOfDataForImport }}</strong> rows of indicator
          data. This will take time, please be patient. Make sure your laptop is
          plugged into the mains. You can carry on using your machine however if
          you want to leave (and lock) your machine then please keep this
          browser open and on this page to prevent your machine from going to
          sleep. Press the "UPLOAD" button below to begin.
        </v-card-text>
        <v-card-text v-if="importToDBPercentage > 0" class="text-body-1">
          <strong>Import Start Time: </strong> {{ importStartTime }}hrs
        </v-card-text>
        <v-card-actions
          v-if="importToBrowserPercentage == 100 && totalIndicatorCodes !== 0"
        >
          <v-progress-linear
            color="success"
            v-model="importToDBPercentage"
            height="25"
          >
            <strong
              >{{ Math.ceil(importToDBPercentage) }}%</strong
            ></v-progress-linear
          >
        </v-card-actions>
        <v-card-actions
          v-if="importToBrowserPercentage == 100 && totalIndicatorCodes !== 0"
        >
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="stopDBImport()" aria-label="cancel">
            cancel
          </v-btn>
          <v-btn
            :disabled="disableUploadButton"
            :loading="disableUploadButton"
            color="green"
            text
            @click="updateMetaDataDB()"
            aria-label="Upload"
          >
            Upload
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon light>mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
        </v-card-actions>
        <v-card-actions v-if="importToBrowserPercentage !== 100">
          <v-spacer></v-spacer>
          <v-btn
            :disabled="importToBrowserPercentage == 100 ? true : false"
            color="red"
            variant="elevated"
            rounded="0"
            @click="
              importDialog = false;
              // eslint-disable-next-line prettier/prettier
              file = null;
            "
            aria-label="cancel"
          >
            cancel
          </v-btn>
          <v-btn
            :disabled="importToBrowserPercentage > 0 ? true : false"
            color="green"
            variant="elevated"
            rounded="0"
            aria-label="Start"
            @click="
              importToBrowserController({});
              // eslint-disable-next-line prettier/prettier
              importToBrowserPercentage = 4;
            "
          >
            Start
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmCacheTruncatingDialog" width="550">
      <v-card>
        <v-toolbar
          :style="
            'background-color: ' +
            this.$store.state.config.siteConfig.toolbar_colour
          "
        >
          <v-toolbar-title class="text-center"
            >Confirm Truncating?</v-toolbar-title
          ></v-toolbar
        >
        <v-card-actions>
          <v-card-text style="text-align: center">
            <p class="mt-5 mb-6">
              Truncating the cache will slowdown future Report, Map and
              Dashboard loading times until they are cached again, which will
              affect our users. Please only use this function if you have to.
            </p>
            <p class="mt-5 mb-6">Are you sure you want to continue?</p>
          </v-card-text>
        </v-card-actions>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="primary"
            variant="elevated"
            rounded="0"
            @click="confirmCacheTruncatingDialog = false"
            aria-label="cancel"
          >
            cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="warning"
            variant="elevated"
            rounded="0"
            @click="truncateCache()"
            aria-label="truncate"
          >
            truncate
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="carryOnQuestionFlag" width="550">
      <v-card>
        <v-toolbar
          :style="
            'background-color: ' +
            this.$store.state.config.siteConfig.toolbar_colour
          "
        >
          <v-toolbar-title class="text-left"
            >Metadata Warnings!</v-toolbar-title
          ></v-toolbar
        >
        <v-card-actions>
          <v-card-text style="text-align: center"
            >There are some warnings for the metadata you are importing, you can
            either ignore these warnings and carry on with the data import OR
            you can cancel the data import and review the warnings. What would
            you like to do?
          </v-card-text>
        </v-card-actions>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="primary"
            variant="elevated"
            rounded="0"
            :loading="abortDBImport"
            @click="abortDBImport = true"
            aria-label="Check Warnings"
          >
            Check Warnings
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon light>mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="warning"
            variant="elevated"
            rounded="0"
            @click="carryOnQuestionFlag = false"
            aria-label="Ignore Warnings"
          >
            Ignore Warnings
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="downloadDataDialog" width="50%" persistent>
      <v-card>
        <v-toolbar
          :style="
            'background-color: ' +
            this.$store.state.config.siteConfig.toolbar_colour
          "
        >
          <v-toolbar-title class="text-left"
            >Download Data</v-toolbar-title
          ></v-toolbar
        >
        <v-card-actions v-if="!downloadComplete">
          <v-card-text>
            <div class="mb-6">
              Export a CSV file of the data for the indicators and areas you
              specify. Each list should be comma separated.
              <ul>
                <li>Indicators should be in the format indicator_code.date</li>
                <li>
                  Areas should be a known alias, or a custom area id (id must
                  belong to your group)
                </li>
              </ul>
              <b
                >Note: Any integer will be taken as a custom area id, so be
                careful when using aliases such as '064' for England, as this
                will be read as custom area id 64</b
              >

              <v-alert type="warning" outlined dense class="mt-4">
                Note: Large downloads can have an impact on the server and
                affect other users. Consider doing larger downloads out of
                hours.
              </v-alert>
            </div>
            <v-textarea
              variant="outlined"
              density="compact"
              rounded="0"
              v-model="dataDownloadIndicators"
              label="Indicators"
            ></v-textarea>
            <v-textarea
              variant="outlined"
              density="compact"
              rounded="0"
              v-model="dataDownloadAreas"
              label="Areas"
            ></v-textarea>
          </v-card-text>
        </v-card-actions>
        <v-card-actions v-if="downloadComplete && downloadError">
          <v-card-text>
            <h4>There was an error downloading your data.</h4>
            <p class="mb-6">
              Please check your indicators & areas, and trying again.
            </p>
          </v-card-text>
          <v-divider></v-divider>
        </v-card-actions>
        <v-card-actions v-if="downloadComplete && !downloadError">
          <v-card-text>
            <h4 v-if="!noDownloadResults">
              Your data has automatically been downloaded.
            </h4>
            <h4 v-else>There were no results for your download.</h4>
            <p class="mb-6">
              You had {{ downloadUnmatchedIndicators.length }} unmatched
              Indicators and {{ downloadUnmatchedAreas.length }} unmatched
              Areas.
            </p>
            <v-btn
              v-if="
                downloadUnmatchedIndicators.length > 0 ||
                downloadUnmatchedAreas.length > 0
              "
              aria-label="Download Unmatched"
              color="warning"
              small
              variant="elevated"
              rounded="0"
              @click="downloadUnmatched()"
            >
              Download Unmatched
            </v-btn>
          </v-card-text>
        </v-card-actions>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="warning"
            variant="elevated"
            rounded="0"
            @click="closeDownloadDataDialog()"
            aria-label="Close"
          >
            Close
          </v-btn>
          <v-spacer></v-spacer>
          <div :class="{ downloadLimit: overDownloadLimit }">
            {{ totalDownloadSize }} / {{ maxDownloadSize }}
          </div>
          <v-btn
            color="success"
            variant="elevated"
            rounded="0"
            @click="downloadData()"
            v-if="!downloadComplete"
            class="ml-6"
            :disabled="!canDownload"
            aria-label="Download"
          >
            Download
          </v-btn>
          <v-btn
            color="success"
            variant="elevated"
            rounded="0"
            @click="resetDownload()"
            class="ml-6"
            v-else
            aria-label="Restart"
          >
            Restart
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-overlay :value="downloadingData">
        <v-progress-circular indeterminate size="250"
          >Downloading Data</v-progress-circular
        >
      </v-overlay>
    </v-dialog>
    <MetaData v-model:metaDataDialog="metaDataDialog" />
  </v-container>
</template>

<script>
import Field from "@/components/Fields.vue";
import ForceAggregation from "@/components/ForceAggregation.vue";
import MetaData from "@/components/MetaData.vue";
import DataManagerFormSchema from "@/schemas/DataManager";
import Success from "@/components/Success";
import { utils, writeFile, read } from "xlsx";
import { setCookie } from "@/mixins/SetCookie";
import { getCookie } from "@/mixins/GetCookie";
import { exportCSVFile } from "@/mixins/ExportCSVFile";
import { useDisplay } from "vuetify";
// import XLSX from "xlsx";
// import { getURLParam } from "@/mixins/GetURLParam";

export default {
  name: "DataManager",
  data: () => ({
    indeterminate: false,
    selectAll: false,
    editCloneValue: null,
    height: useDisplay().height,
    dataDownloadIndicators: "",
    dataDownloadAreas: "",
    dataDownloadIndicatorArray: [],
    dataDownloadAreaArray: [],
    downloadingData: false,
    downloadComplete: false,
    downloadUnmatchedIndicators: [],
    downloadUnmatchedAreas: [],
    downloadError: false,
    errorColumnsOnly: false,
    eAwColumnNames: {},
    formFieldSchema: DataManagerFormSchema,
    file: null,
    fileTypeError: "",
    indicaterCodes: [],
    dataForUpload: [],
    metaData: [],
    userImportedMetaData: [],
    tableHeaders: [],
    selected: [],
    selectedMetaDataField: null,
    importDialog: false,
    numberOfFiles: 0,
    totalImportSize: 0,
    totalIndicatorCodes: 0,
    totalIndicatorCodesCount: 0,
    importToBrowserPercentage: 0,
    importToDBPercentage: 0,
    totalRowsOfDataForImport: 0,
    disableUploadButton: false,
    error500s: [],
    showAllColumns: false,
    metadataErrors: [],
    metadataWarnings: [],
    updateingMetaData: false,
    updateToDBCount: 0,
    importToDBComplete: false,
    viewDataIndicatorCode: null,
    viewDataRowCount: 0,
    generatingDynamicDataFlag: false,
    importFeedbackArray: [],
    metaDataForFeeback: [],
    importFeedbackErrors: [],
    importFeedbackSuccess: [],
    importFeedbackCheckQAChecks: [],
    importFeedbackDynamicSuccess: [],
    importFeedbackDynamicErrors: [],
    abortDBImport: false,
    carryOnQuestionFlag: false,
    importStartTime: "00:00",
    metaDataDialog: false,
    viewMetadata: false,
    confirmCacheTruncatingDialog: false,
    downloadDataDialog: false,
    noDownloadResults: false,
    maxDownloadSize: 250000,
  }),
  components: {
    ForceAggregation,
    Field,
    Success,
    MetaData,
  },
  computed: {
    canDownload() {
      return (
        this.dataDownloadIndicatorArray.length > 0 &&
        this.dataDownloadAreaArray.length > 0 &&
        !this.overDownloadLimit
      );
    },
    totalDownloadSize() {
      return (
        this.dataDownloadIndicatorArray.length *
        this.dataDownloadAreaArray.length
      );
    },
    overDownloadLimit() {
      return this.totalDownloadSize > this.maxDownloadSize;
    },
    selectedCount: {
      get() {
        return this.selected.length;
      },
    },
  },
  props: {},
  mounted() {
    if (getCookie("BEARER_TOKEN")) {
      setCookie("BEARER_TOKEN", getCookie("BEARER_TOKEN"), 2);
      this.updateImportLevelsList();
      this.buildMetaDataTable();
    } else {
      this.router("login");
    }
  },
  methods: {
    updateImportLevelsList() {
      this.formFieldSchema.import_level.radioItems = [];
      for (
        var i = 0;
        i < this.$store.state.config.siteConfig.area_level_names.length;
        i++
      ) {
        if (
          this.$store.state.config.siteConfig.area_level_names[i]
            .area_level_name_abr !== "postcode"
        ) {
          this.formFieldSchema.import_level.radioItems.push(
            this.$store.state.config.siteConfig.area_level_names[i]
              .area_level_name_abr,
          );
        }
      }
      this.formFieldSchema.import_level.templateDefaultValue =
        "options: " + this.formFieldSchema.import_level.radioItems.toString();
    },
    editClone(value) {
      this.editCloneValue = value;
    },
    confirmEdit(item, field) {
      this.emit.emit("systemMessage", {
        title: "Remember!",
        message:
          'Remember to press the "Update Metadata" button below to save these change(s)',
        timeout: 3000,
        colour: "success",
      });
      // update other selected rows
      for (let index = 0; index < this.selected.length; index++) {
        this.selected[index][field] = this.editCloneValue;
      }

      return this.editCloneValue;
    },
    closeDownloadDataDialog() {
      this.downloadDataDialog = false;
      this.resetDownload();
    },
    downloadUnmatched() {
      // format the arrays into a good shape for the csv
      let data = [];
      let headers = [];

      // find the longer one, we'll use this to determine the length of the csv
      const maxLength = Math.max(
        this.downloadUnmatchedIndicators.length,
        this.downloadUnmatchedAreas.length,
      );

      // To have correct alignment, always have both headers because empty cells are filled in with a space character
      headers.push("Unmatched Indicators");
      headers.push("Unmatched Areas");

      // add the data, filling in the blanks if one array is longer than the other
      for (let i = 0; i < maxLength; i++) {
        const indicator = this.downloadUnmatchedIndicators[i] ?? " ";
        const area = this.downloadUnmatchedAreas[i] ?? " ";
        data.push([indicator, area]);
      }

      // throw the file in their face
      exportCSVFile(headers, data, "Unmatched Data");
    },
    resetDownload() {
      this.downloadComplete = false;
      this.downloadError = false;
      this.noDownloadResults = false;
      this.dataDownloadIndicators = "";
      this.dataDownloadAreas = "";
      this.dataDownloadIndicatorArray = [];
      this.dataDownloadAreaArray = [];
      this.downloadUnmatchedIndicators = [];
      this.downloadUnmatchedAreas = [];
    },
    downloadData() {
      this.downloadingData = true;

      // make sure we don't have any quotations contained in these arrays
      this.dataDownloadIndicatorArray = this.dataDownloadIndicatorArray.map(
        (ind) => ind.replace(/[^a-zA-Z0-9.*_]/g, "").trim(),
      );
      this.dataDownloadAreaArray = this.dataDownloadAreaArray.map((area) =>
        area.replace(/[^a-zA-Z0-9]/g, "").trim(),
      );

      // if any of the areas are numbers then we need to convert them to strings starting with 'CSTM'
      this.dataDownloadAreaArray = this.dataDownloadAreaArray.map((area) => {
        if (!isNaN(area)) {
          return "CSTM" + area;
        } else {
          return area;
        }
      });

      // get the data for these indicators and areas
      this.$axios
        .post("/get-data-download", {
          indicatorCodes: this.dataDownloadIndicatorArray,
          areas: this.dataDownloadAreaArray,
        })
        .then((response) => {
          // put the response data into a csv and download it
          if (
            response.data.headers.length > 0 &&
            response.data.data.length > 0
          ) {
            exportCSVFile(
              response.data.headers,
              response.data.data,
              "data_download_" + Date.now(),
            );
          } else {
            this.noDownloadResults = true;
          }

          this.downloadingData = false;
          this.downloadComplete = true;

          this.downloadUnmatchedIndicators = response.data.unmatchedIndicators;
          this.downloadUnmatchedAreas = response.data.unmatchedAreas;
        })
        .catch((error) => {
          console.log(error);
          console.log("error downloading data");
          this.downloadError = true;
          this.downloadingData = false;
          this.downloadComplete = true;
        });
    },
    clearSelect() {
      this.selected = [];
    },
    generateMetadataImportTemplate() {
      var columnHeaders = {};
      for (const [key, value] of Object.entries(this.formFieldSchema)) {
        if (value.useForTemplate) {
          columnHeaders[key] = value.templateDefaultValue;
        }
      }
      const worksheet = utils.json_to_sheet([columnHeaders]);
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet);
      writeFile(workbook, "Metadata Template.xlsx", { compression: true });
    },
    generateMetadataUpdateTemplate() {
      var columnHeaders = {};
      for (const [key, value] of Object.entries(this.formFieldSchema)) {
        if (value.useForUpdate) {
          columnHeaders[key] = "";
        }
      }
      const worksheet = utils.json_to_sheet([columnHeaders]);
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet);
      writeFile(workbook, "Metadata Update Template.xlsx", {
        compression: true,
      });
    },
    downloadTable() {
      const metaData = this.$cloneDeep(this.metaData);
      let keys = null;
      let error = "";

      for (let i = 0; i < metaData.length; i++) {
        delete metaData[i].id;
        delete metaData[i].ind_code;
        keys = Object.keys(metaData[i]);
        for (var j = 0; j < keys.length; j++) {
          error = this.metaDataErrorUIController(
            keys[j],
            metaData[i].indicator_code,
          );
          if (error !== null) {
            metaData[i].indicator_status =
              metaData[i].indicator_status +
              "!! Field: " +
              keys[j] +
              ". Message: " +
              error +
              ". Current Field Value: " +
              metaData[i][keys[j]];
          }
        }
      }

      const worksheet = utils.json_to_sheet(metaData);
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet);
      writeFile(workbook, "Metadata Table.xlsx", { compression: true });
    },
    exportFeedbackArray() {
      const feedbackErrors = utils.json_to_sheet(this.importFeedbackErrors);
      const feedbackSuccess = utils.json_to_sheet(this.importFeedbackSuccess);
      const feedbackQAChecks = utils.json_to_sheet(
        this.importFeedbackCheckQAChecks,
      );
      const feedbackDynamicErrors = utils.json_to_sheet(
        this.importFeedbackDynamicErrors,
      );
      const feedbackDynamicSuccess = utils.json_to_sheet(
        this.importFeedbackDynamicSuccess,
      );
      const errors = utils.json_to_sheet(this.error500s);
      const metaData = utils.json_to_sheet(this.metaDataForFeeback);
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, feedbackErrors, "Standard Data Errors");
      utils.book_append_sheet(
        workbook,
        feedbackSuccess,
        "Standard Data Success",
      );
      utils.book_append_sheet(
        workbook,
        feedbackQAChecks,
        "QA Results Requiring Reviewing",
      );
      utils.book_append_sheet(
        workbook,
        feedbackDynamicErrors,
        "Dynamic Data Errors",
      );
      utils.book_append_sheet(
        workbook,
        feedbackDynamicSuccess,
        "Dynamic Data Success",
      );
      utils.book_append_sheet(workbook, errors, "Server Errors");
      utils.book_append_sheet(workbook, metaData, "Metadata");
      writeFile(workbook, "ImportFeedback.xlsx");
    },
    metaDataRowBackground(item) {
      if (item.indicator_status === "error") {
        return "metaError";
      } else if (item.indicator_status === "warning") {
        return "metaWarning";
      } else {
        return "noMetaError";
      }
    },
    metaDataErrorUIController(field, indicatorCode) {
      for (let index = 0; index < this.metadataWarnings.length; index++) {
        let key = Object.keys(this.metadataWarnings[index])[0];
        if (
          key === field &&
          this.metadataWarnings[index][key]["indicator_code"] === indicatorCode
        )
          return this.metadataWarnings[index][key]["message"];
      }
      for (let index = 0; index < this.metadataErrors.length; index++) {
        let key = Object.keys(this.metadataErrors[index])[0];
        if (
          key === field &&
          this.metadataErrors[index][key]["indicator_code"] === indicatorCode
        )
          return this.metadataErrors[index][key]["message"];
      }
      return null;
    },
    errorsAndWarningsColumnNames() {
      if (!this.errorColumnsOnly) {
        this.buildMetaDataTable();
      } else {
        var colWidth = "auto";
        this.tableHeaders = [];
        this.eAwColumnNames = {
          id: true,
          indicator_code: true,
          indicator_name: true,
        };

        // errors
        for (let i = 0; i < this.metadataErrors.length; i++) {
          this.eAwColumnNames[Object.keys(this.metadataErrors[i])] = true;
        }

        // warnings
        for (let i = 0; i < this.metadataWarnings.length; i++) {
          this.eAwColumnNames[Object.keys(this.metadataWarnings[i])] = true;
        }
        for (var field in this.eAwColumnNames) {
          if (field == "indicator_description") {
            colWidth = "500px";
          } else {
            colWidth = "auto";
          }
          this.tableHeaders.push({
            title: this.formFieldSchema[field].label,
            align: "center",
            sortable: true,
            value: field,
            width: colWidth,
          });
        }
      }
      this.select = [];
    },
    buildMetaDataTable() {
      this.tableHeaders = [];
      var colWidth = "auto";
      for (var field in this.formFieldSchema) {
        // check to see if we need to create the columns
        if (
          this.formFieldSchema[field].advancedSettings &&
          !this.showAllColumns
        ) {
          continue;
        }
        if (field == "indicator_description") {
          colWidth = "500px";
        } else {
          colWidth = "auto";
        }
        this.tableHeaders.push({
          title: this.formFieldSchema[field].label,
          align: "center",
          sortable: true,
          value: field,
          width: colWidth,
        });
      }
      this.select = [];
      this.errorColumnsOnly = false;
    },
    importToBrowserStatus() {
      this.numberOfFiles = this.file.length;
      let sizeRemaining = 0;
      for (let index = 0; index < this.file.length; index++) {
        sizeRemaining = sizeRemaining + this.file[index].size;
      }
      this.totalImportSize = this.bytesToSize(sizeRemaining);
      this.importToBrowserPercentage =
        Number(100 / this.initialNumberOfFiles) *
        Number(this.initialNumberOfFiles - this.file.length);
    },
    fileTypeValidation(fileType) {
      switch (fileType) {
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        case "text/csv":
        case "application/vnd.ms-excel":
          return true;
      }
      this.fileTypeError = "Wrong File type(s). CSV, XLS and XLSX only please";
      return false;
    },
    inputFileFieldOnChange(reset = false) {
      // check we have the correct file type
      this.fileTypeError = "";
      for (var i = 0; i < this.file.length; i++) {
        if (!this.fileTypeValidation(this.file[i].type)) {
          return;
        }
      }
      // reset some variables and set initial import (browser) status
      if (this.file.length > 0 || reset) {
        this.generatingDynamicDataFlag = false;
        this.$options.dataForUpload = null;
        this.importToDBPercentage = 0;
        this.totalIndicatorCodes = 0;
        this.indicatorCodesFromDataBase = [];
        this.metaData = [];
        this.metadataErrors = [];
        this.metadataWarnings = [];
        this.indicaterCodes = [];
        this.userImportedMetaData = [];
        this.initialNumberOfFiles = this.file.length;
        this.importDialog = true;
        // this.error500s = [];
        this.importToBrowserStatus();
        this.selected = [];
      }
    },
    bytesToSize(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },
    importToBrowserController(dataForUpload) {
      if (this.metaDataForFeeback.length > 0) {
        this.resetImportFeedbackData();
      }
      if (this.file.length > 0) {
        this.importToBrowserStatus();
        setTimeout(
          function () {
            this.importToBrowser(dataForUpload);
          }.bind(this),
          500,
        );
      } else {
        this.importToBrowserStatus();
        // we use '$options' so it's none reactive
        this.$options.dataForUpload = dataForUpload;
        this.getMetaData();
      }
    },
    importToBrowser(dataForUpload) {
      var reader = new FileReader();
      reader.readAsBinaryString(this.file[0]);
      reader.onload = () => {
        var data = reader.result;
        this.restackData(
          read(data, { type: "binary", raw: true }),
          dataForUpload,
        );
      };
    },
    // this pulls apart all the data and organises it so we have the right information in the right format for our API's
    restackData(fileContents, dataForUpload) {
      var currentRowNumber = null;
      var currentColLetter = null;
      for (var sheetName in fileContents.Sheets) {
        let rowDataBegins = null;
        let colDataBegins = null;
        let areaCodes = {};
        let sheetColToIndicatorCode = {};
        for (var column in fileContents.Sheets[sheetName]) {
          // set this
          currentRowNumber = Number(column.replace(/\D/g, ""));
          currentColLetter = column.replace(/[0-9]/g, "");
          // need to check if this is a metadata sheet
          if (fileContents.Sheets[sheetName][column].v === "indicator_code") {
            let metaDataImport = utils.sheet_to_json(
              fileContents.Sheets[sheetName],
              { defval: "", raw: false },
            );
            this.userImportedMetaData =
              this.userImportedMetaData.concat(metaDataImport);
            break;
          }
          // find the start of the data
          if (
            fileContents.Sheets[sheetName][column].v === "areacode" ||
            fileContents.Sheets[sheetName][column].v === "area_code"
          ) {
            rowDataBegins = Number(currentRowNumber);
            colDataBegins = currentColLetter;
            continue;
          }
          // if we are still in the areacode column then grab the area code and add it to the 'areaCodes' object
          if (
            currentRowNumber > rowDataBegins &&
            currentColLetter === colDataBegins
          ) {
            areaCodes[currentRowNumber] =
              fileContents.Sheets[sheetName][column].v;
            continue;
          }
          // if this is true then we have found an indicator code
          if (currentRowNumber === rowDataBegins) {
            this.indicaterCodes.push(fileContents.Sheets[sheetName][column].v);
            sheetColToIndicatorCode[currentColLetter] =
              fileContents.Sheets[sheetName][column].v;
            if (
              dataForUpload[fileContents.Sheets[sheetName][column].v] ===
              undefined
            ) {
              dataForUpload[fileContents.Sheets[sheetName][column].v] = [];
            }
          }
          // if this is true then we are looking at a value for a specific indicator code
          if (
            currentRowNumber > rowDataBegins &&
            currentColLetter in sheetColToIndicatorCode
          ) {
            dataForUpload[sheetColToIndicatorCode[currentColLetter]].push({
              area_code: areaCodes[currentRowNumber],
              value: fileContents.Sheets[sheetName][column].v,
            });
          }
        }
      }
      // free up some memory
      fileContents = null;
      // remove this file from the process and go round again (there may be multiple files)
      this.file.shift();
      this.importToBrowserController(dataForUpload);
    },
    getMetaData() {
      // check it's not empty
      if (
        this.indicaterCodes.length === 0 &&
        this.userImportedMetaData.length === 0
      ) {
        this.emit.emit("systemMessage", {
          title:
            "There is no metadata information in this file(s), please check/fix your file(s) and try again.",
          message: "No Metadata!",
          timeout: -1,
          colour: "warning",
        });
        // reset
        this.inputFileFieldOnChange(true);
        this.importDialog = false;
        return;
      }
      this.$axios
        .post("/standard-metadata/get-complete-list", {
          ind_codes: this.indicaterCodes,
          userImportedMetaData: this.userImportedMetaData,
        })
        .then((response) => {
          this.userImportedMetaData = null;
          this.metadataErrors = response.data.metadataErrors;
          this.metadataWarnings = response.data.metadataWarnings;
          if (
            this.metadataErrors.length > 0 ||
            this.metadataWarnings.length > 0
          ) {
            // this.errorColumnsOnly = true;
            this.errorsAndWarningsColumnNames();
          } else {
            this.buildMetaDataTable();
          }
          this.metaData = response.data.indicators;
          // need to make an array of indicator codes here (to help the DB import controller)
          this.indicatorCodesFromDataBase = [];
          for (let index = 0; index < this.metaData.length; index++) {
            this.indicatorCodesFromDataBase.push(this.metaData[index].ind_code);
          }
          this.totalIndicatorCodesCount =
            this.indicatorCodesFromDataBase.length;
          this.totalIndicatorCodes = this.indicatorCodesFromDataBase.length;
          this.importDialog = false;
          if (this.metadataErrors.length > 0) {
            this.emit.emit("systemMessage", {
              title: "Error!",
              message:
                "You have " +
                this.metadataErrors.length +
                " metadata errors, please fix and try again",
              timeout: -1,
              colour: "error",
            });
          } else if (this.metadataWarnings.length > 0) {
            this.emit.emit("systemMessage", {
              title: "Warning!",
              message:
                "You have " +
                this.metadataWarnings.length +
                " metadata warnings (NOT failures). Be sure to review these warnings",
              timeout: -1,
              colour: "warning",
            });
          } else {
            this.emit.emit("systemMessage", {
              title: "Success!",
              message: "Metadata successfully imported",
              timeout: 3000,
              colour: "green",
            });
          }
        })
        .catch((error) => {
          console.error(error.response.data);
          this.emit.emit("systemBusy", false);
          this.emit.emit(
            "systemMessage",
            error.response.data,
            "Error!",
            0,
            "red",
          );
        });
    },
    getObjectSize(obj) {
      const size = encodeURI(JSON.stringify(obj)).split(/%..|./).length - 1;
      const kiloBytes = size / 1024;
      const megaBytes = kiloBytes / 1024;
      return [kiloBytes, megaBytes];
    },
    calcImportToDBProgress() {
      this.importToDBPercentage =
        (100 / this.totalIndicatorCodes) *
        (this.totalIndicatorCodes - this.indicatorCodesFromDataBase.length);
    },
    // this is where we loop through each indicator and it's values and sent them to the server
    importToDBController() {
      // belt and braces having this here...on the edgecase something slips through
      if (this.abortDBImport) {
        this.abortDBImport = false;
        return;
      }
      this.calcImportToDBProgress();
      if (this.indicatorCodesFromDataBase.length > 0) {
        // throttle this...stop too many api calls per minute
        this.importToDBControllerTimer = setTimeout(
          function () {
            // keep this fresh!...it would be boring to get logged out half way through a long import due to an expired cookie
            setCookie("BEARER_TOKEN", getCookie("BEARER_TOKEN"), 2);
            this.importToServer();
          }.bind(this),
          500,
        );
      } else {
        this.importToDBComplete = true;
        this.inputFileFieldOnChange(this.importToDBComplete);
      }
    },
    resetImportFeedbackData() {
      this.error500s = [];
      this.importFeedbackErrors = [];
      this.importFeedbackSuccess = [];
      this.importFeedbackCheckQAChecks = [];
      this.importFeedbackDynamicSuccess = [];
      this.importFeedbackDynamicErrors = [];
      this.updateToDBCount = 0;
      this.metaDataForFeeback = this.$cloneDeep(this.metaData);
    },
    // so we can show the user how much data they are importing
    calculateTotalNumberOfRowsOfData() {
      // set this
      this.getTime();
      // reset these
      this.resetImportFeedbackData();
      // call this
      this.calcImportToDBProgress();
      this.disableUploadButton = false;
      this.importDialog = true;
      this.totalRowsOfDataForImport = 0;
      for (
        let index = 0;
        index < this.indicatorCodesFromDataBase.length;
        index++
      ) {
        if (
          typeof this.$options.dataForUpload[
            this.indicatorCodesFromDataBase[index]
          ] !== "undefined"
        ) {
          this.totalRowsOfDataForImport =
            this.totalRowsOfDataForImport +
            this.$options.dataForUpload[this.indicatorCodesFromDataBase[index]]
              .length;
        }
      }
    },
    updateMetaDataDB(updateMetaDataOnly = false) {
      this.disableUploadButton = true;
      this.updateingMetaData = true;
      this.importDialog = true;
      this.abortDBImport = false;
      this.$axios
        .post("/standard-metadata/bulk", this.metaData)
        .then((response) => {
          // de-select what the user has selected
          this.selected = [];
          this.metaData = response.data.indicators;
          this.metadataErrors = response.data.metadataErrors;
          this.metadataWarnings = response.data.metadataWarnings;
          if (
            this.metadataErrors.length > 0 ||
            this.metadataWarnings.length > 0
          ) {
            this.errorColumnsOnly = true;
            this.errorsAndWarningsColumnNames();
          } else {
            this.buildMetaDataTable();
          }
          // need to make an array of indicator codes here (to help the DB import controller)
          this.indicatorCodesFromDataBase = [];
          for (let index = 0; index < this.metaData.length; index++) {
            this.indicatorCodesFromDataBase.push(this.metaData[index].ind_code);
          }
          this.totalIndicatorCodesCount =
            this.indicatorCodesFromDataBase.length;
          this.totalIndicatorCodes = this.indicatorCodesFromDataBase.length;
          this.updateingMetaData = false;
          if (this.metadataErrors.length > 0) {
            this.importDialog = false;
            this.emit.emit(
              "systemMessage",
              "You have " +
                this.metadataErrors.length +
                " metadata errors, please fix and try again",
              "Error!",
              6000,
              "red",
            );
          } else if (this.metadataWarnings.length > 0 && updateMetaDataOnly) {
            this.importDialog = false;
            this.emit.emit(
              "systemMessage",
              "You have " +
                this.metadataWarnings.length +
                " metadata warnings (NOT failures). Be sure to review these warnings",
              "Error!",
              6000,
              "orange",
            );
          } else if (this.metadataWarnings.length > 0 && !updateMetaDataOnly) {
            this.carryOnQuestionFlag = true;
            this.pauseImport();
          } else {
            if (updateMetaDataOnly) {
              this.emit.emit("systemMessage", {
                title: "Metadata successfully updated",
                message: "Success!",
                timeout: 3000,
                colour: "green",
              });
              this.importDialog = false;
            }
            if (!updateMetaDataOnly && !this.abortDBImport) {
              this.importToDBController();
            } else {
              this.abortDBImport = false;
            }
          }
        })
        .catch((error) => {
          this.emit.emit(
            "systemMessage",
            "message: " +
              error.response.data.message +
              " | file: " +
              error.response.data.file +
              "| <strong>line</strong>: " +
              error.response.data.line,
            "Error!",
            0,
            "red",
          );
        });
    },
    pauseImport() {
      if (this.abortDBImport) {
        this.importToDBController();
        this.carryOnQuestionFlag = false;
        this.importDialog = false;
        this.file = null;
        return;
      } else if (!this.carryOnQuestionFlag) {
        this.importToDBController();
      } else {
        setTimeout(
          function () {
            this.pauseImport();
          }.bind(this),
          2000,
        );
      }
    },
    getTime() {
      var d = new Date(); // for now
      this.importStartTime = d.getHours() + ":" + d.getMinutes();
    },
    error500MessageHandler(error) {
      var message = {
        file: "unkown",
        line: "unkown",
        message: "no message",
      };
      if (typeof error.file !== "undefined") {
        message.file = error.file;
      }
      if (typeof error.line !== "undefined") {
        message.line = error.line;
      }
      if (typeof error.message !== "undefined") {
        message.message = error.message;
      }
      return JSON.stringify(message);
    },
    importToServerSuccess(data) {
      if (!data.success) {
        this.importFeedbackErrors.push(data);
      } else {
        this.importFeedbackSuccess.push(data);
      }
      if (data.check_qa) {
        this.importFeedbackCheckQAChecks.push(data);
      }
      this.updateToDBCount++;
      this.indicatorCodesFromDataBase.shift();
      this.importToDBController();
    },
    // this posts the indicators data to the server
    importToServer() {
      if (
        typeof this.$options.dataForUpload[
          this.indicatorCodesFromDataBase[0]
        ] === "undefined"
      ) {
        this.importToServerSuccess(
          "No data was imported for this indicator due to there not being any data related to them in the import files",
        );
      } else {
        var data = {};
        data[this.indicatorCodesFromDataBase[0]] =
          this.$options.dataForUpload[this.indicatorCodesFromDataBase[0]];
        this.$axios
          .post("/standard-data", data)
          .then((response) => {
            if (this.abortDBImport) {
              this.abortDBImport = false;
              return;
            } else {
              this.importToServerSuccess(response.data);
            }
          })
          .catch((error) => {
            if (this.abortDBImport) {
              this.abortDBImport = false;
              return;
            }
            // store the error
            this.error500s.push({
              errorMessage: this.error500MessageHandler(error.response.data),
              indicatorName: this.indicatorCodesFromDataBase[0],
            });
            console.error(error.response.data);
            // if we are getting too many errors (more than half of the indicators) then stop the import, if not then move onto the next indicator
            if (
              this.error500s.length >
              this.indicatorCodesFromDataBase.length / 2
            ) {
              // reset this
              this.importToDBPercentage = 0;
              this.importDialog = false;
              // generate excel spreadsheet
              const errors = utils.json_to_sheet(this.error500s);
              const workbook = utils.book_new();
              utils.book_append_sheet(workbook, errors, "Errors");
              writeFile(workbook, "ImportError500s.xlsx");
              this.emit.emit("systemMessage", {
                title:
                  "Sorry but there have been too many system errors to continue with this import, please see import errors spreadsheet for more details",
                message: "Error!",
                timeout: -1,
                colour: "red",
              });
            } else {
              this.indicatorCodesFromDataBase.shift();
              this.importToDBController();
            }
          });
      }
    },
    stopDBImport() {
      // stop timer
      clearTimeout(this.importToDBControllerTimer);
      // flag this
      this.abortDBImport = true;
      // close this
      this.importDialog = false;
      // empty this
      this.indicatorCodesFromDataBase = [];
      // reset these
      this.resetImportFeedbackData();
      // after a pause reset this incase they want to go again
      setTimeout(
        function () {
          for (let index = 0; index < this.metaData.length; index++) {
            this.indicatorCodesFromDataBase.push(this.metaData[index].ind_code);
          }
          // reset this
          this.importToDBPercentage = 0;
        }.bind(this),
        3000,
      );
    },
    updateIndicatorCode(originalID, newID) {
      this.$options.dataForUpload[newID] =
        this.$options.dataForUpload[originalID];
      delete this.$options.dataForUpload[originalID];
      // redo the array of indicator codes here
      this.indicatorCodesFromDataBase = [];
      for (let index = 0; index < this.metaData.length; index++) {
        this.indicatorCodesFromDataBase.push(this.metaData[index].ind_code);
      }
    },
    truncateCache() {
      this.emit.emit("systemMessage", {
        title: "Give me a moment...",
        message: "Truncating cache...",
        timeout: 3000,
        colour: "warning",
      });

      this.confirmCacheTruncatingDialog = false;
      this.$axios
        .delete("/truncate-cache")
        .then(() => {
          this.emit.emit("systemMessage", {
            title: "Cache was truncated",
            message: "Success!",
            timeout: 3000,
            colour: "green",
          });
        })
        .catch((error) => {
          console.error(error.response.data);
          this.emit.emit("systemMessage", {
            title: "Something went wrong...",
            message: error.response.data?.message,
            timeout: -1,
            colour: "error",
          });
        });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (typeof this.$options.dataForUpload !== "undefined") {
      if (this.$options.dataForUpload !== null) {
        const answer = window.confirm(
          "STOP! Do you really want to leave? Your data import is NOT complete! You will have to start again if you leave.",
        );
        // yes
        if (answer) {
          // purge data from browser (free up some memory) before leaving
          this.inputFileFieldOnChange(true);
          this.resetImportFeedbackData();
          next();
        }
      } else {
        next();
      }
    } else {
      next();
    }
  },
  watch: {
    selectAll: {
      handler() {
        this.selected = this.selectAll ? this.metaData : [];
      },
    },
    selected: {
      handler() {
        if (!this.selected.length) {
          this.indeterminate = false;
        } else if (this.selected.length !== this.metaData.length) {
          this.indeterminate = true;
        } else {
          this.indeterminate = false;
        }
      },
      deep: true,
    },
    showAllColumns: {
      handler() {
        this.buildMetaDataTable();
      },
    },
    errorColumnsOnly: {
      handler() {
        this.errorsAndWarningsColumnNames();
      },
    },
    dataDownloadIndicators() {
      if (this.dataDownloadIndicators.length > 0) {
        this.dataDownloadIndicatorArray =
          this.dataDownloadIndicators.split(",");
      }
    },
    dataDownloadAreas() {
      if (this.dataDownloadAreas.length > 0) {
        this.dataDownloadAreas = this.dataDownloadAreas.toUpperCase();
        this.dataDownloadAreaArray = this.dataDownloadAreas.split(",");
      }
    },
  },
};
</script>

<style scoped>
.table-cell {
  border: 1px solid silver;
  padding: 5px;
}
.table-cell:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
.downloadTable {
  font-size: 12px;
  margin-left: 15px;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.here {
  font-weight: bold;
  color: blue;
  cursor: pointer;
  text-decoration: underline;
}

.viewData {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.table-footer-prepend {
  margin-top: -55px;
  height: 58px;
}

.metaError {
  background-color: rgba(255, 0, 0, 0.219);
}

.metaWarning {
  background-color: #fb8c00a6;
}

.noMetaError {
  background-color: rgb(252, 252, 252);
}

.downloadLimit {
  color: red;
}
</style>
