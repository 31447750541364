const XLSX = require("xlsx");

// Function to convert string to ArrayBuffer
function stringToArrayBuffer(s) {
  const buffer = new ArrayBuffer(s.length);
  const view = new Uint8Array(buffer);
  for (let i = 0; i < s.length; i++) {
    view[i] = s.charCodeAt(i) & 0xff;
  }
  return buffer;
}

// Function to trigger the download of a blob
function downloadBlob(blob, filename) {
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}

/*
 * Function to export data to Excel
 *
 * @param {Array} sheetsData - Array of objects containing the sheets to export in one file
 * @param {String} fileTitle - Name of the file to download
 *
 * Example of sheetsData:
 * [
 *   {
 *     headers: ['Name', 'Age'],
 *     items: [{ Name: 'John', Age: 30 }, { Name: 'Jane', Age: 25 }],
 *     sheetName: 'Sheet1'
 *   },
 *   {
 *     headers: ['City', 'Country'],
 *     items: [{ City: 'New York', Country: 'USA' }, { City: 'Paris', Country: 'France' }],
 *     sheetName: 'Sheet2'
 *   }
 * ]
 */
function exportToExcel(sheetsData, fileTitle) {
  let workbook = XLSX.utils.book_new();
  sheetsData.forEach(({ headers, items, sheetName }) => {
    let worksheet = XLSX.utils.json_to_sheet(items, { header: headers });
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
  });

  const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
  const blob = new Blob([stringToArrayBuffer(wbout)], {
    type: "application/octet-stream",
  });

  downloadBlob(blob, `${fileTitle}`);
}

export { exportToExcel };
