<template>
  <div>
    <v-dialog persistent v-model="showHideDialog" max-width="600px" scrollable>
      <v-card style="height: 75vh">
        <v-toolbar
          :style="
            'max-height: 60px; ' +
            'background-color: ' +
            this.$store.state.config.siteConfig.toolbar_colour
          "
          class="text-h6"
        >
          <v-toolbar-title>
            <span v-if="createMode" class="text-h5">Add a New Site Config</span>
            <span v-else class="text-h5">Edit Site Config</span>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form" v-model="validation" lazy-validation>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <!-- Load up the form -->
                  <div v-for="item in siteConfigObject" :key="item.label">
                    <v-select
                      v-if="item.fieldType == 'select'"
                      :label="item.label"
                      v-model="item.value"
                      :items="item.items"
                      :error-messages="item.apiResponseError"
                      :rules="item.frontEndValidation"
                      item-title="text"
                      item-value="value"
                      variant="outlined"
                      density="compact"
                      rounded="0"
                      class="fields"
                      persistent-hint
                      :hint="item.hint"
                      clearable
                    >
                    </v-select>
                    <v-text-field
                      v-if="
                        item.fieldType == 'text' &&
                        showField(item.hideForCreate) &&
                        item.label !== 'ID'
                      "
                      :label="item.label"
                      v-model="item.value"
                      variant="outlined"
                      density="compact"
                      rounded="0"
                      class="fields"
                      :readonly="item.readOnly"
                      :error-messages="item.apiResponseError"
                      :rules="item.frontEndValidation"
                      autocomplete="off"
                    ></v-text-field>
                    <v-textarea
                      v-if="
                        item.fieldType == 'text-area' &&
                        showField(item.hideForCreate)
                      "
                      :label="item.label"
                      :hint="item.hint"
                      v-model="item.value"
                      density="compact"
                      rounded="0"
                      variant="outlined"
                      class="fields"
                      :readonly="item.readOnly"
                      :error-messages="item.apiResponseError"
                      :rules="item.frontEndValidation"
                    ></v-textarea>
                    <v-checkbox
                      v-if="
                        item.fieldType == 'checkbox' &&
                        showField(item.hideForCreate)
                      "
                      :label="item.label"
                      v-model="item.value"
                      density="compact"
                      rounded="0"
                      variant="outlined"
                      class="fields"
                      :readonly="item.readOnly"
                      :error-messages="item.apiResponseError"
                      :rules="item.frontEndValidation"
                    ></v-checkbox>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            variant="elevated"
            tile
            @click="$emit('dialog', false)"
            aria-label="Close"
          >
            Close
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="createMode"
            variant="elevated"
            color="success"
            tile
            @click="createConfig()"
            aria-label="Save New Site"
          >
            Save New Site
          </v-btn>
          <v-btn
            v-else
            variant="elevated"
            color="success"
            tile
            @click="updateConfig()"
            aria-label="Save Changes"
          >
            Save Changes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "ConfigDialog",
  computed: {
    showHideDialog: {
      get() {
        return this.dialog;
      },
    },
    availableCountries: {
      get() {
        if (this.$store.state.config.siteConfig.site_country === "aus") {
          return [{ text: "Australia", value: "aus" }];
        } else {
          return [
            {
              text: "England",
              value: "eng",
            },
            {
              text: "Wales",
              value: "wls",
            },
            {
              text: "Scotland",
              value: "sct",
            },
          ];
        }
      },
    },
    config: {
      get() {
        return this.$store.state.siteConfigAdmin;
      },
      set(value) {
        this.$store.commit("setSiteConfigAdmin", value);
      },
    },
    allConfigs: {
      get() {
        return this.allConfigsProp;
      },
      set(val) {
        this.$emit("update:allConfigsProp", val);
      },
    },
  },
  data: () => ({
    siteConfigObjectClone: {},
    upsertObject: {},
    validation: false,
    siteConfigObject: {
      id: {
        fieldName: "id",
        label: "ID",
        readOnly: true,
        fieldType: "text",
        value: null,
        apiResponseError: null,
        frontEndValidation: [],
        hideForCreate: true,
      },
      name: {
        fieldName: "name",
        label: "Site Name*",
        readOnly: false,
        fieldType: "text",
        value: null,
        apiResponseError: null,
        frontEndValidation: [
          (v) => !!v || "Name is required",
          (v) =>
            (v && v.length <= 60) || "Name must be less than 60 characters",
        ],
        hideForCreate: false,
      },
      description: {
        fieldName: "description",
        label: "Description*",
        hint: null,
        readOnly: false,
        fieldType: "text-area",
        value: null,
        apiResponseError: null,
        frontEndValidation: [
          (v) => !!v || "Description is required",
          (v) => (v && v.length >= 0) || "Name must be more than 0 characters",
        ],
        hideForCreate: false,
      },
      page_title: {
        fieldName: "page_title",
        label: "Page Title*",
        readOnly: false,
        fieldType: "text",
        value: null,
        apiResponseError: null,
        frontEndValidation: [
          (v) => !!v || "Page Title is required",
          (v) =>
            (v && v.length <= 60) ||
            "Page Title must be less than 60 characters",
        ],
        hideForCreate: false,
      },
      site_country: {
        fieldName: "site_country",
        label: "Country*",
        readOnly: false,
        fieldType: "select",
        value: null,
        items: [],
        apiResponseError: null,
        frontEndValidation: [(v) => !!v || "Country is required"],
        hideForCreate: false,
      },
      tag_line: {
        fieldName: "tag_line",
        label: "Tag Line",
        readOnly: false,
        fieldType: "text",
        value: null,
        apiResponseError: null,
        frontEndValidation: [],
        hideForCreate: false,
      },
      url: {
        fieldName: "url",
        label: "URL*",
        readOnly: false,
        fieldType: "text",
        value: "li.localinsight.org",
        apiResponseError: null,
        frontEndValidation: [
          (v) => !!v || "URL is required",
          (v) => (v && !/\s/g.test(v)) || "No Spaces Please!",
        ],
        hideForCreate: false,
      },
      toolbar_colour: {
        fieldName: "toolbar_colour",
        label: "Toolbar Colour",
        readOnly: false,
        fieldType: "text",
        value: "#51627C",
        apiResponseError: null,
        frontEndValidation: [],
        hideForCreate: false,
      },
      logo: {
        fieldName: "logo",
        hint: "Logo Base64 data url string (will default to standard Logo if left empty)",
        label: "Logo Base64 data url string",
        readOnly: false,
        fieldType: "text-area",
        value: null,
        apiResponseError: null,
        hideForCreate: false,
      },
      favicon: {
        fieldName: "favicon",
        hint: "Favicon Base64 data url string (will default to standard Favicon if left empty)",
        label: "Favicon Base64 data url string",
        readOnly: false,
        fieldType: "text-area",
        value: null,
        apiResponseError: null,
        hideForCreate: false,
      },
      is_public_site: {
        fieldName: "is_public_site",
        label: "Is Public Site",
        readOnly: false,
        fieldType: "checkbox",
        value: false,
        apiResponseError: null,
        frontEndValidation: [],
        hideForCreate: false,
      },
    },
  }),
  components: {},
  props: {
    createMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    dialog: {
      type: Boolean,
      required: false,
      default: false,
    },
    allConfigsProp: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  mounted() {
    this.siteConfigObject.site_country.items = this.availableCountries;
  },
  methods: {
    populateUpsertObject() {
      // create upsert object (update or insert)
      for (var key in this.siteConfigObject) {
        this.upsertObject[key] = this.siteConfigObject[key].value;
      }
    },
    showField(hideForCreate) {
      if (hideForCreate && this.createMode) {
        return false;
      } else {
        return true;
      }
    },
    updateConfig() {
      // let the user know what's happening
      this.populateUpsertObject();
      this.resetApiValidationErrors();
      // if this passes validation then call the api
      this.$refs.form.validate().then(({ valid: isValid }) => {
        if (isValid) {
          this.emit.emit("systemMessage", {
            title: "Updating Site Config",
            message: "Updating",
            timeout: 4000,
            colour: "warning",
          });
          // make the call!
          this.$axios
            .put("/site-config/" + this.upsertObject.id, this.upsertObject)
            .then((response) => {
              this.emit.emit("systemMessage", {
                title: "Update Complete",
                message: "Success!",
                timeout: 3000,
                colour: "green",
              });
              // free up memory
              this.upsertObject = {};
              // update the selected config (this will also propagate to the dropdown list)
              Object.assign(this.config, response.data);
              // close the dialogue
              this.$emit("dialog", false);
            })
            .catch((error) => {
              this.emit.emit("systemMessage", {
                message: error.response.data.message,
                title: "Error! Site Config Update Failed",
                timeout: -1,
                colour: "red",
              });
              this.apiValidationErrors(error.response.data.errors);
            });
        }
      });
    },
    createConfig() {
      // let the user know what's happening
      this.populateUpsertObject();
      this.resetApiValidationErrors();
      // if this passes validation then call the api
      this.$refs.form.validate().then(({ valid: isValid }) => {
        if (isValid) {
          this.emit.emit("systemMessage", {
            title: "Creating new Site Config",
            message: "Creating",
            timeout: -1,
            colour: "warning",
          });
          // make the call!
          this.$axios
            .post("/site-config", this.upsertObject)
            .then((response) => {
              this.emit.emit("systemMessage", {
                title: "Successful Config Creation",
                message: "Success!",
                timeout: 3000,
                colour: "green",
              });
              // free up memory
              this.upsertObject = {};
              // close the dialogue
              this.$emit("dialog", false);
              // reset the siteConfigObject object
              this.siteConfigObject = JSON.parse(
                JSON.stringify(this.siteConfigObjectClone),
              );
              // add the new one to allConfigs
              this.allConfigs.push(response.data);
            })
            .catch((error) => {
              this.emit.emit("systemMessage", {
                message: error.response.data.message,
                title: "Error! Site Config Creation Failed",
                timeout: -1,
                colour: "red",
              });
              this.apiValidationErrors(error.response.data.errors);
            });
        }
      });
    },
    apiValidationErrors(errors) {
      for (const field in errors) {
        this.siteConfigObject[field].apiResponseError = errors[field];
      }
    },
    resetApiValidationErrors() {
      for (var field in this.siteConfigObject) {
        this.siteConfigObject[field].apiResponseError = null;
      }
    },
  },
  watch: {
    dialog: {
      handler() {
        if (this.dialog) {
          // opening
          if (this.config !== null) {
            for (let key in this.siteConfigObject) {
              this.siteConfigObject[key].value = this.config[key];
            }
          } else {
            for (let key2 in this.siteConfigObject) {
              this.siteConfigObject[key2].value =
                this.siteConfigObject[key2].fieldName === "is_public_site"
                  ? false
                  : null;
            }
          }
          this.resetApiValidationErrors();
        }
      },
    },
  },
};
</script>

<style scoped></style>
