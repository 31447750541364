// call this to get the value from a url parameter
function getURLParam(param) {
  var url = window.location.href
    .slice(window.location.href.indexOf("?") + 1)
    .split("&");
  for (var i = 0; i < url.length; i++) {
    var params = url[i].split("=");
    if (params[0] === param) {
      return decodeURIComponent(params[1]);
    }
  }
  return false;
}

export { getURLParam };
