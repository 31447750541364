<template>
  <v-card-text class="text-left">
    <div>Documentation</div>
  </v-card-text>
</template>
<script>
export default {
  name: "DatastoreDocumentation",
  data: () => ({}),
};
</script>
