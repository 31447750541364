<template>
  <div>
    <v-dialog v-model="deleteReportThemeDialogue" width="500px">
      <template v-slot:activator="{}">
        <v-btn
          id="deleteReportTheme"
          color="error"
          class="ml-3 pa-2"
          @click="deleteReportThemeDialogue = true"
          rounded="0"
          variant="elevated"
        >
          delete theme
        </v-btn>
      </template>
      <v-card tile>
        <v-toolbar
          :color="this.$store.state.config.siteConfig.toolbar_colour"
          dark
          class="text-h6 text-left"
        >
          <v-toolbar-title>Confirm Delete?</v-toolbar-title>
        </v-toolbar>
        <v-spacer>
          <v-card-text
            style="font-size: 15px; height: 10vh"
            class="pb-0 text-center"
          >
            Are you sure you want to delete this theme? This action cannot be
            undone.
          </v-card-text>
        </v-spacer>
      </v-card>
      <v-card tile>
        <v-card-actions>
          <v-btn
            color="success"
            rounded="0"
            variant="elevated"
            :disabled="loading"
            aria-label="close"
            @click="deleteReportThemeDialogue = false"
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            id="confirmDelete"
            color="error"
            :disabled="loading"
            aria-label="delete"
            rounded="0"
            variant="elevated"
            @click="deleteReportTheme(0)"
          >
            delete report
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="failedDeletionDialog"
      scrollable
      persistent
      :max-width="failedOnes.length ? '800px' : '500px'"
    >
      <v-card>
        <v-toolbar
          :color="this.$store.state.config.siteConfig.toolbar_colour"
          dark
          class="text-h6 text-center"
          max-height="64px"
        >
          <v-spacer>
            <v-toolbar-title>Warning </v-toolbar-title>
          </v-spacer>
        </v-toolbar>
        <v-container style="min-height: 50vh" class="pa-0 pb-4">
          <!-- loading UX -->
          <v-card-actions v-if="loading" tile elevation="0" class="progress">
            <div class="progressText">
              <v-progress-circular
                :size="200"
                :width="3"
                color="primary"
                indeterminate
                >Deleting...
              </v-progress-circular>
            </div>
          </v-card-actions>
          <v-card-text v-else class="mt-4 pb-1">
            <v-alert
              text
              color="warning"
              width="100%"
              class="mt-3"
              variant="outlined"
              border="left"
            >
              <v-row align="center" no-gutters>
                <v-col cols="11">
                  <v-icon class="mr-2" color="warning">mdi-alert</v-icon>
                  Warning
                  <br />
                  <div v-html="errorMessage" class="ml-9"></div>
                </v-col>
              </v-row>
            </v-alert>
            <v-data-table
              :headers="tableHeaders"
              :items="failedOnes"
              class="elevation-1"
              hide-default-footer
            >
            </v-data-table>
          </v-card-text>
        </v-container>
        <v-card>
          <v-card-actions>
            <v-btn
              color="success"
              rounded="0"
              variant="elevated"
              :disabled="loading"
              @click="closeFailedDeletionDialog"
              aria-label="close"
            >
              close
            </v-btn>
            <v-spacer />
            <v-btn
              color="error"
              rounded="0"
              variant="elevated"
              :disabled="loading"
              @click="deleteReportTheme(1)"
              aria-label="delete reports"
            >
              delete reports
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "DeleteReportTheme",
  data: () => ({
    loading: false,
    failedDeletionDialog: false,
    errorMessage: "",
    tableHeaders: [],
    failedOnes: [],
    deleteReportThemeDialogue: false,
  }),
  props: {
    reportThemeObject: {
      type: Object,
      required: true,
    },
  },
  computed: {},
  methods: {
    deleteReportTheme(removeSavedOrSummaryReports = 0) {
      this.loading = true;
      this.emit.emit("systemMessage", {
        title: "Please wait... until all checks are done.",
        message: "Deleting",
        timeout: -1,
        colour: "warning",
      });

      this.$axios
        .delete(
          "/reports-themes/" +
            this.reportThemeObject.id +
            "/" +
            removeSavedOrSummaryReports,
        )
        .then(
          function () {
            // handle success
            this.emit.emit("systemMessage", {
              title: "Theme deleted successfully",
              message: "Success",
              timeout: 3000,
              colour: "success",
            });

            this.closeFailedDeletionDialog();
            this.$emit("getThemeList");
            this.$emit("clearPanel");
          }.bind(this),
        )
        .catch(
          function (error) {
            this.errorMessage = error.response.data.error;

            if (error.response.status === 500) {
              let response = error.response.data;
              this.emit.emit("systemMessage", {
                title: "See the warning below",
                message: "Theme is not deleted!",
                timeout: 2000,
                colour: "warning",
              });

              // eslint-disable-next-line no-prototype-builtins
              if (response.hasOwnProperty("summary_reports")) {
                this.tableHeaders = [
                  { text: "id", value: "id" },
                  { text: "Name", value: "name" },
                  { text: "Created", value: "created_at" },
                ];

                this.failedOnes = response.summary_reports;
              } else {
                this.tableHeaders = [
                  { text: "id", value: "id" },
                  { text: "Creater", value: "user" },
                  { text: "Client", value: "client" },
                  { text: "Type", value: "type" },
                  { text: "Created", value: "created_at" },
                ];

                this.failedOnes = response.saved_reports;
              }

              // open dialogue
              this.failedDeletionDialog = true;
            } else {
              this.emit.emit("systemMessage", {
                message: error.response.data.message,
                title: "Error! Failed",
                timeout: -1,
                colour: "error",
              });
            }
          }.bind(this),
        )
        .finally(() => {
          this.loading = false;
          this.deleteReportThemeDialogue = false;
        });
    },
    closeFailedDeletionDialog() {
      this.failedDeletionDialog = false;
      this.failedOnes = [];
      this.errorMessage = "";
    },
  },
};
</script>

<style scoped></style>
