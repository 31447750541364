<template>
  <HeaderModule
    title="Datastore"
    icon="book-variant"
    description="Search all indicators from the OCSI database"
    width="1224px"
    padding="10px"
    :tabs="tabs"
    :selectedTabRoute="selectedTabRoute"
    @scrollTabEvent="handleTabContentScroll"
  >
    <template #catalogue>
      <DatastoreCatalogue ref="catalogue" />
    </template>

    <template #queryBuilder>
      <DatastoreQueryBuilder ref="queryBuilder" />
    </template>

    <template #favourites>
      <DatastoreFavourites />
    </template>

    <template #apiKeys>
      <DatastoreApiKeys />
    </template>

    <template #documentation>
      <DatastoreDocumentation />
    </template>
  </HeaderModule>
</template>
<script>
import HeaderModule from "@/components/HeaderModule.vue";
import DatastoreCatalogue from "@/components/DatastoreCatalogue.vue";
import DatastoreQueryBuilder from "@/components/DatastoreQueryBuilder.vue";
import DatastoreFavourites from "@/components/DatastoreFavourites.vue";
import DatastoreApiKeys from "@/components/DatastoreApiKeys.vue";
import DatastoreDocumentation from "@/components/DatastoreDocumentation.vue";
import { systemMessages } from "@/mixins/SystemMessages";

export default {
  name: "DatastorePage",
  mixins: [systemMessages],
  components: {
    HeaderModule,
    DatastoreCatalogue,
    DatastoreQueryBuilder,
    DatastoreFavourites,
    DatastoreApiKeys,
    DatastoreDocumentation,
  },
  props: {
    selectedTabRoute: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    tabs: [
      { title: "Catalogue", value: "catalogue", route: "catalogue" },
      { title: "Query builder", value: "queryBuilder", route: "query-builder" },
      { title: "Favourites", value: "favourites", route: "favourites" },
      { title: "My API Keys", value: "apiKeys", route: "api-keys" },
      {
        title: "Documentation",
        value: "documentation",
        route: "documentation",
      },
    ],
  }),
  created() {
    this.fetchApiKeys();
  },
  methods: {
    // handle scrolling through tabs
    handleTabContentScroll(tab) {
      if (tab === "catalogue") {
        this.$refs.catalogue.getPaginatedIndicators();
      }
    },

    /** fetch api keys from the backend and commit to vuex */
    async fetchApiKeys() {
      let filters = JSON.stringify([
        { col: "status", operator: "neq", comparator: "deleted" },
      ]);

      try {
        const response = await this.$axios.get("/datastore/apikeys", {
          params: {
            filters: filters,
          },
        });
        this.$store.commit("datastore/setApiKeys", response.data);
      } catch (error) {
        this.errorMsg({
          title: "There was an error",
          message: "there was a problem fetching your API keys",
        });
      }
    },
  },
};
</script>

<style scoped></style>
