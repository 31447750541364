<template>
  <div>
    <!-- select standard geographies page -->
    <div v-if="!$route.params.parentAreaIDs">
      <v-card
        class="mx-3 mt-3"
        tile
        :style="
          'height: ' +
          (height - 125) +
          'px; overflow-x: hidden; overflow-y: auto;'
        "
      >
        <v-card-title class="text-left"
          ><h1 class="h1">Discovery Tool</h1></v-card-title
        >
        <v-card-text class="mt-5 text-left">
          <p>
            Select the specific areas you want to learn more about below, when
            you are happy with your selection select the
            <strong>View Areas</strong>
            button to uncover insights on your area
          </p>
          <v-row class="mt-5">
            <v-col cols="9">
              <v-select
                v-model="selectedStdGeography"
                variant="outlined"
                density="compact"
                rounded="0"
                label="Standard Geography"
                :items="stdGeographyData"
                item-title="area_level_name"
                item-value="area_level"
                :loading="loadingGeographies"
                :disabled="loadingGeographies"
              ></v-select>
            </v-col>
            <v-col cols="3">
              <v-select
                v-model="selectedStdGeographyTimepoint"
                variant="outlined"
                rounded="0"
                density="compact"
                label="Year"
                prepend-inner-icon="mdi-calendar"
                :items="stdGeographyTimepointsForSelected"
                :loading="loadingGeographies"
                :disabled="loadingGeographies"
              ></v-select>
            </v-col>
          </v-row>

          <v-divider></v-divider>

          <v-row class="mt-5">
            <v-col cols="4">
              <v-text-field
                v-model="search"
                variant="outlined"
                density="compact"
                rounded="0"
                label="Search areas"
                prepend-inner-icon="mdi-magnify"
                :disabled="loadingGeographies || loadingAreas"
                autocomplete="off"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-row>
          <v-col cols="12">
            <div class="mx-4">
              <v-data-table
                id="areasTable"
                :headers="areaTableHeaders"
                :items="availableAreas"
                :search="search"
                height="100%"
                :loading="loadingAreas"
              >
                <template v-slot:[`item.select`]="{ item }">
                  <v-checkbox-btn
                    v-model="selectedAreas"
                    :value="item.id"
                    :inline="true"
                  ></v-checkbox-btn>
                </template>
                <template v-slot:loading>
                  <v-skeleton-loader type="table-row@10"></v-skeleton-loader>
                </template>
                <template v-slot:no-data>
                  <v-alert
                    type="warning"
                    :text="areaTableWarning"
                    class="my-5"
                  ></v-alert>
                </template>
              </v-data-table>
              <v-divider></v-divider>
            </div>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="mx-3 mb-3" tile>
        <v-card-actions cols="12">
          <v-spacer />
          <v-btn
            color="success"
            variant="elevated"
            aria-label="view areas"
            rounded="0"
            :disabled="selectedAreas.length === 0"
            @click="displayMap"
          >
            VIEW AREAS
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>

    <!-- map view page -->
    <div v-else>
      <router-view />
    </div>
  </div>
</template>

<script>
import { useDisplay } from "vuetify";

export default {
  name: "DiscoveryTool",
  data: () => ({
    height: useDisplay().height,
    search: "",
    loadingGeographies: false,
    loadingAreas: false,
    showGeographiesSelect: true,
    stdGeographyLevels: [6, 7], //data levels available in tool
    stdGeographyData: [], //standard geographies data
    stdGeographyTimepoints: [], //available timepoints for all standard geographies
    stdGeographyTimepointsForSelected: [], //timepoints for selected geography
    selectedStdGeographyTimepoint: null,
    selectedAreas: [],
    availableAreas: [], //all available areas for selected geography and timepoint
    areaTableHeaders: [
      {
        title: "Area name",
        key: "area_name",
        align: "start",
        cellProps: {
          class: "text-left",
        },
      },
      {
        title: "Select",
        key: "select",
        align: "end",
        cellProps: { class: "text-right" },
        sortable: false,
      },
    ],
  }),
  components: {},
  async mounted() {
    await this.fetchStandardGeographyData();
  },
  watch: {
    /** update available areas when the geography timepoint changes */
    selectedStdGeographyTimepoint() {
      this.clearSelectedAreas();
      this.fetchAvailableAreas();
    },
  },
  computed: {
    selectedStdGeography: {
      //store value in vuex for use in DiscoveryToolReportDialog
      get() {
        return this.$store.state.DTSelectedStdGeography;
      },
      set(value) {
        this.$store.commit("setDTSelectedStdGeography", value);
        /** update list of timepoints when standard geography dropdown changes */
        const timepoint = this.stdGeographyTimepoints.find(
          (t) => t.area_level === value,
        );
        this.stdGeographyTimepointsForSelected = timepoint.years;

        this.selectedStdGeographyTimepoint =
          this.stdGeographyTimepointsForSelected[0];

        if (value) {
          this.fetchAvailableAreas();
        }
      },
    },
    /** generate warning message to display in area selection table when no data */
    areaTableWarning: {
      get() {
        if (this.availableAreas.length === 0) {
          return "No Data to Display";
        }

        return `We couldn't find any matches for "${this.search}"`;
      },
    },
  },
  beforeUnmount() {
    this.emit.emit("systemBusy", false);
  },
  methods: {
    /** toggle view from area selector to map view */
    displayMap() {
      // get string of selected area IDs with '-' separator and pass to the URL
      const areaIDs = this.selectedAreas.join("-");

      this.$router.push("/discovery-tool/map/" + areaIDs);
    },
    /** fetch all standard geography data for stdGeographyLevels */
    async fetchStandardGeographyData() {
      this.loadingGeographies = true;

      await this.fetchStandardGeographyTimepoints();

      const response = await this.$axios.get("/get-std-geo-discovery-tool");
      this.stdGeographyData = response.data.filter((d) =>
        this.stdGeographyLevels.includes(d.area_level),
      );
      this.selectedStdGeography = this.stdGeographyData[0].area_level;

      this.loadingGeographies = false;
    },
    /** fetch all standard geography timepoints for stdGeographyLevels */
    async fetchStandardGeographyTimepoints() {
      const response = await this.$axios.get(
        "/get-std-geo-timepoints-discovery-tool",
      );
      this.stdGeographyTimepoints = response.data.filter((d) =>
        this.stdGeographyLevels.includes(d.area_level),
      );
    },
    /** fetch areas for selected geography and timepoint */
    async fetchAvailableAreas() {
      this.loadingAreas = true;

      const response = await this.$axios.get(
        `/get-available-areas-discovery-tool/${this.selectedStdGeography}/${this.selectedStdGeographyTimepoint}`,
      );
      this.availableAreas = response.data;

      this.loadingAreas = false;
    },
    /** clear selected areas */
    clearSelectedAreas() {
      this.selectedAreas = [];
    },
  },
};
</script>

<style scoped>
#areasTable :deep(table) {
  border: thin solid #e0e0e0;
}

#areasTable :deep(th) {
  background-color: #f5f5f5;
  font-weight: 600;
}

#areasTable :deep(.v-data-table-footer) {
  justify-content: center;
}
</style>
