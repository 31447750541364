<template>
  <v-container class="pa-0 ma-0">
    <!-- Authorisation -->
    <v-card class="my-4 main">
      <!-- Header -->
      <v-card-title class="title pa-0 pr-4">
        <v-card-actions>
          <v-icon :color="isValid ? 'green' : '#FB8C00'" class="ma-2">
            {{ isValid ? "mdi-check-circle" : "mdi-alert" }}
          </v-icon>
          <div class="header-text">Authorisation</div>
        </v-card-actions>
      </v-card-title>

      <!-- Content under the panel -->
      <v-card-text>
        <div class="text caption mt-3">
          An authorisation token is needed to access the Datastore API, ensuring
          your requests remain secure and verified. Please select an existing
          token if you have one, or create a new token to continue.
        </div>
        <!-- Dropdown -->
        <v-select
          v-model="selectedKey"
          :items="apiKeys"
          item-title="nickname"
          item-value="id"
          label="Select Authorisation Token"
          class="mt-4 select-token"
          variant="outlined"
          density="compact"
          rounded="0"
        ></v-select>
        <!-- Button to create new API key -->
        <div class="button-container">
          <v-btn
            @click="$router.push('/datastore/api-keys')"
            variant="elevated"
            prepend-icon="mdi-plus"
            color="#0e5b99"
            rounded="0"
            class="mt-4 create-api-key-btn"
          >
            Create API Key
          </v-btn>
        </div>
      </v-card-text>
    </v-card>

    <!-- Indicator -->
    <v-card class="my-4 main">
      <!-- Header -->
      <v-card-title class="title pa-0 pr-4">
        <v-card-actions>
          <v-icon
            :color="isIndicatorComplete ? 'green' : '#FB8C00'"
            class="ma-2"
          >
            {{ isIndicatorComplete ? "mdi-check-circle" : "mdi-alert" }}
          </v-icon>
          <div class="header-text">Indicator</div>
        </v-card-actions>
      </v-card-title>

      <!-- No indicator selected -->
      <v-card-text v-if="!selectedIndicator" class="text-left pa-4">
        <!-- Alert -->
        <v-alert color="#bfe0f8" class="infoAlert" icon="mdi-information">
          <div style="font-weight: 500">Add an indicator</div>
          <div>Add an indicator to build you query</div>
        </v-alert>
        <div class="button-container">
          <v-btn
            class="mt-4 create-api-key-btn"
            variant="elevated"
            prepend-icon="mdi-plus"
            color="#0e5b99"
            @click="$router.push('/datastore/catalogue')"
            rounded="0"
          >
            add indicator from catalogue
          </v-btn>
        </div>
      </v-card-text>

      <!-- Selected indicator -->
      <v-card-text v-else class="text-left pa-4">
        <v-card-actions class="pa-0">
          <div>
            <div class="text-subtitle-1" style="font-weight: 400">
              {{ selectedIndicator.indicator_name }}
            </div>
            <div class="text-caption" style="color: gray">
              <v-icon icon="mdi-code-tags" size="15px" />
              {{ selectedIndicator.indicator_code }}
            </div>
          </div>
          <v-spacer />
          <div>
            <v-icon
              @click="removeIndicatorConfirmDialog = true"
              icon="mdi-delete"
              color="error"
            />
          </div>
        </v-card-actions>

        <!-- Indicator timepoints -->
        <v-card-actions class="px-0">
          <v-select
            v-model="queryParameters.timepoint"
            label="Time point"
            :items="timepoints"
            variant="outlined"
            :no-data-text="
              loadingTimepoints
                ? 'Loading timepoints'
                : 'No timepoints available'
            "
            :loading="loadingTimepoints"
            class="py-4"
            item-value="dateSelection"
            item-title="label"
            hide-details
            density="compact"
            rounded="0"
          />
        </v-card-actions>

        <!-- Checkboxes -->
        <div class="text-subtitle-1" style="font-weight: 400">Values</div>
        <v-card-text class="pa-0">
          <div
            @click.stop="
              queryParameters.raw_values = !queryParameters.raw_values
            "
            class="d-flex pa-1 checkbox-container"
          >
            <v-checkbox
              v-model="queryParameters.raw_values"
              label="Raw values"
              hide-details
              color="primary"
            />
          </div>
          <div
            @click.stop="queryParameters.rates = !queryParameters.rates"
            class="d-flex pa-1 checkbox-container"
          >
            <v-checkbox
              v-model="queryParameters.rates"
              label="Rates"
              hide-details
              color="primary"
            />
          </div>
        </v-card-text>
      </v-card-text>
    </v-card>

    <DynamicDialog
      :show="pickDateRange"
      @dialogOk="setCustomTimeRange()"
      @dialogCancel="
        pickDateRange = false;
        removeEscapeListener();
      "
      @update:showDyamicDialog="(state) => (pickDateRange = state)"
      max-width="700"
      min-height="400"
      :okBtnDisabled="!start_year || !end_year"
      okBtnColor="success"
      cancelBtnColor="none"
      cancelBtnVariant="text"
    >
      <template v-slot:title>Select date range</template>
      <template v-slot:content>
        <v-row justify="center">
          <v-col cols="6" class="text-left">
            <!-- Header of the picker -->
            <v-card-text
              class="pa-0 mb-3 pl-4 text-body-2"
              style="height: 40px"
            >
              <div class="font-weight-thin">FROM</div>
              <div class="font-weight-medium d-flex mt-1">
                <a class="date mr-2" @click="show_start_year = false">{{
                  months[start_month] || "Month"
                }}</a>

                <a class="date mr-2" @click="show_start_year = true">{{
                  start_year || "Year"
                }}</a>
              </div>
            </v-card-text>

            <v-divider class="mb-1" />
            <v-date-picker-years
              v-if="show_start_year"
              v-model="start_year"
              class="pa-0 bg-white"
              color="#8ACEFF9C"
              elevation="24"
              @update:modelValue="show_start_year = false"
            />

            <v-date-picker-months
              v-else
              v-model="start_month"
              class="pa-0 bg-white"
              color="#8ACEFF9C"
              elevation="24"
            />
          </v-col>
          <v-divider vertical />
          <v-col cols="6" class="text-left">
            <!-- Header of the picker -->
            <v-card-text
              class="pa-0 mb-3 pl-4 text-body-2"
              style="height: 40px"
            >
              <div class="font-weight-thin">TO</div>
              <div class="font-weight-medium d-flex mt-1">
                <a class="date mr-2" @click="show_end_year = false">{{
                  months[end_month] || "Month"
                }}</a>
                <a
                  class="date"
                  variant="text"
                  color="primary"
                  style="height: 0"
                  @click="show_end_year = true"
                  >{{ end_year || "Year" }}</a
                >
              </div>
            </v-card-text>

            <v-divider class="mb-1" />
            <v-date-picker-years
              v-if="show_end_year"
              v-model="end_year"
              width="230"
              class="pa-0 bg-white"
              color="#8ACEFF9C"
              elevation="24"
              @update:modelValue="show_end_year = false"
            />
            <v-date-picker-months
              v-else
              v-model="end_month"
              width="230"
              class="pa-0 bg-white"
              color="#8ACEFF9C"
              elevation="24"
            />
          </v-col>
        </v-row>
      </template>
      <template v-slot:okBtnTitle>set date range</template>
    </DynamicDialog>

    <!-- Areas -->
    <v-card class="my-4 main">
      <!-- Header -->
      <v-card-title class="title pa-0 pr-4">
        <v-card-actions>
          <v-icon :color="isValid ? 'green' : '#FB8C00'" class="ma-2">
            {{ isValid ? "mdi-check-circle" : "mdi-alert" }}
          </v-icon>
          <div class="header-text">Areas</div>
        </v-card-actions>
      </v-card-title>
      <v-card-text class="text-left pa-4">
        <!-- Alert -->
        <v-alert color="#bfe0f8" class="infoAlert" icon="mdi-information">
          <div style="font-weight: 500">No areas defined</div>
          <div>Add at least one area to filter the indicator data by</div>
        </v-alert>
        <div class="button-container">
          <v-btn
            class="mt-4 create-api-key-btn"
            variant="elevated"
            prepend-icon="mdi-plus"
            color="#0e5b99"
            rounded="0"
          >
            add area
          </v-btn>
        </div>
      </v-card-text>
    </v-card>

    <!-- Query -->
    <v-card class="my-4 main">
      <!-- Header -->
      <v-card-title class="title pa-0 pr-4">
        <v-card-actions>
          <v-icon :color="isValid ? 'green' : '#FB8C00'" class="ma-2">
            {{ isValid ? "mdi-check-circle" : "mdi-alert" }}
          </v-icon>
          <div class="header-text">Your Query</div>
        </v-card-actions>
      </v-card-title>
      <v-card-text class="text-left pa-4">
        <!-- Alert -->
        <v-alert color="#bfe0f8" class="infoAlert" icon="mdi-information">
          <div style="font-weight: 500">Complete your query</div>
          <div>
            Your query is missing some details. Complete the fields above to
            finish your query.
          </div>
        </v-alert>
      </v-card-text>
    </v-card>

    <DynamicDialog
      :show="removeIndicatorConfirmDialog"
      @dialogOk="removeIndicator()"
      @dialogCancel="removeIndicatorConfirmDialog = false"
      @update:showDyamicDialog="
        (state) => (removeIndicatorConfirmDialog = state)
      "
      max-width="450"
      okBtnColor="error"
      cancelBtnColor="none"
      cancelBtnVariant="outlined"
    >
      <template v-slot:title>Remove indicator</template>
      <template v-slot:content>
        <p>Are you sure you want to remove this indicator from the query?</p>
      </template>
      <template v-slot:okBtnTitle>remove indicator</template>
    </DynamicDialog>
  </v-container>
</template>

<script>
import { systemMessages } from "@/mixins/SystemMessages";
import DynamicDialog from "@/components/DynamicDialog.vue";

export default {
  mounted() {},
  name: "DatastoreQueryBuilder",
  mixins: [systemMessages],
  components: {
    DynamicDialog,
  },
  data: () => ({
    queryParameters: {
      indicator: null,
      area: null,
      raw_values: false,
      rates: false,
      timepoint: null,
    },
    selectedKey: null,
    timepoints: [],
    removeIndicatorConfirmDialog: false,
    loadingTimepoints: false,
    date: null,
    pickDateRange: false,

    // timepoint range
    show_start_year: true,
    show_end_year: true,
    start_year: new Date().getFullYear(),
    start_month: 0,
    end_year: new Date().getFullYear(),
    end_month: 0,
    months: {
      0: "January",
      1: "February",
      2: "March",
      3: "April",
      4: "May",
      5: "June",
      6: "July",
      7: "August",
      8: "September",
      9: "October",
      10: "November",
      11: "December",
    },
  }),
  computed: {
    // Check if the selected key is valid
    isValid() {
      return this.selectedKey !== null;
    },
    isIndicatorComplete() {
      return (
        this.selectedIndicator?.id &&
        (this.queryParameters?.raw_values || this.queryParameters?.rates) &&
        this.queryParameters?.timepoint
      );
    },
    selectedIndicator: {
      get() {
        return this.$store.getters["datastore/selectedIndicator"];
      },
      set(val) {
        this.$store.commit("datastore/setSelectedIndicator", val);
      },
    },
    apiKeys: {
      get() {
        return this.$store.getters["datastore/activeApiKeys"];
      },
    },
  },
  methods: {
    async getIndicatorTimepoints() {
      if (this.loadingTimepoints) return;

      this.loadingTimepoints = true;
      try {
        const response = await this.$axios.get(
          `/datastore/query-builder/get-indicator-timepoints/${this.selectedIndicator?.id}`,
        );

        this.timepoints = response.data.timepoints;
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingTimepoints = false;
      }
    },
    removeIndicator() {
      this.selectedIndicator = null;
      this.removeIndicatorConfirmDialog = false;
      this.timepoints = [];
    },
    openDatePicker() {
      this.pickDateRange = !this.pickDateRange;
      if (this.pickDateRange) {
        this.$nextTick(() => {
          this.addEscapeListener();
        });
      } else {
        this.removeEscapeListener();
      }
    },
    handleEscapeKey(event) {
      if (event.key === "Escape") {
        this.pickDateRange = false;
        this.removeEscapeListener();
      }
    },
    addEscapeListener() {
      window.addEventListener("keyup", this.handleEscapeKey);
    },
    removeEscapeListener() {
      window.removeEventListener("keyup", this.handleEscapeKey);
    },
    setCustomTimeRange() {
      // check if start date is greater than end date
      if (
        this.start_year > this.end_year ||
        (this.start_year === this.end_year && this.start_month > this.end_month)
      ) {
        this.warningPill({
          title: "Start date cannot be greater than end date",
          icon: "mdi-information",
        });
        return;
      }

      const dateSelection = `${this.start_year}-${this.start_month + 1}-${this.end_year}-${this.end_month + 1}`;
      const label = `${this.months[this.start_month]} ${this.start_year} - ${this.months[this.end_month]} ${this.end_year}`;

      // push to the list if it doesn't exist
      if (
        !this.timepoints.some(
          (timepoint) => timepoint.dateSelection === dateSelection,
        )
      ) {
        this.timepoints.push({ dateSelection, label });
      }

      // set the selected timepoint
      this.queryParameters.timepoint = dateSelection;

      this.pickDateRange = false;
      this.removeEscapeListener();
    },
  },
  watch: {
    selectedIndicator: {
      handler(newVal, oldVal) {
        // hit on every indicator change
        if (newVal && newVal?.id !== oldVal?.id) {
          this.getIndicatorTimepoints();

          this.queryParameters.indicator = newVal.id;
        }
      },
      deep: true,
    },
    "queryParameters.timepoint": function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        if (newVal === "date_range") {
          // open date picker
          this.openDatePicker();

          // reset these
          this.queryParameters.timepoint = null;
          this.start_year = new Date().getFullYear();
          this.start_month = 0;
          this.end_year = new Date().getFullYear();
          this.end_month = 0;
        }
      }
    },
  },
};
</script>

<style scoped>
.circle {
  width: 11px;
  height: 11px;
  border-radius: 10px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
}
.caption {
  text-align: left;
  color: #4d4d4d;
}
.title {
  color: #4d4d4d;
  font-size: 16px;
  font-weight: 600;
  border-bottom: solid 1px #c2c2c2;
}
.header-text {
  font-weight: 500;
}
.main {
  width: calc(100% - 32px);
  max-width: 800px;
  margin: 0 auto;
  border: solid 1px #c2c2c2;
  border-radius: 8px;
}
@media (min-width: 600px) {
  .main {
    width: auto;
  }
}
.select-key {
  color: #4d4d4d;
}
.infoAlert * {
  color: #093f6b;
}
.checkbox-container {
  border-left: solid 1px gray;
  border-right: solid 1px gray;
  cursor: pointer;
}
.checkbox-container:nth-of-type(1) {
  border-bottom: solid gray 1px;
  border-top: solid gray 1px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.checkbox-container:nth-of-type(2) {
  border-bottom: solid gray 1px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.date {
  color: #1867c0;
  text-transform: uppercase;
  cursor: pointer;
}
</style>

<style>
/* info alert styling */
.infoAlert .mdi-information {
  font-size: 24px !important;
  width: 24px !important;
  height: 24px !important;
}
.infoAlert .v-alert__prepend {
  margin-right: 10px;
}
</style>
