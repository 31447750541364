<template>
  <!-- geoJSON import card -->
  <v-card tile width="100%" pa-5>
    <v-container fluid>
      <v-card-text>
        Import options currently disabled. To import, upload the latest ONSPD
        csvs in multi_csv format to sftp/uploads, and click 'Import
        Postcodes'</v-card-text
      >
      <v-row align="center">
        <v-col class="d-flex" cols="8" sm="6">
          <v-select
            :items="type_options"
            label="Import Option"
            density="compact"
            rounded="0"
            variant="outlined"
            v-model="type"
            :disabled="true"
          ></v-select>
        </v-col>
        <v-col class="d-flex" cols="8" sm="6">
          <v-file-input
            label=".geojson"
            accept=".geojson,.GEOJSON "
            type="file"
            density="compact"
            rounded="0"
            variant="outlined"
            v-model="file"
            :disabled="true"
          ></v-file-input>
        </v-col>
        <v-col class="d-flex" cols="8" sm="6">
          <v-btn
            color="blue darken-1"
            density="compact"
            rounded="0"
            variant="elevated"
            @click="handleFile()"
            aria-label="Import Postcodes"
            :disabled="true"
          >
            Import Postcodes
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="dialog"
      max-width="600px"
      transition="dialog-bottom-transition"
      persistent
    >
      <v-card>
        <v-card-title v-if="!this.import_complete"
          >Importing Postcodes</v-card-title
        >
        <v-card-title v-if="this.import_complete">Complete</v-card-title>
        <v-container v-if="!this.import_complete">
          <v-progress-linear indeterminate color="cyan"></v-progress-linear>
        </v-container>
        <v-container>
          <v-row class="text-center">
            <v-col cols="12">
              <ProgressTracker
                :import_details="postcode_import"
                v-on:complete="import_complete = true"
              ></ProgressTracker>
            </v-col>
          </v-row>
        </v-container>
        <v-container v-if="this.import_complete">
          <v-row
            v-if="this.import_success"
            class="text-center d-flex justify-center mb-6"
          >
            <v-alert dense type="success"> {{ import_message }}</v-alert>
          </v-row>
          <v-row
            v-if="!this.import_success"
            class="text-center d-flex justify-center mb-6"
          >
            <v-alert outlined dense type="error">{{ import_error }}</v-alert>
          </v-row>
        </v-container>
        <v-btn
          color="blue darken-1"
          variant="elevated"
          tile
          @click="
            dialog = false;
            import_complete = false;
          "
          aria-label="Close"
        >
          Close
        </v-btn>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import ProgressTracker from "@/components/ProgressTracker";

export default {
  name: "GeoJSONPostcodeImporter",
  data: () => ({
    file: null,
    dialog: false,
    import_complete: false,
    import_message: null,
    import_success: false,
    import_error: null,
    type_options: ["Import File", "Import from ONS"],
    type: null,
    postcode_import: {
      name: "geojson",
      puid: null,
      import_percentage: 0,
      results_expected: 0,
      results_final: 0,
      start: false,
    },
  }),
  components: {
    ProgressTracker,
  },
  props: {},
  computed: {},
  mounted() {},
  methods: {
    handleFile() {
      // set up tracker object
      this.postcode_import.puid = "postcode_import_" + Date.now();
      this.postcode_import.import_percentage = 0;
      this.postcode_import.results_expected = 0;
      this.postcode_import.results_final = 0;
      this.postcode_import.start = false;

      // set up import data
      let formData = new FormData();
      //formData.append("file", this.file);
      formData.append("puid", this.postcode_import.puid);
      formData.append("delete", "");

      // send to back end for processing
      this.busy = true;
      this.dialog = true;
      this.postcode_import.start = true;

      // make the call!
      this.$axios
        .post("/postcode-import-csv", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(
          function (response) {
            this.import_complete = true;
            this.busy = false;
            this.postcode_import.start = false;
            this.import_message = response.data.message;
            this.import_success = response.data.message;
            this.import_error = response.data.error;
            // handle success
            console.log(response);
          }.bind(this),
        )
        .catch((error) => {
          this.busy = false;
          this.dialog = false;
          this.postcode_import.start = false;
          this.emit.emit("systemMessage", error.response.data.message);
        });
    },
  },
  watch: {},
};
</script>

<style scoped></style>
