<template>
  <v-card height="100%" flat rounded="0">
    <v-card
      flat
      :style="'height: ' + (height - 190) + 'px; overflow: auto'"
      id="sidePanel"
      rounded="0"
    >
      <v-container class="text-left px-5 py-3 text-h6"> Map </v-container>
      <v-divider></v-divider>

      <v-card class="px-4 pt-4 pb-0" flat>
        <v-card-actions class="pl-0 py-0">
          <!-- data display level dropdown -->
          <MapDataLevelSelect
            :selectedDataLevelId="viewInfo.viewportInfo.dataLevel"
            :lowestDataLevelId="viewInfo.indicatorInfo.lowest_show_level"
            :lockedView="lockedView"
            :loading="loadingIndicators"
            loadingStyle="skeleton"
            @selectedDataLevelChanged="
              (newLevel) => this.$emit('selectedDataLevelChanged', newLevel)
            "
            @lockViewChanged="(state) => this.$emit('lockViewChanged', state)"
          ></MapDataLevelSelect>
        </v-card-actions>
        <v-divider />

        <!-- Hovered area -->
        <div class="text-start py-3" style="height: 73px">
          <b>{{ levelNameAbr }} </b>
          {{ areaMouseOverInfo?.area_name || "None" }}
        </div>
        <v-divider />

        <!-- Loading indicators -->
        <v-card-text v-if="loadingIndicators" class="pa-0">
          <v-skeleton-loader
            v-for="n in 3"
            :key="n"
            type="list-item-two-line"
            class="py-2 pb-0 w-100"
          />
        </v-card-text>

        <!-- Indicators -->
        <v-card-text v-else class="pa-0 pt-1">
          <div
            v-for="indicator in selectedIndicators"
            :key="indicator.id"
            class="text-start py-2"
          >
            <div>
              <b> {{ indicator.indicator_name }} </b>
            </div>
            <!-- Value -->
            <v-spacer></v-spacer>
            <div class="text-button">
              <b>Value: </b>
              {{
                getAreaValue(
                  indicator,
                  areaMouseOverInfo[indicator.id]?.value ?? "-",
                )
              }}
            </div>
            <v-divider class="mt-2" />
          </div>
        </v-card-text>

        <!--  Display data switcher  -->
        <v-card-actions class="displayDataSwitcher pa-0 pb-2">
          <v-switch
            v-model="dataVisToggleState"
            @change="
              this.$emit('showDataOutsideBoundaries', dataVisToggleState)
            "
            color="black"
            class="text-left text-black opacity-100"
            label="Display data visualisations outside area boundaries"
          ></v-switch>
        </v-card-actions>
      </v-card>
      <v-divider></v-divider>

      <!-- Areas of interest / Neighbourhoods of Interest -->
      <v-card
        class="pa-4"
        flat
        max-height="60px"
        style="display: flex; align-items: center"
      >
        <span class="subheader title">Neighbourhoods of Interest</span>
        <v-spacer />
        <span>
          <v-icon
            aria-label="edit neighbourhoods of interest"
            class="ml-2"
            icon="mdi-pencil"
            @click.prevent="closeMap"
          />
        </span>
      </v-card>
      <v-divider></v-divider>

      <!-- Expansion panels -->
      <v-card flat>
        <!-- search box  -->
        <v-container class="pt-0 px-0 pb-2 searchAreasOfInterest">
          <v-autocomplete
            v-model="selectedAreaSearch"
            v-model:search="search"
            :loading="searchingForAreas"
            :items="searchResults"
            item-title="area_name"
            :disabled="loadingAreasOfInterest"
            item-value="id"
            :no-data-text="searchingForAreas ? 'Searching...' : 'No results'"
            label="Search neighbourhoods"
            placeholder="Type lsoa name, area code or value of cni or imd"
            :custom-filter="customFilter"
            return-object
            clearable
            autocomplete="off"
          >
            <template v-slot:item="{ props, item }">
              <v-list-item class="text-left" v-bind="props">
                <template v-slot:append>
                  <div
                    v-if="item.raw.is_double_disadvantage"
                    style="background-color: #d6d1fa; border-radius: 20px"
                    class="px-2 py-0"
                  >
                    Double Disadvantaged
                  </div>
                </template>
              </v-list-item>
            </template>
          </v-autocomplete>
        </v-container>
        <!-- Double disadvantaged areas / Doubly disadvantaged neighbourhoods -->
        <button class="collapsible">
          <span class="collapsible-content">
            Doubly disadvantaged neighbourhoods
            {{
              areasOfInterest.disadvantaged?.length
                ? ` (${areasOfInterest.disadvantaged.length})`
                : ""
            }}
          </span>
        </button>
        <div
          v-if="
            loadingAreasOfInterest || areasOfInterest.disadvantaged?.length > 0
          "
          class="content"
          id="disadvantagedAreasList"
          @scroll="lazyLoading($event, 'disadvantaged')"
        >
          <!-- Loading  -->
          <v-container v-if="loadingAreasOfInterest" class="pa-0">
            <v-skeleton-loader
              v-for="n in 3"
              :key="n"
              class="mx-auto border"
              width="100%"
              type="list-item-three-line"
            ></v-skeleton-loader>
          </v-container>
          <v-card
            v-else
            v-for="area in areasOfInterest.disadvantaged"
            flat
            :key="area.id"
            class="pa-0"
            :id="`area-${area.id}`"
            min-height="80px"
            :class="{ highlightedArea: area.selected }"
            elevation="0"
            rounded="0"
          >
            <v-card-actions
              :style="this.$vuetify.display.smAndDown ? 'grid-gap: 0;' : ''"
            >
              <v-card-actions
                class="pa-0 mr-1 areaOrInterestCheckbox"
                style="min-width: 15%"
              >
                <v-progress-circular
                  v-if="area.loading"
                  color="primary"
                  size="25"
                  class="ml-2 mb-1"
                  style="margin-right: 7px"
                  indeterminate
                />
                <v-checkbox
                  v-else
                  v-model="area.selected"
                  @change.prevent="toggleArea(area)"
                  color="primary"
                  :aria-label="`select ${area.area_name}`"
                />
              </v-card-actions>
              <v-card-actions
                class="px-0 py-2 text-left"
                :style="`width: ${this.$vuetify.display.mdAndDown ? '70%' : '75%'}`"
              >
                <div style="width: 100%">
                  {{ area.area_name }}
                  <div class="d-flex flex-row mt-2" style="width: 100%">
                    <div class="flex-row" style="width: 50%">
                      <v-icon color="red" class="mr-2"> mdi-square </v-icon>
                      <b>CNI: </b> {{ area.cni_value }}
                    </div>
                    <div class="flex-row" style="width: 50%">
                      <v-icon :color="area.cni_value_colour" class="mr-2">
                        mdi-square
                      </v-icon>
                      <b>IoD: </b> {{ area.imd_value }}
                    </div>
                  </div>
                </div>
              </v-card-actions>
              <v-spacer />
              <v-card-actions class="pa-0 mr-3">
                <v-icon
                  v-if="area.selected"
                  :disabled="area.loading"
                  :aria-label="`zoom to ${area.area_name}`"
                  icon="mdi-magnify-plus-outline"
                  @click="zoomToAreaOfInterest(area.id)"
                />
              </v-card-actions>
            </v-card-actions>
            <v-divider />
          </v-card>
        </div>
        <!-- no areas  -->
        <div v-else class="content" style="height: 70px !important">
          <div class="mt-3">No doubly disadvantaged neighbourhoods found</div>
        </div>

        <!-- Other areas / Other neighbourhoods -->
        <button class="collapsible">
          <span class="collapsible-content">
            Other neighbourhoods
            {{
              areasOfInterest.others?.length
                ? ` (${areasOfInterest.others.length})`
                : ""
            }}
          </span>
        </button>
        <div
          v-if="loadingAreasOfInterest || areasOfInterest.others?.length > 0"
          class="content"
          id="otherAreasList"
          @scroll="lazyLoading($event, 'others')"
        >
          <!-- Loading  -->
          <v-container v-if="loadingAreasOfInterest" class="pa-0">
            <v-skeleton-loader
              v-for="n in 3"
              :key="n"
              class="mx-auto border"
              width="100%"
              type="list-item-three-line"
            ></v-skeleton-loader>
          </v-container>
          <v-card
            v-else
            v-for="area in areasOfInterest.others"
            flat
            :key="area.id"
            :id="`area-${area.id}`"
            class="pa-0"
            min-height="80px"
            :class="{ highlightedArea: area.selected }"
            elevation="0"
            rounded="0"
          >
            <v-card-actions
              :style="this.$vuetify.display.smAndDown ? 'grid-gap: 0;' : ''"
            >
              <v-card-actions
                class="pa-0 mr-1 areaOrInterestCheckbox"
                style="min-width: 15%"
              >
                <v-progress-circular
                  v-if="area.loading"
                  color="primary"
                  size="25"
                  class="ml-2 mb-1"
                  style="margin-right: 7px"
                  indeterminate
                />
                <v-checkbox
                  v-else
                  v-model="area.selected"
                  @change.prevent="toggleArea(area)"
                  color="primary"
                  :aria-label="`select ${area.area_name}`"
                />
              </v-card-actions>
              <v-card-actions
                class="px-0 py-2 text-left"
                :style="`width: ${this.$vuetify.display.mdAndDown ? '70%' : '75%'}`"
              >
                <div style="width: 100%">
                  {{ area.area_name }}
                  <div class="d-flex flex-row mt-2" style="width: 100%">
                    <div class="flex-row" style="width: 50%">
                      <v-icon :color="area.cni_value_colour" class="mr-2">
                        mdi-square
                      </v-icon>
                      <b>CNI: </b> {{ area.cni_value }}
                    </div>
                    <div class="flex-row" style="width: 50%">
                      <v-icon :color="area.imd_value_colour" class="mr-2">
                        mdi-square
                      </v-icon>
                      <b>IoD: </b> {{ area.imd_value }}
                    </div>
                  </div>
                </div>
              </v-card-actions>
              <v-spacer />
              <v-card-actions class="pa-0 mr-3">
                <v-icon
                  v-if="area.selected"
                  :disabled="area.loading"
                  :aria-label="`zoom to ${area.area_name}`"
                  icon="mdi-magnify-plus-outline"
                  @click="zoomToAreaOfInterest(area.id)"
                />
              </v-card-actions>
            </v-card-actions>
            <v-divider />
          </v-card>
        </div>
        <!-- no areas  -->
        <div v-else class="content" style="height: 70px !important">
          <div class="mt-3">No areas found</div>
        </div>
      </v-card>
      <v-divider />
    </v-card>
    <v-divider />

    <!-- View summary report -->
    <v-card class="pa-4 text-center" rounded="0">
      <div class="truncateLogo" id="truncateLogo">
        {{
          !countSelectedAreasOfInterest
            ? "Select at least one neighbourhood to access the report"
            : `Selected neighbourhoods: ${countSelectedAreasOfInterest}`
        }}
      </div>

      <v-btn
        variant="elevated"
        :disabled="!countSelectedAreasOfInterest"
        color="success"
        aria-label="view summary report"
        :width="this.$vuetify.display.mdAndUp ? '80%' : '100%'"
        class="mt-2"
        tile
        @click="reportDialog = true"
        >view summary report
      </v-btn>

      <v-btn
        variant="text"
        :disabled="!countSelectedAreasOfInterest"
        color="error"
        aria-label="clear selection"
        :width="this.$vuetify.display.mdAndUp ? '80%' : '100%'"
        class="mt-2 font-weight-bold"
        tile
        @click="showClearDialog = true"
        >clear selection
      </v-btn>
    </v-card>

    <!-- Report Dialog -->
    <DiscoveryToolReportDialog
      ref="reportDialog"
      :show="reportDialog"
      @update:showDTReportDialog="(state) => (reportDialog = state)"
      :geographies="this.selectedLAorCLA"
      :areas="this.selectedAreaIds"
      :indicators="this.indicators.map((i) => i.id)"
    ></DiscoveryToolReportDialog>

    <!-- clear selection dialog -->
    <DynamicDialog
      :show="showClearDialog"
      @dialogOk="clearSelection()"
      @update:showDynamicDialog="(state) => (showClearDialog = state)"
      max-width="500"
      okBtnColor="error"
      cancelBtnColor="none"
    >
      <template v-slot:title>Clear Selection</template>
      <template v-slot:content>
        <p class="pb-4">
          Are you sure you want to clear all selected LSOAs? This action cannot
          be undone.
        </p>
      </template>
      <template v-slot:okBtnTitle>CLEAR SELECTION</template>
    </DynamicDialog>
  </v-card>
</template>

<script>
import { useDisplay } from "vuetify";
// import DiscoveryToolSelectIndicator from "@/components/DiscoveryToolSelectIndicator.vue";
import { formatDataValue } from "@/mixins/FormatDataValue";
import MapDataLevelSelect from "@/components/MapDataLevelSelect.vue";
import DiscoveryToolReportDialog from "@/components/DiscoveryToolReportDialog.vue";
import DynamicDialog from "@/components/DynamicDialog.vue";

export default {
  name: "DiscoveryToolMapSidebar",
  components: {
    // DiscoveryToolSelectIndicator,
    MapDataLevelSelect,
    DiscoveryToolReportDialog,
    DynamicDialog,
  },
  data: () => ({
    height: useDisplay().height,
    selectedLAorCLA: [],
    indicatorDialog: false,
    lockView: false,
    value: null,
    loadingAreasOfInterest: false,
    fetchingMoreAreasOfInterest: false,
    search: "",
    searchingForAreas: false,
    selectedAreaSearch: null,
    searchResults: [],
    searchIteration: null,
    dataVisToggleState: false,
    reportDialog: false,
    showClearDialog: false,
  }),
  emits: [
    "closeMap",
    "changeIndicator",
    "zoomToAreaOfInterest",
    "showDataOutsideBoundaries",
    "lockViewChanged",
    "selectedAreasChanged",
    "getAreaOfInterestGeometries",
    "displayAreaOfInterestOnMaps",
    "hideAreaOfInterestOnMaps",
  ],
  mounted() {
    this.getAreasOfInterest();
    this.setExpansionPanels();
  },
  props: {
    viewInfo: {
      type: Object,
      default: () => ({}),
    },
    areaMouseOverInfo: {
      type: Object,
      default: () => ({}),
    },
    colourScheme: {
      type: Array,
      default: () => [],
    },
    lockedView: {
      type: Boolean,
      default: false,
    },
    indicators: {
      type: Array,
      default: () => [],
    },
    loadingIndicators: {
      type: Boolean,
      default: false,
    },
    selectedIndicatorsProp: {
      type: Array,
      default: () => [],
    },
    showDataOutsideBoundaries: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    /*isEllipsisActive: {
      get() {
        let element = document.getElementById("truncateLogo");

        if (element) {
          return element.offsetWidth < element.scrollWidth;
        }

        return false;
      },
    },*/
    areasOfInterest: {
      get() {
        return this.$store.state.areasOfInterest;
      },
      set(value) {
        this.$store.commit("setAreasOfInterest", value);
      },
    },
    levelNameAbr() {
      if (!this.viewInfo.viewportInfo.dataLevel) {
        return "Unknown";
      } else {
        const level = this.$store.state.config.siteConfig.area_level_names.find(
          (item) => item.area_level === this.viewInfo.viewportInfo.dataLevel,
        );
        return level ? level.area_level_name_abr : "Unknown";
      }
    },
    selectedIndicators: {
      get() {
        return this.selectedIndicatorsProp;
      },
      set(val) {
        this.$emit("update:selectedIndicatorsProp", val);
      },
    },
    countSelectedAreasOfInterest() {
      return [
        ...this.areasOfInterest["disadvantaged"],
        ...this.areasOfInterest["others"],
      ].filter((area) => area.selected).length;
    },
    selectedAreaIds() {
      return [
        ...this.areasOfInterest["disadvantaged"],
        ...this.areasOfInterest["others"],
      ]
        .filter((area) => area.selected)
        .map((filtered) => filtered.id);
    },
  },
  methods: {
    getAreaValue(indicator, value) {
      if (value === null || value === undefined) {
        return "-";
      }

      // round it to the decimal places
      value = formatDataValue(value, indicator.decimal_places);

      if (indicator.suffix === "%" && value > 100) {
        // catch anything over 100%
        value = 100;
      }

      const prefix = indicator.prefix || "";
      const suffix = indicator.suffix || "";

      // send it back with any prefix or suffix
      return prefix + value + suffix;
    },
    getAreasOfInterest() {
      if (this.loadingAreasOfInterest) return;

      this.loadingAreasOfInterest = true;

      this.selectedLAorCLA = this.$route.params.parentAreaIDs.split("-");

      this.$axios
        .post("/get-areas-of-interest-discovery-tool", {
          parent_area_ids: this.selectedLAorCLA,
        })
        .then((response) => {
          // handle success
          this.areasOfInterest.others = response.data.others;
          this.areasOfInterest.disadvantaged = response.data.disadvantaged;
        })
        .catch((error) => {
          // handle error
          console.error(error);
          this.emit.emit("systemMessage", {
            message: error.response.data.message,
            title: "Failed to fetch neighbourhoods of interest",
            timeout: -1,
            colour: "error",
          });
        })
        .finally(() => {
          this.loadingAreasOfInterest = false;
        });
    },
    getPaginatedAreasOfInterest(type) {
      if (this.fetchingMoreAreasOfInterest) return;

      this.fetchingMoreAreasOfInterest = true;

      // get existing area IDs so we don't fetch them again
      let ids = [];
      this.areasOfInterest[type].forEach((item) => {
        ids.push(item.id);
      });

      this.$axios
        .post("/get-paginated-areas-of-interest-discovery-tool", {
          parent_area_ids: this.selectedLAorCLA,
          existing_area_ids: ids,
          type: type,
        })
        .then((response) => {
          // handle success
          let type = response.data.type ?? null;
          let areas = response.data.areas;

          // add more areas
          areas.forEach((area) => {
            this.areasOfInterest[type].push(area);
          });
        })
        .catch((error) => {
          // handle error
          console.error(error);
          this.emit.emit("systemMessage", {
            message: error.response.data.message,
            title: "Failed to fetch more neighbourhoods of interest",
            timeout: -1,
            colour: "error",
          });
        })
        .finally(() => {
          this.fetchingMoreAreasOfInterest = false;
        });
    },
    zoomToAreaOfInterest(areaID) {
      this.$emit("zoomToAreaOfInterest", areaID);
    },
    setExpansionPanels() {
      var coll = document.getElementsByClassName("collapsible");
      var i;

      for (i = 0; i < coll.length; i++) {
        coll[i].addEventListener("click", function () {
          this.classList.toggle("active");
          var content = this.nextElementSibling;
          if (content.style.maxHeight) {
            content.style.maxHeight = null;
          } else {
            content.style.maxHeight = "25vh";
          }
        });
      }

      // Simulate a click to open the panel
      setTimeout(() => {
        for (let j = 0; j < coll.length; j++) {
          this.openPanel(j);
        }
      }, 2000);
    },
    openPanel(index) {
      var coll = document.getElementsByClassName("collapsible");
      if (index >= 0 && index < coll.length) {
        coll[index].click();
      }
    },
    closeMap() {
      // clear these
      this.$emit("closeMap");
      this.areasOfInterest = {
        disadvantaged: [],
        others: [],
      };
      this.loadingAreasOfInterest = false;
      this.fetchingMoreAreasOfInterest = false;
    },
    toggleArea(area) {
      if (area.selected) {
        this.$emit("displayAreaOfInterestOnMaps", area);
      } else {
        this.$emit("hideAreaOfInterestOnMaps", area.id);
      }

      //reset the report dialog flags to force regeneration of report
      this.$refs.reportDialog.selectedAreasChanged();
    },
    lazyLoading: function (event, type) {
      if (
        event.target.offsetHeight + event.target.scrollTop >=
        event.target.scrollHeight - 5
      ) {
        this.getPaginatedAreasOfInterest(type);
      }
    },
    searchAreasOfInterest() {
      // to pass the special characters through the URL we need to encode string
      let searchString = encodeURIComponent(this.search.replace("%", "\\%"));

      if (searchString) {
        this.searchingForAreas = true;
        this.$axios
          .post("/search-areas-of-interest-by-name-or-value/" + searchString, {
            parent_area_ids: this.selectedLAorCLA,
          })
          .then((response) => {
            // handle success
            this.searchResults = response.data;
          })
          .catch((error) => {
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Failed to search neighbourhoods of interest",
              timeout: -1,
              colour: "error",
            });
          })
          .finally(() => {
            this.searchingForAreas = false;
          });
      }
    },
    customFilter(lsoaName, queryText, item) {
      const searchLower = queryText.toLowerCase();
      return (
        item.raw.area_code.toLowerCase().includes(searchLower) ||
        item.raw.area_name.toLowerCase().includes(searchLower) ||
        item.raw.cni_value
          .toString()
          .replaceAll(",", "")
          .includes(searchLower) ||
        item.raw.imd_value.toString().replaceAll(",", "").includes(searchLower)
      );
    },
    scrollToArea(area, type) {
      // get the correct list ID
      let listID =
        type === "disadvantaged" ? "disadvantagedAreasList" : "otherAreasList";

      // get the list element and the area element
      let areaElement = document.getElementById(`area-${area.id}`);
      let listElement = document.getElementById(listID);
      let sidePanel = document.getElementById("sidePanel"); // main sidebar

      if (sidePanel && listElement && areaElement) {
        // Calculate the correct offset for the sidebar
        let sidebarOffset =
          listElement.getBoundingClientRect().top -
          sidePanel.getBoundingClientRect().top +
          sidePanel.scrollTop -
          sidePanel.clientHeight / 2 +
          listElement.clientHeight / 2;

        // Scroll the sidebar to the list element smoothly and center it in the viewport
        sidePanel.scrollTo({
          top: sidebarOffset,
          behavior: "smooth",
        });

        // Scroll the list element to the area element smoothly and center it in the viewport
        listElement.scrollTo({
          top:
            areaElement.offsetTop -
            listElement.offsetTop -
            listElement.clientHeight / 2 +
            areaElement.clientHeight / 2 -
            10, // Adjusted offset
          behavior: "smooth",
        });
      }
    },
    clearSelection() {
      this.areasOfInterest.disadvantaged.forEach((area) => {
        area.selected = false;
        this.$emit("hideAreaOfInterestOnMaps", area.id);
      });
      this.areasOfInterest.others.forEach((area) => {
        area.selected = false;
        this.$emit("hideAreaOfInterestOnMaps", area.id);
      });
      this.showClearDialog = false;
    },
  },
  watch: {
    // selectedIndicators() {
    // if (val !== oldVal) {
    // this.$emit("changeIndicator", val);
    // }
    //reset the report dialog flags to force regeneration of report
    // this.$refs.reportDialog.selectedIndicatorChanged();
    // },
    showDataOutsideBoundaries: {
      handler() {
        this.dataVisToggleState = this.showDataOutsideBoundaries;
      },
      immediate: true,
    },
    search(val, oldVal) {
      if (val && val !== oldVal) {
        clearTimeout(this.searchIteration);

        this.searchIteration = setTimeout(() => {
          this.searchAreasOfInterest();
        }, 500);
      }
    },
    selectedAreaSearch(val) {
      if (!val) return;

      // add the selected item to the list if it's not already in there
      let type = val.is_double_disadvantage ? "disadvantaged" : "others";
      let area = this.areasOfInterest[type].find((a) => a.id === val.id);

      // do we have this area already fetched or it's a new one
      if (!area) {
        area = JSON.parse(JSON.stringify(val));
        this.areasOfInterest[type].push(area);
      }

      // set selected to true
      this.areasOfInterest[type].forEach((a) => {
        if (a.id === area.id) {
          a.selected = true;
        }
      });

      this.$emit("displayAreaOfInterestOnMaps", area, true);

      // Scroll to the area in the list
      this.scrollToArea(area, type);

      this.$refs.reportDialog.selectedAreasChanged();
    },
  },
};
</script>

<style scoped>
.subheader {
  font-size: 20px;
  font-weight: 520;
}

.truncateLogo {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title {
  font-size: 18px;
  font-weight: 600;
}

.active,
.collapsible:hover {
  background-color: #e1e1e1;
}

.collapsible-content {
  flex-grow: 1;
  margin-right: 10px; /* Adjust as needed */
}

.collapsible {
  background-color: #f1f1f1;
  color: black;
  cursor: pointer;
  padding: 18px 25px 18px 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  font-weight: 600;
  min-height: 50px;
  transition: min-height 0.2s ease-out;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.collapsible.active {
  min-height: 65px; /* Increase height by 5px when active */
}

.collapsible:after {
  float: right;
  content: "\25bc"; /* mdi-chevron-down */
}

.collapsible.active:after {
  content: "\25b2"; /* mdi-chevron-up */
}

.content {
  padding: 0;
  transition: max-height 0.2s ease-out;
  max-height: 0;
  overflow-y: auto;
}
</style>

<style>
/* remove extra underneath some fields  */
.displayDataSwitcher .v-input__details,
.areaOrInterestCheckbox .v-input__details,
.searchAreasOfInterest .v-input__details,
.levelDropdown .v-input__details {
  display: none;
}

.expandAreas .v-expansion-panel-text__wrapper {
  padding: 0;
}

.expandAreas .v-expansion-panel {
  background-color: #f1f1f1;
}

.expandAreas .v-expansion-panel-text__wrapper {
  background-color: white;
}

button.v-expansion-panel-title {
  font-weight: 600;
}

.highlightedArea {
  background-color: #bfe1fb;
  transition: background-color 0.5s;
}

.v-label {
  opacity: 1 !important;
}
</style>
