function convertToCSV(objArray) {
  const array = typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
  let str = "";

  for (let i = 0; i < array.length; i++) {
    let line = "";
    for (let index in array[i]) {
      if (line != "") line += ",";

      // Escape quotes and commas
      array[i][index] = String(array[i][index]).replaceAll('"', '""');
      if (array[i][index].includes(",")) {
        array[i][index] = '"' + array[i][index] + '"';
      }

      line += array[i][index];
    }

    str += line + "\r\n";
  }

  return str;
}

function exportCSVFile(headers, items, fileTitle) {
  if (headers) {
    items.unshift(headers);
  }

  // Convert undefined and empty strings to maintain column alignment
  const replacer = (key, value) =>
    value === undefined || value === "" ? " " : value;

  // Convert Object to JSON
  let jsonObject = JSON.stringify(items, replacer);
  let csv = convertToCSV(jsonObject);

  let exportedFilename = fileTitle + ".csv" || "dashboard.csv";

  let blob = new Blob(["\ufeff" + csv], {
    type: "text/csv;charset=utf-8;",
  });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFilename);
  } else {
    let link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      let url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export { exportCSVFile };
