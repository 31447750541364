function addTargetToLinks(text, target = "_blank") {
  // accepts string of html and finds all links and adds target="_blank" to open them in a new tab
  if (!text) return text;
  // check if there's any links
  if (!text.includes("<a")) return text;
  let parser = new DOMParser();
  let doc = parser.parseFromString(text, "text/html");
  let links = doc.getElementsByTagName("a");
  for (let i = 0; i < links.length; i++) {
    links[i].setAttribute("target", target);
  }
  return doc.body.innerHTML;
}

export { addTargetToLinks };
