<template>
  <v-dialog v-model="dialog" scrollable persistent hide-overlay fullscreen>
    <template v-slot:activator="{ props }">
      <v-btn
        tile
        color="primary"
        v-bind="props"
        variant="elevated"
        @click="dialog = true"
        aria-label="default zoom level"
        append-icon="mdi-map"
        width="250px"
      >
        Default Map View
      </v-btn>
    </template>
    <v-card>
      <v-toolbar
        :color="this.$store.state.config.siteConfig.toolbar_colour"
        dark
        class="text-h5"
        max-height="64px"
      >
        <v-btn
          tile
          class="ml-2"
          variant="text"
          @click="dialog = false"
          aria-label="Close"
          icon="mdi-close"
        />
        <v-toolbar-title>Set Default Map View</v-toolbar-title>
      </v-toolbar>
      <v-card-text
        class="pa-0 no-scroll"
        style="overflow: hidden; height: 90vh"
      >
        <!-- the map container -->
        <div
          ref="mapCanvas"
          id="mapCanvas"
          class="map-canvas"
          style="width: 100%"
          :style="{ height: height - 63 + 'px' }"
        ></div>
        <v-card class="panel">
          <p class="mt-2">
            <b>Default Map View: </b> Set your optional map view settings
          </p>
          <v-card-subtitle class="pl-0 mt-3 mb-2"
            >Default zoom and center</v-card-subtitle
          >
          <v-row class="my-2">
            <v-col cols="6">
              <v-btn
                tile
                color="primary"
                block
                variant="elevated"
                @click="handleSetDefaultView"
                :disabled="settingDefaultView || resettingDefaultView"
                aria-label="Set Default"
                width="100%"
              >
                <template v-if="settingDefaultView">
                  <v-progress-circular
                    size="20"
                    color="white"
                    indeterminate
                  ></v-progress-circular>
                </template>
                <template v-else> Set Default</template>
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                tile
                variant="elevated"
                color="error"
                block
                @click="handleRemoveDefaultView"
                :disabled="computedDisabledReset || settingDefaultView"
                aria-label="Remove Default"
                width="100%"
              >
                <template v-if="resettingDefaultView">
                  <v-progress-circular
                    size="20"
                    color="white"
                    indeterminate
                  ></v-progress-circular>
                </template>
                <template v-else> Remove Default</template>
              </v-btn>
            </v-col>
          </v-row>
          <v-card-subtitle class="pl-0 mt-4">Group Boundary</v-card-subtitle>
          <v-row>
            <v-col cols="6">
              <div class="pb-2">
                <BoundaryAreaSelector
                  @updateBoundary="loadClientBoundary"
                ></BoundaryAreaSelector>
              </div>
            </v-col>
            <v-col cols="6">
              <div v-if="!loadingBoundary">
                <v-switch
                  label="Show Boundary"
                  v-model="boundarySwitch"
                  inset
                  :disabled="hasBoundary"
                  color="primary"
                  class="boundary-switch"
                ></v-switch>
              </div>
              <div v-else class="mt-3">
                <v-progress-circular
                  indeterminate
                  color="rgb(81, 98, 124)"
                  size="25"
                  class="mx-4"
                ></v-progress-circular>
                Loading Boundary...
              </div></v-col
            >
          </v-row>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
/* global google */

import { loadGoogleMaps } from "@/mixins/LoadGoogleMaps";
import BoundaryAreaSelector from "@/components/BoundaryAreaSelector.vue";
import { useDisplay } from "vuetify";
import { toRaw } from "vue";

// store map here so vue doesn't wrap it in a proxy - Google doesn't like that.
let map = null;

export default {
  data() {
    return {
      dialog: false,
      height: useDisplay().height,
      center: {},
      zoom: 6,
      settingDefaultView: false,
      hasDefaultView: false,
      resettingDefaultView: false,
      clientBoundary: [],
      boundarySwitch: false,
      loadingBoundary: false,
    };
  },
  components: {
    BoundaryAreaSelector,
  },
  computed: {
    getDefaultCenter() {
      return this.$store.state.config.siteConfig.initialMapZoom;
    },
    computedDisabledReset() {
      return !this.hasDefaultView || this.resettingDefaultView;
    },
    hasBoundary() {
      if (this.clientBoundary.length > 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    // Init the view to default for country
    this.center = this.getDefaultCenter;
  },
  props: {},
  methods: {
    centreToDefaultView() {
      // get the clients default view
      const defaultZoom = this.$store.getters.customClientConfig.default_zoom;
      const defaultLat = this.$store.getters.customClientConfig.default_lat;
      const defaultLng = this.$store.getters.customClientConfig.default_lng;

      // if they have them ALL, set the map up to that
      if (defaultZoom && defaultLat && defaultLng) {
        this.firstLoad = true;
        this.hasDefaultView = true;
        if (!isNaN(defaultLat) && !isNaN(defaultLng)) {
          map.setZoom(defaultZoom);
          map.setCenter({
            lat: parseFloat(defaultLat),
            lng: parseFloat(defaultLng),
          });
        } else {
          console.error("Invalid coordinates for default view");
        }
      }
    },
    handleRemoveDefaultView() {
      // call the remove defaults endpoint
      this.resettingDefaultView = true;

      this.$axios
        .put(
          "/reset-default-map-view/" +
            this.$store.getters.userProfile.client_id,
        )
        .then(
          function () {
            // handle success
            // set our local version while we're here
            this.zoom = 6;
            this.center = this.getDefaultCenter;
            this.hasDefaultView = false;
            this.resettingDefaultView = false;

            this.$store.getters.customClientConfig.default_zoom = 6;
            this.$store.getters.customClientConfig.default_lat =
              this.getDefaultCenter.lat;
            this.$store.getters.customClientConfig.default_lng =
              this.getDefaultCenter.lng;
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.resettingDefaultView = false;
          }.bind(this),
        );
    },
    handleSetDefaultView() {
      this.settingDefaultView = true;
      // get the current map centre & zoom level
      const center = map.getCenter();
      const zoom = map.getZoom();

      // break the centre down into lat & lng
      const centerLat = center.lat();
      const centerLng = center.lng();

      // save it to the database
      this.$axios
        .post("/set-default-map-view", {
          client_id: this.$store.getters.userProfile.client_id,
          default_lat: centerLat,
          default_lng: centerLng,
          default_zoom: zoom,
        })
        .then(
          function () {
            // handle success
            // set our local versions while we're here
            this.zoom = zoom;
            this.center = { lat: centerLat, lng: centerLng };
            this.settingDefaultView = false;
            this.hasDefaultView = true;

            // and update the store
            this.$store.getters.customClientConfig.default_zoom = zoom;
            this.$store.getters.customClientConfig.default_lat = centerLat;
            this.$store.getters.customClientConfig.default_lng = centerLng;
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.settingDefaultView = false;
          }.bind(this),
        );
    },
    closeDialog() {
      if (map) {
        map.setZoom(this.zoom);
        map.setCenter(this.center);
      }
    },
    async loadClientBoundary() {
      // first clear out anything that's there
      if (this.clientBoundary.length > 0) {
        this.clientBoundary.forEach((polygon) => {
          toRaw(polygon).setMap(null);
        });

        this.clientBoundary = [];
      }

      // get the clients boundary if they have one
      if (this.$store.getters.customClientConfig.has_boundary) {
        this.loadingBoundary = true;
        const { Polygon } = await google.maps.importLibrary("maps");

        this.$axios
          .get("/client-boundary")
          .then(
            function (response) {
              // build and show the polygon
              if (response.data.boundary) {
                const boundary = JSON.parse(response.data.boundary);

                // build an array of polygons, can be one, or many for a multiplygon
                let polygons = [];

                if (boundary.type === "Polygon") {
                  // Polygon
                  const googleMapsPaths = boundary.coordinates.map((ring) =>
                    ring.map((coord) => {
                      return { lat: coord[1], lng: coord[0] };
                    }),
                  );

                  polygons.push(
                    new Polygon({
                      map,
                      paths: googleMapsPaths,
                      fillOpacity: 0,
                      strokeWeight: 4,
                      strokeColor: response.data.boundary_colour,
                      zIndex: 10,
                      clickable: false,
                    }),
                  );
                  this.clientBoundary = polygons;
                } else {
                  // MultiPolygon
                  const multiCoordinates = boundary.coordinates;
                  multiCoordinates.forEach((coords) => {
                    const googleMapsPaths = coords.map((coord) => {
                      return coord.map((subCoord) => {
                        return { lat: subCoord[1], lng: subCoord[0] };
                      });
                    });

                    polygons.push(
                      new Polygon({
                        map,
                        paths: googleMapsPaths,
                        fillOpacity: 0,
                        strokeWeight: 4,
                        strokeColor: response.data.boundary_colour,
                        zIndex: 10,
                        clickable: false,
                      }),
                    );
                    this.clientBoundary = polygons;
                  });
                }
              }
              this.boundaryFlag = true;
              this.loadingBoundary = false;
            }.bind(this),
          )
          .catch(
            function (error) {
              // handle error
              console.error(error);
              this.loadingBoundary = false;
            }.bind(this),
          );
      }
    },
  },
  watch: {
    dialog: {
      handler(val) {
        if (val) {
          // load map
          loadGoogleMaps(this.center, this.zoom).then((loadedMap) => {
            map = loadedMap;
            this.loadClientBoundary();

            // zoom the map to the default view if they have one
            this.centreToDefaultView();
          });
        } else {
          this.closeDialog();
        }
      },
    },
    clientBoundary: function (newVal) {
      console.log("boundary loaded");
      if (newVal !== null) {
        // we've loaded one. Set the switch to true
        this.boundarySwitch = true;
      }
    },
    boundarySwitch: function (newVal) {
      // set the map of the polygon according to the switch
      if (newVal) {
        this.clientBoundary.forEach((polygon) => {
          toRaw(polygon).setMap(map);
        });
      } else {
        this.clientBoundary.forEach((polygon) => {
          toRaw(polygon).setMap(null);
        });
      }
    },
  },
};
</script>
<style scoped>
.panel {
  position: absolute !important;
  top: 75px;
  right: 7px;
  margin-right: 5px;
  z-index: 1;
  width: 500px;
  padding: 10px 10px 0 10px;
}
</style>
