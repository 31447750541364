<template>
  <div style="display: contents">
    <v-card-actions class="readText"
      >Using the highest and lowest figures for each indicator in your selected
      areas, three equal value bands are created and displayed on the dashboard
      below
    </v-card-actions>
    <v-spacer />
    <v-card-actions class="mr-2">
      <div
        class="colorSquare"
        :style="`background-color: #${clientColourScheme[4]}`"
        aria-label="Colour for high equal interval band"
      >
        <v-icon
          icon="mdi-arrow-up-bold"
          class="accessibility-icon"
          v-if="$store.state.config.userProfile.show_icons_on_dashboard"
          left
          size="small"
          :color="isDarkColor(clientColourScheme[4]) ? '#FFFFFF' : '#000000'"
        />
      </div>
      <span class="mr-2 readText">HIGH</span>
      <div
        class="colorSquare"
        :style="`background-color: #${clientColourScheme[2]}`"
        aria-label="Colour for medium equal interval band"
      >
        <v-icon
          icon="mdi-format-align-middle"
          class="accessibility-icon"
          v-if="$store.state.config.userProfile.show_icons_on_dashboard"
          left
          size="small"
          :color="isDarkColor(clientColourScheme[2]) ? '#FFFFFF' : '#000000'"
        />
      </div>
      <span class="mr-2 readText">MEDIUM</span>
      <div
        class="colorSquare"
        :style="`background-color: #${clientColourScheme[0]}`"
        aria-label="Colour for low equal interval band"
      >
        <v-icon
          icon="mdi-arrow-down-bold"
          class="accessibility-icon"
          v-if="$store.state.config.userProfile.show_icons_on_dashboard"
          left
          size="small"
          :color="isDarkColor(clientColourScheme[0]) ? '#FFFFFF' : '#000000'"
        />
      </div>
      <span class="mr-2 readText">LOW</span>
    </v-card-actions>
  </div>
</template>
<script>
export default {
  name: "DashboardLegend",
  props: {
    clientColourScheme: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    isDarkColor(color_hex) {
      if (typeof color_hex !== "undefined")
        return this.getColorBrightness(color_hex) < 127;
    },
    getColorBrightness(color_hex) {
      let amount_of_red = parseInt(color_hex.substr(0, 2), 16);
      let amount_of_green = parseInt(color_hex.substr(2, 2), 16);
      let amount_of_blue = parseInt(color_hex.substr(4, 2), 16);
      return (
        (amount_of_red * 299 + amount_of_green * 587 + amount_of_blue * 114) /
        1000
      );
    },
  },
};
</script>
<style scoped>
.colorSquare {
  border-radius: 7px;
  width: 30px;
  height: 30px;
}

.readText {
  color: rgba(0, 0, 0, 0.7);
}
</style>
