async function copyToClipboard(text) {
  if (navigator.clipboard) {
    try {
      await navigator.clipboard.writeText(text);
      // clipboard successfully set
      return true;
    } catch (err) {
      // clipboard write failed
      return false;
    }
  } else {
    // Fallback for browsers that don't support the Clipboard API
    try {
      let textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      // clipboard successfully set
      document.body.removeChild(textarea);
      return true;
    } catch (err) {
      // clipboard write failed
      return false;
    }
  }
}

export { copyToClipboard };
