/** vuex store for datastore module  */
export const datastore = {
  namespaced: true,
  /** state (data) */
  state: () => ({
    apiKeys: [],
    selectedIndicator: null,
  }),
  /** setters */
  mutations: {
    setApiKeys(state, value) {
      state.apiKeys = value;
    },
    setSelectedIndicator(state, value) {
      state.selectedIndicator = value;
    },
  },
  /** getters */
  getters: {
    apiKeys: (state) => state.apiKeys,
    activeApiKeys(state) {
      return state.apiKeys.filter(
        (key) => key.status.toLowerCase() == "active",
      );
    },
    selectedIndicator: (state) => state.selectedIndicator,
  },
};
