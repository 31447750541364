<template>
  <v-dialog
    v-model="serviceIssuesDialogue"
    scrollable
    persistent
    max-width="800px"
  >
    <v-card min-height="90vh">
      <v-toolbar
        :color="this.$store.state.config.siteConfig.toolbar_colour"
        dark
        tile
        class="text-h5 text-start"
        max-height="64px"
        style="position: sticky; top: 0; z-index: 2"
      >
        <v-btn
          style="position: absolute"
          icon
          dark
          @click="serviceIssuesDialogue = false"
          aria-label="Close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="ml-14"> Details </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="removeScrollbar">
        <v-card-actions>
          <v-alert
            v-if="importedCount"
            variant="tonal"
            color="success"
            class="mt-2"
            style="border-radius: 10px; width: 100%"
          >
            <v-row style="padding: 10px">
              <v-col>
                <b>{{ importedCount }}</b> services were imported and saved
                successfully.
              </v-col>
            </v-row>
          </v-alert>
        </v-card-actions>
        <v-card-actions>
          <!--       show user a text warning    -->
          <v-alert
            text
            color="error"
            width="100%"
            class="mt-3 mb-0"
            variant="outlined"
            border="start"
          >
            <v-row align="center" no-gutters>
              <v-col cols="11">
                <v-icon class="mr-2" color="error">mdi-alert</v-icon>
                <b> Not all your data was imported </b>
              </v-col>
            </v-row>
          </v-alert>
        </v-card-actions>
        <v-card-actions class="pb-0 mt-0">
          <v-card-text style="font-size: 17px" class="pt-0">
            <b>Details:</b> we saved all your data except for the following,
            some {{ aus ? "addresses" : "postcodes" }} were not recognised or
            they are beyond your country. You can download the file below and
            fix the issues then import that file again.
          </v-card-text>
        </v-card-actions>
        <v-card-actions class="pt-0" tile elevation="0">
          <v-container>
            <v-menu offset-y>
              <template v-slot:activator="{}">
                <v-data-table
                  mobile-breakpoint="0"
                  :headers="headers"
                  :items="unmatchedPostcodes"
                  :items-per-page="10"
                  class="elevation-1 text-h6"
                  style="max-width: 90%; margin: auto"
                  height="400"
                >
                  <template v-slot:item="{ item }">
                    <tr>
                      <td
                        v-for="(header, index) in headers"
                        :key="index"
                        class="tooltip-content"
                      >
                        <v-tooltip
                          v-if="item.beyondCountry"
                          activator="parent"
                          location="bottom"
                        >
                          <template v-slot:activator="{ on }">
                            <div v-bind="on">
                              <span class="text-error">
                                {{ item[header.value] }}
                              </span>
                            </div>
                          </template>
                          <span
                            >{{ aus ? "Address" : "Postcode" }} is beyond your
                            country</span
                          >
                        </v-tooltip>
                        <v-tooltip v-else activator="parent" location="bottom">
                          <template v-slot:activator="{ on }">
                            <div v-bind="on">
                              <span class="text-error">
                                {{ item[header.value] }}
                              </span>
                            </div>
                          </template>
                          <span
                            >Unidentified
                            {{ aus ? "Address" : "Postcode" }}</span
                          >
                        </v-tooltip>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </template>
            </v-menu>
          </v-container>
        </v-card-actions>
      </v-card-text>
      <v-card-actions style="z-index: 2" class="pb-4">
        <v-btn
          color="error"
          rounded="0"
          variant="elevated"
          @click="serviceIssuesDialogue = false"
          aria-label="close"
        >
          close
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          rounded="0"
          variant="elevated"
          @click="downloadUnmatched"
          aria-label="download errors"
        >
          download errors
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { exportCSVFile } from "@/mixins/ExportCSVFile";

export default {
  name: "ImportServiceIssues",
  data: () => ({
    headers: [],
  }),
  mounted() {
    this.headers = this.aus
      ? [
          { title: "Service", value: "service", sortable: false },
          { title: "Latitude", value: "lat", sortable: false },
          { title: "Longitude", value: "lng", sortable: false },
        ]
      : [{ title: "Service", value: "service", sortable: false }];
  },
  methods: {
    downloadUnmatched() {
      // build the csv
      var month = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      let headers = this.aus
        ? ["Service", "Latitude", "Longitude", "Error", "Type"]
        : ["Service", "Error", "Type"];

      // Collect unique labels from all objects in unmatched_postcodes
      var uniqueLabels = new Set();
      this.unmatchedPostcodes.forEach(function (entry) {
        for (var label in entry.labels) {
          uniqueLabels.add(label);
        }
      });

      // Convert the unique labels to an array and add them as headers
      var labelHeaders = Array.from(uniqueLabels);
      headers = headers.concat(labelHeaders);

      var data = [];

      // Populate data array with values from unmatched_postcodes
      this.unmatchedPostcodes.forEach((entry) => {
        let rowData = [
          entry.service,
          ...(this.aus ? [entry.lat, entry.lng] : []),
          entry.beyondCountry
            ? this.aus
              ? "Address beyond your country"
              : "Postcode beyond your country"
            : this.aus
              ? "Unidentified address"
              : "Unidentified postcode",
          entry.type,
        ];

        // Add label values to the rowData
        labelHeaders.forEach(function (label) {
          rowData.push(entry.labels[label] || ""); // Use an empty string if label doesn't exist
        });

        data.push(rowData);
      });

      var d = new Date();
      var filename =
        "Errors_service_export_" +
        d.getDate() +
        "_" +
        month[d.getMonth()] +
        "_" +
        d.getFullYear();

      exportCSVFile(headers, data, filename);
    },
  },
  props: {
    unmatchedPostcodes: {
      type: Array,
      default: () => [],
    },
    importedCount: {
      type: Number,
      default: 0,
    },
    dialog: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    aus() {
      return this.$store.state.config.siteConfig.site_country === "aus";
    },
    serviceIssuesDialogue: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("update:dialog", val);
      },
    },
  },
};
</script>

<style scoped>
.removeScrollbar {
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
}
</style>
