// call this get a properly formatted data value
function formatDataValue(value, dp = 0) {
  if (value === "-") {
    return "-";
  }

  // check if is a quintile default and return accordingly
  if (value === 1000000000000000 || value === -1000000000000000) {
    return "N/A";
  }

  function addCommas(nStr) {
    nStr += "";
    let x = nStr.split(".");
    let x1 = x[0];
    let x2 = x.length > 1 ? "." + x[1] : "";
    let rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, "$1" + "," + "$2");
    }
    return x1 + x2;
  }

  // if a null value is passed in just return it
  if (value == null) {
    return null;
  }

  return addCommas(parseFloat(value).toFixed(dp));
}

export { formatDataValue };
